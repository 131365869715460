<!-- SiteMap Component -->

<template>
  <div class="sitemap-page">
    <section aria-label="Sitemap">
      <div class="container text-center">
        <h1 class="text-white text-center">Sitemap</h1>
      </div>
    </section>
    <div class="container">
      <el-row>
        <el-col :md="24" :lg="6" :xl="6">
          <div class="terms-policies">
            <terms-policies></terms-policies>
          </div>
        </el-col>
        <el-col :md="24" :lg="18" :xl="18">
          <div class="site-map">
            <h2 class="title mt-4" id="main-content">{{siteMapText.title}}</h2>
          </div>
          <div class="site-map mb-5">
            <ul>
              <li>
                <router-link to="/home">
                  <p class="sub-title">Home</p>
                </router-link>
                <hr />
              </li>
              <li>
                <router-link to="/about">
                  <p class="sub-title">About Us</p>
                </router-link>
                <hr />
                <ul>
                  <li>
                    <router-link to="/membership">
                      <p class="sub-title sub-list">Membership</p>
                    </router-link>
                    <hr />
                  </li>
                  <li>
                    <router-link to="/scotland-house-brussels">
                      <p class="sub-title sub-list">Scotland House Brussels</p>
                    </router-link>
                    <hr />
                  </li>
                  <li>
                    <router-link to="/about">
                      <p class="sub-title sub-list">About our Organisation</p>
                    </router-link>
                    <hr />
                  </li>
                </ul>
              </li>
              <li>
                <router-link to="/news-resources">
                  <p class="sub-title">News & Resources</p>
                </router-link>
                <hr />
              </li>
              <li>
                <router-link to="/business-events-webinars">
                  <p class="sub-title">Events & Webinars</p>
                </router-link>
                <hr />
              </li>
              <li>
                <router-link to="/contact-us">
                  <p class="sub-title">Contact Us</p>
                </router-link>
                <hr />
              </li>
              <li>
                <router-link to="/sign-up-select">
                  <p class="sub-title">Join Scotland Europa</p>
                </router-link>
                <hr />
              </li>
              <li>
                <router-link to="/login">
                  <p class="sub-title">Login</p>
                </router-link>
                <hr />
              </li>
            </ul>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import TermsPolicies from "../../components/partials/terms-policies";
export default {
  name: 'site-map',
  metaInfo: {
    title: "Sitemap"
  },
  components: {
    TermsPolicies
  },
  data() {
    return {
      siteMapText: {
        title: "Site Map"
      }
    };
  }
};
</script>
<style lang="scss">
.site-map .title {
  font-family: "Lato", Helvetica Neue, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: $font-size-40;
  line-height: $line-height-60;
  color: black;
}
.sub-title {
  font-style: normal;
  font-weight: 400;
  font-size: $font-size-30;
  line-height: $line-height-42;
  color: black;
  padding-bottom: 10px;
  padding-top: 10px;
}
.sub-list {
  margin-left: 20%;
}
.text-white.banner-title {
  margin-left: 20%;
  font-weight: 400;
  margin-bottom: 30px;
}
.banner-sub {
  color: white;
  margin-left: 20%;
  width: 400px;
  font-size: $font-size-19;
  line-height: $line-height-28;
}
/*li {
  list-style: none;
}*/
</style>
