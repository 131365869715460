<template><svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
<g clip-path="url(#clip0)">
<path d="M11.5 4C11.5 2.066 16.648 0.5 22.5 0.5V17C16.648 17 11.5 18.566 11.5 20.5C11.5 18.566 6.351 17 0.5 17V0.5C6.351 0.5 11.5 2.066 11.5 4Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.5 4V20.375" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9 6.23999C6.90298 5.52095 4.71445 5.10345 2.5 5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9 9.23999C6.90298 8.52095 4.71445 8.10345 2.5 8" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9 12.24C6.90298 11.5209 4.71445 11.1035 2.5 11" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9 15.24C6.90298 14.5209 4.71445 14.1035 2.5 14" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14 12.24C16.097 11.5211 18.2856 11.1036 20.5 11" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14 15.24C16.097 14.5211 18.2856 14.1036 20.5 14" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M20.5 0.5V9L18.5 7L16.5 9V1" stroke="white" stroke-width="1.5" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0">
<rect width="23" height="21" fill="white"/>
</clipPath>
</defs>
</svg></template><script>export default {}</script>
