<!-- Privacy Component -->

<template>
  <div class="terms-page">
    <section aria-label="Privacy policy">
      <div class="container text-center">
        <h1 class="text-white">
          Privacy notice
        </h1>
      </div>
    </section>
    <div class="container">
      <el-row>
        <el-col :md="24" :lg="6" :xl="6">
          <div class="terms-policies">
            <terms-policies></terms-policies>
          </div>
        </el-col>
        <el-col :md="24" :lg="18" :xl="18">
          <div class="privacy-policy">
            <h2 id="main-content" class="sub-title">
              {{ privacyNotice.title }}
            </h2>
            <p>{{ privacyNotice.fisrtSub }}</p>
            <p>{{ privacyNotice.secondSub }}</p>
            <p>{{ privacyNotice.thirdSub }}</p>
            <p>{{ privacyNotice.fourthSub }}</p>
            <p v-html="privacyNotice.fifthSub"></p>
            <p>
              <a href="mailTo:enquiries@scotent.co.uk"
                >enquiries@scotent.co.uk</a
              >
            </p>
            <p>
              <a href="tel:0300 013 3385">0300 013 3385</a> - (calls charged at
              local rates) <br />
              <a href="tel:0141 846 0200">0141 846 0200</a> - from mobile phones
              or if outside Scotland
            </p>
            <p v-html="privacyNotice.sixthSub"></p>
            <p>{{ privacyNotice.seventhSub }}</p>
            <p>{{ privacyNotice.eighthSub }}</p>
          </div>
          <div class="privacy-policy">
            <h2 class="sub-title">
              {{ WhoThisPrivacyAppliedTo.title }}
            </h2>
            <p>{{ WhoThisPrivacyAppliedTo.firstSub }}</p>
            <ul>
              <li
                v-for="(point, index) in WhoThisPrivacyAppliedTo.bulletPoints"
                :key="index"
              >
                {{ point.text }}
              </li>
            </ul>
          </div>
          <div class="privacy-policy">
            <h2 class="sub-title">
              {{ howWeCollectYourInfos.title }}
            </h2>
            <p>{{ howWeCollectYourInfos.firstSub }}</p>
            <ul>
              <li
                v-for="(point, index) in howWeCollectYourInfos.bulletPoints"
                :key="index"
              >
                {{ point.text }}
              </li>
            </ul>
          </div>
          <div class="privacy-policy">
            <h2 class="sub-title">
              {{ lettingUsKnow.title }}
            </h2>
            <p>{{ lettingUsKnow.firstSub }}</p>
          </div>
          <div class="privacy-policy">
            <h2 class="sub-title">
              {{ personalInformationWeCollect.title }}
            </h2>
            <p v-html="personalInformationWeCollect.firstSub"></p>
            <p>{{ personalInformationWeCollect.secondSub }}</p>
            <ul>
              <li
                v-for="(
                  point, index
                ) in personalInformationWeCollect.bulletPoints"
                :key="index"
              >
                <span v-html="point.text"></span>
              </li>
            </ul>
          </div>
          <div class="privacy-policy">
            <h2 class="sub-title">
              {{ otherDataWeCollect.title }}
            </h2>
            <p>{{ otherDataWeCollect.firstSub }}</p>
            <p v-html="otherDataWeCollect.secondSub"></p>
            <p v-html="otherDataWeCollect.thirdSub "></p>
            <p v-html="otherDataWeCollect.fourthSub "></p>
          </div>
          <div class="privacy-policy">
            <h2 class="sub-title">
              {{ storageOfPersonalInfos.title }}
            </h2>
            <p>{{ storageOfPersonalInfos.firstSub }}</p>
            <p>{{ storageOfPersonalInfos.secondSub }}</p>
            <p>{{ storageOfPersonalInfos.thirdSub }}</p>
          </div>
          <div class="privacy-policy">
            <h2 class="sub-title">
              {{ basicForUsing.title }}
            </h2>
            <p>{{ basicForUsing.firstSub }}</p>
            <table>
              <thead>
                <tr>
                  <th>
                    <span class="font-weight-bold">Purpose/Activity</span>
                  </th>
                  <th><span class="font-weight-bold">Type of data </span></th>
                  <th>
                    <span class="font-weight-bold"
                      >Lawful basis for processing including basis of legitimate
                      interest
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in basicForUsing.table" :key="index">
                  <td>
                    <ul>
                      <li
                        v-for="(item, indexPurpose) in row.purposeActivity"
                        :key="indexPurpose"
                      >
                        {{ item }}
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li
                        v-for="(item, indexType) in row.typeOfData"
                        :key="indexType"
                      >
                        {{ item }}
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li
                        v-for="(item, indexBasis) in row.lawfulbasis"
                        :key="indexBasis"
                      >
                        {{ item }}
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="privacy-policy">
            <h2 class="sub-title">
              {{ sharingPersonalInfors.title }}
            </h2>
            <p>{{ sharingPersonalInfors.firstSub }}</p>
            <ul>
              <li
                v-for="(point, index) in sharingPersonalInfors.bulletPoints"
                :key="index"
              >
                {{ point.text }}
              </li>
            </ul>
            <p>{{ sharingPersonalInfors.secondSub }}</p>
          </div>
          <div class="privacy-policy">
            <h2 class="sub-title">
              {{ ifYouDontProvide.title }}
            </h2>
            <p>{{ ifYouDontProvide.firstSub }}</p>
          </div>
          <div class="privacy-policy">
            <h2 class="sub-title">
              {{ howLong.title }}
            </h2>
            <p>{{ howLong.firstSub }}</p>
          </div>
          <div class="privacy-policy">
            <h2 class="sub-title">
              {{ protectingYourData.title }}
            </h2>
            <p>{{ protectingYourData.firstSub }}</p>
            <p>{{ protectingYourData.secondSub }}</p>
          </div>
          <div class="privacy-policy">
            <h2 class="sub-title">
              {{ security.title }}
            </h2>
            <p>{{ security.firstSub }}</p>
          </div>
          <div class="privacy-policy">
            <h2 class="sub-title">
              {{ yourRights.title }}
            </h2>
            <p v-html="yourRights.firstSub"></p>
            <p v-html="yourRights.secondSub"></p>
            <p v-html="yourRights.thirdSub"></p>
            <ul>
              <li
                v-for="(point, index) in yourRights.bulletPoints"
                :key="index"
              >
                {{ point.text }}
              </li>
            </ul>
          </div>
          <div class="privacy-policy">
            <h2 class="sub-title">
              {{ objecting.title }}
            </h2>
            <p>{{ objecting.firstSub }}</p>
          </div>
          <div class="privacy-policy">
            <h2 class="sub-title">
              {{ withdrawing.title }}
            </h2>
            <p>{{ withdrawing.firstSub }}</p>
          </div>
          <div class="privacy-policy">
            <h2 class="sub-title">
              {{ usingTheseRights.title }}
            </h2>
            <p>{{ usingTheseRights.firstSub }}</p>
          </div>
          <div class="privacy-policy">
            <h2 class="sub-title">
              {{ usingTheseRights.title }}
            </h2>
            <p>{{ usingTheseRights.firstSub }}</p>
            <div class="font-weight-bold">
              {{ usingTheseRights.addressTitle }}<br />
            </div>
            <div v-html="usingTheseRights.address"></div>
            <br />
            <div class="font-weight-bold">
              {{ usingTheseRights.emailTitle }}
            </div>
            <div v-html="usingTheseRights.email"></div>
            <br />
            <div class="font-weight-bold">
              {{ usingTheseRights.phoneTitle }}
            </div>
            <ul>
              <li v-for="(point, index) in usingTheseRights.phone" :key="index">
                <span v-html="point.text"></span>
              </li>
            </ul>
          </div>
          <div class="privacy-policy">
            <h2 class="sub-title">
              {{ complaints.title }}
            </h2>
            <p>{{ complaints.firstSub }}</p>
            <div v-html="complaints.secondSub"></div>
            <p v-html="complaints.thirdSub"></p>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import TermsPolicies from "../../components/partials/terms-policies";
export default {
  name: "PrivacyPolicy",
  metaInfo: {
    title: "Privacy policy",
    meta: [
      {
        name: "description",
        content: "Read the privacy policy for the Scotland Europa website.",
      },
    ],
  },
  components: {
    TermsPolicies,
  },
  data() {
    return {
      privacyNotice: {
        title: "Our Privacy Notice",
        firstSub:
          "This is the privacy notice of Scotland Europa, a private limited company with its registered office at Atrium Court, 50 Waterloo Street, Glasgow, G2 6HQ",
        secondSub:
          "Scotland Europa is a membership-based organisation that promotes Scotland’s interests across the institutions of the European Union and to the representatives of Europe’s regions and Member States. Scotland Europa is a wholly owned subsidiary of Scottish Enterprise, Scotland’s national economic development agency. With experienced and specialised staff based in Brussels and Scotland, Scotland Europa fosters strong links between Scotland and Europe, providing intelligence, policy analysis and strategic funding advice",
        thirdSub:
          "For the purposes of UK data protection laws, Scotland Europa is the controller of all personal data processed Scottish Enterprise.",
        fourthSub:
          "However, the Scottish Enterprise Data Protection and Information Governance Officer (DPIGO) handles questions related to this privacy notice. If you have any questions about the privacy notice, please contact the Scottish Enterprise DPIGO at:",
        fifthSub: `Data Protection and Information Governance Officer<br>
        Atrium Court<br>
        50 Waterloo Street<br>
        Glasgow G2 6HQ<br>`,
        sixthSub: `If you're deaf or hearing impaired you can use your textphone to contact us through Relay
        UK, or you can search for Relay UK on the App Store for iPhone or iPad and at Google Play for
        Android.<br>
        The privacy notice will tell you how we collect, use, share and look after your personal
        information. This privacy notice will also tell you about your privacy rights and how the law
        protects you. In this privacy notice references to our website refers to <a href="https://www.scotlandeuropa.com" target="_blank">https://www.scotlandeuropa.com <span class="sr-only">this link will open in a new window</span><svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" class="ml-1">
                <path d="M6.5 3.69189H0.5V15.6919H12.5V9.72217" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3.5 12.707L15.5 0.707031" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.5 0.707031H15.5V6.70703" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                </svg></a>`,
        seventhSub:
          "This is the current version of our privacy notice. It was last updated in August 2022",
        eighthSub: `It’s important that you read this privacy notice together with any other privacy notices or fair
        processing notices from our parent organisation, Scottish Enterprise, as well as the
        organisations who are members of Scotland Europa. This privacy notice adds to the other
        notices and is not intended to replace them.`,
      },
      WhoThisPrivacyAppliedTo: {
        title: "Who this Privacy Notice applies to",
        firstSub: "The privacy notice applies when you:",
        bulletPoints: [
          { text: "visit our website or" },
          {
            text: "provide us with your personal information to administer your or your organisation’s membership or",
          },
          { text: "otherwise contact us." },
        ],
      },
      howWeCollectYourInfos: {
        title: "How we collect your information",
        firstSub:
          "We will collect information about you from the following sources:",
        bulletPoints: [
          {
            text: "directly from you for example, when you apply to have a user account as part of your organisation’s membership of Scotland Europa, when you use the service, or submit an enquiry to us;",
          },
          {
            text: "indirectly from people who provide it to us (for example, your employer or third-party service providers that we use to help operate our organisation); ",
          },
          {
            text: "automatically as you interact with our website and our networking platform, we automatically collect technical data about your equipment, browsing actions and patterns;",
          },
          {
            text: "publicly available resources; orthe internet and social networking sites, such as LinkedIn or information provided on your organisation's website.",
          },
        ],
      },
      lettingUsKnow: {
        title: "Letting us know about changes to your personal information",
        firstSub:
          "It’s important that the personal information that we hold about you is accurate and current. Please let us know if your personal information changes during your relationship with us. You can do this by contacting us using the details provided.",
      },
      personalInformationWeCollect: {
        title: "Personal information we collect",
        firstSub: `By <b>‘personal information’</b> or <b>‘personal data’</b>, we mean any information about you that could
        be used to identify you Anything we do with your personal information is called processing,
        and it applies from the moment we collect your data to the moment we delete it from our
        systems.`,
        secondSub: `We collect, use, store and share personal information about you. To help you identify the data,
        we’ve grouped them together as follows.`,
        bulletPoints: [
          {
            text: `<b>Identity data</b>. This includes first name, , last name, username or similar identifier,
            images of you , title, `,
          },
          {
            text: `<b>Contact data</b>. This includes your email address, address, postcode, company name,
            company address, town, country, telephone, social media profiles and contacts.`,
          },
          {
            text: `<b>Technical data</b>. This includes your Internet protocol (IP) address, your login data,
            browser type and version, time zone setting and location, browser plug-in types and
            versions, operating system and platform and other technology on the devices you use
            to access this website.`,
          },
          {
            text: `<b>Usage data</b>. This includes information about how you use our website, products, and services.`,
          },
          {
            text: `<b>Marketing data</b>. This includes your preferences related to receiving marketing from us.`,
          },
        ],
      },
      otherDataWeCollect: {
        title: "Other data we collect",
        firstSub:
          "We also collect, use, store and share other data that is not personal information.",
        secondSub: `<b>Aggregated data</b>. This is where personal information is combined to create statistics or
        demographic data. We collect, use, and share this data for any purpose. For example, we may
        combine usage data to calculate the percentage of users accessing the site from different
        locations. Even though it comes from personal data, it’s not considered personal information
        in law because it does not directly or indirectly reveal your identity. `,
        thirdSub: `However, if we combine or connect aggregated data with your personal information in a way
        that can identify you, we treat the combined data as personal information. We’ll then use this
        data in line with this privacy notice`,
        fourthSub: `<b>Cookies</b>. We use third-party analytics session cookies to report on how people use the website.
        This helps us track website performance and improve the experience for users. This data is
        collected by external services like Google Analytics. For more information, read <a href="/cookie-policy"> our cookie policy </a>`,
      },
      storageOfPersonalInfos: {
        title: "Storage of your personal information",
        firstSub: `All information you provide to us is stored on our secure servers. It should be noted that, as
        Scotland Europa is based in Belgium, any data provided will be stored securely outside the
        United Kingdom and within the European Union.`,
        secondSub: `Where we have given you (or where you have chosen) a password which enables you to access
        certain parts of our site, you are responsible for keeping this password confidential.`,
        thirdSub: `Unfortunately, the transmission of information via the internet is not completely secure.
        Although we will do our best to protect your information, we cannot guarantee the security of
        your data transmitted to our site; any transmission is at your own risk. Once we have received
        your personal information, we will use strict procedures and security features to try to prevent
        unauthorised access.`,
      },
      basicForUsing: {
        title: `Basis for using your personal information`,
        firstSub: `The table below sets out our legal grounds and reasons for processing your personal
        information. Where we rely on legitimate interests for processing, the table below explains
        the relevant legitimate interest.`,
        table: [
          {
            purposeActivity: [
              "To consider your application as a Scotland Europa and/or Scotland House Brussels member",
            ],
            typeOfData: ["Contact", "Identity"],
            lawfulbasis: [
              "Performance of a contract with you.",
              "Consent",
              "Necessary for our legitimate interests (to keep records updated and to process new applications)",
            ],
          },
          {
            purposeActivity: [
              "For events, engagements, and other operations where we work as Scotland House Brussels, we will share necessary biographical information and contact data with our Scottish Government colleagues",
            ],
            typeOfData: ["Identity", "Contact"],
            lawfulbasis: [
              "Consent",
              "Necessary for our legitimate interests (to study how customers use our products/services, to develop them and grow our organisation)",
            ],
          },
          {
            purposeActivity: [
              "To create and publish a profile on the Scotland Europa website",
            ],
            typeOfData: ["Identity", "Contact", "Marketing and Communications"],
            lawfulbasis: ["Performance of a contract with you", "Consent"],
          },
          {
            purposeActivity: [
              "To log any activity against your record, relating to you in your role as a Scotland Europa and/or Scotland House Brussels member (for example, any support you have provided at events)",
            ],
            typeOfData: [
              "Identity",
              "Contact",
              "Usage",
              "Marketing",
              "Technical",
            ],
            lawfulbasis: [
              "Necessary for our legitimate interests (to keep our records updated)",
            ],
          },
          {
            purposeActivity: [
              "To provide you with information or communications relating to Scotland Europa/Scotland House Brussels activities/events or marketing",
            ],
            typeOfData: ["Identity", "Contact", "Marketing"],
            lawfulbasis: [
              "Consent",
              "Performance of task carried out in the public interest or in the exercise of our official authority",
              "Necessary for our legitimate interests (to meet the objectives of the organisation)",
            ],
          },
          {
            purposeActivity: [
              "To provide you with information or communications relating to any changes to Scotland Europa membership services",
            ],
            typeOfData: ["Identity", "Contact"],
            lawfulbasis: ["Performance of a contract with you"],
          },
          {
            purposeActivity: [
              "To allow other Scotland Europa members to search and contact you",
            ],
            typeOfData: ["Identity", "Contact"],
            lawfulbasis: [
              "Performance of a contract with you",
              "Necessary for our legitimate interests (to meet the objectives of the organisation)",
            ],
          },
          {
            purposeActivity: [
              "To maintain our records and ensure information is up to date",
            ],
            typeOfData: [
              "Identity",
              "Contact",
              "Technical",
              "Usage",
              "Marketing",
            ],
            lawfulbasis: [
              "Necessary for our legitimate interests (to keep our records updated and for security purposes)",
            ],
          },
          {
            purposeActivity: ["To answer your enquiries"],
            typeOfData: ["Identity", "Contact"],
            lawfulbasis: ["Performance of a contract with you"],
          },
          {
            purposeActivity: [
              "To create and publish podcasts and other recorded material and use your image and/or biography alongside this (subject to approval by Scotland Europa administrators).",
            ],
            typeOfData: ["Identity", "Contact"],
            lawfulbasis: ["Consent"],
          },
          {
            purposeActivity: [
              "To enable you to take part in online conversations through chats and/or other such methods",
            ],
            typeOfData: ["Identity", "Contact"],
            lawfulbasis: ["Performance of a contract with you"],
          },
          {
            purposeActivity: [
              "To participate in calls with team members (such as relationship managers and engagement partners) on how you might support Scottish companies and to maintain key relationships.",
            ],
            typeOfData: ["Identity", "Contact", "Usage", "Marketing"],
            lawfulbasis: [
              "Necessary for our legitimate interests (to study how our users interact with the organisation, to develop our organisation and to inform our operational strategy)",
            ],
          },
          {
            purposeActivity: [
              "To collate and provide Scottish Enterprise with statistical information on Scotland Europa membership activity levels",
            ],
            typeOfData: ["Technical", "Usage"],
            lawfulbasis: [
              "Necessary for our legitimate interests (to study how users interact with the organisation and to continue to develop and improve our membership offerings)",
            ],
          },
        ],
      },
      sharingPersonalInfors: {
        title: "Sharing your personal information with third parties",
        firstSub:
          "We may share your personal information with the following third parties if this is required by law; necessary to enter a contract with you; where we have a legitimate interest in doing so; or where it is necessary to protect your vital interests or those of another person:",
        secondSub:
          "We require all third parties to respect the security of your personal information and to treat it in accordance with the law. We do not allow our third-party service providers to use your personal information for their own purposes and only permit them to process your personal information for specified purposes and in accordance with our instructions.",
        bulletPoints: [
          {
            text: "Affiliates: We share your personal information with Scottish Enterprise, Scottish Government and other Scotland Europa member organisations for internal administrative 7 purposes and uses that are consistent with this notice such as event organisation, promotion and marketing and coordination of such activities between partners noted above",
          },
          {
            text: "Service Providers: We may share your personal information with third-party service providers who perform services on our behalf or for your benefit, such as for payment processing, marketing and analytics .",
          },
          {
            text: "Professional advisors: We may share your personal information with our professional advisors, including our legal and accountancy service providers.",
          },
          {
            text: "Business transfers: Your personal information may be disclosed as part of a corporate business transaction, such as a merger, acquisition, joint venture, or financing or sale of company assets, and could be transferred to a third party as one of the business assets in such a transaction. It may also be disclosed in the event of insolvency, bankruptcy or receivership.",
          },
          { text: "Other third parties as necessary to comply with the law." },
        ],
      },
      ifYouDontProvide: {
        title: "If you don’t provide personal information",
        firstSub:
          "If you apply access Scotland Europa membership services and don’t provide your personal information, your application will be denied, and you will not be granted access to our membership services.",
      },
      howLong: {
        title: "How long we’ll keep your personal information",
        firstSub:
          "If the organisation you represent leaves Scotland Europa, your personal information, including any direct messages or comments left in forums, will be removed from scotlandeuropa.com with immediate effect. We may retain some of your personal information for our records for a maximum of 12 months. If you do not want us to retain your personal information for our records, you can tell us when you leave the network.",
      },
      protectingYourData: {
        title: "Protecting your data outside the UK",
        firstSub:
          "Scotland Europa works with Scottish Government and Scottish Development International (SDI), Scottish Enterprise’s overseas arm. We might share your personal information with SDI and Scottish Government field offices worldwide. This may involve transferring your personal data outside the European Economic Area (EEA). Where your personal information is transferred outside the EEA, we ensure a similar degree of protection is afforded to it by ensuring at least one of the following safeguards is implemented:",
        secondSub:
          "We will only transfer your personal information to countries that have been deemed to provide adequate level of protection for personal data by the European Commission. 8 Where we use certain service providers, we may use specific contracts approved by the European Commission which give personal information the same protection it has in Europe.",
      },
      security: {
        title: "Security",
        firstSub:
          "We’ve put security measures in place to prevent your personal information from being lost, changed, revealed, or used in an unauthorised way. Our employees, agents and suppliers will only process personal information if they’re asked to by us. They’ll always follow the privacy policy and they’re also subject to a duty of confidentiality.",
      },
      yourRights: {
        title: "Your Rights",
        firstSub:
          "<b>Access to your information</b>. You have the right to ask for a copy of the personal information about you that we hold.",
        secondSub:
          "<b>Correcting your information</b>. We want to make sure that your personal information is correct, complete, and up to date. You can ask us to correct any personal information about you that you don’t think meets these standards.",
        thirdSub:
          "<b>Deletion of your information</b>. You have the right to ask us to delete personal information about you if:",
        bulletPoints: [
          {
            text: "You think we no longer need the information for the purposes we originally asked for it",
          },
          {
            text: "We’re using information with your consent, and you have withdrawn your consent – see 'Withdrawing consent to using your information'",
          },
          {
            text: "You have objected to Scotland Europa’s use of your personal information for a valid reason – see ‘Objecting to how we may use your information’",
          },
          {
            text: "Our use of your personal information is against the law or other legal obligations.",
          },
        ],
      },
      objecting: {
        title: "Objecting to how we may use your information",
        firstSub:
          "You have the right to ask us to stop using your personal information at any time. All such requests are unique and will be subject to review and decision making will be based on the impact of the request on the site and the service. If you ask, we’ll stop using that personal information unless there are genuine grounds to continue. We’ll let you know if that is the case.",
      },
      withdrawing: {
        title: "Withdrawing consent using your information",
        firstSub:
          "Where we use your personal information with your consent, you may withdraw that consent at any time. We’ll stop using your personal information for the purpose(s) for which consent was given.",
      },
      usingTheseRights: {
        title: "Using these rights",
        firstSub:
          "If you wish to use any of these rights, get in touch with Scotland Europa using the details below.",
        addressTitle: "Address:",
        address:
          "Scotland Europa<br>Scotland House Brussels<br>RondPoint Schuman 6<br>1040<br>Brussels<br>Belgium<br>",
        emailTitle: "Email address:",
        email: "<a href='mailto:information.desk@scotent.co.uk'>information.desk@scotent.co.uk</a>",
        phoneTitle: "Phone number:",
        phone: [
          {
            text: "<a href='tel:+322 282 83 304'>+32 2 282 83 304</a> (calls charged at local rates)",
          },
          {
            text: "We have appointed a Data Protection Officer (DPO) who is responsible for overseeing questions in relation to this privacy notice. If you have any questions about the privacy notice, including any requests to exercise your legal rights in respect of your personal information, please contact Scottish Enterprise directly at the following email address: <a href='mailTo:enquiries@scotent.co.uk'>enquiries@scotent.co.uk</a>",
          },
        ],
      },
      complaints: {
        title: "Complaints",
        firstSub:
          "We wish to directly resolve all complaints about how we handle personal information, but you also have the right to lodge a complaint with the Information Commissioner's Office (ICO), whose contact details are as follows:",
        secondSub: `Information Commissioner's Office<br>
        Wycliffe House<br>
        Water Lane<br>
        Wilmslow<br>
        Cheshire<br>
        SK9 5AF<br>
        Telephone: <a href="tel:0303 123 1113">0303 123 1113</a> (local rate) or <a href="tel:01625 545 745">01625 545 745</a>`,
        thirdSub:
          `<a href='https://ico.org.uk/concerns' target="_blank">Complaint guidance on the ICO website <span class="sr-only">this link will open in a new window</span><svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" class="ml-1">
                <path d="M6.5 3.69189H0.5V15.6919H12.5V9.72217" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3.5 12.707L15.5 0.707031" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.5 0.707031H15.5V6.70703" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                </svg></a>`,
      },
    };
  },
};
</script>
<style lang="scss">
.privacy-policy {
  .sub-title {
    font-style: normal;
    font-weight: 500;
    font-size: $font-size-30;
    line-height: $line-height-42;
    color: black;
    padding-bottom: 10px;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: $font-size-19;
    line-height: $line-height-28;
    padding-bottom: 15px;
  }
  li{
    margin-bottom: 0.5rem;
  }
  td{
    vertical-align:top
  }
}
</style>
