<template>
  <el-col :sm="1" :xl="1" :xs="1" :class="classes">
    <el-dropdown
      class="activity-menu-hover"
      :class="{ 'activity-hover-grey-background': listActionShown }"
      trigger="click"
      @visible-change="getListActionStatus"
    >
      <span class="el-dropdown-link">
        <span class="sr-only">delete activity</span>
        <svg width="28" height="28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="7" cy="14" r="2" fill="#585857"/>
          <circle cx="14" cy="14" r="2" fill="#585857"/>
          <circle cx="21" cy="14" r="2" fill="#585857"/>
        </svg>
      </span>
      <el-dropdown-menu slot="dropdown" class="only-one-child no-hover">
        <el-dropdown-item @click.native="$emit('delete'), listActionShown = false" class="activity-dropdown-item">Delete activity</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </el-col>
</template>

<script>
export default {
  name: 'ActivityMenuDropdown',
  props: {
    classes: {
      type: String,
      default: '',
    }
  },
  data () {
    return {
      listActionShown: false,
    }
  },
  methods: {
    getListActionStatus (value) {
      this.listActionShown = value
    }
  },
}
</script>
