<template>
  <el-dialog
    :show-close="true"
    :visible.sync="dialogVisible"
    custom-class="multi-factor-dialog new-ui"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onClose()"
  >
    <div class="body">
      <h2 class="mb-2">
        {{ step1 ? "Text Message" : "Enter Code" }}
      </h2>
      <p v-if="step2">
        A text message with a 6-digit verification code was sent to the mobile
        ending in ***{{
          phoneForm.phoneNumber ? phoneForm.phoneNumber.slice(-3) : ""
        }}
      </p>
      <el-row v-if="step1">
        <el-col>
          <p class="mb-3">
            <!-- {{
              !hasMultiFactorEnabled
                ? "Multi-Factor authentication is pending verification. "
                : ""
            }} -->
            Before we add this phone number to your account, we need to check that
            it can receive verification codes
          </p>
        </el-col>
        <el-col>
          <p class="small w-100 text-left">
            Phone Number
          </p>
          <div class="d-flex">
            <el-select
              v-model="phoneForm.prefix"
              filterable
              class="mr-1"
              placeholder=""
              aria-required="true"
            >
              <el-option
                v-for="(item, index) in availablePhonePrefixes"
                :key="`${item.code}_${index}`"
                :value="item.dial_code"
              >
                <span>
                  {{ item.name }}
                  <i
                    class="fi mr-1"
                    :class="`fi-${item.code.toLowerCase()}`"
                  ></i>
                  {{ item.dial_code }}
                </span>
              </el-option>
            </el-select>
            <el-input
              ref="phoneInput"
              v-model="phoneForm.phoneNumber"
              class="ml-1"
              aria-required="true"
              auto-complete="off"
              type="number"
            ></el-input>
          </div>
        </el-col>
        <el-col class="d-flex mt-4 w-100 justify-content-center">
          <el-button
            :disabled="codeLoading"
            class="mb-2 w-50 mx-0 mr-1"
            @click="onClose()"
          >
            Cancel
          </el-button>
          <el-button
            :disabled="codeLoading"
            :loading="codeLoading"
            type="primary"
            class="mb-2 w-50 ml-1 mr-0"
            @click="sendCode()"
          >
            Send Code
          </el-button>
        </el-col>
      </el-row>
      <el-row
        v-if="step2"
        class="w-100"
      >
        <el-form
          id="phoneAuthenticatorForm"
          ref="phoneAuthenticatorForm"
          v-loading="codeLoading"
          :model="form"
          autocomplete="false"
          :rules="rules"
          class="mt-0"
          @submit.native.prevent
        >
          <el-form-item
            prop="code"
            class="is-no-asterisk mt-2 mb-0"
            :error="verificationCodeError"
          >
            <template slot="label">
              <slot name="textarea-label">
                Verification Code
              </slot>
              <br />
              <span
                class="sr-only"
                aria-live="polite"
                :hidden="!hasInlineValidationErrorFor('code')"
                v-text="getInlineValidationErrorFor('code')"
              ></span>
            </template>
            <el-input
              id="code"
              v-model="form.code"
              name="verification code"
              placeholder="verification code"
              :max-length="6"
              aria-required="true"
            ></el-input>
          </el-form-item>
          <div class="d-flex mt-4 w-100 justify-content-center">
            <el-button
              class="mt-3 mb-3 submit-btn mx-auto"
              @click="sendCode"
            >
              Send again
            </el-button>
            <el-button
              class="mt-3 mb-3 submit-btn mx-auto"
              type="primary"
              @click="submitConfirmation"
            >
              Submit
            </el-button>
          </div>
        </el-form>
      </el-row>
    </div>
  </el-dialog>
</template>
<script>
import "/node_modules/flag-icons/css/flag-icons.min.css";
import gsApi from "@/services/gs.api";
import inlineValidationErrorsMixin from "@/mixins/inlineValidationErrorsMixin";
import phonePrefixes from "@/helpers/phone.json";
import enumHelper from "@/helpers/enumHelper";
export default {
  mixins: [inlineValidationErrorsMixin],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    hasMultiFactorEnabled: {
      type: Boolean,
      default: false,
    },
    contactNumber: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dialogVisible: false,
      step1: true,
      step2: false,
      phoneForm: {
        prefix: "+44",
        phoneNumber: this.contactNumber,
      },
      form: {
        code: null,
        enable: true,
        two_factor_type: enumHelper.MFAType.PHONE,
      },
      availablePhonePrefixes: phonePrefixes,

      codeLoading: false,
      verificationCodeError: null,
      rules: {
        code: [
          {
            required: true,
            message: "Verification code is required",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    visible() {
      this.dialogVisible = this.visible;
    },
  },
  created() {
    this.dialogVisible = this.visible;
    
  },
  mounted(){    
    this.$nextTick(() => {
      this.$refs.phoneInput.focus();
    });
    
  },
  methods: {
    onClose() {
      this.dialogVisible = false;
      this.$emit("closed");
    },
    async sendCode() {
      this.codeLoading = true;
      // Enable Two Factor Phone Authentication
      const codeReponse = await gsApi.mfa
        .multiFactorAuthentification({
          two_factor_method: `${this.phoneForm.prefix}${this.phoneForm.phoneNumber}`,
          two_factor_type: enumHelper.MFAType.PHONE,
        })
        .catch((error) => {
          this.codeLoading = false;

          this.$alert(error.response.data.error.message, "", {
            confirmButtonText: "Try again",
            customClass: "notification-modal message-modal",
          });

          return false;
        });

      if (!codeReponse) return;

      this.step1 = false;
      this.step2 = true;

      this.codeLoading = false;
    },
    submitConfirmation() {
      this.$refs.phoneAuthenticatorForm.validate(async (valid) => {
        if (valid) {
          this.codeLoading = true;

          const twoFactorConfirmedResponse = await gsApi.mfa
            .confirmedMultiFactorPhoneAuthentication(this.form)
            .catch((error) => {
              //console.log(error?.response?.data?.error?.message);
              this.verificationCodeError =
                error?.response?.data?.error?.message;

              return false;
            });

          if (!twoFactorConfirmedResponse) {
            this.codeLoading = false;
            return;
          }

          this.$emit("refresh-user-multifactor-methods");

          this.verificationCodeError = null;
          // refresh the user data to get all available methods
          this.onClose();

          this.$alert(
            `<i class="el-icon el-icon-circle-check"></i>
                <p class='title mb-5 mt-2'>Multi-factor authentication enabled</p>
                <p class='method-name mb-2' >TEXT MESSAGE</p>
                <p class='message'>Verification has successfully been added to your account</p>
                `,
            "",
            {
              dangerouslyUseHTMLString: true,
              confirmButtonText: "Close",
              showClose: true,
              customClass: "notification-modal message-modal",
            }
          );
        }
      });
    },
  },
};
</script>
