var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"header-white hidden-lg-and-up"},[_c('div',{staticClass:"platform-navbar mobile-nav hidden-lg-and-up container"},[_c('el-row',[_c('el-col',{attrs:{"md":4,"sm":5,"xs":1}},[_c('a',{staticClass:"burger",attrs:{"href":"javascript:void(0)"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$emit('onOpenMobileMenuLanding')},"click":function($event){return _vm.$emit('onOpenMobileMenuLanding')}}},[_c('svg-collapse'),_c('span',{staticClass:"sr-only"},[_vm._v("Main menu")])],1)]),_c('el-col',{staticClass:"block-logo text-center",attrs:{"md":16,"sm":14,"xs":24}},[_c('router-link',{staticClass:"navbar-brand",attrs:{"to":"/"}},[_c('div',{staticClass:"site-logo-2"},[_c('svg-logo-small')],1)])],1)],1)],1)]),_c('div',{staticClass:"l-header-2 hidden-md-and-down",attrs:{"id":"header","role":"banner"}},[_c('div',{staticClass:"container l-header-2__content"},[_c('router-link',{attrs:{"to":{ name: 'Home' },"tabindex":"0"}},[_c('div',{staticClass:"site-logo-2"},[_c('svg-logo'),_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm.user.isLogged ? "Back to Feed" : "Back to Home"))])],1)]),_c('div',{staticClass:"l-header-2__buttons",attrs:{"role":"navigation"}},[(
            !_vm.user.isLogged &&
              !_vm.userRequiresVerification &&
              _vm.$route.path !== '/sign-up-select' &&
              _vm.$route.name !== 'NotFound' &&
              _vm.$route.name !== 'Reset Password'
          )?_c('router-link',{staticClass:"el-button el-button--primary el-button--medium join-home",attrs:{"to":"/sign-up-select","tabindex":"0"}},[_vm._v(" Join Scotland Europa ")]):_vm._e(),(
            !_vm.user.isLogged &&
              !_vm.userRequiresVerification &&
              _vm.$route.path !== '/login' &&
              _vm.$route.name !== 'NotFound' &&
              _vm.$route.name !== 'Reset Password'
          )?_c('router-link',{staticClass:"el-button el-button--medium login-home",attrs:{"to":"/login","tabindex":"0"}},[_vm._v(" Sign in ")]):_vm._e(),(
            (_vm.user.isLogged || _vm.userRequiresVerification) &&
              _vm.$route.name !== 'Reset Password'
          )?_c('el-button',{staticClass:"el-button el-button--medium",attrs:{"tabindex":"0"},on:{"click":function($event){return _vm.logout($event)}}},[_vm._v(" Logout ")]):_vm._e()],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }