<template>
  <div class="pulse-feed news-and-feed white-background-section recent-pulse">
    <div class="container">
      <div class="heading-text mb-20 d-flex justify-content-between">
        <h2>Latest from the community feed</h2>
        <h2 class="view-pulse-feed view-page-link hidden-sm-and-down">
          <router-link :to="{ name: 'Pulse' }">
            Go to community feed
            <svg-purple-arrow-right class="arrow"></svg-purple-arrow-right>
          </router-link>
        </h2>
      </div>
      <div>
        <div class="results-section">
          <div class="mt-0">
            <el-row v-if="posts.length > 0">
              <questions-card :posts="posts" />
            </el-row>
            <el-row v-else>
              <el-col>
                <div class="no-event-text">
                  <h1>No community posts are available yet</h1>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <h2 class="view-pulse-feed view-page-link hidden-md-and-up mt-3 mb-4">
        <router-link :to="{ name: 'Pulse' }">
          Go to community feed
          <svg-purple-arrow-right class="arrow"></svg-purple-arrow-right>
        </router-link>
      </h2>
    </div>
  </div>
</template>

<script>
import PurpleArrowRight from "@/components/assets/svg-purple-arrow-right";
import GradientChat from "@/components/assets/svg-gradient-chat";
import QuestionsCard from "@/components/platform/pulse/questions-card";
export default {
  components: {
    "svg-purple-arrow-right": PurpleArrowRight,
    "svg-gradient-chat": GradientChat,
    QuestionsCard
  },
  props: ['posts'],
  data() {
    return {};
  },
  methods: {
    clickBtn() {
    }
  }
};
</script>
<style>
</style>
