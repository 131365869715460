<template>
  <div>
    <el-form
      id="form"
      :model="filter"
      class="follower-following-search-form"
      ref="submitForm"
      @submit.native.prevent
    >
      <el-form-item
        label="Keyword search"
        prop="keyword"
      >
        <el-input
          v-model="filter.keyword"
          @input="onSearch"
          placeholder="Search for name"
          auto-complete="off"
          maxlength="255"
        >
        </el-input>
      </el-form-item>
    </el-form>
    <div
      v-loading="mainLoader"
      element-loading-background="rgba(255, 255, 255, 1)"
    >
      <follower-following-item
        v-for="(item, index) in listFollowers"
        :key="index"
        :item="item"
      ></follower-following-item>

      <div class="followers-pagination-help-section">
        <div class="center-btn">
          <el-button class="load-more-btn" @click="fetch" v-if="!onLastPage"
            >Load more</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import gsApi from "@/services/gs.api";
import FollowerFollowingItem from "@/components/platform/edit-profile/follower-following-item";
import { mapGetters } from "vuex";
export default {
  components: {
    FollowerFollowingItem
  },
  computed: {
    ...mapGetters(["user"])
  },
  data() {
    return {
      onLastPage: true,
      mainLoader: false,
      listFollowers: [],
      filter: {
        keyword: ""
      },
      pagination: {
        page: 1,
        per_page: 5
      }
    };
  },
  methods: {
    fetch() {
      this.mainLoader = true;
      const data = {
        user_id: this.user.id,
        search: this.filter.keyword ? this.filter.keyword : null,
        page : this.pagination.page,
        per_page : this.pagination.per_page,
      };
      gsApi.followable
        .followers(data)
        .then(response => {
          if (this.pagination.page == 1) {
            this.listFollowers = response.data.data;
          } else {
            this.listFollowers.push(...response.data.data);
          }

          if (
            response.data.meta.pagination.current_page !==
            response.data.meta.pagination.total_pages
          ) {
            this.onLastPage = false;
            this.pagination.page++;
          } else {
            this.onLastPage = true;
          }
        })
        .catch(response => {
          if (response.status == 422) {
            let firstError =
            response.data.errors[Object.keys(response.data.errors)[0]]
            this.$alert(firstError[0], 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
          } else {
            this.$alert('Sorry, your request could not be processed', `Error ${error.response.data.error.status_code} - ${error.response.data.error.message}`, { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
          }
        })
        .finally(() => {
          this.mainLoader = false;
        });
    },
    onSearch(event) {
      this.search(event, this.fetch());
    },
    search: _.debounce((event, callback) => {
      callback;
    }, 300)
  },
  created() {
    this.fetch();
  }
};
</script>
