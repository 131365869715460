<template>
    <div class="verify-email">
        <div class="red-banner">
            <h1>VERIFY YOUR EMAIL</h1>
        </div>
        <div class="verify-block">
            <div class="email-icon">
                <svg-app-email></svg-app-email>
                <div class="notifications-count" v-if="notifications_count > 0">{{ notifications_count }}</div>
            </div>
            <h2>CHECK YOUR INBOX</h2>
            <p>
                An email has been sent to <span>{{ email }}</span>, please click the link provided to verify your address
            </p>
            <p class="wrong-email-txt">
                Wrong email address?
            </p>
            <el-row type="flex" justify="center">
                <el-col :xl="17" :lg="17" :md="18" :sm="24" :xs="24">
                    <el-form :model="resendForm" :rules="rules" ref="resendForm">
                        <el-row :gutter="20" class="d-flex">
                            <el-col :xl="14" :lg="18" :md="18" :sm="24">
                                <el-form-item label="Email address" prop="email">
                                    <el-input type="email" id="email" placeholder="Your email address" v-model="resendForm.email"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :xl="10" :lg="6" :md="6" :sm="24" class="resend-btn">
                                <el-button type="button" id="submit" @click="submitForm('resendForm')" :loading="isLoading">resend</el-button>
                            </el-col>
                        </el-row>
                    </el-form>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
    import store from '../../store'
    import gsApi from '@/services/gs.api'
    import Email from '../../components/assets/svg-blue-email'
    import {validate} from 'email-validator'

    export default {
        name: 'verify-email',
        metaInfo: {
          title: "Verify email"
        },
        components: {
            'svg-app-email': Email
        },
        props: ['email', 'id'],
        created() {
            if (store.state.isLogged) {
                this.$router.push('/feed')
            }
            if (this.email == null || this.id == null) {
                this.$router.push('/home')
            }
        },
        data() {
            let validateEmail = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('Please input your email'));
                } else if (validate(value) == false) {
                    callback(new Error('Please enter a valid email address'));
                } else {
                    callback();
                }
            };
            return {
                notifications_count: 1,
                resendForm: {
                    email :''
                },
                rules: {
                    email: [
                        { validator: validateEmail, required: true }
                    ]
                },
                isLoading: false
            }
        },
        methods: {
            submitForm(formName) {
                this.isLoading = true;
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        gsApi.auth.resendVerification(this.id, this.resendForm.email)
                        .then((response) => {
                            this.isLoading = false;
                            Message.success(response.data.message)
                            this.$router.push("/login");
                        }).catch(() => {
                            this.isLoading = false;
                            this.$alert('Sorry, your request could not be processed', `The email has already been taken.`, { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
                        })
                    } else {
                        this.isLoading = false;
                        return false;
                    }
                });
            }
        },
    }
</script>
