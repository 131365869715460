<template>
  <focus-lock>
    <el-dialog ref="closeAccountDialog" @opened="dialogOpen('closeAccountDialog')"
      :show-close="false"
      :visible.sync="dialogVisible"
      custom-class="closed-account-dialog"
      @close="onClose()"
      title="Account Closed. It looks like this account has been temporarily closed by an Admin.
          Please Contact us for more information"
    >
      <div class="body">
        <svg-danger-exclamation />
        <h1>Account Closed</h1>
        <p>
          It looks like this account has been temporarily closed by an Admin.
          Please Contact us for more information
        </p>

        <div class="button-wrap d-flex">
          <el-button @click="onClose()">
            OK
          </el-button>
        </div>
      </div>
    </el-dialog>
  </focus-lock>
</template>

<script>
import svgDangerExclamation from "@/components/assets/svg-danger-exclamation";

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogVisible: false
    };
  },
  components: {
    svgDangerExclamation
  },

  methods: {
    onClose() {
      this.dialogVisible = false;
      this.$emit("closed");
    }
  },
  watch: {
    visible(value) {
      this.dialogVisible = this.visible;
    }
  },
  created() {
    this.dialogVisible = this.visible;
  }
};
</script>
