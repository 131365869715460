<template>
  <focus-lock>
    <el-dialog ref="suspendAccountDialog" @opened="dialogOpen('suspendAccountDialog')" aria-describedby="dialogDescription"
    :show-close="false"
      :visible.sync="dialogVisible"
      custom-class="closed-account-dialog"
      v-loading.fullscreen.lock="mainLoader"
      element-loading-background="rgba(255, 255, 255, 0.8)"
      @close="onClose($event)"
      title="Are you sure? This will temporarily suspend your account and make you invisible to the network. You can reactivate your account by logging in."
    >
      <div class="body">
        <svg-danger-exclamation />
        <h1>Are you sure?</h1>
        <p>
          This will temporarily suspend your account and make you invisible to
          the network. You can reactivate your account by logging in.
        </p>

        <div class="button-wrap d-flex">
          <el-button @click="onClose($event)">
            Go, back
          </el-button>
          <el-button
            type="primary"
            @click.prevent="suspendAccount()"
            :loading="mainLoader"
          >
            Yes, suspend my account
          </el-button>
        </div>
      </div>
    </el-dialog>
  </focus-lock>
</template>

<script>
import store from "@/store";
import gsApi from "@/services/gs.api";
import svgDangerExclamation from "@/components/assets/svg-danger-exclamation";

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      mainLoader: false,
      dialogVisible: false
    };
  },
  components: {
    svgDangerExclamation
  },

  methods: {
    onClose(e) {
      this.dialogVisible = false;
      this.$emit("closed");
    },

    suspendAccount() {
      this.mainLoader = true;
      gsApi.auth
        .markAsSuspended()
        .then(response => {
          store.commit("USER_LOGOUT");
          this.$router.push("/login");
        })
        .catch(error => {
          this.$alert(
            "",
            `Unfortunately we're having problems contacting our service. Please try again later.`,
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal"
            }
          );
        })
        .finally(() => {
          this.mainLoader = false;
        });
    }
  },
  watch: {
    visible(value) {
      this.dialogVisible = this.visible;
    }
  },
  created() {
    this.dialogVisible = this.visible;
  }
};
</script>
