<template>
  <div class="white-background-section">
    <div class="container">
      <el-row>
        <div class="involve-heading-text mt-5">
          <div class="involve-data">
            <div>
              <el-col class :md="24" :lg="24">
                <div class="left-text pr-2">
                  <h1 class="involve-title">{{ text.heading }}</h1>
                  <p class="involve-sub-text">{{ text.listStart }}</p>
                  <div class>
                    <ul class="involve-list">
                      <li
                        v-for="point in bulletPoints"
                        :key="point.text"
                        v-html="point.text"
                      ></li>
                    </ul>
                  </div>
                  <p class="involve-sub-text">{{ text.subListStart }}</p>
                  <div class>
                    <ul class="involve-list">
                      <li
                        v-for="point in subBulletPoints"
                        :key="point.text"
                        v-html="point.text"
                      ></li>
                    </ul>
                  </div>
                </div>
              </el-col>
            </div>
          </div>
        </div>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      text: {
        heading: "Our residents and members",
        listStart: "Our current residents are:",
        subListStart: "And our current members are:",
      },
      bulletPoints: [
        {
          text: `<a href='http://www.brodies.com/node/1915' target='_blank'>Brodies LLP <span class="sr-only">this link will open in a new window</span><svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" class="ml-1">
                <path d="M6.5 3.69189H0.5V15.6919H12.5V9.72217" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3.5 12.707L15.5 0.707031" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.5 0.707031H15.5V6.70703" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                </svg></a>`,
        },
        {
          text: `<a href='http://www.channelislands.eu/' target='_blank'>Channel Islands Brussels Office <span class="sr-only">this link will open in a new window</span><svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" class="ml-1">
                <path d="M6.5 3.69189H0.5V15.6919H12.5V9.72217" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3.5 12.707L15.5 0.707031" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.5 0.707031H15.5V6.70703" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                </svg></a>`,
        },
        {
          text: `<a href='https://www.cityoflondon.gov.uk/business/eu-and-regulation/city-office-brussels/Pages/default.aspx' target='_blank'>City Office in Brussels <span class="sr-only">this link will open in a new window</span><svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" class="ml-1">
                <path d="M6.5 3.69189H0.5V15.6919H12.5V9.72217" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3.5 12.707L15.5 0.707031" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.5 0.707031H15.5V6.70703" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                </svg></a>`,
        },
        {
          text: `<a href='http://www.euroace.org/' target='_blank'>EuroACE - European Alliance of Companies for Energy Efficiency in Buildings <span class="sr-only">this link will open in a new window</span><svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" class="ml-1">
                <path d="M6.5 3.69189H0.5V15.6919H12.5V9.72217" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3.5 12.707L15.5 0.707031" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.5 0.707031H15.5V6.70703" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                </svg></a>`,
        },
        {
          text: `<a href='http://www.eubia.org/' target='_blank'>EUBIA - European Biomass Industry Association <span class="sr-only">this link will open in a new window</span><svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" class="ml-1">
                <path d="M6.5 3.69189H0.5V15.6919H12.5V9.72217" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3.5 12.707L15.5 0.707031" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.5 0.707031H15.5V6.70703" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                </svg></a>`,
        },
        {
          text: `<a href='http://www.gesi.org/' target='_blank'>GeSi - Global e-Sustainability Initiative <span class="sr-only">this link will open in a new window</span><svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" class="ml-1">
                <path d="M6.5 3.69189H0.5V15.6919H12.5V9.72217" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3.5 12.707L15.5 0.707031" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.5 0.707031H15.5V6.70703" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                </svg></a>`,
        },
        {
          text: `<a href='https://www.gov.im/' target='_blank'>Isle of Man Government Brussels Office <span class="sr-only">this link will open in a new window</span><svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" class="ml-1">
                <path d="M6.5 3.69189H0.5V15.6919H12.5V9.72217" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3.5 12.707L15.5 0.707031" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.5 0.707031H15.5V6.70703" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                </svg></a>`,
        },
        {
          text: `<a href='http://www.sdi.co.uk/' target='_blank'>Scottish Development International <span class="sr-only">this link will open in a new window</span><svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" class="ml-1">
                <path d="M6.5 3.69189H0.5V15.6919H12.5V9.72217" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3.5 12.707L15.5 0.707031" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.5 0.707031H15.5V6.70703" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                </svg></a>`,
        },
        {
          text: `<a href='http://www.gov.scot/europe' target='_blank'>Scottish Government EU Office <span class="sr-only">this link will open in a new window</span><svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" class="ml-1">
                <path d="M6.5 3.69189H0.5V15.6919H12.5V9.72217" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3.5 12.707L15.5 0.707031" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.5 0.707031H15.5V6.70703" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                </svg></a>`,
        },
      ],
      subBulletPoints: [
        {
          text: `<a href='https://www.europeanpolicysolutions.com/' target='_blank'>European Policy Solutions (EPS) <span class="sr-only">this link will open in a new window</span><svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" class="ml-1">
                <path d="M6.5 3.69189H0.5V15.6919H12.5V9.72217" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3.5 12.707L15.5 0.707031" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.5 0.707031H15.5V6.70703" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                </svg></a>`,
        },
        {
          text: `<a href='https://www.globalcyberalliance.org/' target='_blank'>Global Cyber Alliance (GCA) <span class="sr-only">this link will open in a new window</span><svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" class="ml-1">
                <path d="M6.5 3.69189H0.5V15.6919H12.5V9.72217" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3.5 12.707L15.5 0.707031" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.5 0.707031H15.5V6.70703" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                </svg></a>`,
        },
        {
          text: `<a href='https://www.culturalrelations.co.uk/' target='_blank'>International Cultural Relations (ICR) <span class="sr-only">this link will open in a new window</span><svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" class="ml-1">
                <path d="M6.5 3.69189H0.5V15.6919H12.5V9.72217" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3.5 12.707L15.5 0.707031" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.5 0.707031H15.5V6.70703" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                </svg></a>`,
        },
        {
          text: `<a href='https://www.novainnovation.com/' target='_blank'>Nova Innovation <span class="sr-only">this link will open in a new window</span><svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" class="ml-1">
                <path d="M6.5 3.69189H0.5V15.6919H12.5V9.72217" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3.5 12.707L15.5 0.707031" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.5 0.707031H15.5V6.70703" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                </svg></a>`,
        },
      ],
    };
  },
};
</script>
<style>
.invlove-img {
  /*  margin-bottom: 50px;*/
}
.left-text {
  margin-bottom: 50px;
}
</style>
