<template>
  <div class="personal-details-page">
    <gs-personal-details-form />
  </div>
</template>

<script>
import GsPersonalDetailsForm from "@/components/platform/edit-profile/gs-personal-details-form"
export default {
  name: "PersonalDetailsPage",

  metaInfo: {
    title: "Personal details - Edit profile",
  },

  components: {
    GsPersonalDetailsForm,
  },
}
</script>
