<template>
  <div class="forgot-password-page page">
    <header class="forgot-password-page__header">
      <div class="header__content__bg"></div>
      <div class="header__content container d-flex flex-column align-items-stretch justify-content-center">
        <div class="row">
          <div class="col-md-24 col-lg-12">
            <div class="header__content__wrapper d-flex flex-column align-items-start justify-content-center">
              <h1 class="header__title">Reset Password</h1>
            </div>
          </div>
        </div>
      </div>
    </header>

    <main class="forgot-password-page__body">
      <div class="container">
        <div class="row">
          <div class="col-md-24 col-lg-12">
            <el-button type="text"
                        size="medium"
                        class="step-goback-button align-self-start color-black"
                        @click="goBack($router.push('/login'))">
              <span aria-hidden="true">&lt; Back</span>
               <span class="sr-only">Go back to login</span>
            </el-button>
            <validation-errors v-if="validationErrors.length" :errors="validationErrors"></validation-errors>
            <el-form :model="forgotForm" :rules="rules" id="forgotForm" ref="forgotForm" @validate="handleForgotPasswordFormFieldInput">

              <div class="body__content">

                <h1 class="body__title">Enter your email to reset your password</h1>
                <el-form-item prop="email">
                  <template slot="label">
                      Email address
                      <span class="sr-only"
                            aria-live="polite"
                            :hidden="! this.hasInlineValidationErrorFor('email')"
                            v-text="this.getInlineValidationErrorFor('email')"></span>
                  </template>
                  <el-input type="email" v-model="forgotForm.email"
                            placeholder="Your email address"
                            id="email"
                            label="Please enter your email address in this field"
                            aria-required="true"
                  ></el-input>
                </el-form-item>

                <div class="send-button-wrapper">
                  <el-button type="primary"
                             native-type="submit"
                             id="submit"
                             @click.prevent="submitForm('forgotForm')"
                             :loading="isLoading" class="send-button"
                  >
                    Submit
                  </el-button>
                </div>

              </div>

            </el-form>

          </div>
        </div>
      </div>
    </main>

  </div>
</template>

<script>
  import store from '@/store'
  import gsApi from '@/services/gs.api'
  import validationErrors from '@/components/partials/validation-errors'
  import {validate} from 'email-validator'
  import inlineValidationErrorsMixin from '@/mixins/inlineValidationErrorsMixin'

  export default {
    name: 'forgot-password',
    mixins: [inlineValidationErrorsMixin],
    metaInfo: {
      title: "Forgot password"
    },
    components: {
      validationErrors
    },
    created() {
      if (store.state.isLogged) {
        this.$router.push('/feed')
      }
    },
    data() {
      let validateEmail = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('Please input your email'));
        } else if (validate(value) == false) {
          callback(new Error('Please enter a valid email address'));
        } else {
          callback();
        }
      };
      return {
        forgotForm: {
          email: ''
        },
        rules: {
          email: [
            { validator: validateEmail, required: true }
          ]
        },
        isLoading: false,
        validationErrors: [],
      }
    },
    methods: {
      submitForm(formName) {
        this.isLoading = true
        this.$refs[formName].validate((valid) => {
          if (valid) {
            gsApi.auth.forgot(this.forgotForm.email)
            .then((response) => {
              this.isLoading = false
              this.validationErrors = [];

              const h = this.$createElement

              this.$alert(
                h('p', null, [
                  h('span', 'If this email address is in our system, we\'ll send a link to reset your password. If you don\'t receive an email, '),
                  h('a', {
                    attrs: {
                      href: '/contact-us',
                    }
                  }, 'contact our admins for help')
                ]),
                'Check your email',
                {
                  confirmButtonText: 'Close',
                  showClose: false,
                  customClass: 'notification-modal'
                }
              )
              this.$router.push('/login')
            }).catch(() => {
              this.validationErrors = [];
              this.isLoading = false

              if (error.response && error.response.status == 422 && error.response.data) {
                let errors = error.response.data.errors;
                for (let [key, value] of Object.entries(errors)) {
                  let obj = {}
                  obj.id = key;
                  obj.message = value[0];
                  this.validationErrors.push(obj);
                }
                window.scrollTo({top: 0, behavior: 'smooth'});
              } else {
                this.$alert('The selected email is invalid.', 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
              }
            })
          } else {
            this.isLoading = false
            this.validationErrors = [];
            this.$refs.forgotForm.fields.forEach(field => {
              if(field.validateState == 'error') {
                let obj = {}
                obj.id = field.labelFor;
                obj.message = field.validateMessage;
                this.validationErrors.push(obj);
              }
            });
            this.$nextTick(() => {
              document.getElementById('formErrorBlock').scrollIntoView({ behavior: 'smooth', block: 'start' })
              let text = document.getElementById("forgotForm").getElementsByClassName('el-form-item__error')[0].textContent
              this.$announcer.set(text)
            })
          }
        });
      },
      handleForgotPasswordFormFieldInput() {
        this.handleSingleFormFieldInput('forgotForm');
      }
    },
  }
</script>
