<!-- EXPERIENCE AND EXPERTISE -->
<template>
  <div
    v-loading="mainLoader || metaLoader"
    class="section-experience-expertise"
    element-loading-background="rgba(255, 255, 255, 1)"
  >
    <h2>Interests and alerts</h2>
    <validation-errors
      v-if="validationErrors.length"
      :errors="validationErrors"
    ></validation-errors>
    <el-form
      v-if="!metaLoader"
      id="form"
      ref="submitForm"
      :model="form"
      class="experiense-expertise-form"
      @submit.native.prevent
      @validate="handleSubmitFormFieldInput"
    >
      <el-form-item
        prop="group_ids"
        class="w-80"
      >
        <template slot="label">
          <span aria-hidden="true">What types of updates and alerts are you interested in?</span>
          <span class="sr-only">Please select one or more types of update</span>
          <span
            class="sr-only"
            aria-live="polite"
            :hidden="! hasInlineValidationErrorFor('group_ids')"
            v-text="getInlineValidationErrorFor('group_ids')"
          ></span>
        </template>
        <el-checkbox-group
          v-model="form.group_ids"
          aria-label="Please check one or more types of updates"
        >
          <el-row>
            <el-col
              v-for="(group,index) in groups"
              :key="index"
              :sm="12"
              class="mb-4"
            >
              <el-checkbox
                :key="group.id"
                name="group_ids"
                :label="group.id"
              >
                {{ group.name }}
              </el-checkbox>
            </el-col>
          </el-row>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item
        prop="expertise"
        class="w-80"
      >
        <template slot="label">
          <span aria-hidden="true">What topics are you interested in receiving alerts about?</span>
          <span class="sr-only">Please select one or more of topic are you interested in receiving alerts about</span>
          <span
            class="sr-only"
            aria-live="polite"
            :hidden="! hasInlineValidationErrorFor('expertise')"
            v-text="getInlineValidationErrorFor('expertise')"
          ></span>
        </template>
        <el-checkbox-group
          v-model="form.expertise"
          aria-label="Please check one or more topics"
        >
          <el-row>
            <el-col
              v-for="(expertise,index) in expertises"
              :key="index"
              :sm="12"
              class="mb-4"
            >
              <el-checkbox
                :key="expertise.id"
                name="group_ids"
                :label="expertise.id"
              >
                {{ expertise.name }}
              </el-checkbox>
            </el-col>
          </el-row>
        </el-checkbox-group>
      </el-form-item>


      <div class="step-hr mb-4"></div>
      <div class="d-flex form-actions">
        <el-button
          class="btn-cancel"
          @click="$router.push({name: 'SE Profile', params: { id: user.id }})"
        >
          Cancel
        </el-button>
        <el-button
          type="primary"
          class="btn-submit"
          @click="submitForm($event)"
        >
          Save changes
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapState } from "vuex"
import gsApi from '@/services/gs.api'
import validationErrors from '@/components/partials/validation-errors'
import inlineValidationErrorsMixin from "@/mixins/inlineValidationErrorsMixin";

export default {
  name: "InterestsAndAlerts",

  components: {
    validationErrors
  },
  mixins: [inlineValidationErrorsMixin],

  metaInfo: {
    title: "Interests and alerts - Edit profile",
  },
  data() {
    return {
      validationErrors: [],
      mainLoader: false,
      metaLoader: true,
      groups: [],
      expertises: [],
      form: {
        group_ids: [],
        expertise: [],
      },
    }
  },
  computed: {
    ...mapState(['user']),
  },
  created() {
    if(this.$store.state.user.isLogged){
      this.getRegisterMeta();
      this.fetchCurrentExpertises();
      this.fetchCurrentGroups()
    }
  },
  methods: {
    submitForm() {
      this.$refs['submitForm'].validate((valid) => {
        if (valid) {
          const data = {};

          data.group_ids = this.form.group_ids;
          data.sub_group_ids = this.form.sub_group_ids;
          data.connected_countries = this.form.connected_countries;
          data.expertise = this.form.expertise;
          data.activities = this.form.activities;

          this.mainLoader = true

          gsApi.globalscot.user.updateExperienceExpertise(data)
            .then(response => {
              this.$store.commit('UPDATE_GS_EXPERIENCE_EXPERTISE', response.data);
              this.mainLoader = false;
              this.validationErrors = [];
              this.$alert('You successfully updated your experience and expertise.', 'Success', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
            })
            .catch(error => {
              this.validationErrors = [];
              this.mainLoader = false

              if (error.response && error.response.status == 422 && error.response.data) {
                let errors = error.response.data.errors;
                for (let [key, value] of Object.entries(errors)) {
                  let obj = {}
                  obj.id = key;
                  obj.message = value[0];
                  this.validationErrors.push(obj);
                }
                window.scrollTo({top: 0, behavior: 'smooth'});
              } else {
                this.$alert('There are errors in the form, please fix these errors and try again.', 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
              }
            })
        } else {
          this.validationErrors = [];
          this.$refs.submitForm.fields.forEach(field => {
            if(field.validateState == 'error') {
              let obj = {}
              obj.id = field.labelFor;
              obj.message = field.validateMessage;
              this.validationErrors.push(obj);
            }
          });
          this.$nextTick(() => {
            document.getElementById('formErrorBlock').scrollIntoView({ behavior: 'smooth', block: 'start' })
            let text = document.getElementById("submitForm").getElementsByClassName('el-form-item__error')[0].textContent
            this.$announcer.set(text)
          })
        }
      })
    },
    getRegisterMeta() {
      gsApi.globalscot.meta.index()
        .then(response => {
          setTimeout(()=>{
            this.metaLoader = false
          }, 500)
          this.countries = response.data.countries.data
          this.groups = response.data.groups.data
          this.expertises = response.data.expertise.data
          this.activities = response.data.activities.data
        }).catch(() => {
          this.mainLoader = false
          this.$alert('An unknown error occurred. Please try again later.', 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
        })
    },
    fetchCurrentGroups() {
      let user = this.$store.state.user;
      
      if (user.groups && user.groups.length) {
        this.form.group_ids = user.groups.map(x=>x.id)
      }
    },
    fetchCurrentExpertises() {
      let user = this.$store.state.user;

      if(user.expertProfile.expertise && user.expertProfile.expertise.data) {
        this.form.expertise = user.expertProfile.expertise.data.map(x=>x.id)
      }
    },
    handleSubmitFormFieldInput() {
      this.handleSingleFormFieldInput('submitForm');
    },
  }
}
</script>
