<template>
  <focus-lock>
    <el-dialog
      ref="cancel121BookedTimeSlot"
      @opened="dialogOpen('cancel121BookedTimeSlot')"
      aria-describedby="dialogDescription"
      custom-class="events-general-dialog"
      :show-close="false"
      :visible.sync="dialogVisible"
      element-loading-background="rgba(255, 255, 255, 0.8)"
      title="Please confirm you want to sign up for this event"
      :before-close="onClose"
    >
      <div class="body">
        <h2 class="event-dialog-title">Can’t make this 1-2-1?</h2>
        <p class="event-dialog-text" v-text="cancelText"></p>
        <div class="button-wrap d-flex events-dialog-buttons">
          <el-button class="events-dialog-cancel" @click="onClose($event)">
            Cancel
          </el-button>
          <el-button
            class="events-dialog-confirm"
            type="primary"
            @click.prevent="cancel121BookedTimeSlot()"
            :loading="mainLoader"
          >
            {{ confirmText }}
          </el-button>
        </div>
      </div>
    </el-dialog>
  </focus-lock>
</template>

<script>
import store from '@/store'
import gsApi from '@/services/gs.api'

export default {
  name: 'Cancel121BookedTimeSlotDialog',

  props: {
    visible: {
      type: Boolean,
      default: false
    },
    eventHost: {
      type: Object,
      default: null,
    },
    hasOnlyOneBookedSlot: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {
      mainLoader: false,
      dialogVisible: false
    }
  },

  computed: {
    cancelText () {
      return this.hasOnlyOneBookedSlot
        ? 'You only have 1 slot to this event. If you continue, your attendance to this event will be cancelled.'
        : 'If you no longer wish to use this time slot you can cancel the booking and the time will become available to other users.';
    },
    confirmText () {
      return this.hasOnlyOneBookedSlot
        ? 'Yes, cancel'
        : 'Remove time slot'
    }
  },

  methods: {
    onClose () {
      if (!this.mainLoader) {
        this.dialogVisible = false;
        this.$emit('closed');
      }
    },

    cancel121BookedTimeSlot () {
      this.mainLoader = true
      gsApi.events
        .releaseASlot(
          this.eventHost.current_user_slot.event_day_id,
          this.eventHost.current_user_slot.id
        )
        .then((response) => {
          this.mainLoader = false;
          this.$emit('slotReleased')
          this.onClose()
        })
        .catch((error) => {
          console.log(error, 'error cancelling booked slot')
          if (error.response && error.response.status === 422 && error.response.data) {
            let firstError =
              error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            this.$alert(
              firstError[0],
              'Error',
              {
                confirmButtonText: 'Close',
                showClose: false,
                customClass: 'notification-modal'
              }
            )
          } else {
            this.$alert(
              'Sorry, your request could not be processed',
              `Error ${error.response.status} - ${error.response.data.message}`, {
              confirmButtonText: 'Close',
              showClose: false,
              customClass: 'notification-modal'
            })
          }
        })
        .finally(() => {
          this.mainLoader = false
        })

    },
  },

  watch: {
    visible (value) {
      this.dialogVisible = this.visible
    }
  },
  created () {
    this.dialogVisible = this.visible
  }
}
</script>

<style lang="scss">
.events-general-dialog {
  min-height: 422px;
  .body {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .event-dialog-title {
    font-style: normal;
    font-weight: 420;
    font-size: 2.1875rem;
    line-height: $line-height-45;
    /* identical to box height, or 129% */

    text-align: center;

    color: #000000;
    @media screen and(max-width: 425px) {
      font-size: 1.4rem;
    }
  }

  .event-dialog-text {
    font-style: normal;
    font-weight: 390;
    font-size: 1.5rem;
    line-height: $line-height-35;
    text-align: center;
    color: #000000;
    padding-top: 1.375rem;
    max-width: 586px;
    width: 100%;
  }

  .events-dialog-buttons {
    width: 100%;

    .events-dialog-cancel {
      width: 100%;
      max-width: 231px;
    }

    .events-dialog-confirm {
      width: 100%;
      max-width: 231px;
    }

    @media screen and(max-width: 425px) {
      flex-direction: column;
      width: 100%;

      .events-dialog-confirm {
        width: 100%;
        margin: 0.625rem 0 0;
      }
    }
  }

  .sign-up-buttons {
    padding-top: 2rem;
    @media screen and(max-width: 425px) {
      padding-top: 1rem
    }
  }
}

</style>
