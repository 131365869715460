<template>
  <focus-lock>
    <el-dialog ref="suspendedDialog" @opened="dialogOpen('suspendedDialog')"
      :visible.sync="dialogVisible"
      custom-class="suspended-dialog"
      @close="onClose($event)"
      title="Your account has been suspended.It looks like your account has been suspended by an admin. Contact the
          Scotland Europa team to reactivate your account."
    >
      <div class="body">
        <svg-danger-exclamation />
        <h1>Your account has been suspended</h1>
        <p>
          It looks like your account has been suspended by an admin. Contact the
          Scotland Europa team to reactivate your account.
        </p>
        <div class="contact-wrap d-flex">
          <p class="d-flex align-items-center number">
            <img
              aria-hidden="true"
              class="mr-3 phone-icon"
              src="../../../static/img/contact-phone.svg"
              alt="Phone Icon"
            />
            <a
              aria-label="Click to call 00 32 2 282 8304"
              href="tel:00 32 2 282 8304"
              class="se-tel"
              >00 32 2 282 8304</a
            >
          </p>
          <el-button type="primary" @click="openContact($event)">
            Contact Scotland Europa team
          </el-button>
        </div>
      </div>
    </el-dialog>
  </focus-lock>
</template>

<script>
import store from "@/store";
import svgDangerExclamation from "@/components/assets/svg-danger-exclamation";

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  components: {
    svgDangerExclamation
  },
  data() {
    return {
      dialogVisible: false
    };
  },
  methods: {
    openContact(e) {
      window.open("https://support.scotlandeuropa.com/portal/newticket", "_blank");
    },

    onClose(e) {
      if (store.state.app.loggedOutBySuspension) {
        store.commit("RESET_LOGGED_OUT_BY_SUSPENSION");
      }
      this.dialogVisible = false;
      this.$emit("closed");
    }
  },
  watch: {
    visible(value) {
      this.dialogVisible = this.visible;
    }
  },

  created() {
    this.dialogVisible = this.visible;
  }
};
</script>

<style lang="scss">
.suspended-dialog {
  .body {
    .phone-icon {
      width: 26px;
      height: 26px;
    }
  }
}
</style>
