<template>
  <div class="show-more-list-wrap">
    <ul>
      <li :key="index" v-for="(item, index) in visibleItems">
        <slot :item="item" />
      </li>
    </ul>
    <a
      href="#"
      class="show-more-link"
      @click.prevent="showMore = !showMore"
      v-if="exceededLimit">
      {{ showMore ? 'Show less' : 'Show more' }}
    </a>
  </div>
</template>

<script>
  export default {
    name: "list-show-more-component",

    props: {
      items: {
        type: Array,
        default: () => []
      },
      limit : {
        type: Number,
        default: 5
      }
    },

    data () {
      return {
        showMore: false
      }
    },

    computed: {
      exceededLimit () {
        return this.items.length > this.limit
      },

      visibleItems () {
        if (!this.exceededLimit) {
          return this.items
        }

        return this.showMore ? this.items : this.items.slice(0, this.limit)
      }
    }
  }
</script>
