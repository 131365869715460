<template>
  <slide-panel
    title="Filter"
    @panelclose="closeMenu"
  >
    <el-form
      id="mobileSearchForm"
      ref="mobileSearchForm"
      :model="value"
      class="members-filter"
      @submit.native.prevent
    >
      <el-row>
        <el-col :sm="24">
          <el-form-item
            prop="sortby"
            class="is-no-asterisk"
          >
            <template slot="label">
              <span aria-hidden="true">Sort by</span>
              <span class="sr-only">Please select a filter</span>
            </template>
            <v-select
              v-model="value.sortby"
              placeholder="Choose from the following"
              :options="sortOptions"
              :searchable="true"
              :clearable="false"
              :reduce="sortOption => sortOption.name"
              input-id="sortby"
              autocomplete="false"
              label="name"
              class="half-content"
            />
          </el-form-item>
        </el-col>

        <el-col
          v-if="!isAlertTypeSelected"
          :sm="24"
          :md="12"
          :lg="6"
        >
          <el-form-item
            prop="update_type"
            class="is-no-asterisk"
          >
            <template slot="label">
              <span aria-hidden="true">Type</span>
              <span class="sr-only">Please select a Type</span>
            </template>
            <v-select
              v-model="value.update_type"
              placeholder="Choose from the following"
              :options="updateTypes"
              :searchable="true"
              :clearable="false"
              :reduce="updateType => updateType.id"
              input-id="updateType"
              autocomplete="false"
              label="name"
              class="half-content"
            />
          </el-form-item>
        </el-col>

        <el-col
          :sm="24"
          :md="12"
          :lg="6"
        >
          <el-form-item
            prop="topic"
            class="is-no-asterisk"
          >
            <template slot="label">
              <span aria-hidden="true">Topic</span>
              <span class="sr-only">Please select a topic</span>
            </template>
            <v-select
              v-model="value.topic"
              placeholder="Choose from the following"
              :options="topics"
              :searchable="true"
              :clearable="false"
              :reduce="topic => topic.id"
              input-id="sector"
              autocomplete="false"
              label="name"
              class="half-content"
            />
          </el-form-item>
        </el-col>

        <el-col :sm="24">
          <div class="d-flex mt-4">
            <span
              class="reset-btn float-right"
              @click="$emit('reset')"
            >Reset</span>
            <el-button
              type="primary"
              class="float-right update-results-btn flex-fill ml-4"
              @click="onSearch"
            >
              Search
            </el-button>
          </div>
        </el-col>
      </el-row>
    </el-form>
  </slide-panel>
</template>

<script>
import { mapState } from "vuex"
import SlidePanel from '@/components/slide-panel/slide-panel'

export default {
  name: 'NewsFilter',
  components: {
    'slide-panel': SlidePanel
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    topics: {
      type: Array,
      required: true
    },
    updateTypes: {
      type: Array,
      required: true
    },
    sortOptions: {
      type: Array,
      required: true
    },
    type:{
      type: String,
      default: 'News'
    },
    isAlertTypeSelected:{
      type:Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(['user']),
  },
  methods: {
    onSearch() {
      this.$emit('search')
    },
    closeMenu() {
      this.$emit('close')
    },
  },
}
</script>
