<template>
  <section
    class="footer-join"
    aria-label="How to reach Scotland House Brussels"
  >
    <div class="container">
      <el-row>
        <el-col
          :xl="24"
          :lg="24"
          :md="24"
          :sm="24"
          :xs="24"
          class="head"
        >
          <h1 class="text-white footer-text">
            {{ footerText.heading }}
          </h1>
        </el-col>
        <el-col
          :xl="24"
          :lg="24"
          :md="24"
          :sm="24"
          :xs="24"
        >
          <p
            class="content-std text-white footer-text join-sub-text join-hub-sub-text-first"
            v-html="footerText.sub"
          ></p>
          <p
            class="content-std text-white footer-text join-sub-text join-hub-sub-text-last"
            v-html="footerText.secondSub"
          ></p>
        </el-col>
      </el-row>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      footerText: {
        heading: "How to reach us",
        sub:
          `Scotland House Brussels is centrally located in Brussels' European Quarter at <a href='https://www.google.com/maps/place/Scotland+House+Brussels/@50.841902,4.3816272,17z/data=!3m1!4b1!4m12!1m6!3m5!1s0x47c3c4a1f15c5ecf:0x32e3e7b8ed273861!2sScotland+Europa!8m2!3d50.8418986!4d4.3838159!3m4!1s0x47c3c52177d2f373:0x588e422e432cca76!8m2!3d50.8418986!4d4.3838159' target='_blank'>Rond-Point Schuman 6 <span class="sr-only">this link will open in a new window</span><svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" class="ml-1">
                <path d="M6.5 3.69189H0.5V15.6919H12.5V9.72217" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3.5 12.707L15.5 0.707031" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.5 0.707031H15.5V6.70703" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                </svg></a>, 1040 Brussels. It enjoys excellent transport links as Schuman metro and train stations are less than a few minutes' walk and Brussels Airport is only 15 minutes by train or 25 minutes by bus or car.`,
        secondSub: "You can also reach us at <a href='mailto:scotland.house@scotent.co.uk'>scotland.house@scotent.co.uk</a> or +32 2 282 83 04."
      }
    };
  }
};
</script>
<style>
.join-hub-sub-text-first {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}
.footer-text {
  text-align: center;
  margin-top: 30px;
}
h1 {
  font-weight: 400;
}
.footer-text {
  padding-top: 10px;
  padding-bottom: 10px;
}
.join-btn-bottom {
  margin: auto;
}
.give-feedback p {
  color: white;
  margin-left: 10px;
  /* float: left; */
  text-decoration: underline;
}
.give-feedback {
  text-align: center;
  padding-top: 45px;
}
</style>
