<template>
  <el-col :sm="24" :md="12" :lg="8" :xl="8" class="news-card-col">
    <el-card class="news-card box-card text-center">
      <div class="news-img">
        <el-image :alt="altText" :src="imageForEvent" fit="cover"></el-image>

        <span class="news-type">{{ event.type_name }}: {{ event.category_name }}</span>
      </div>
      <div class="title-position">
        <h3 class="text-truncate">{{ event.title }}</h3>
        <p class="text-truncate mt-0">{{ event.start_date }} - {{ event.end_date }}</p>
        <p class="text-truncate">{{ event.description }}</p>
      </div>
      <div class="read-more-btn-container">
        <el-button type="primary" class="read-more-btn" @click="readMore(event.slug)">Read more</el-button>
      </div>
    </el-card>
  </el-col>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "events-card",
  components: {},
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      eventTypes: {
        events: "Events",
        webinars: "Webinars",
      }
    };
  },
  computed: {
    ...mapState(["user"]),
    imageForEvent() {
      return this.event.mediaFile && this.event.mediaFile.large_thumb
        ? this.event.mediaFile.large_thumb
        : null;
    },
    altText() {
      return (this.event.mediaFile && this.event.mediaFile.alt_attribute) ? this.event.mediaFile.alt_attribute: ''
    },
  },
  methods: {
    readMore(slug) {
      this.$router.push({ name: "Public Events", params: { slug: slug } });
    }
  }
};
</script>
