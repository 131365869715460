<template>
  <div
    class="section-account-details"
    v-loading.fullscreen.lock="mainLoader"
    element-loading-background="rgba(255, 255, 255, 0.8)"
  >
    <h2>Update password</h2>
    <validation-errors v-if="validationErrors.length" :errors="validationErrors"></validation-errors>
    <el-form :model="form" id="submitForm" class="update-password-form" ref="submitForm" :rules="rules"
             @submit.native.prevent @validate="handleAccountPasswordFormFieldInput">
      <el-form-item prop="current_password">
        <template slot="label">
          Current password
          <span class="sr-only"
                aria-live="polite"
                :hidden="! this.hasInlineValidationErrorFor('current_password')"
                v-text="this.getInlineValidationErrorFor('current_password')"></span>
        </template>
        <el-row>
          <el-col :md="24" :lg="16">
            <el-input
              :show-password="true"
              v-model="form.current_password"
              id="current_password"
              autocomplete="false"
              aria-required="true"/>
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item prop="new_password">
        <template slot="label">
          New password
          <span class="sr-only"
                aria-live="polite"
                :hidden="! this.hasInlineValidationErrorFor('new_password')"
                v-text="this.getInlineValidationErrorFor('new_password')"></span>
        </template>
        <el-row>
          <el-col :md="24" :lg="16">
            <el-input
              :show-password="true"
              v-model="form.new_password"
              id="new_password"
              autocomplete="false"
              aria-required="true"/>
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item prop="new_password_confirmation">
        <template slot="label">
          Confirm new password
          <span class="sr-only"
                aria-live="polite"
                :hidden="! this.hasInlineValidationErrorFor('new_password_confirmation')"
                v-text="this.getInlineValidationErrorFor('new_password_confirmation')"></span>
        </template>
        <el-row>
          <el-col :md="24" :lg="16">
            <el-input
              :show-password="true"
              v-model="form.new_password_confirmation"
              id="new_password_confirmation"
              autocomplete="false"
              aria-required="true"/>
          </el-col>
        </el-row>
      </el-form-item>
      <div class="d-flex form-actions">
        <el-button
          type="primary"
          class="btn-submit"
          @click="submitForm($event)">
          Submit
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
  import gsApi from '@/services/gs.api'
  import validationErrors from '@/components/partials/validation-errors'
  import inlineValidationErrorsMixin from '@/mixins/inlineValidationErrorsMixin';

  export default {
    mixins: [inlineValidationErrorsMixin],
    name: "account-password",
    metaInfo: {
      title: "Update Password",
    },
    components: {
      validationErrors
    },
    data() {
      let validateCurrentPassword = (rule, value, callback) => {
        if (value === "") {
          callback(new Error("Enter your current password"));
        } else {
          callback();
        }
      };

      let validatePassword = (rule, value, callback) => {
        let reg = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])[a-zA-Z0-9#?!@$%^&*-]{8,}$/;
        if (value === "") {
          callback(new Error("Enter a new password"));
        } else if (reg.test(value) == false) {
          callback(
            new Error(
              "Enter a valid new password. It must be more than 8 characters long and should contain at least 1 uppercase, 1 lowercase, 1 numeric and 1 special character (#?!@$%^&*-)"
            )
          );
        } else {
          callback();
        }
      };

      let validatePasswordConfirmation = (rule, value, callback) => {
        if (value === "" || value !== this.form.new_password) {
          callback(
            new Error(
              "This doesn't match the new password you entered. Try confirming your new password again."
            )
          );
        } else {
          callback();
        }
      };

      return {
        validationErrors: [],
        mainLoader: false,
        form: {
          current_password: "",
          new_password: "",
          new_password_confirmation: ""
        },
        rules: {
          current_password: [{validator: validateCurrentPassword, required: true}],
          new_password: [{validator: validatePassword, required: true}],
          new_password_confirmation: [
            {validator: validatePasswordConfirmation, required: true}
          ]
        }
      };
    },
    methods: {
      submitForm() {
        this.$refs['submitForm'].validate((valid) => {
          if (valid) {
            this.validationErrors = []

            const data = {};

            data.current_password = this.form.current_password;
            data.new_password = this.form.new_password;
            data.new_password_confirmation = this.form.new_password_confirmation;

            this.mainLoader = true

            gsApi.users.updatePassword(data)
              .then(response => {
                this.mainLoader = false;
                this.validationErrors = [];
                this.$alert('You successfully updated your password', 'Success', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' }).then(() => {
                  this.$refs.submitForm.resetFields();
                });
              })
              .catch(error => {
                this.validationErrors = [];
                this.mainLoader = false

                if (error.response && error.response.status == 422 && error.response.data) {
                  let errors = error.response.data.errors;
                  for (let [key, value] of Object.entries(errors)) {
                    let obj = {}
                    obj.id = key;
                    obj.message = value[0];
                    this.validationErrors.push(obj);
                  }
                  window.scrollTo({top: 0, behavior: 'smooth'});
                } else {
                  this.$alert(error.response.data.message, 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
                }
              })
          } else {
            this.validationErrors = [];
            this.$refs.submitForm.fields.forEach(field => {
              if(field.validateState == 'error') {
                let obj = {}
                obj.id = field.labelFor;
                obj.message = field.validateMessage;
                this.validationErrors.push(obj);
              }
            });
            this.$nextTick(() => {
              document.getElementById('formErrorBlock').scrollIntoView({ behavior: 'smooth', block: 'start' })
              let text = document.getElementById("submitForm").getElementsByClassName('el-form-item__error')[0].textContent
              this.$announcer.set(text)
            })
          }
        })
      },
      handleAccountPasswordFormFieldInput() {
        this.handleSingleFormFieldInput('submitForm');
      }
    }
  };
</script>
