<template>
  <section class="proud-community-partial" aria-label="Scotland House Brussels">
    <el-row>
      <div class="community-data">
        <div class="community-text">
          <el-col :md="24">
            <div class="community-heading">
              <h2 class="Comunity-title">{{communityText.heading}}</h2>
            </div>
          </el-col>
          <el-col :md="24" :lg="12">
            <div>
              <h3 class="community-sub-text text-black">{{communityText.subText}}</h3>
              <!-- <h3 class="community-sub-text second-sub text-black mt-3">{{communityText.secondSub}}</h3> -->
              <el-button class="btn-text community-btn" size="small" @click="$router.push({name: 'Scotland House Brussels'})">Find out more about Scotland House</el-button>
            </div>
          </el-col>
        </div>
        <el-col :md="24" :lg="12">
          <img alt="Community" class="community-data-img" src="../../../static/img/scotland-house.jpg" />
        </el-col>
      </div>
    </el-row>
  </section>
</template>
<script>
export default {
  data() {
    return {
      communityText: {
        heading: "Scotland House Brussels",
        subText:
          "Scotland House is the home of Scotland Europa and the Scottish Government EU office. As well as providing a platform for us to play an active role in Europe and support collaborative working, it also provides our members with a base in Brussels. Depending on the type of membership you have at Scotland Europa, there are a range of membership services your organisation could gain access to. For example, you can become be a permanent resident member of Scotland House, or you can access our co-working facilities and conference centre when visiting Brussels.",
        secondSub:
          "By matching ambitious companies with experienced leaders, Scotland Europa is a network that encourages, develops and nurtures Scotland’s business community – at home and abroad. Through meetings, events, mentorships and conversation, we aim to accelerate Scotland’s growth in international markets and help support future business leaders to get a foothold overseas."
      }
    };
  }
};
</script>
