<template>
  <article>
    <el-col :sm="24" :md="12" :lg="colSize" :xl="colSize" class="news-card-col">
      <el-card class="news-card box-card text-center">
        <div class="news-img">
          <el-image :alt="altText" :src="imageForArticle" fit="cover"></el-image>

          <!-- <div v-if="article.type_name == articleTypes.spotlight" class="avatar">
            <el-avatar v-if="avatar" :size="140" :src="avatar" shape="circle" alt="Profile picture">
              <img src="/static/img/default-image.png" alt="Profile picture" />
            </el-avatar>
          </div> -->

          <span class="news-type">{{ article.type_name }}</span>
        </div>
        <div class="title-position">
          <h3 class="text-truncate">{{ article.title }}</h3>
          <p class="text-truncate">{{ article.description }}</p>
        </div>
        <div class="read-more-btn-container">
          <el-button type="primary" class="read-more-btn" @click="readMore(article.slug)">Read more <span class="sr-only">about {{ article.title }}</span></el-button>
        </div>
      </el-card>
    </el-col>
  </article>

</template>

<script>
import store from "@/store";
import { mapState } from "vuex";

export default {
  name: "news-card",
  components: {},
  props: {
    article: {
      type: Object,
      required: true
    },
    isPublic: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      articleTypes: {
        news: "News",
        spotlight: "Spotlight",
        feature: "Feature",
        event: "Event",
        casestudy: "Case Study"
      }
    };
  },
  computed: {
    ...mapState(["user"]),
    colSize() {
      return this.isPublic ? 12 : 8
    },
    imageForArticle() {
      return this.article.mediaFile && this.article.mediaFile.large_thumb
        ? this.article.mediaFile.large_thumb
        : null;
    },
    altText() {
      return (this.article.mediaFile && this.article.mediaFile.alt_attribute) ? this.article.mediaFile.alt_attribute: ''
    },
    avatar() {
      return this.article.spotlightUser && this.article.spotlightUser.avatar
        ? this.article.spotlightUser.avatar
        : "";
    }
  },
  methods: {
    readMore(slug) {
      if (this.isPublic) {
        this.$router.push({ name: "Public Articles", params: { slug: slug } });
      } else {
        this.$router.push({ name: "News", params: { slug: slug } });
      }
    }
  }
};
</script>
