<template>
  <div
    v-loading.fullscreen.lock="mainLoader"
    class="gs-profile-page body__content"
    element-loading-background="rgba(255, 255, 255, 0.8)"
  >
    <div class="back-to-search-section">
      <div class="container">
        <router-link
          v-if="showBackToSearch"
          :to="{ name: 'Members' }"
          class="back-to-search-link"
        >
          <span>
            <svg-white-arrow-left class="mr-1"></svg-white-arrow-left>Back to
            search
          </span>
        </router-link>
        <a
          v-else-if="showBackToEvent"
          aria-label="go back to event page"
          href="javascript:void(0)"
          class="back-to-search-link linkified"
          @click="goBackToEventPage"
        >
          <span><svg-white-arrow-left class="mr-1"></svg-white-arrow-left> Back to
            event</span>
        </a>
      </div>
    </div>
    <div v-if="globalScot">
      <div class="white-background-section">
        <div class="container profile-card-container">
          <el-card class="profile-card">
            <div class="profile-card-body">
              <el-row>
                <el-col
                  :xs="5"
                  :sm="5"
                  :md="5"
                  :lg="4"
                >
                  <el-avatar
                    shape="circle"
                    class="avatar"
                    :src="globalScot.avatar"
                    alt="Profile picture"
                  >
                    <img src="../../../static/img/default-image.png" alt="Default Profile picture" />
                  </el-avatar>
                </el-col>
                <el-col
                  id="main-content"
                  :xs="19"
                  :sm="19"
                  :md="19"
                  :lg="13"
                >
                  <div class="user-info">
                    <div class="d-flex justify-content-between">
                      <h1>{{ globalScot.full_name }}</h1>
                      <div class="followers-section d-flex hidden-md-and-down">
                        <!--                         <p v-if="isProfileOwner"><span>649</span> Following</p>
                        <p v-if="isProfileOwner" class="separator">|</p>
                        <p><span>649</span> Followers</p>-->
                      </div>
                    </div>

                    <div class="d-flex mb-2 mt-2">
                      <p>
                        {{ globalScot.expertProfile.role }}
                        <svg-vertical-line
                          class="vertical-line"
                          aria-labeledby="vertical line"
                        ></svg-vertical-line>
                        <router-link
                          :to="{
                            name: 'Organisation Profile',
                            params: {
                              slug: globalScot.expertProfile.organisation.slug
                            },
                          }"
                        >
                          {{ globalScot.expertProfile.organisation.name }}
                        </router-link>
                      </p>
                    </div>

                    <div class="location d-flex">
                      <p class="d-flex">
                        <svg-map-pin-gradient
                          class="mr-2 map-pin"
                        ></svg-map-pin-gradient>
                        {{ globalScot.expertProfile.location_name }}
                      </p>
                      <!-- <span class="ml-3 mr-3">|</span> -->
                      <div class="d-none d-md-block">
                        <svg-vertical-line
                          class="vertical-line"
                          aria-labeledby="vertical line"
                        ></svg-vertical-line>
                      </div>
                      <p class="d-flex">
                        Member for {{ memberFor }}
                      </p>
                    </div>
                  </div>
                </el-col>
                <el-col
                  :xs="24"
                  :sm="24"
                  :md="24"
                  :lg="7"
                >
                  <div class="followers-section d-flex justify-content-end">
                    <p
                      v-if="isProfileOwner"
                      class="clickable"
                      @click="$router.push({ name: 'Following' })"
                    >
                      <span>{{ globalScot.following_count }}</span> Following
                    </p>
                    <div
                      v-if="isProfileOwner"
                      class="separator"
                    >
                      |
                    </div>
                    <p
                      :class="{ clickable: isProfileOwner }"
                      @click="
                        isProfileOwner
                          ? $router.push({ name: 'Followers' })
                          : null
                      "
                    >
                      <span>{{ `${globalScot.followers_count}` }}</span> Followers
                    </p>
                  </div>
                </el-col>
              </el-row>
            </div>
            <el-row>
              <el-col :md="24">
                <div class="profile-card-actions-container">
                  <div
                    v-if="!isProfileOwner && helpRequestsPaused"
                    class="view-page-link px-4 w-100"
                  >
                    <router-link to="/contact-us">
                      Contact admin to request help from this GlobalScot.
                    </router-link>
                  </div>

                  <div class="profile-card-actions">
                    <!-- Hide it if help requests are paused-->
                    <!--el-button
                      type="primary"
                      v-if="!this.isProfileOwner && !helpRequestsPaused"
                      @click="dialogVisible = true"
                    >Request help</!--el-button-->
                    <!--el-button
                      type="primary"
                      @click="sendMessage()"
                      v-if="isGlobalScotViewing && !directMessagesPaused"
                    >Send message</!el-button
                    -->
                    <el-button
                      v-if="!isProfileOwner"
                      v-loading="startAction"
                      type="button"
                      @click="followUnfollowProfile()"
                    >
                      {{ globalScot.has_followed ? "Unfollow" : "Follow" }}
                    </el-button>

                    <!--<el-button type="primary" v-if="isProfileOwner">
                      Invite colleague
                    </el-button>-->
                    <el-button
                      v-if="isProfileOwner"
                      type="primary"
                      @click="editProfile"
                    >
                      Edit profile
                    </el-button>
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-card>
        </div>
        <div class="container main-content">
          <el-row>
            <el-col :lg="12">
              <div
                v-if="isProfileOwner"
                class="about-section"
              >
                <h1>About you</h1>
                <p>{{ globalScot.expertProfile.experience }}</p>
              </div>
              <div
                v-if="!isProfileOwner"
                class="about-section"
              >
                <h1>About {{ globalScot.first_name }}</h1>
                <p>{{ globalScot.expertProfile.experience }}</p>
              </div>
            </el-col>
            <el-col :lg="12">
              <div class="companies-connections">
                <h1>Colleagues</h1>
                <div
                  v-if="
                    globalScot.colleagues && globalScot.colleagues.data.length
                  "
                >
                  <colleague-card
                    v-for="(item, index) in getRandomeColleagues"
                    :key="index"
                    :colleague="item"
                  />
                  <a
                    v-if="globalScot.colleagues.data.length > 3"
                    href="#"
                    class="show-more-link"
                    @click.prevent="showAllColleagues = true"
                  >
                    Show more
                  </a>
                </div>
                <div v-else>
                  This User currently has no colleagues in the Scotland Europa
                  website
                </div>
              </div>
            </el-col>
          </el-row>
          <hr
            v-if="expertises.length || sectors.length"
            class="content-separator"
          />
          <div class="sectors-section">
            <el-row>
              <el-col
                v-if="expertises.length"
                :sm="24"
                :md="12"
              >
                <h1>Topic</h1>
                <div
                  v-for="expertise in expertises"
                  :key="expertise.id"
                  class="sector-pill sector mr-1"
                >
                  {{ expertise.name }}
                </div>
              </el-col>
              <el-col
                v-if="sectors.length"
                :sm="24"
                :md="12"
              >
                <h1>Update types</h1>
                <div
                  v-for="sector in sectors"
                  :key="sector.id"
                  class="sector-pill sector mr-1"
                >
                  {{ sector.name }}
                </div>
              </el-col>
            </el-row>
          </div>
          <hr class="content-separator" />
          <div class="media-uploads-section">
            <div class="d-flex justify-content-between">
              <h1 v-if="isProfileOwner">
                Your uploads
              </h1>
              <h1 v-if="!isProfileOwner">
                {{ globalScot.first_name }}'s uploads
              </h1>
              <a
                v-if="media.length > 0"
                href="#"
                class="view-all-link"
                @click.prevent="showMediaGrid = true"
              >
                View all
                <svg-purple-arrow-right class="ml-1"></svg-purple-arrow-right>
              </a>
            </div>

            <p
              v-if="!media.length > 0 && !isProfileOwner"
              class="no-media-text text-center mt-5 mb-5"
            >
              No uploads yet.
            </p>

            <el-row class="media-section">
              <el-col
                v-for="(file, index) in mediaPreview"
                :key="file.id"
                :sm="24"
                :md="12"
                :lg="6"
              >
                <div
                  v-if="fileIsVideoOrImage(file)"
                  class="image-container"
                >
                  <el-image
                    :alt="altText(file)"
                    :class="`media-item ${file.media_type}`"
                    :src="file.large_thumb || file.video_thumb"
                    fit="cover"
                  />
                  <svg-play-video
                    v-if="fileIsVideo(file)"
                    class="play-video-svg"
                  ></svg-play-video>
                  <div class="image-view-hover">
                    <div class="overlay"></div>
                    <el-button
                      class="view-media-btn"
                      @click="openMediaCarousel(index)"
                    >
                      View
                    </el-button>
                  </div>
                </div>
                <div
                  v-else
                  class="
                    document-file
                    text-center
                    d-flex
                    flex-column
                    justify-content-center
                    media-item
                  "
                >
                  <img
                    alt=""
                    class="doc-file-svg"
                    :src="helpers.getFileIcon(file.filename)"
                  />
                  <span>
                    <a
                      :href="file.path"
                      target="_blank"
                      download
                    >{{
                      file.filename
                    }}</a>
                    <svg-download-file
                      class="download-file-svg"
                    ></svg-download-file>
                  </span>
                  <div class="image-view-hover">
                    <div class="overlay"></div>
                    <el-button
                      class="view-media-btn"
                      @click="openMediaCarousel(index)"
                    >
                      View
                    </el-button>
                  </div>
                </div>
              </el-col>
            </el-row>

            <div
              v-if="isProfileOwner"
              class="add-media-section"
            >
              <el-row>
                <el-col
                  :sm="3"
                  :md="3"
                  :lg="2"
                >
                  <svg-document-gradient></svg-document-gradient>
                </el-col>
                <el-col
                  :sm="21"
                  :md="15"
                  :lg="18"
                >
                  <h1>Got a document, video or image you want to add?</h1>
                  <p>Share PDF, XLS, PNG, JPG, Doc, ODF, MP4 or PPT</p>
                </el-col>
                <el-col
                  :sm="24"
                  :md="6"
                  :lg="4"
                >
                  <el-upload
                    ref="upload"
                    action="#"
                    class="selected-upload p-0"
                    :auto-upload="false"
                    :on-change="fileUploaded"
                  >
                    <el-button
                      type="primary"
                      class="select-file-btn float-right"
                    >
                      Select file
                    </el-button>
                  </el-upload>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="activity-section">
          <el-row>
            <el-col :sm="24">
              <h1 v-if="isProfileOwner">
                My activity history
              </h1>
              <h1 v-else>
                {{ globalScot.first_name }}'s activity history
              </h1>

              <recent-activity
                :is-global-scot-profile="true"
                :is-profile-owner="isProfileOwner"
              ></recent-activity>
            </el-col>
          </el-row>
        </div>
      </div>
      <focus-lock>
        <el-dialog
          ref="mediaDialog"
          custom-class="media-grid-dialog"
          :visible.sync="showMediaGrid"
          :show-close="false"
          :fullscreen="true"
          @opened="dialogOpen('mediaDialog')"
        >
          <div class="container">
            <div
              class="d-flex justify-content-between media-grid-modal-heading"
            >
              <h1>{{ globalScot.first_name }}'s uploads</h1>
              <div
                class="close-modal-btn"
                @click="closeMediaGrid()"
              >
                <svg-info-close></svg-info-close>
              </div>
            </div>
            <hr />
            <div v-if="!showMediaCarousel">
              <el-row class="media-section media-grid">
                <el-col
                  v-for="(file, index) in media"
                  :key="file.id"
                  :sm="24"
                  :md="12"
                  :lg="6"
                >
                  <div
                    v-if="fileIsVideoOrImage(file)"
                    class="image-container"
                  >
                    <el-image
                      :alt="altText(file)"
                      :class="`media-item ${file.media_type}`"
                      :src="file.large_thumb || file.video_thumb"
                      fit="cover"
                    />
                    <svg-play-video
                      v-if="fileIsVideo(file)"
                      class="play-video-svg"
                    ></svg-play-video>
                    <div class="image-view-hover">
                      <div class="overlay"></div>
                      <el-button
                        class="view-media-btn"
                        @click="openMediaCarousel(index)"
                      >
                        View
                      </el-button>
                    </div>
                  </div>
                  <div
                    v-else
                    class="
                      document-file
                      text-center
                      d-flex
                      flex-column
                      justify-content-center
                      media-item
                    "
                  >
                    <img
                      alt=""
                      class="doc-file-svg"
                      :src="helpers.getFileIcon(file.filename)"
                    />
                    <span>
                      <a
                        :href="file.path"
                        target="_blank"
                        download
                      >{{
                        file.filename
                      }}</a>
                      <svg-download-file
                        class="download-file-svg"
                      ></svg-download-file>
                    </span>
                    <div class="image-view-hover">
                      <div class="overlay"></div>
                      <el-button
                        class="view-media-btn"
                        @click="openMediaCarousel(index)"
                      >
                        View
                      </el-button>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
            <div v-if="showMediaCarousel">
              <div class="carousel-controls d-flex justify-content-between">
                <a
                  class="control"
                  @click.prevent="closeMediaCarousel()"
                >
                  <svg-purple-arrow-left class="mr-2"></svg-purple-arrow-left>Back to Uploads
                </a>
                <div class="d-flex prev-next-links">
                  <a
                    v-if="isProfileOwner"
                    class="delete-control mr-5 hidden-sm-and-down"
                    @click.prevent="deleteFile()"
                  >
                    <svg-delete-bin class="mr-2"></svg-delete-bin>Delete
                  </a>
                  <a
                    class="control mr-5"
                    @click.prevent="previousCarouselItem"
                  >
                    <svg-purple-arrow-left class="mr-2"></svg-purple-arrow-left>Previous
                  </a>
                  <a
                    class="control"
                    @click.prevent="nextCarouselItem"
                  >
                    Next
                    <svg-purple-arrow-right
                      class="ml-2"
                    ></svg-purple-arrow-right>
                  </a>
                </div>
                <a
                  v-if="isProfileOwner"
                  class="delete-control mr-5 hidden-md-and-up"
                  @click.prevent="deleteFile()"
                >
                  <svg-delete-bin class="mr-2"></svg-delete-bin>Delete
                </a>
              </div>
              <el-carousel
                ref="mediaCarousel"
                trigger="click"
                arrow="never"
                :autoplay="false"
                :initial-index="carouselIndex"
                indicator-position="none"
                height="700px"
                class="media-carousel mt-4"
                @change="updateCarouselIndex($event)"
              >
                <el-carousel-item
                  v-for="file in media"
                  :key="file.id"
                >
                  <el-image
                    v-if="fileIsImage(file)"
                    :class="`media-carousel-item ${file.media_type}`"
                    :src="file.path"
                    fit="cover"
                    :alt="altText(file)"
                  />
                  <video
                    v-else-if="fileIsVideo(file)"
                    width="100%"
                    height="100%"
                    controls
                  >
                    <source :src="file.path" />
                    Your browser does not support this video.
                  </video>
                  <div
                    v-else
                    class="
                      document-file
                      text-center
                      d-flex
                      flex-column
                      justify-content-center
                      media-item
                    "
                  >
                    <img
                      alt=""
                      class="doc-file-svg"
                      :src="helpers.getFileIcon(file.filename)"
                    />
                    <span>
                      <a
                        :href="file.path"
                        target="_blank"
                        download
                      >{{
                        file.filename
                      }}</a>
                      <svg-download-file
                        class="download-file-svg"
                      ></svg-download-file>
                    </span>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </el-dialog>
      </focus-lock>
    </div>
    <focus-lock>
      <el-dialog
        ref="helpRequestDialog"
        :visible.sync="dialogVisible"
        fullscreen
        :show-close="false"
        @opened="dialogOpen('helpRequestDialog')"
      >
        <request-help
          v-if="renderComponent"
          :global-scot="globalScot"
          @clicked="dialogVisible = false"
          @success="greatThing()"
        ></request-help>
      </el-dialog>
    </focus-lock>

    <focus-lock>
      <el-dialog
        ref="helpRequestConfirmDialog"
        :show-close="false"
        custom-class="request-success-modal"
        :visible.sync="successDialogVisible"
        title="Your request has been sent.GlobalScots aim to respond within 3 working days. Depending where they’re based, time zone differences could affect their response time."
        @opened="dialogOpen('helpRequestConfirmDialog')"
      >
        <div class="text-center success-dialog">
          <svg-success-border></svg-success-border>
          <h2 class="mt-3">
            Your request has been sent
          </h2>
          <p class="mt-4 mb-4">
            GlobalScots aim to respond within 3 working days. Depending where
            they’re based, time zone differences could affect their response
            time.
          </p>

          <el-button
            class="mx-auto"
            @click="successDialogVisible = false"
          >
            Close
          </el-button>
        </div>
      </el-dialog>
    </focus-lock>
    <el-dialog
      v-if="
        globalScot && globalScot.colleagues && globalScot.colleagues.data.length
      "
      title="Organisation Members"
      :visible.sync="showAllColleagues"
    >
      <div class="text-center success-dialog">
        <colleague-card
          v-for="(item, index) in globalScot.colleagues.data"
          :key="index"
          :colleague="item"
        />
        <el-button
          class="mx-auto mt-3"
          @click="showAllColleagues = false"
        >
          Close
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import store from "@/store";
import moment from "moment";
import { mapGetters, mapState } from "vuex";
import gsApi from "@/services/gs.api";
import DeleteBin from "@/components/assets/svg-delete-bin";
import PlayVideo from "@/components/assets/svg-play-video";
import InfoClose from "@/components/assets/svg-info-close";
import RolePermissionMixin from "@/mixins/RolePermissionMixin";
import DownloadFile from "@/components/assets/svg-download-file";
import WhiteArrowLeft from "@/components/assets/svg-white-arrow-left";
import MapPinGradient from "@/components/assets/svg-map-pin";
import PurpleArrowLeft from "@/components/assets/svg-purple-arrow-left";
import DocumentGradient from "@/components/assets/svg-document-large";
import PurpleArrowRight from "@/components/assets/svg-purple-arrow-right";
import SuccessBorder from "@/components/assets/svg-success-border";
import RequestHelp from "@/components/platform/members/request-help";
import RecentActivity from "@/components/partials/recent-activity";
import VerticalLine from "@/components/assets/svg-vertical-line";
import enumHelper from "@/helpers/enumHelper";
import ColleagueCard from "@/components/partials/colleague-card";
import helpers from "@/helpers/index";
const ALLOWED_TYPES = [
  "image/jpeg",
  "image/png",
  "application/pdf",
  "video/ogg",
  "video/mpeg",
  "video/webm",
  "video/mp4",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.oasis.opendocument.presentation",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.oasis.opendocument.text",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.oasis.opendocument.spreadsheet",
];
const MAX_FILE_SIZE = 200 * 1024 * 1024; // 200mb

export default {
  name: "GsProfile",
  metaInfo: {
    title: "Member profile",
  },
  components: {
    "svg-play-video": PlayVideo,
    "svg-info-close": InfoClose,
    "svg-delete-bin": DeleteBin,
    "svg-download-file": DownloadFile,
    "svg-white-arrow-left": WhiteArrowLeft,
    "svg-map-pin-gradient": MapPinGradient,
    "svg-purple-arrow-left": PurpleArrowLeft,
    "svg-document-gradient": DocumentGradient,
    "svg-purple-arrow-right": PurpleArrowRight,
    "svg-success-border": SuccessBorder,
    "svg-vertical-line": VerticalLine,
    RequestHelp,
    RecentActivity,
    ColleagueCard,
  },
  mixins: [RolePermissionMixin],
  data() {
    return {
      helpers,
      enumHelper,
      startAction: false,
      isNonGlobalScotViewing: false,
      isGlobalScotViewing: false,
      isProfileOwner: false,
      dialogVisible: false,
      renderComponent: true,
      successDialogVisible: false,
      globalScot: null,
      mainLoader: true,
      showMediaGrid: false,
      showMediaCarousel: false,
      carouselIndex: 0,
      showBackToSearch: false,
      showExtraExpertise: false,
      showExtraOtherBusinesses: false,
      showAllColleagues: false,
    };
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["navigatedFrom"]),
    /*primaryBusiness() {
      let primary = this.globalScot.businesses.data.find(b => b.is_primary);
      return primary ? primary : this.globalScot.businesses.data[0];
    },*/
    businesses() {
      /*let businesses = this.globalScot.businesses.data.filter(
        business => business.type !== 3
      );
      return businesses;*/
      return [];
    },
    otherBusinesses() {
      /*let businesses = this.globalScot.businesses.data.filter(
        business => business.type == 3
      );
      return businesses;*/
      return [];
    },
    expertises() {
      let expertises = this.globalScot.expertProfile.expertise.data;
      return expertises;
    },
    sectors() {
      let sectors = this.globalScot.groups.data;
      return sectors;
    },
    memberFor() {
      return moment(
        this.globalScot.expertProfile.joined_at,
        "DD/MM/YYYY"
      ).fromNow(true);
    },
    mediaPreview() {
      return this.globalScot.media.data.slice(0, 4);
    },
    media() {
      return this.globalScot.media.data;
    },
    helpRequestsPaused() {
      const settings = this.globalScot.expertProfile.settings;
      return (
        settings !== null &&
        Object.prototype.hasOwnProperty.call(
          settings,
          "help_requests_paused_at"
        ) &&
        settings.help_requests_paused_at !== null
      );
    },
    directMessagesPaused() {
      const settings = this.globalScot.expertProfile.settings;
      return (
        settings !== null &&
        Object.prototype.hasOwnProperty.call(
          settings,
          "direct_messages_paused_at"
        ) &&
        settings.direct_messages_paused_at !== null
      );
    },
    showBackToEvent() {
      return (
        this.navigatedFrom.name && this.navigatedFrom.name === "event-view"
      );
    },
    getRandomeColleagues() {
      //globalScot.colleagues.data
      if (
        !this.globalScot ||
        !this.globalScot.colleagues ||
        !this.globalScot.colleagues.data.length
      )
        return [];

      return JSON.parse(JSON.stringify(this.globalScot.colleagues.data)).slice(
        0,
        3
      );
    },
    altText() {
      return file => (file && file.alt_attribute) ? file.alt_attribute: ''        
    },
  },
  watch: {
    $route(to, from) {
      if (from.path !== to.path && this.user.isLogged) {
        this.mainLoader = true;
        this.checkRole();
        this.fetchGsProfile();
      }
    },
  },
  created() {
    if (this.$route.params.showBackToSearch) {
      this.showBackToSearch = true;
    }

    if (store.state.user.isLogged) {
      this.fetchGsProfile();
    }
  },
  mounted() {
    this.checkRole();
  },
  beforeDestroy() {
    this.$store.dispatch("updateNavigatedFrom", {
      name: null,
    });
  },
  methods: {
    greatThing() {
      this.successDialogVisible = true;
      this.renderComponent = false;

      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    sendMessage() {
      this.mainLoader = true;
      gsApi.chat
        .create({
          type: "single",
          members: [this.$route.params.id],
        })
        .then((response) => {
          if (response && response.data) {
            this.mainLoader = false;
            this.$store.commit("UPDATE_SELECTED_CHAT", response.data);
            this.$router.push({ name: "My Chats" });
          }
        })
        .catch((error) => {
          this.mainLoader = false;
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.existingChat
          ) {
            this.$store.commit(
              "UPDATE_SELECTED_CHAT",
              error.response.data.existingChat
            );
            this.$router.push({ name: "My Chats" });
          }
        });
    },
    deleteFile() {
      let file = this.media[this.carouselIndex];

      if (file) {
        this.mainLoader = true;

        gsApi.globalscot.user
          .deleteFile(file.id)
          .then(({ data }) => {
            this.mainLoader = false;
            this.$alert("File successfully deleted.", "Success", {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal",
            });
            this.globalScot.media.data.splice(this.carouselIndex, 1);

            if (!this.globalScot.media.data.length > 0) {
              this.closeMediaGrid();
            }
          })
          .catch(({ response }) => {
            this.mainLoader = false;
            this.$alert(
              "Unable to delete file, please try again later.",
              "Error",
              {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal",
              }
            );
          });
      } else {
        this.$alert("Unable to delete file, please try again later.", "Error", {
          confirmButtonText: "Close",
          showClose: false,
          customClass: "notification-modal",
        });
      }
    },
    updateCarouselIndex(index) {
      this.carouselIndex = index;
    },
    openMediaCarousel(index) {
      this.carouselIndex = index;
      this.showMediaGrid = true;
      this.showMediaCarousel = true;
    },
    closeMediaCarousel() {
      this.carouselIndex = 0;
      this.showMediaCarousel = false;
    },
    closeMediaGrid() {
      this.closeMediaCarousel();
      this.showMediaGrid = false;
    },
    previousCarouselItem() {
      document.querySelectorAll("video").forEach((vid) => vid.pause());
      this.$refs.mediaCarousel.prev();
    },
    nextCarouselItem() {
      document.querySelectorAll("video").forEach((vid) => vid.pause());
      this.$refs.mediaCarousel.next();
    },
    fileIsVideoOrImage(file) {
      return file.media_type == "video" || file.media_type == "image";
    },
    fileIsVideo(file) {
      return file.media_type == "video";
    },
    fileIsImage(file) {
      return file.media_type == "image";
    },
    validateFileUpload({ raw: file }) {
      if (ALLOWED_TYPES.indexOf(file.type) == -1) {
        this.$alert(
          "The file must be a file of type: jpg, png, jpeg, pdf, mp4, ogv, webm, ppt, pptx, doc, docx, xls, xlsx, odt, odp, ods.",
          "Error",
          {
            confirmButtonText: "Close",
            showClose: false,
            customClass: "notification-modal",
          }
        );
        return false;
      }
      if (MAX_FILE_SIZE < file.size) {
        this.$alert("File size must be less than 200MB.", "Error", {
          confirmButtonText: "Close",
          showClose: false,
          customClass: "notification-modal",
        });
        return false;
      }
      return true;
    },
    fileUploaded(file) {
      if (!this.validateFileUpload(file)) return;

      this.mainLoader = true;

      const formData = new FormData();
      formData.append("file", file.raw);

      gsApi.globalscot.user
        .uploadFile(formData)
        .then(({ data }) => {
          this.globalScot.media.data.unshift(data.data);
        })
        .catch((error) => {
          let message = "Something went wrong. Please try again later.";

          if (error.code === "ECONNABORTED") {
            (message =
              "The upload took too long at the current time, please try again later."),
            "Error";
          } else if (error.status == 422) {
            let errorData = error.data.errors;
            let errors = [];
            for (let item in errorData) {
              errors.push(errorData[item][0]);
            }
            message = errors.join(" ");
          } else if (error.status == 413) {
            message = `File is too large. Must be below 200MB!`;
          }

          this.$alert(message, "Error", {
            confirmButtonText: "Close",
            showClose: false,
            customClass: "notification-modal",
          });
        })
        .finally(() => {
          this.mainLoader = false;
        });
    },
    editProfile() {
      this.$router.push({ name: "Edit Profile" });
    },
    fetchGsProfile() {
      gsApi.users
        .profile(this.$route.params.id, {})
        .then((response) => {
          this.mainLoader = false;
          if (
            !response.data.expertProfile ||
            !response.data.roles.includes("expert")
          ) {
            this.$alert(
              "Unable to load users profile. Please try again later.",
              "Error",
              {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal",
              }
            );
            this.$router.push({ name: "Members" });
          } else if (response.data.suspended) {
            this.$alert("User is not currently active.", "Error", {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal",
            });
            this.$router.push({ name: "Members" });
          } else {
            this.globalScot = response.data;
          }
        })
        .catch((error) => {
          this.mainLoader = false;
          this.$alert(
            "Unable to load users profile. Please try again later.",
            "Error",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal",
            }
          );
          this.$router.push({ name: "Members" });
        });
    },
    checkRole() {
      this.isNonGlobalScotViewing = false;
      this.isProfileOwner = false;
      this.isGlobalScotViewing = false;

      if (this.isRole("trader") && this.user.id !== this.$route.params.id) {
        this.isNonGlobalScotViewing = true;
      }

      if (this.user.id == this.$route.params.id) {
        this.isProfileOwner = true;
        return;
      }

      if (this.isRole("expert") && this.user.id !== this.$route.params.id) {
        this.isGlobalScotViewing = true;
      }
    },
    followUnfollowProfile() {
      this.startAction = true;
      let action = this.globalScot.has_followed
        ? enumHelper.FollowAction.UNFOLLOW
        : enumHelper.FollowAction.FOLLOW;
      const data = {
        id: this.$route.params.id,
        module: "User",
        entity: "User",
      };
      gsApi.followable
        .followUnfollow(data, action)
        .then((response) => {
          this.globalScot.has_followed = response.data.has_followed;
          this.globalScot.followers_count = response.data.followers_count;
          if (action == enumHelper.FollowAction.FOLLOW) {
            this.$alert(
              `Your are now successfully following ${this.globalScot.full_name}.`,
              "Success",
              {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal",
              }
            );
          } else {
            this.$alert(
              `Your have successfully unfollowed ${this.globalScot.full_name}.`,
              "Success",
              {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal",
              }
            );
          }
        })
        .catch((response) => {
          if (response.status == 422) {
            let firstError =
              response.data.errors[Object.keys(response.data.errors)[0]];
            this.$alert(firstError[0], "Error", {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal",
            });
          } else {
            this.$alert(
              "Sorry, your request could not be processed",
              `Error ${error.response.data.error.status_code} - ${error.response.data.error.message}`,
              {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal",
              }
            );
          }
        })
        .finally(() => {
          this.startAction = false;
        });
    },
    goBackToEventPage() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss">
.vertical-line {
  top: 4px;
  margin-top: 0px !important;
  margin: 0 10px !important;
  position: relative;
  height: 18px;
}
</style>
