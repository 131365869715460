<template>
  <el-row
    v-loading="loading"
    class="py-1 multi-factor-method mb-2"
  >
    <el-col
      :xs="2"
      :sm="2"
      class="d-flex align-items-center item-height"
    >
      <component :is="type == enumHelper.MFAType.GOOGLE ? 'svg-google-authentication' : type == enumHelper.MFAType.PHONE ? 'svg-phone-authentication' : 'svg-mail-authentication' " />
    </el-col>
    <el-col
      :xs="method ? 19 : 12"
      :sm="10"
      class="d-flex align-items-center item-height"
    >
      {{ getLabelText }}
      <i
        v-if="method?.is_default"
        class="ml-2 el-icon-success text-success "
      ></i>
    </el-col>
    <el-col
      :xs="method ? 12 : 0"
      :sm="8"
      class="d-flex align-items-center item-height"
    >
      <p>{{ method && method.is_enabled ? "Enabled" : "" }}</p>
    </el-col>
    <el-col
      :xs="method ? 12 : 10"
      :sm="4"
    >
      <el-dropdown
        v-if="method"
        size="small"
        class="float-right"
        @command="onActionSelected"
      >
        <el-button type="primary">
          Action <i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="change">
            Change
          </el-dropdown-item>
          <el-dropdown-item command="remove">
            Remove
          </el-dropdown-item>
          <el-dropdown-item
            v-if="method && !method.is_default"
            command="mark-as-default"
          >
            Make preferred
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-button
        v-else
        type="text"
        size="small"
        class="p-0 pull-right btn-setup"
        @click="setupMethod()"
      >
        Setup
      </el-button>
    </el-col>
  </el-row>
</template>
<script>
import SvgGoogleAuthentication from "@/components/assets/svg-google-authentication";
import SvgPhoneAuthentication from "@/components/assets/svg-phone-authentication";
import SvgMailAuthentication from "@/components/assets/svg-mail-authentication";
import enumHelper from '@/helpers/enumHelper'
export default {
  components: {
    SvgGoogleAuthentication,
    SvgPhoneAuthentication,
    SvgMailAuthentication
  },
  props: {
    method: {
      type: Object,
      default: null,
    },
    type:{
      type: Number,
      required:true
    },
    loading:{
      type: Boolean,
      default:false
    }
  },
  data(){
    return {
      enumHelper
    }
  },
  computed: {
    getLabelText(){

      if(this.type == enumHelper.MFAType.PHONE){
        return  `Text Message ${this.method ? `– number ending in ${this.method.two_factor_method}` : ''}`
      }
      if(this.type == enumHelper.MFAType.EMAIL){
        return  `Email ${this.method ? `– address ${this.method.two_factor_method}` : ''}`
      }

      return "Google Authenticator"
    }
  },
  methods: {
    onActionSelected(event){
      this.$emit(event, this.method)
    },
    setupMethod(){
      this.$emit('setup', this.type)
    }
  }
};
</script>
<style lang="scss">
.multi-factor-method{
  .el-dropdown{
    .el-button{
      padding: 5px 15px !important;
      min-height: auto !important;
    }
  }
  
}
</style>