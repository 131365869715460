export default function ( editor ) {
    // The upcast converter will convert <a class="mention" href="" data-user-id="">
    // elements to the model 'mention' attribute.
    editor.conversion.for( 'upcast' ).elementToAttribute( {
        view: {
            name: 'strong',
            key: 'data-mention',
            classes: 'mention',
            attributes: {
                'data-user-id': true
            }
        },
        model: {
            key: 'mention',
            value: viewItem => {
                // The mention feature expects that the mention attribute value
                // in the model is a plain object with a set of additional attributes.
                // In order to create a proper object, use the toMentionAttribute helper method:
                const mentionAttribute = editor.plugins.get( 'Mention' ).toMentionAttribute( viewItem, {
                    // Add any other properties that you need.
                    user_id: viewItem.getAttribute( 'data-user-id' )
                } );

                return mentionAttribute;
            }
        },
        converterPriority: 'high'
    } );

    // Downcast the model 'mention' text attribute to a view <a> element.
    editor.conversion.for( 'downcast' ).attributeToElement( {
        model: 'mention',
        view: ( modelAttributeValue, viewWriter ) => {
            // Do not convert empty attributes (lack of value means no mention).
            if ( !modelAttributeValue ) {
                return;
            }

          if ( modelAttributeValue.id[ 0 ] === '@' ) {
            return viewWriter.createAttributeElement( 'strong', {
              class: 'mention',
              'data-mention': modelAttributeValue.id,
              'data-user-id': modelAttributeValue.user_id,
            } );
          } else if (modelAttributeValue.id[ 0 ] === '#') {
            return viewWriter.createAttributeElement( 'strong', {
              class: 'mention',
              'data-mention': modelAttributeValue.id,
              'data-user-id': modelAttributeValue.user_id + Date.now(),
              'data-hashtag': modelAttributeValue.id
            } );
          }
        },
        converterPriority: 'high'
    } );
}
