<template>
  <slide-panel
    title="Menu"
    @panelclose="closeMenu"
  >
    <div class="hr mb-3"></div>
    <div
      class="profile-section justify-content-between d-flex"
      @click="goToProfile()"
    ></div>
    <nav>
      <ul
        class="menu-items mt-3"
        role="menubar"
      >
        <li
          class="el-menu-item"
          @click="closeMenu"
        >
          <router-link :to="{ name: 'Home', params: { isActiveFilter: 1 } }">
            {{ user.isLogged ? "Feed" : "Home" }}
          </router-link>
        </li>
        <li
          class="el-menu-item"
          @click="closeMenu"
        >
          <router-link :to="{ name: 'Membership' }">
            Membership
          </router-link>
        </li>
        <li
          class="el-menu-item"
          @click="closeMenu"
        >
          <router-link :to="{ name: 'About' }">
            About our organisation
          </router-link>
        </li>
        <li
          class="el-menu-item"
          @click="closeMenu"
        >
          <router-link :to="{ name: 'Scotland House Brussels' }">
            Scotland House Brussels
          </router-link>
        </li>
        <li
          class="el-menu-item"
          @click="closeMenu"
        >
          <router-link :to="{ name: 'News and Resources Public' }">
            News & resources
          </router-link>
        </li>

        <li
          class="el-menu-item"
          @click="closeMenu"
        >
          <router-link :to="{ name: 'Business Events Webinars' }">
            Events & webinars
          </router-link>
        </li>

        <li
          class="el-menu-item"
          @click="closeMenu"
        >
          <router-link :to="{ name: 'Contact Us' }">
            Contact us
          </router-link>
        </li>
      </ul>
    </nav>
    <div class="hr mt-3 mb-3"></div>

    <ul class="menu-links">
      <li @click="closeMenu">
        <router-link
          v-if="
            !user.isLogged &&
              !userRequiresVerification &&
              $route.path !== '/sign-up-select' &&
              $route.name !== 'NotFound' &&
              $route.name !== 'Reset Password'
          "
          class="el-button el-button--primary el-button--medium"
          to="/sign-up-select"
          tabindex="0"
        >
          Join Scotland Europa
        </router-link>
      </li>
      <li @click="closeMenu">
        <router-link
          v-if="
            !user.isLogged &&
              !userRequiresVerification &&
              $route.path !== '/login' &&
              $route.name !== 'NotFound' &&
              $route.name !== 'Reset Password'
          "
          class="el-button el-button--medium"
          to="/login"
          tabindex="0"
        >
          Log in
        </router-link>
      </li>
      <li @click="closeMenu">
        <el-button
          v-if="
            (user.isLogged || userRequiresVerification) &&
              $route.name !== 'Reset Password'
          "
          class="el-button el-button--medium"
          tabindex="0"
          @click="logout($event)"
        >
          Logout
        </el-button>
      </li>
    </ul>
  </slide-panel>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import SlidePanel from "@/components/slide-panel/slide-panel";

export default {
  name: "MobileMenu",
  components: {
    "slide-panel": SlidePanel,
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["userRequiresVerification"]),
    isGlobalScot() {
      return this.user.expertProfile && this.user.roles.includes("expert");
    },
  },
  methods: {
    goToProfile() {
      if (this.isGlobalScot) {
        this.closeMenu();
        this.$router.push({
          name: "SE Profile",
          params: { id: this.user.id },
        });
      } else {
        this.closeMenu();
        this.$router.push({
          name: "NonGlobalScot Profile",
          params: { id: this.user.id },
        });
      }
    },
    closeMenu() {
      this.$emit("close");
    },
  },
};
</script>
