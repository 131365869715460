<template>
  <div class="purple-help-section">
    <div class="get-in-touch">
      <div class="get-in-touch-text">
        <h1>Need to get in touch with the Scotland Europa team?</h1>
      </div>
      <div>
        <router-link :to="{ name: 'Contact Us' }">
          <el-button type="primary"
            class="btn-text get-in-contact-button"
            size="small"
          >Contact Scotland Europa</el-button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
  }
};
</script>
