<!-- About Component -->
<template>
  <div class="about-page">
    <el-row :gutter="0" class="m-0">
      <top-navigation></top-navigation>
      <el-col :span="24" class="article-featured px-0">
        <div class="organisation-page__header small-header">
          <div class="header__content__bg"></div>
          <div class="header__image__bg about-org-bg"></div>
          <div class="header__content container">
            <el-row>
              <el-col :md="24" :lg="12">
                <div class="header__content__wrapper">
                  <h1 id="main-content" class="header__title">{{text.headingText}}</h1>
                  <p class="header__content-info">{{text.headingSub}}</p>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
    <div>
      <about-us-and-what-we-do></about-us-and-what-we-do>
    </div>
  </div>
</template>

<script>
import TopNavigation from "../../layout/top-navigation";
import AboutUsAndWhatWeDo from "../../pages/landing/about/about-us-and-what-we-do";
export default {
  name: "about",
  metaInfo: {
    title: "About us and what we do",
    meta: [{
      name: 'description',
      content: "Scotland Europa is a membership-based organisation that promotes Scotland's interests across the institutions of the European Union and to the representatives of Europe's regions and Member States."
    }]
  },
  components: {
    AboutUsAndWhatWeDo,
    TopNavigation
  },
  data() {
    return {
      text: {
        headingText: "About us and what we do",
        headingSub:
          "Scotland Europa is a membership-based organisation that promotes Scotland's interests across the institutions of the European Union and to the representatives of Europe's regions and Member States. Find out more about who we are and what we do."
      }
    };
  }
};
</script>
