<!--Article Page-->
<template>
  <div
    v-loading.fullscreen.lock="mainLoader"
    element-loading-background="rgba(255, 255, 255, 0.8)"
    class="news-detail-page"
  >
    <div v-if="alert">
      <news-header
        :image="{path: require('../../../../static/img/se-email-alert-header.png')}"
        alt-text="email alert"
      ></news-header>
      <div class="news-content content-container">
        <div class="container">
          <el-row>
            <el-col
              :md="24"
              :lg="16"
              class="left-content"
            >
              <h2 class="news-title">
                {{ alert.title }}
              </h2>
              <div class="d-flex mt-3">
                <div class="type-article">
                  Email Alert :
                </div>
                <div class="added-on">
                  {{ alert.created_at }}
                </div>
              </div>
              <hr class="content-hr" />
              <div
                v-if="alert.tag"
                class="alert-tag"
              >
                {{ alert.tag.name }}
              </div>
              <div
                class="news-content"
                v-html="$options.filters.domPurify(content)"
              ></div>

              <a
                v-if="alert.url"
                :href="alert.url"
                target="_blank"
                class="el-button el-button--primary"
                style="max-width: 200px"
              >
                Read more <span class="sr-only">this link will open in a new window</span>
              </a>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "../../../store";
import gsApi from "@/services/gs.api";
import NewsHeader from "@/components/partials/news-header";
export default {
  name: "AlertEmailDetails",
  metaInfo() {
    return {
      title: "News & Resources",
      meta: [
        {
          property: "og:title",
          content: this.alert ? this.alert.title : null,
          vmid: "og:title",
        },
        {
          property: "og:image",
          content: "",
          vmid: "og:image",
        },
        {
          property: "og:url",
          content: window.location.href,
          vmid: "og:url",
        },
        {
          property: "og:site_name",
          content: "Scotland Europa",
          vmid: "og:site_name",
        },
      ],
      /*link:[
        {
          rel:"image_src",
          href:this.article && this.article.mediaFile ? this.article.mediaFile.medium_thumb  : null,
        }
      ]*/
    };
  },
  components: {
    "news-header": NewsHeader,
  },
  data() {
    return {
      alert: null,
      mainLoader: true,
      relatedItems: [],
    };
  },
  computed: {
    content() {
      let cloneDiv = document.createElement("div");
      cloneDiv.innerHTML = this.alert.content;
      return cloneDiv.innerHTML.replaceAll("&nbsp;", "");
    },
  },
  watch: {
    $route(to, from) {
      this.fetchEmailAlert();
    },
  },
  created() {
    if (store.state.user.isLogged) {
      this.fetchEmailAlert();
    }
  },
  methods: {
    getRelatedItems() {
      /*let params = {
        item_id: this.article.id,
        type: "Modules\\Article\\Entities\\Article",
        entity_type: "article__articles",
        take: 2
      };

      gsApi.articles
        .getRelatedItems(params)
        .then(response => {
          this.relatedItems = response.data.data;
        })
        .catch(error => {
          this.relatedItems = [];
        });*/
    },
    fetchEmailAlert() {
      gsApi.alerts
        .getById(this.$route.params.id)
        .then((response) => {
          this.mainLoader = false;
          this.alert = response.data;
        })
        .catch((error) => {
          this.mainLoader = false;
          this.$router.push({ name: "News and Features" });
        });
    },
  },
};
</script>
<style lang="scss">
.alert-tag {
  background-color: #ea6576;
  padding: 10px;
  color: #fff;
  margin-bottom: 15px;
  width: fit-content;
}
</style>