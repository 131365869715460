export default {
  methods: {
    dialogOpen(dialogRef) {
      let dialog = this.$refs[dialogRef];
      if (dialog) {
        dialog.$parent.handleDialogFocusLock();
      }
    }
  }
};
