<template>
  <div class="terms-page">
    <section aria-label="Cookie Policy">
      <div class="container text-center">
        <h1 class="text-white text-center">
          Cookie policy
        </h1>
      </div>
    </section>
    <div class="container">
      <el-row>
        <el-col
          :md="24"
          :lg="6"
          :xl="6"
        >
          <div class="terms-policies">
            <terms-policies></terms-policies>
          </div>
        </el-col>
        <el-col
          :md="24"
          :lg="18"
          :xl="18"
        >
          <div class="cookie-policy">
            <h2
              id="main-content"
              class="title"
            >
              What is a cookie?
            </h2>
            <p>
              This website uses cookies to distinguish you from other users of this website. A
              cookie is a small file of letters and numbers that we store on your browser or the
              hard drive of your computer if you agree. Cookies contain information that is
              transferred to your computers hard drive.
            </p>
            <p>
              Cookies help the delivery partners of findbusinessupport.gov.uk to provide you with a
              good experience when you browse our website and also allow us to improve our
              website. We assume that you are happy to receive all the cookies that your browser
              settings allow. You can change your cookie settings at any time.
            </p>
            <p>
              We use the following cookies:
            </p>
            <ul class="cookie-list">
              <li>
                <p>
                  <strong>Strictly necessary cookies -</strong>
                  These are cookies that are required for the operation
                  of our website. They include, for example, cookies that enable you to log into secure
                  areas of our website.
                </p>
              </li>
              <li>
                <p>
                  <strong>Analytical / performance cookies -</strong>
                  They allow us to recognise and count the
                  number of visitors and to see how visitors move around our website when they are
                  using it. This helps us to improve the way our website works, for example, by
                  ensuring that users are finding what they are looking for easily.
                  You can find more information about the individual cookies we use and the purposes
                  for which we use them in this table:
                </p>
              </li>
            </ul>
            <div
              id="cookie-table"
              class="mb-5 w-100"
            >
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Essential to the users</th>
                    <th>Supplied from</th>
                    <th>Expiry length</th>
                    <th>Use</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>XSRF-TOKEN</td>
                    <td>YES</td>
                    <td>.scotlandeuropa.com</td>
                    <td>End of Session</td>
                    <td>This cookie is written to help with site security in preventing Cross-Site Request Forgery attacks.</td>
                  </tr>
                  <tr>
                    <td>laravel_session</td>
                    <td>YES</td>
                    <td>.scotlandeuropa.com</td>
                    <td>End of Session</td>
                    <td>This is used to hold information about your current visit with us. This cookie is essential to the security and functionality of the website.</td>
                  </tr>
                  <tr>
                    <td>awsalb</td>
                    <td>YES</td>
                    <td>.scotlandeuropa.com</td>
                    <td>End of Session</td>
                    <td>This is used to hold and manage session information and routing for the user.</td>
                  </tr>
                  <tr>
                    <td>awsalbcors</td>
                    <td>YES</td>
                    <td>.scotlandeuropa.com</td>
                    <td>End of Session</td>
                    <td>This is used to hold and manage session information and routing for the user.</td>
                  </tr>
                  <tr>
                    <td><strong>Google Analytics</strong></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>_ga</td>
                    <td>YES</td>
                    <td>.scotlandeuropa.com</td>
                    <td>after 2 years</td>
                    <td>
                      This cookie is used to distinguish unique
                      users by assigning a randomly generated
                      number as a client identifier. It is included in
                      each page request in a site and used to
                      calculate visitor, session and campaign data
                      for the sites analytics reports. Examples of
                      purposes for which a cookie may be used:
                      Used to distinguish users and sessions. The
                      cookie is updated every time data is sent to
                      Google Analytics.
                    </td>
                  </tr>
                  <tr>
                    <td>_gat</td>
                    <td>YES</td>
                    <td>.scotlandeuropa.com</td>
                    <td>after 1 minute</td>
                    <td> Used to limit the collection of analytics data on high traffic websites.</td>
                  </tr>
                  <tr>
                    <td>_gid</td>
                    <td>YES</td>
                    <td>.scotlandeuropa.com</td>
                    <td> When browser is closed</td>
                    <td> This cookie is used to distinguish unique users.</td>
                  </tr>
                  <tr>
                    <td><strong>Site Improve</strong></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>nmstat</td>
                    <td>YES</td>
                    <td>.scotlandeuropa.com</td>
                    <td>after 1,000 days</td>
                    <td>
                      This cookie is used to help record the visitor’s
                      use of the website. It is used to collect statistics
                      about site usage such as when the visitor last
                      visited the site.
                    </td>
                  </tr>
                  <tr>
                    <td>siteimproveses</td>
                    <td>YES</td>
                    <td>.scotlandeuropa.com</td>
                    <td> When browser is closed </td>
                    <td>
                      This cookie is used purely to track the sequence
                      of pages a visitor looks at during a visit to the
                      site.
                    </td>
                  </tr>
                  <tr>
                    <td><strong>Other cookies</strong></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Accept Cookie</td>
                    <td>YES</td>
                    <td>.scotlandeuropa.com</td>
                    <td>After 2 years</td>
                    <td>Stores the users acceptance of the cookie policy</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p>
              This website will not use cookies to collect personally identifiable information about
              you. However, if you wish to restrict or block the cookies which are set by this, or any
              third party websites, you can do this through your browser settings. The Help
              function within your browser should tell you how.
            </p>
            <p>
              Alternatively, you may wish to visit the <a>About cookies</a> website, which contains
              comprehensive information about cookies and how to restrict or delete cookies on a
              wide variety of browsers
            </p>
            <p>
              Please be aware that restricting cookies may impact on the functionality of the website.
            </p>
            <p>
              This and our other websites use a number of suppliers who set cookies on our behalf
              in order to deliver the services that they are providing. We are constantly reviewing
              our use of cookies and, as such, this cookies policy will be regularly renewed to
              include up to date information about the cookies used by our suppliers. We would
              highly recommend that you check this page on a regular basis.
            </p>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import TermsPolicies from '../../components/partials/terms-policies'
export default {
  name: 'CookiePolicy',
  metaInfo: {
    title: "Cookie policy",
    meta: [{
      name: 'description',
      content: "Read the cookie policy for the Scotland Europa website."
    }]
  },
  components: {
    TermsPolicies
  }
}
</script>

<style scoped>
.cookie-list li{
  list-style: disc;
}
</style>
