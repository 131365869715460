<!--Article Page-->
<template>
  <div
    v-loading.fullscreen.lock="mainLoader"
    element-loading-background="rgba(255, 255, 255, 0.8)"
    class="news-detail-page"
  >
    <div v-if="article">
      <news-header
        :image="article.mediaFile"
        :alt-text="altText"
      ></news-header>

      <div
        v-if="isSpotlight"
        :id="isSpotlight ? 'main-content' : ''"
        class="spotlight-content content-container"
      >
        <div class="container">
          <el-row>
            <el-col
              :md="5"
              :lg="4"
              :xl="3"
            >
              <user-avatar
                shape="circle"
                :is-global-scot="article.spotlightUser.is_global_scot"
                :author-exist="!!article.spotlightUser"
                width="134px"
                :size="134"
                :src="avatar"
                :alt="
                  article.spotlightUser
                    ? `${article.spotlightUser.first_name} ${article.spotlightUser.last_name}`
                    : '' + 'Profile picture'
                "
              ></user-avatar>
            </el-col>
            <el-col
              :md="19"
              :lg="15"
              :xl="17"
            >
              <div class="d-flex flex-column spotlight-name">
                <h4>Spotlight</h4>
                <h2>
                  {{ article.spotlightUser.first_name }}
                  {{ article.spotlightUser.last_name }}
                </h2>
              </div>
              <div class="d-flex role">
                <span>{{ article.spotlightUser.primary_business.role }}</span>
                <span class="ml-2 mr-2"> | </span>
                <span>{{ article.spotlightUser.primary_business.name }}</span>
              </div>
              <div class="location d-flex">
                <p class="d-flex">
                  <svg-map-pin-gradient
                    class="mr-2 map-pin"
                  ></svg-map-pin-gradient>{{ location }}
                </p>
                <p class="d-flex ml-4">
                  <svg-gs-profile-icon
                    class="mr-2 gs-globe"
                  ></svg-gs-profile-icon>
                  GlobalScot for, {{ memberFor }}
                </p>
              </div>
            </el-col>
            <el-col
              :md="24"
              :lg="5"
              :xl="4"
            >
              <el-button
                type="primary"
                class="view-profile-btn"
                @click="viewProfile"
              >
                View profile
              </el-button>
            </el-col>
          </el-row>

          <hr class="content-hr" />
          <el-row>
            <el-col
              :md="24"
              :lg="16"
              class="left-content"
            >
              <p class="news-description">
                {{ article.description }}
              </p>
              <div
                class="news-content"
                v-html="$options.filters.domPurify(content)"
              ></div>
              <div v-if="attachedFiles.length">
                <document-view :attached-files="attachedFiles" />
              </div>
            </el-col>
            <el-col
              :md="24"
              :lg="8"
              class="sidebar-content"
              role="complementary"
            >
              <h4 class="related-news-title mb-3">
                Sectors
              </h4>
              <el-row>
                <el-col
                  v-for="sector in sectors"
                  :key="sector.id"
                  :md="24"
                >
                  <div class="sector-pill sector">
                    {{ sector.name }}
                  </div>
                  <div v-if="sector.subGroups.data.length > 0">
                    <div
                      v-for="subSector in sector.subGroups.data"
                      :key="subSector.id"
                      class="sector-pill sub-sector"
                    >
                      {{ subSector.name }}
                    </div>
                  </div>
                </el-col>
              </el-row>

              <h4 class="related-news-title mb-3 mt-4">
                Expertise
              </h4>
              <list-show-more
                :items="expertises"
                class="mb-5"
              >
                <template slot-scope="{ item }">
                  <span>{{ item.name }}</span>
                </template>
              </list-show-more>
            </el-col>
          </el-row>
        </div>
      </div>

      <div
        v-else
        class="news-content content-container"
      >
        <div class="container">
          <el-row>
            <el-col
              :md="24"
              :lg="16"
              class="left-content"
            >
              <h2
                :id="!isSpotlight ? 'main-content' : ''"
                class="news-title"
              >
                {{ article.title }}
              </h2>
              <div class="d-flex mt-3">
                <div class="type-article">
                  Article
                </div>
                <div class="added-on">
                  {{ article.created_at }}
                </div>
              </div>
              <hr class="content-hr" />
              <p class="news-description">
                {{ article.description }}
              </p>
              <div
                class="news-content"
                v-html="$options.filters.domPurify(content)"
              ></div>
              <div v-if="attachedFiles.length">
                <document-view :attached-files="attachedFiles" />
              </div>
              <div v-if="showFeaturedUsers">
                <hr class="content-hr" />
                <div class="featured-podcast-section">
                  <b>
                    {{
                      article.type == 1
                        ? "Featured members"
                        : "Members featured in this Case study"
                    }}:</b>
                  <div
                    v-for="member in article.spotlightUser.data"
                    :key="member.id"
                    class="member-card d-flex"
                  >
                    <user-avatar
                      shape="circle"
                      :is-global-scot="member.is_global_scot"
                      :author-exist="!!member"
                      width="40px"
                      :size="40"
                      :src="member.avatar"
                      :alt="member ? member.fullname : '' + 'Profile picture'"
                    ></user-avatar>
                    <router-link
                      v-if="member.is_global_scot"
                      :to="{
                        name: 'SE Profile',
                        params: { id: member.id, showBackToSearch: false },
                      }"
                    >
                      {{ member.fullname }}
                    </router-link>
                    <router-link
                      v-else
                      :to="{
                        name: 'NonGlobalScot Profile',
                        params: { id: member.id, showBackToSearch: false },
                      }"
                    >
                      {{ member.fullname }}
                    </router-link>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col
              :md="24"
              :lg="8"
              role="complementary"
            >
              <h4 class="related-news-title mb-3">
                Other news and resources
              </h4>
              <el-row v-if="relatedItems.length > 0">
                <el-col
                  v-for="relatedItem in relatedItems"
                  :key="relatedItem.id"
                  :sm="24"
                  :md="12"
                  :lg="24"
                >
                  <news-card
                    :key="relatedItem.id"
                    :article="relatedItem"
                  />
                </el-col>
              </el-row>
              <el-row v-else>
                <el-col :md="24">
                  <p>No other news found</p>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import store from "../../../store";
import gsApi from "@/services/gs.api";
import NewsCard from "@/components/platform/news-features/news-card";
import NewsHeader from "@/components/partials/news-header";
import ListShowMore from "@/components/partials/list-show-more";
import MapPinGradient from "@/components/assets/svg-map-pin-gradient";
import UserAvatar from "@/components/core/user-avatar";
import SvgGsProfileicon from "@/components/assets/svg-gs-profile-icon";
import DocumentView from "@/components/core/document-view";
import helpers from "@/helpers/index";
export default {
  name: "NewsDetails",
  metaInfo() {
    return {
      title: "News & Resources",
    };
  },
  components: {
    "news-card": NewsCard,
    "news-header": NewsHeader,
    "svg-map-pin-gradient": MapPinGradient,
    "svg-gs-profile-icon": SvgGsProfileicon,
    ListShowMore,
    UserAvatar,
    DocumentView
  },
  data() {
    return {
      article: null,
      mainLoader: true,
      relatedItems: [],
      attachedFiles: [],
    };
  },
  computed: {
    location() {
      return this.article.spotlightUser.country &&
        this.article.spotlightUser.state
        ? this.article.spotlightUser.state.name +
            ", " +
            this.article.spotlightUser.country.name
        : "";
    },
    memberFor() {
      let memberFor = moment(
        this.article.spotlightUser.created_at.date
      ).fromNow(true);
      return memberFor;
    },
    altText() {
      return this.article.mediaFile && this.article.mediaFile.alt_attribute
        ? this.article.mediaFile.alt_attribute
        : null;
    },
    isSpotlight() {
      return this.article.type_name == "Spotlight";
    },
    avatar() {
      return this.article.spotlightUser && this.article.spotlightUser.avatar
        ? this.article.spotlightUser.avatar
        : "";
    },
    sectors() {
      let sectors = this.article.spotlightUser.groups.data;
      return sectors;
    },
    expertises() {
      return this.article.spotlightUser && this.article.spotlightUser.expertise
        ? this.article.spotlightUser.expertise
        : [];
    },
    showFeaturedUsers() {
      // 1 : Featured , 3: Case Study
      return (
        (this.article.type == 1 || this.article.type == 3) &&
        this.article.spotlightUser &&
        this.article.spotlightUser.data &&
        this.article.spotlightUser.data.length > 1
      );
    },
    content() {
      let cloneDiv = document.createElement("div");
      cloneDiv.innerHTML = this.article.content;

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.$set(this, "attachedFiles", helpers.getAttachedFiles(cloneDiv));

      return cloneDiv.innerHTML.replaceAll("&nbsp;", "");
    },
  },
  watch: {
    $route(to, from) {
      this.mainLoader = true;

      gsApi.articles
        .getBySlug(to.params.slug)
        .then((response) => {
          this.mainLoader = false;
          this.article = response.data;

          if (this.article.type_name !== "Spotlight") {
            this.getRelatedItems();
          }
        })
        .catch((error) => {
          this.$router.push({ name: "News and Features" });
        });
    },
  },
  created() {
    if (store.state.user.isLogged) {
      this.fetchArticle();
    }
  },
  methods: {
    viewProfile() {
      if (this.article.spotlightUser.suspended) {
        this.$alert("User is not currently active.", "Error", {
          confirmButtonText: "Close",
          showClose: false,
          customClass: "notification-modal",
        });
        return;
      } else {
        this.$router.push({
          name: "SE Profile",
          params: {
            id: this.article.spotlightUser.id,
            showBackToSearch: false,
          },
        });
      }
    },
    getRelatedItems() {
      let params = {
        item_id: this.article.id,
        type: "Modules\\Article\\Entities\\Article",
        entity_type: "article__articles",
        take: 2,
      };

      gsApi.articles
        .getRelatedItems(params)
        .then((response) => {
          this.relatedItems = response.data.data;
        })
        .catch((error) => {
          this.relatedItems = [];
        });
    },
    fetchArticle() {
      gsApi.articles
        .getBySlug(this.$route.params.slug)
        .then((response) => {
          this.mainLoader = false;
          this.article = response.data;

          if (this.article.type_name !== "Spotlight") {
            this.getRelatedItems();
          }
        })
        .catch((error) => {
          this.mainLoader = false;
          this.$router.push({ name: "News and Features" });
        });
    },
  },
};
</script>
