import axios from "axios";
import store from "@/store";
import router from "@/router";
import headers from "./gs-http-headers.api";

let instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 60000,
});

instance.interceptors.request.use((config) => {
  config.headers = headers();

  return config;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (store.state.user && store.state.user.isLogged) {
      if (
        error.response &&
        error.response.status == 401 &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.message == "Invalid User Token"
      ) {
        store.commit("USER_LOGOUT");
        router.push("/login");
      } else if (
        error.response &&
        error.response.status == 403 &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.message == "Your account has been suspended."
      ) {
        store.commit("USER_LOGOUT_BY_SUSPENSION");
        router.push("/login");
      } else if (
        error.response &&
        error.response.status == 403 &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.message == "Your account has been closed."
      ) {
        store.commit("USER_LOGOUT_BY_CLOSED_ACCOUNT");
        router.push("/login");
      }
    }
    return Promise.reject(error);
  }
);

export default {
  users: {
    checkInviteCode(params = {}) {
      return instance({
        url: "/users/check-invite-code",
        method: "get",
        params: params,
      });
    },
    recentActivity(params = {}) {
      return instance({
        url: "users/activities",
        method: "get",
        params: params,
      });
    },
    deleteActivity(activityId) {
      return instance({
        url: `users/activities/${activityId}`,
        method: "delete",
      });
    },
    profile(userId, params = {}) {
      return instance({
        url: `/users/profile/${userId}`,
        method: "get",
        params: params,
      });
    },
    updatePassword(form) {
      return instance({
        url: `/users/change_password`,
        method: "post",
        data: form,
      });
    },
    updateEmail(form) {
      return instance({
        url: `/users/settings/change-email`,
        method: "post",
        data: form,
      });
    },
    checkActivationEmailExpiration(params) {
      return instance({
        url: `/users/settings/check-activation-email-expiration`,
        method: "get",
        params: params,
      });
    },
    activateNewEmail(params) {
      return instance({
        url: `/users/settings/activate-new-email`,
        method: "post",
        params: params,
      });
    },
    dashboard() {
      return instance({
        url: `/users/dashboard`,
        method: "get",
      });
    },
    public() {
      return instance({
        url: `/users/public-info`,
        method: "get",
      });
    },
    contactUs(form) {
      return instance({
        url: `/users/contact-us`,
        method: "post",
        data: form,
        headers: { "Content-Type": "multipart/form-data" },
      });
    },
    entityAuthor(userId) {
      return instance({
        url: `/users/directory/${userId}/entity-author`,
        method: "get",
      });
    },
    updateTimeZone(params) {
      return instance({
        url: "/users/timezone",
        method: "patch",
        params: params,
      });
    },
  },
  mfa: {
    //----- Start Google authentication
    generateQRCode() {
      return instance({
        url: "/mfa/two-factor-qr-code",
        method: "get",
      });
    },
    confirmedTwoFactorGoogleAuthentication(params) {
      return instance({
        url: "/mfa/confirmed-two-factor-authentication",
        method: "POST",
        params,
      });
    },
    twoFactorGoogleAuthentication(params) {
      return instance({
        url: "/mfa/two-factor-authentication",
        method: "POST",
        params,
      });
    },
    //----- End Google authentication

    //----- Start Phone/Email authentication
    multiFactorAuthentification(params) {
      return instance({
        url: "/mfa/multi-factor-authentication",
        method: "POST",
        params,
      });
    },
    confirmedMultiFactorPhoneAuthentication(params) {
      return instance({
        url: "/mfa/confirmed-multi-factor-authentication",
        method: "POST",
        params,
      });
    },
    generateMultiFactorCode(params) {
      return instance({
        url: "/mfa/multi-factor-code",
        method: "GET",
        params,
      });
    },
    //----- END Phone/Email authentication

    multiFactorMethods() {
      return instance({
        url: "/mfa/multi-factor-authentication-methods",
        method: "GET",
      });
    },
    delete(id) {
      return instance({
        url: `/mfa/${id}`,
        method: "DELETE",
      });
    },
    markAsDefault(id) {
      return instance({
        url: `/mfa/mark-as-default/${id}`,
        method: "POST",
      });
    },
  },
  events: {
    attend(eventId) {
      return instance({
        url: `/events/${eventId}/attend`,
        method: "post",
      });
    },
    cancelAttendance(eventId) {
      return instance({
        url: `/events/${eventId}/attend/cancel`,
        method: "post",
      });
    },
    getHostEventDays(eventId, userId) {
      return instance({
        url: `/events/${eventId}/user/${userId}/event-days`,
        method: "get",
      });
    },
    bookASlot(eventDayId, slotId, params = {}) {
      return instance({
        url: `/event-days/${eventDayId}/slots/${slotId}/book`,
        method: "patch",
        params: params,
      });
    },
    changeBookedSlot(slotId, params = {}) {
      return instance({
        url: `/slots/${slotId}/update-booking`,
        method: "patch",
        params: params,
      });
    },
    releaseASlot(eventDayId, slotId) {
      return instance({
        url: `/event-days/${eventDayId}/slots/${slotId}/release`,
        method: "patch",
      });
    },
    attendWebinar(eventId) {
      return instance({
        url: `/events/webinar/${eventId}/attend`,
        method: "post",
      });
    },
    leaveWebinar(eventId) {
      return instance({
        url: `/events/webinar/${eventId}/leave`,
        method: "post",
      });
    },
    search(params = {}) {
      return instance({
        url: "/events",
        method: "get",
        params: params,
      });
    },
    getById(eventId, params = {}) {
      return instance({
        url: `/events/${eventId}`,
        method: "get",
        params: params,
      });
    },
    getBySlug(eventSlug, params = {}) {
      return instance({
        url: `/events/${eventSlug}`,
        method: "get",
        params: params,
      });
    },
    trackWebinarWatch(eventSlug, params = {}) {
      return instance({
        url: `/events/webinar/${eventSlug}`,
        method: "get",
        params: params,
      });
    },
    trackPodcastListen(eventSlug, params = {}) {
      return instance({
        url: `/events/podcast/${eventSlug}`,
        method: "get",
        params: params,
      });
    },
    public() {
      return instance({
        url: `/public-events`,
        method: "get",
      });
    },
    getByIdPublic(eventId, params = {}) {
      return instance({
        url: `/public-events/${eventId}`,
        method: "get",
        params: params,
      });
    },
    getBySlugPublic(eventSlug, params = {}) {
      return instance({
        url: `/public-events/${eventSlug}`,
        method: "get",
        params: params,
      });
    },
    trackPublicWebinarWatch(eventId, params = {}) {
      return instance({
        url: `/public-events/webinar/${eventId}`,
        method: "get",
        params: params,
      });
    },
    trackPublicPodcastListen(eventId, params = {}) {
      return instance({
        url: `/public-events/podcast/${eventId}`,
        method: "get",
        params: params,
      });
    },
    searchPublic(params = {}) {
      return instance({
        url: "/public-events",
        method: "get",
        params: params,
      });
    },
    joinWaitingList(eventSlug, params = {}) {
      return instance({
        url: `/events/${eventSlug}/waiting-list/join`,
        method: "post",
        params: params,
      });
    },
    cancelWaitingList(eventSlug, params = {}) {
      return instance({
        url: `/events/${eventSlug}/waiting-list/cancel`,
        method: "delete",
        params: params,
      });
    },
  },
  articles: {
    search(params = {}) {
      return instance({
        url: "/articles",
        method: "get",
        params: params,
      });
    },
    getById(articleId, params = {}) {
      return instance({
        url: `/articles/${articleId}`,
        method: "get",
        params: params,
      });
    },
    getBySlug(articleSlug, params = {}) {
      return instance({
        url: `/articles/${articleSlug}`,
        method: "get",
        params: params,
      });
    },
    getRelatedItems(params = {}) {
      return instance({
        url: "/feed/related",
        method: "get",
        params: params,
      });
    },
    getPublicRelatedItems(params = {}) {
      return instance({
        url: "/public-feed/related",
        method: "get",
        params: params,
      });
    },
    public(params = {}) {
      return instance({
        url: `/article/public/case-studies`,
        method: "get",
        params: params,
      });
    },
    getByIdPublic(articleId, params = {}) {
      return instance({
        url: `/article/public/case-studies/${articleId}`,
        method: "get",
        params: params,
      });
    },
    getBySlugPublic(slug, params = {}) {
      return instance({
        url: `/article/public/case-studies/${slug}`,
        method: "get",
        params: params,
      });
    },
    searchPublic(params = {}) {
      return instance({
        url: "/article/public",
        method: "get",
        params: params,
      });
    },
  },
  projects: {
    search(params = {}) {
      return instance({
        url: "/challenges",
        method: "get",
        params: params,
      });
    },
    join(projectId) {
      return instance({
        url: `/challenges/${projectId}/join`,
        method: "post",
      });
    },
    leave(projectId) {
      return instance({
        url: `/challenges/${projectId}/leave`,
        method: "post",
      });
    },
    getRelatedItems(params = {}) {
      return instance({
        url: `/challenges/${projectId}/related`,
        method: "get",
        params: params,
      });
    },
    getById(projectId, params = {}) {
      return instance({
        url: `/challenges/${projectId}`,
        method: "get",
        params: params,
      });
    },
  },
  members: {
    search(params = {}) {
      return instance({
        url: "/users/directory",
        method: "get",
        params: params,
      });
    },
    tagsSearch(params = {}) {
      return instance({
        url: "/users/directory/tags",
        method: "get",
        params: params,
      });
    },
    mapSearch(params = {}) {
      return instance({
        url: "/users/directory/map",
        method: "get",
        params: params,
      });
    },
    membersForStates(params = {}) {
      return instance({
        url: "/users/directory/map-by-states",
        method: "get",
        params: params,
      });
    },
    singleMember(memberId) {
      return instance({
        url: `/users/directory/${memberId}`,
        method: "get",
      });
    },
  },
  aspect: {
    index(params = {}) {
      return instance({
        url: "/aspects",
        method: "get",
        params: params,
      });
    },
  },
  auth: {
    resendVerification(id, email) {
      return instance({
        url: "/users/resend-verification",
        method: "post",
        data: {
          id: id,
          email: email,
        },
      });
    },
    validateToken(token) {
      return instance({
        url: "/users/validate-token",
        method: "post",
        data: {
          token: token,
        },
      });
    },
    login(email, password) {
      return instance({
        url: `/users/login`,
        method: "post",
        data: {
          email: email,
          password: password,
          client_id: 2,
        },
      });
    },
    reactivate(email, password) {
      return instance({
        url: `/users/reactivate`,
        method: "post",
        data: {
          email: email,
          password: password,
          client_id: 2,
        },
      });
    },
    unsuspend(email, password) {
      return instance({
        url: `/users/unsuspend`,
        method: "post",
        data: {
          email: email,
          password: password,
          client_id: 2,
        },
      });
    },
    markAsClosed() {
      return instance({
        url: `/users/close-account`,
        method: "get",
      });
    },
    markAsSuspended() {
      return instance({
        url: `/users/suspend-account`,
        method: "get",
      });
    },
    logout(token) {
      return instance({
        url: `/users/logout`,
        method: "post",
        data: {
          user_token: token,
        },
      });
    },
    forgot(email) {
      return instance({
        url: `/users/forgotten-password`,
        method: "post",
        data: {
          email: email,
        },
      });
    },
    reset(data, userId, code) {
      return instance({
        url: `/users/reset-password/${userId}/${code}`,
        method: "post",
        data: data,
      });
    },
  },
  country: {
    index(params = {}) {
      return instance({
        url: "/companies/countries",
        method: "get",
        params: params,
      });
    },
  },
  state: {
    index(countryId, params = {}) {
      return instance({
        url: `/companies/countries/${countryId}/states`,
        method: "get",
        params: params,
      });
    },
  },
  group: {
    index(params = {}) {
      return instance({
        url: "/groups",
        method: "get",
        params: params,
      });
    },
    subGroups(groudId, params = {}) {
      return instance({
        url: `/groups/${groudId}/subgroups`,
        method: "get",
        params: params,
      });
    },
  },
  staff: {
    user: {
      updatePersonalInfo(form) {
        return instance({
          url: "/staff/profile/personal-info",
          method: "post",
          data: form,
          headers: { "Content-Type": "multipart/form-data" },
        });
      },
    },
    organisations(params = {}) {
      return instance({
        url: "/organizations",
        method: "get",
        params: params,
      });
    },
    validateStepOne(form) {
      return instance({
        url: "/staff/register/validate-step-one",
        method: "post",
        data: form,
      });
    },
    register(form) {
      return instance({
        url: "/staff/register",
        method: "post",
        data: form,
        headers: { "Content-Type": "multipart/form-data" },
      });
    },
    checkCode(params = {}) {
      return instance({
        url: "/staff/register/check-approved-code",
        method: "get",
        params: params,
      });
    },
    complete(form) {
      return instance({
        url: "/staff/register/use-approved-code",
        method: "post",
        data: form,
        headers: { "Content-Type": "multipart/form-data" },
      });
    },
  },
  member: {
    validateStepOne(form) {
      return instance({
        url: "/traders/register/validate-step-one",
        method: "post",
        data: form,
      });
    },
    validateStepTwo(form) {
      return instance({
        url: "/traders/register/validate-step-two",
        method: "post",
        data: form,
      });
    },
    checkCode(params = {}) {
      return instance({
        url: "/traders/register/check-approved-code",
        method: "get",
        params: params,
      });
    },
    complete(form) {
      return instance({
        url: "/traders/register/use-approved-code",
        method: "post",
        data: form,
        headers: { "Content-Type": "multipart/form-data" },
      });
    },
    meta: {
      index(params = {}) {
        return instance({
          url: "/traders/register/meta",
          method: "get",
          params: params,
        });
      },
    },
    register(form) {
      return instance({
        url: "/traders/register",
        method: "post",
        data: form,
        headers: { "Content-Type": "multipart/form-data" },
      });
    },
    user: {
      removeAvatar(file) {
        return instance({
          url: "/users/profile/avatar",
          method: "delete",
        });
      },
      updateAvatar(file, onUploadProgressCallback) {
        return instance({
          url: "/users/profile/avatar",
          method: "post",
          data: file,
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: onUploadProgressCallback,
        });
      },
      updatePersonalInfo(form) {
        return instance({
          url: "/traders/profile/personal-info",
          method: "post",
          data: form,
          headers: { "Content-Type": "multipart/form-data" },
        });
      },
      updateCompany(data) {
        return instance({
          url: "/traders/profile/company",
          method: "post",
          data,
        });
      },
      addCompany(data) {
        return instance({
          url: "/traders/profile/add-company",
          method: "post",
          data,
        });
      },
      updateCompanyAsPrimary(id) {
        return instance({
          url: `/traders/profile/company/${id}/primary`,
          method: "post",
          data: { id: id },
        });
      },
      updatePlansAmbitions(form) {
        return instance({
          url: "/traders/profile/plans",
          method: "post",
          data: form,
        });
      },
      updateMailingAddress(form) {
        return instance({
          url: "/traders/profile/mailing-address",
          method: "post",
          data: form,
        });
      },
    },
    requestHelp(form) {
      return instance({
        url: "/traders/help-requests",
        method: "post",
        data: form,
      });
    },
    requestHelpEnd(data) {
      return instance({
        url: "/traders/help-requests/end",
        method: "post",
        data,
      });
    },
  },
  expertise: {
    index(params = {}) {
      return instance({
        url: "/expertise",
        method: "get",
        params: params,
      });
    },
  },
  nationality: {
    index(params = {}) {
      return instance({
        url: "/nationalities",
        method: "get",
        params: params,
      });
    },
  },
  region: {
    index(params = {}) {
      return instance({
        url: "/regions",
        method: "get",
        params: params,
      });
    },
  },
  globalscot: {
    meta: {
      index(params = {}) {
        return instance({
          url: "/experts/register/meta",
          method: "get",
          params: params,
        });
      },
    },
    checkCode(params = {}) {
      return instance({
        url: "/experts/register/check-approved-code",
        method: "get",
        params: params,
      });
    },
    validateStepOne(form) {
      return instance({
        url: "/experts/register/validate-step-one",
        method: "post",
        data: form,
      });
    },
    validateStepTwo(form) {
      return instance({
        url: "/experts/register/validate-step-two",
        method: "post",
        data: form,
      });
    },
    register(form) {
      return instance({
        url: "/experts/register",
        method: "post",
        data: form,
        headers: { "Content-Type": "multipart/form-data" },
      });
    },
    complete(form) {
      return instance({
        url: "/experts/register/use-approved-code",
        method: "post",
        data: form,
        headers: { "Content-Type": "multipart/form-data" },
      });
    },
    user: {
      updatePersonalInfo(form) {
        return instance({
          url: "/experts/profile/personal-info",
          method: "post",
          data: form,
          headers: { "Content-Type": "multipart/form-data" },
        });
      },
      updateCompany(data) {
        return instance({
          url: "/experts/profile/company",
          method: "post",
          data,
        });
      },
      addCompany(data) {
        return instance({
          url: "/experts/profile/add-company",
          method: "post",
          data,
        });
      },
      addOtherCompany(data) {
        return instance({
          url: "/experts/profile/add-other-company",
          method: "post",
          data,
        });
      },
      deleteOtherBusiness(data) {
        return instance({
          url: `/experts/profile/company/delete`,
          method: "delete",
          data,
        });
      },
      updateCompanyAsPrimary(id) {
        return instance({
          url: `/experts/profile/company/${id}/primary`,
          method: "post",
          data: { id: id },
        });
      },
      updateExperienceExpertise(form) {
        return instance({
          url: "/experts/profile/experience",
          method: "post",
          data: form,
        });
      },
      uploadFile(data) {
        return instance({
          url: "/users/profile/media",
          method: "post",
          timeout: 120000,
          headers: { "Content-Type": "multipart/form-data" },
          data,
        });
      },
      deleteFile(fileId) {
        return instance({
          url: `/users/profile/media/${fileId}`,
          method: "delete",
          headers: { "Content-Type": "multipart/form-data" },
        });
      },
    },
    requestHelpValid(id) {
      return instance({
        url: `/experts/help-requests/${id}/valid`,
        method: "get",
      });
    },
    requestHelpAccept(data) {
      return instance({
        url: "/experts/help-requests/accept",
        method: "post",
        data,
      });
    },
    requestHelpDecline(data) {
      return instance({
        url: "/experts/help-requests/decline",
        method: "post",
        data,
      });
    },
    requestHelpEnd(data) {
      return instance({
        url: "/experts/help-requests/request-end",
        method: "post",
        data,
      });
    },
  },
  post: {
    index(params = {}, nextLink = false) {
      if (nextLink) {
        return instance({
          url: `/posts${nextLink}`,
          method: "get",
          params: params,
        });
      }
      return instance({
        url: `/posts`,
        method: "get",
        params: params,
      });
    },
    create(data) {
      return instance({
        url: `/posts`,
        method: "post",
        data,
      });
    },
    update(id, data) {
      return instance({ url: `/posts/${id}/update`, method: "post", data });
    },
    delete(id) {
      return instance({ url: `/posts/${id}/delete`, method: "delete" });
    },
    show(id) {
      return instance({ url: `/posts/${id}`, method: "get" });
    },
    category: {
      index(params = {}) {
        return instance({
          url: `/posts/categories`,
          method: "get",
          params: params,
        });
      },
    },
    media: {
      create(file, onUploadProgressCallback) {
        return instance({
          url: `/posts/media`,
          method: "post",
          data: file,
          timeout: 120000,
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: onUploadProgressCallback,
        });
      },
    },
  },
  chat: {
    index(params = {}) {
      return instance({
        url: `/chats`,
        method: "get",
        params: params,
      });
    },
    create(data = {}) {
      return instance({
        url: "/chats",
        method: "post",
        data,
      });
    },
    getNewMembersList(params = {}) {
      return instance({
        url: `/chats/new-members-list`,
        method: "get",
        params: params,
      });
    },
    getNonMembersList(id, params = {}) {
      return instance({
        url: `/chats/${id}/non-members`,
        method: "get",
        params: params,
      });
    },
    getMembersList(id, params = {}) {
      return instance({
        url: `/chats/${id}/members`,
        method: "get",
        params: params,
      });
    },
    addMember(id, params = {}) {
      return instance({
        url: `/chats/${id}/members`,
        method: "post",
        params: params,
      });
    },
    removeMember(id, memberId) {
      return instance({
        url: `/chats/${id}/members/${memberId}`,
        method: "delete",
      });
    },
    show(id) {
      return instance({
        url: `/chats/${id}`,
        method: "get",
      });
    },
    message: {
      index(id, params = {}) {
        return instance({
          url: `/chats/${id}/messages`,
          method: "get",
          params: params,
        });
      },
      create(id, data) {
        return instance({
          url: `/chats/${id}/messages`,
          method: "post",
          data,
        });
      },
    },
    file: {
      index(id, params = {}) {
        return instance({
          url: `/chats/${id}/files`,
          method: "get",
          params: params,
        });
      },
      upload(id, file, onUploadProgressCallback) {
        return instance({
          url: `/chats/${id}/files`,
          method: "post",
          data: file,
          timeout: 120000,
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: onUploadProgressCallback,
        });
      },
    },
  },
  comment: {
    index(params = {}, nextLink = false) {
      if (nextLink) {
        return instance({
          url: `/comments${nextLink}`,
          method: "get",
          params: params,
        });
      }

      return instance({
        url: `/comments`,
        method: "get",
        params: params,
      });
    },
    create(data) {
      return instance({
        url: `/comments`,
        method: "post",
        data,
      });
    },
    delete(commentId) {
      return instance({
        url: `/comments/${commentId}`,
        method: "delete",
      });
    },
    update(id, data) {
      return instance({ url: `/comments/${id}/update`, method: "post", data });
    },
  },
  notifications: {
    index(params = {}) {
      return instance({
        url: `/notifications`,
        method: "get",
        params: params,
      });
    },
    read(notificationId) {
      return instance({
        url: `/notifications/${notificationId}/read`,
        method: "get",
      });
    },
    readAll() {
      return instance({
        url: `/notifications/read-all`,
        method: "get",
      });
    },
    delete(notificationId) {
      return instance({
        url: `/notifications/${notificationId}`,
        method: "delete",
      });
    },
    deleteAll() {
      return instance({
        url: `/notifications/delete-all`,
        method: "get",
      });
    },
  },
  feedback: {
    submit(data) {
      return instance({
        url: `/feedback/submit`,
        method: "post",
        data: data,
      });
    },
  },
  companyHouse: {
    getForNumber(number) {
      return instance({
        url: `/companyhouse/${number}`,
        method: "get",
      });
    },
  },
  report: {
    submit(data) {
      return instance({
        url: `/reports/submit`,
        method: "post",
        data: data,
      });
    },
    reason: {
      index(params = {}) {
        return instance({
          url: `/reports/reasons`,
          method: "get",
          params: params,
        });
      },
    },
  },
  followable: {
    followers(data) {
      return instance({
        url: "/followers",
        method: "get",
        params: data,
      });
    },
    following(data) {
      return instance({
        url: "/following",
        method: "get",
        params: data,
      });
    },
    followUnfollow(data, action) {
      return instance({
        url: `/${action}`,
        method: "post",
        data: data,
      });
    },
  },
  likeable: {
    like(data) {
      return instance({ url: "/like", method: "post", data });
    },
    unlike(data) {
      return instance({ url: "/unlike", method: "post", data });
    },
    dislike(data) {
      return instance({ url: "/dislike", method: "post", data });
    },
    undislike(data) {
      return instance({ url: "/undislike", method: "post", data });
    },
  },
  organisation: {
    index(params = {}) {
      return instance({
        url: `/organisations`,
        method: "get",
        params: params,
      });
    },
    random(params = {}) {
      return instance({
        url: "/organisations/random",
        method: "get",
        params: params,
      });
    },
    show(id) {
      return instance({ url: `/organisations/${id}`, method: "get" });
    },
  },
  alerts: {
    search(params = {}) {
      return instance({
        url: "/alerts",
        method: "get",
        params: params,
      });
    },
    getById(id) {
      return instance({
        url: `/alerts/${id}`,
        method: "get",
      });
    },
    unsubscribe(email) {
      return instance({
        url: `/users/unsubscribe/${email}`,
        method: "post",
      });
    },
  },
};
