<template>
  <section
    class="white-background-section pb-2"
    aria-label="About Scotland Europa"
  >
    <div class="container">
      <el-row>
        <div class="join-body">
          <el-col
            class
            :md="24"
            :lg="24"
          >
            <div class="left-text mb-4 mt-4">
              <h2 class="involve-title">
                {{ scotlandEUText.heading }}
              </h2>
              <p class="involve-sub-text">
                {{ scotlandEUText.text_1 }}
              </p>
              <h2 class="involve-title">
                {{ experiencedTeam.heading }}
              </h2>
              <p class="involve-sub-text">
                {{ experiencedTeam.text_1 }}
              </p>
              <p class="involve-sub-text">
                {{ experiencedTeam.text_2 }}
              </p>
              <el-button
                class="btn-text about-membership-btn"
                size="small"
                @click="$router.push({ name: 'Contact Us' })"
              >
                Contact us
              </el-button>
            </div>
          </el-col>
        </div>
      </el-row>

      <el-row>
        <el-col
          class
          :md="24"
          :lg="12"
        >
          <div class="left-text pr-2">
            <h2 class="involve-title">
              {{ scotlandEconomy.heading }}
            </h2>
            <p class="involve-sub-text">
              {{ scotlandEconomy.text_1 }}
            </p>
          </div>
        </el-col>

        <el-col
          :md="24"
          :lg="12"
        >
          <div class="invlove-img mt-5 scotland-in-europe-image">
            <img
              alt="Calton Hill"
              class
              src="../../../../static/img/calton-hill.jpg"
            />
          </div>
        </el-col>
      </el-row>

      <el-row>
        <div class="join-body">
          <el-col
            class
            :md="24"
            :lg="24"
          >
            <div class="left-text mb-4 mt-4">
              <h2 class="involve-title">
                {{ membersBenefit.heading }}
              </h2>
              <p class="involve-sub-text">
                {{ membersBenefit.text_1 }}
              </p>
              <div class>
                <ul class="involve-list">
                  <li
                    v-for="point in membersBenefitBulletPoints_1"
                    :key="point.text"
                  >
                    {{ point.text }}
                  </li>
                </ul>
              </div>
              <el-button
                class="btn-text about-membership-btn mt-4"
                size="small"
                @click="$router.push({ name: 'Membership' })"
              >
                Find out more about Scotland Europa membership
                opportunities
              </el-button>
              <h2 class="involve-title mt-4">
                {{ scotlandHouseText.heading }}
              </h2>
              <p class="involve-sub-text">
                {{ scotlandHouseText.text_1 }}
              </p>
              <p
                class="involve-sub-text"
                v-html="scotlandHouseText.text_2"
              ></p>
              <el-button
                class="btn-text about-membership-btn mt-4"
                size="small"
                @click="$router.push({ name: 'Scotland House Brussels' })"
              >
                Find out more about Scotland House
              </el-button>
            </div>
          </el-col>
        </div>
      </el-row>

      <el-row>
        <el-col
          class
          :md="24"
          :lg="12"
        >
          <div class="left-text pr-2 mb-5">
            <h2 class="involve-title">
              {{ supportingScotlandText.heading }}
            </h2>
            <p class="involve-sub-text">
              {{ supportingScotlandText.text_1 }}
            </p>
          </div>
        </el-col>

        <el-col
          :md="24"
          :lg="12"
        >
          <div class="invlove-img mt-5 scotland-in-europe-image mb-5">
            <img
              alt="1992 Square de meeus team"
              class
              src="../../../../static/img/1992-Square-de-meeus-team.jpg"
            />
          </div>
        </el-col>
      </el-row>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      scotlandEUText: {
        heading: "Scotland in the EU",
        text_1:
          "Scotland Europa membership brings together a wide range of Scottish organisations, including public authorities, businesses, local government, trade unions, academia and voluntary organisations. This diverse partnership gives us a variety of perspectives on Scottish interests in Europe and an influential voice in European affairs. We promote Scottish interests across the institutions of the European Union and to the representatives of Europe's regions and nations.",
      },
      experiencedTeam: {
        heading: "Our experienced team",
        text_1:
          "With experienced and specialised staff based in Brussels and Scotland, Scotland Europa fosters strong links between Scotland and Europe, providing intelligence, policy analysis and strategic funding advice and working to network Scotland in Europe.",
        text_2:
          "If you need to get in touch, you can submit an enquiry on our contact page, or use our list of individual contacts to get in touch directly.",
      },
      scotlandEconomy: {
        heading: "Supporting Scotland's economy",
        text_1:
          "Scotland Europa is also a part of Scottish Enterprise - Scotland's main economic, enterprise, innovation, and investment agency. Working closely with our colleagues, both in Scotland and in Scottish Development International offices across the world, we aim to enhance Scotland's economic development and influence in Europe.",
      },
      membersBenefit: {
        heading: "How our members benefit",
        text_1:
          "Our member organisations have access to a range of exclusive services and benefits to help them develop their connections in Europe and their understanding of EU policy. Our key benefits include:",
      },
      membersBenefitBulletPoints_1: [
        {
          text: "Updates and analysis of relevant EU policy developments",
        },
        {
          text: "EU funding and project support",
        },
        {
          text: "Learning opportunities and help achieving EU engagement ambitions",
        },
        {
          text: "Access to our extensive networks and contacts in the EU",
        },
      ],
      scotlandHouseText: {
        heading: "Scotland House Brussels",
        text_1:
          "Scotland House Brussels, our base at the heart of the European Quarter in Brussels, is also home to the Scottish Government EU Office, as well as other key partners. It also houses our conference centre, which is available to members for events and meetings.",
        text_2:
          `Scotland House Brussels is also a part of Scotland's wider international network. The Scottish Government are establishing a number of <a href='https://www.gov.scot/policies/europe/innovation-and-investment-hubs/' target='_blank'>Innovation and Investment Hubs <span class="sr-only">this link will open in a new window</span><svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" class="ml-1">
                <path d="M6.5 3.69189H0.5V15.6919H12.5V9.72217" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3.5 12.707L15.5 0.707031" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.5 0.707031H15.5V6.70703" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                </svg></a> across Europe, including Brussels, as part of wider work across government, partners and businesses to support trade, investment, innovation and inter-governmental relations with Europe. Scotland House Brussels capitalises on Scotland's existing experience in Brussels and consolidates and co-ordinates activity to maximise the economic, cultural and diplomatic benefits of our presence in Brussels. Its work will be critical throughout Brexit negotiations and beyond.`,
      },
      supportingScotlandText: {
        heading: "Supporting Scotland since 1992",
        text_1:
          "Scotland has had a presence in Europe since 1979, originally through the Scottish Development Agency (SDA). In 1992, Scotland Europa was founded by Scottish Enterprise with 11 initial founding members. We've come a long way since then, gaining new members, launching publications, hosting events, developing new strategies and approaches, and promoting Scotland's interests in the EU.",
      },
    };
  },
};
</script>
<style lang="scss">
.join-body h2 {
  font-size: $font-size-30;
  line-height: $line-height-42;
  font-weight: 420;
  margin-bottom: 30px;
  color: black;
}
.join-body p {
  font-weight: 390;
  font-size: $font-size-19;
  line-height: $line-height-28;
}
.join-link {
  margin-top: 30px;
}
.join-experts,
.join-organisations {
  margin: 50px 0 50px 0;
}
.join-heading-text {
  margin-top: 50px;
}
</style>
