<template>
  <div class="dialog-focus-lock">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'focus-lock',

  data() {
    return {
      focusableEls: [],
      firstFocusableEl: [],
      lastFocusableEl: [],
      focusedElBeforeOpen: document.activeElement,
    };
  },

  methods: {
    handleDialogFocusLock() {
      const selectors = '' +
        'a[href], ' +
        'input:not([disabled]), ' +
        'select:not([disabled]), ' +
        'textarea:not([disabled]), ' +
        'button:not([disabled])';

      const getEls = this.$el.querySelectorAll(selectors);

      //console.log(this.$el)
      //console.log(getEls)

      this.focusableEls = Array.prototype.slice.call(getEls);

      const dialogcloseButtonIndex = this.focusableEls.findIndex(el => el.className.includes("el-dialog__headerbtn"));

      //--- ignore the top close button on the dialog modal
      if(this.focusableEls.length > 1 && dialogcloseButtonIndex >= 0){
        const start = dialogcloseButtonIndex+1;
        const end = start + 2;
        [this.firstFocusableEl] = this.focusableEls.slice(start,end); // get first array item
      }else{
        [this.firstFocusableEl] = this.focusableEls
      }

      [this.lastFocusableEl] = this.focusableEls.slice(-1); // get last array item

      this.$el.addEventListener('keydown', e => this.handleKeyDown(e));

      setTimeout(() => {
        if(this.firstFocusableEl)
          this.firstFocusableEl.focus();
      }, 3000)
      if(this.firstFocusableEl)
        this.firstFocusableEl.focus();
    },

    handleBackwardTab(e) {
      if (document.activeElement === this.firstFocusableEl) {
        e.preventDefault();
        if(this.lastFocusableEl)
          this.lastFocusableEl.focus();
      }
    },

    handleForwardTab(e) {
      if (document.activeElement === this.lastFocusableEl) {
        e.preventDefault();
        this.firstFocusableEl.focus();
      }
    },

    handleKeyDown(e) {
      const KEY_TAB = 9;

      switch (e.keyCode) {
        case KEY_TAB:
          if (this.focusableEls.length === 1) {
            e.preventDefault();
            break;
          }
          if (e.shiftKey) {
            this.handleBackwardTab(e);
          } else {
            this.handleForwardTab(e);
          }
          break;
        default:
          break;
      }
    },
  },

  mounted() {
    setTimeout(() => {
      // Investigate: Slot DOM elements aren't availale without timeout when mounted lifecycle is used.
      this.handleDialogFocusLock();
    }, 100)
  },
};
</script>
