<!-- Navigation Bar Component -->
<template>
  <header
    id="header"
    class="p-header"
    role="banner"
  >
    <div
      id="navbar"
      class="container-fluid platform-navbar"
    >
      <div class="mobile-nav hidden-lg-and-up container">
        <el-row>
          <el-col
            :md="4"
            :sm="5"
            :xs="1"
          >
            <a
              href="javascript:void(0)"
              class="burger"
              @keyup.enter="$emit('onOpenMobileMenuLanding')"
              @click="$emit('onOpenMobileMenu')"
            ><svg-collapse></svg-collapse><span class="sr-only">Main menu</span></a>
          </el-col>
          <el-col
            :md="16"
            :sm="14"
            :xs="22"
            class="block-logo text-center"
          >
            <router-link
              to="/"
              class="navbar-brand"
            >
              <div class="site-logo-2">
                <svg-logo-small />
                <span class="sr-only">Back to Feed</span>
              </div>
            </router-link>
          </el-col>
          <el-col
            :md="4"
            :sm="3"
            :xs="1"
            class="btns-nav btns-dash text-right"
          >
            <router-link
              to="/settings/expertise"
              class="hidden-md-and-down"
            >
              <svg-chat class="chat-icon" />
              <span class="sr-only">chat icon </span>
            </router-link>
            <el-badge
              :value="$store.state.unreadNots"
              :class="{ 'zero-notifications': !$store.state.unreadNots }"
              :max="99"
              class="item"
              @click.native="showNot"
            >
              <a href="#">
                <span
                  class="sr-only"
                  :aria-live="$store.state.unreadNots ? 'assertive' : null"
                >
                  {{ $store.state.unreadNots }} Notifications</span>
                <svg-bell-white class="notification-icon" />
              </a>
            </el-badge>
            <el-menu
              ref="menu"
              class="my-profile hidden-md-and-down"
              mode="horizontal"
            >
              <el-submenu
                ref="profile"
                popper-class="my-profile-sub"
                index="4"
              >
                <template slot="title">
                  <div class="profile">
                    <img
                      v-if="user.avatar"
                      :src="user.avatar"
                      alt="Profile picture"
                    />
                    <img
                      v-else
                      alt="Default Profile picture"
                      src="../../static/img/default-image.png"
                    />
                    <i class="el-icon-caret-bottom profile-dropdown"></i>
                  </div>
                </template>
                <el-menu-item
                  v-if="isGlobalScot"
                  index="4-2"
                >
                  <router-link
                    :to="{
                      name: 'SE Profile',
                      params: { id: user.id },
                    }"
                  >
                    View profile
                  </router-link>
                </el-menu-item>
                <el-menu-item
                  v-else-if="isStaff"
                  index="4-2"
                >
                  <router-link
                    :to="{ name: 'Staff Profile', params: { id: user.id } }"
                  >
                    View profile
                  </router-link>
                </el-menu-item>
                <el-menu-item
                  v-else
                  index="4-2"
                >
                  <router-link
                    :to="{
                      name: 'NonGlobalScot Profile',
                      params: { id: user.id },
                    }"
                  >
                    View profile
                  </router-link>
                </el-menu-item>
                <el-menu-item index="4-2">
                  <router-link to="/settings">
                    Settings
                  </router-link>
                </el-menu-item>
                <!--el-menu-item index="4-2" v-if="!isGlobalScot"><router-link :to="{ name: 'Become Member' }">Become a GlobalScot</router-link></!--el-menu-item-->
                <el-menu-item index="4-2">
                  <a
                    href="https://support.scotlandeuropa.com/"
                    target="_blank"
                  >Help
                    <span class="sr-only">this link will open in a new window</span><svg-link-purple class="ml-1" /></a>
                </el-menu-item>
                <el-menu-item
                  index="4-3"
                  @click.native="logout"
                >
                  <router-link
                    to="#"
                    class="logout-link"
                  >
                    Logout
                    <svg-purple-arrow-right
                      class="ml-2"
                    ></svg-purple-arrow-right>
                  </router-link>
                </el-menu-item>
              </el-submenu>
            </el-menu>
          </el-col>
        </el-row>
      </div>
      <div class="container hidden-md-and-down">
        <el-row>
          <el-col
            :xl="4"
            :lg="4"
            :md="4"
          >
            <router-link
              to="/"
              class="navbar-brand"
            >
              <div class="site-logo-2">
                <svg-logo />
                <span class="sr-only">Back to Feed</span>
              </div>
            </router-link>
          </el-col>
          <el-col
            :xl="15"
            :lg="16"
            :md="14"
            class="btns-chats"
          >
          </el-col>
          <el-col
            :xl="5"
            :lg="4"
            :md="4"
            class="btns-nav btns-dash float-right"
          >
            <!--router-link to="/chats">
             <span class="sr-only">chats</span>
              <svg-chat alt="chat icon to go to chats" class="chat-icon" />
            </!--router-link-->
            <el-badge
              :value="$store.state.unreadNots"
              :class="{ 'zero-notifications': !$store.state.unreadNots }"
              :max="99"
              class="item"
              @click.native="showNot"
            >
              <a href="#">
                <span
                  class="sr-only"
                  :aria-live="$store.state.unreadNots ? 'assertive' : null"
                >
                  {{ $store.state.unreadNots }} Notifications</span>
                <svg-notification class="notification-icon" />
              </a>
            </el-badge>

            <div class="profile-dropdown-container">
              <ul class="">
                <li id="sub-menu-container">
                  <a
                    id="profile-parent-link"
                    role="button"
                    tabindex="0"
                  >
                    <span class="sr-only">Profile picture</span>
                    <div class="profile">
                      <img
                        v-if="user.avatar"
                        :src="user.avatar"
                        alt="Profile picture"
                        class="mb-0"
                      />
                      <img
                        v-else
                        src="../../static/img/default-image.png"
                        alt="Default Profile picture"
                        class="mb-0"
                      />
                      <div>
                        <!-- <i class="el-icon-caret-bottom profile-dropdown"></i> -->
                        <img
                          src="../../static/img/circle-dropdown.svg"
                          alt="menu dropdown arrow"
                          class="profile-dropdown"
                        />
                      </div>
                    </div>
                  </a>
                  <ul
                    id="sub-nav"
                    class="sub-nav"
                  >
                    <li v-if="isGlobalScot">
                      <router-link
                        id="view-profile-link"
                        :to="{
                          name: 'SE Profile',
                          params: { id: user.id },
                        }"
                        class="sub-nav-item"
                      >
                        View profile <span class="sr-only">View profile</span>
                      </router-link>
                    </li>
                    <li v-else-if="isStaff">
                      <router-link
                        id="view-profile-link"
                        :to="{ name: 'Staff Profile', params: { id: user.id } }"
                        class="sub-nav-item"
                      >
                        View profile <span class="sr-only">View profile</span>
                      </router-link>
                    </li>
                    <li v-else>
                      <router-link
                        id="view-profile-link"
                        :to="{
                          name: 'NonGlobalScot Profile',
                          params: { id: user.id },
                        }"
                        class="sub-nav-item"
                      >
                        View profile <span class="sr-only">View profile</span>
                      </router-link>
                    </li>
                    <li>
                      <router-link
                        id="settings-link"
                        to="/settings"
                        class="sub-nav-item"
                      >
                        Settings <span class="sr-only">Settings</span>
                      </router-link>
                    </li>
                    <!--li v-if="!isGlobalScot">
                      <router-link :to="{ name: 'Become Member' }" id="become-gs-link" class="sub-nav-item">Become a GlobalScot</router-link>
                    </!li-->
                    <li>
                      <a
                        id="help-link"
                        href="https://support.scotlandeuropa.com/"
                        target="_blank"
                        class="sub-nav-item"
                      >Help
                        <span class="sr-only">Help : this link will open in a new window</span><svg-link-purple class="ml-1" /></a>
                    </li>
                    <li id="logout-li">
                      <router-link
                        id="logout-link"
                        to="#"
                        class="sub-nav-item logout-link"
                        @click.native="logout"
                      >
                        Logout <span class="sr-only">Logout</span>
                        <svg-purple-arrow-right
                          class="ml-2"
                        ></svg-purple-arrow-right>
                      </router-link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <nav class="main-navigation-menu-bar hidden-md-and-down">
      <ul
        class="navbar p-navbar"
        role="menubar"
      >
        <li>
          <router-link :to="{ name: 'Feed', params: { isActiveFilter: 1 } }">
            <svg-feed-white></svg-feed-white> Dashboard <span class="sr-only">Dashboard</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'Pulse' }">
            <svg-pulse-white></svg-pulse-white> Community feed <span class="sr-only">Community feed</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'Members' }">
            <svg-find-expert-white></svg-find-expert-white> Members <span class="sr-only">Members</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'Events and Webinars' }">
            <svg-events-white></svg-events-white> Events & webinars <span class="sr-only">Events & webinars </span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'News and Features' }">
            <svg-news-white></svg-news-white> News & resources <span class="sr-only">News & resources </span>
          </router-link>
        </li>
        <!--li v-if="!isMember">
          <router-link :to="{ name: 'Projects' }">
            <svg-news-white></svg-news-white> Projects
          </router-link>
        </!--li-->
      </ul>
    </nav>
    <notifications-nav
      v-if="isCollapsedNot"
      @close="closeNot"
      @request-notification="openRequestModal($event)"
      @pulse-notification="openPulseModal($event)"
      @project-notification="openProjectPostModal($event)"
    ></notifications-nav>
  </header>
</template>

<script>
import Logo from "@/components/assets/svg-gs-logo-full";
import LogoSmall from "@/components/assets/svg-gs-logo-only";
import Chat from "@/components/assets/svg-chat-menu";
import FeedWhite from "@/components/assets/svg-feed-white";
import FindExpertWhite from "@/components/assets/svg-find-expert-white";
import BellWhite from "@/components/assets/svg-bell-white";
import NewsWhite from "../components/assets/svg-news-white";
import EventsWhite from "../components/assets/svg-events-white";
import Collapse from "@/components/assets/svg-mobile-menu";
import { mixin as onClickOutside } from "vue-on-click-outside";
import RolePermissionMixin from "@/mixins/RolePermissionMixin";
import store from "@/store";
import PulseWhite from "@/components/assets/svg-pulse-white";
import NotificationsNav from "@/components/slide-panel/panels/notifications-panel";
import Notification from "@/components/assets/svg-notification";
import gsApi from "@/services/gs.api";
import { mapState } from "vuex";
import PurpleArrowRight from "@/components/assets/svg-purple-arrow-right";
import svgLinkPurple from "@/components/assets/svg-link-purple";
export default {
  components: {
    "svg-logo": Logo,
    "svg-logo-small": LogoSmall,
    "svg-chat": Chat,
    "svg-feed-white": FeedWhite,
    "svg-find-expert-white": FindExpertWhite,
    "svg-bell-white": BellWhite,
    "svg-pulse-white": PulseWhite,
    "svg-collapse": Collapse,
    "svg-news-white": NewsWhite,
    "svg-events-white": EventsWhite,
    "svg-notification": Notification,
    "notifications-nav": NotificationsNav,
    "svg-purple-arrow-right": PurpleArrowRight,
    svgLinkPurple,
  },
  mixins: [onClickOutside, RolePermissionMixin],
  data: function () {
    return {
      pulsepostId: new Date(),
      projectPostId: new Date(),
      activeIndex: "1",
      activeIndex2: "1",
      isCollapsed: true,
      isCollapsedNot: false,
      isDropDown: false,
      isTrader: false,
      isExpert: false,
      isTraderOrExpert: false,
      notificationUpdated: false,
    };
  },
  computed: {
    ...mapState(["user"]),
    isGlobalScot() {
      return this.user.expertProfile && this.user.roles.includes("expert");
    },
    isMember() {
      return this.user.traderProfile && this.user.roles.includes("trader");
    },
    isStaff() {
      return this.user.staffProfile && this.user.roles.includes("staff");
    },
  },
  mounted() {
    this.checkRole();
    this.initialiseGetNotification();
    document.addEventListener(
      "keyup",
      (e) => {
        this.subMenuFocusCheck();
      },
      false
    );
  },
  methods: {
    openPulseModal(post) {
      this.pulsepostId += 1;

      store.commit("OPEN_PULSE_NOTIFICATION_MODAL", {
        post: post,
        isOpen: true,
        pulsePostId: this.pulsepostId,
      });
      document.getElementById("content").setAttribute("aria-hidden", "true");
    },
    openRequestModal(request) {
      store.commit("OPEN_REQUEST_NOTIFICATION_MODAL", {
        request: request,
        isOpen: true,
      });
      document.getElementById("content").setAttribute("aria-hidden", "true");
      this.closeNot();
    },
    openProjectPostModal(post) {
      this.projectPostId += 1;
      store.commit("OPEN_PROJECT_NOTIFICATION_MODAL", {
        post: post,
        isOpen: true,
        projectPostId: this.projectPostId,
      });
    },
    initialiseGetNotification() {
      const vm = this;
      setInterval(function () {
        vm.notificationUpdated = false;
      }, 9000);
    },
    handleSelect(key, keyPath) {
      this.isDropDown = false;
    },
    showNot() {
      this.isCollapsedNot = true;
      // document.getElementById("notSidenav").style.transform = "translate(0)";
    },
    closeNot() {
      if (this.isCollapsedNot === true) {
        this.isCollapsedNot = false;
        // document.getElementById("notSidenav").style.transform = "translate(100%)";
      }
    },
    dropDown() {
      if (this.isDropDown) {
        this.isDropDown = false;
        this.$refs["menu"].close(this.$refs["profile"].index);
      } else {
        this.isDropDown = true;
        this.$refs["menu"].open(this.$refs["profile"].index);
      }
    },
    dropDownClose() {
      this.$refs["menu"].close(this.$refs["profile"].index);
    },
    logout() {
      gsApi.auth.logout(this.user.token).then(() => {
        store.commit("USER_LOGOUT");
        this.$nextTick(() => {
          this.$router.push({ name: "Home" });
        });
      });
    },
    checkRole() {
      if (this.isRole("trader")) this.isTrader = true;

      if (this.isRole("expert")) this.isExpert = true;

      if (this.hasRoles(["expert", "trader"])) this.isTraderOrExpert = true;
    },
    subMenuFocusCheck() {
      let subNav = document.getElementById("sub-nav");
      var activeElementClass = document.activeElement.id;

      if (subNav) {
        if (
          activeElementClass == "profile-parent-link" ||
          activeElementClass == "view-profile-link" ||
          activeElementClass == "settings-link" ||
          activeElementClass == "become-gs-link" ||
          activeElementClass == "help-link" ||
          activeElementClass == "code-link" ||
          activeElementClass == "logout-link"
        ) {
          subNav.style.display = "block";
        } else {
          subNav.style.display = "none";
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.slide-enter-active {
  transition: all 0.3s ease;
}
.slide-leave-active {
  transition: all 0.3s ease;
}
.slide-enter, .slide-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translate(10px);
  opacity: 0;
}

.profile-dropdown-container {
  text-align: center;
  display: inline-block;
  border: 0;
  position: relative;
  top: -5px;
  #logout-li {
    height: 57px !important;
    line-height: $line-height-70 !important;
  }
  #logout-link {
    color: $primary !important;
  }
  #sub-menu-container:hover > ul {
    display: block !important;
  }
  #sub-nav {
    width: 247px;
    padding: 10px 20px;
    display: none;
    background: $white;
    position: absolute;
    z-index: 300;
    right: 0;
    border-radius: 5px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    li {
      width: 100%;
      height: 32px;
      line-height: $line-height-35;
      float: left;
      font-size: $font-size-14;
      cursor: pointer;
      position: relative;
      white-space: nowrap;
      list-style: none;
      vertical-align: middle;
      margin: 0;
      padding: 0px;
      &:last-child {
        border-bottom: none;
      }
      a {
        width: 100%;
        height: 35px;
        font-weight: bold;
        font-size: $font-size-18;
        text-decoration: none;
        color: $black;
        text-align: left;
      }
    }
  }
  ul {
    display: inline-block;
    background: transparent;
    border-bottom: 0;
  }
  li {
    list-style: none;
    .router-link-exact-active {
      border-bottom: 3px inset #a8308c;
      color: #fff;
      font-weight: 420;
    }
    a {
      font-weight: 400;
      font-size: $font-size-19;
      text-decoration: none;
      color: #fff;
      display: block;
    }
  }
}
</style>
