<template>
  <div>
    <el-col
      :sm="24"
      :md="12"
      :lg="8"
      :xl="8"
      v-for="(post, index) in posts"
      :key="index"
    >
      <el-card class="box-card member-card questions-card" shadow="always">
        <div class="questions-header">
          <div>
            <user-avatar
              shape="circle"
              :isGlobalScot="post.author && post.author.is_global_scot"
              :authorExist="!!post.author"
              width="40px"
              :size="40"
              :src="post.author_avatar_url"
              :alt="post.author ? post.author.name : '' + 'Profile picture'"
            ></user-avatar>
          </div>
          <div>
            <p class="name">{{ post.author.name }}</p>
            <p>{{ post.created_at_time_diff }}</p>
          </div>
        </div>

        <div class="sub-text" v-html="$options.filters.domPurify(post.content)"></div>

        <div class="question-card-icons">
          <div class="question-card-icon">
            <svg-chat-purple></svg-chat-purple>
            <p class="ml-2">{{ post.reply_count }} Replies</p>
          </div>
          <div class="question-card-icon right view-reply-link">
            <router-link :to="{ name: 'Pulse', params: { id: post.id } }">
              <p>Reply</p>
              <span class="sr-only">to Community Post</span>
              <svg-purple-arrow-right
                class="arrow reply-arrow"
              ></svg-purple-arrow-right>
            </router-link>
          </div>
        </div>
      </el-card>
    </el-col>
  </div>
</template>
<script>
import PurpleArrowRight from "@/components/assets/svg-purple-arrow-right";
import ChatPurple from "@/components/assets/svg-chat-purple";
import UserAvatar from "@/components/core/user-avatar";
export default {
  components: {
    "svg-purple-arrow-right": PurpleArrowRight,
    "svg-chat-purple": ChatPurple,
    UserAvatar
  },
  props: {
    posts: {
      type: Array
    }
  },
  methods: {}
};
</script>

<style lang="scss">
.questions-header {
  display: grid;
  grid-template-columns: 0.4fr 1.6fr;
  margin-bottom: 5%;
}

.sub-text {
  word-break: break-word!important;
  line-height: $line-height-24;
  font-size: $font-size-19;
  font-weight: 390;
  overflow-y: auto;
  text-overflow: ellipsis;
  height: 75px;
}
</style>
