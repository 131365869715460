<!-- About Component -->
<template>
  <div class="about-page">
    <div>
      <el-row :gutter="0" class="m-0">
        <top-navigation></top-navigation>
        <el-col :span="24" class="article-featured px-0">
          <div class>
            <div class="become-page__header small-header scotland-house-header">
              <div class="header__content__bg"></div>
              <div class="header__image__bg scotland-house-bg"></div>
              <div class="header__content container">
                <el-row class="pb-3">
                  <el-col :md="24" :lg="12">
                    <div class="header__content__wrapper">
                      <h1 id="main-content" class="header__title">
                        {{ text.headingText }}
                      </h1>
                      <p class="header__content-info">
                        {{ text.headingSub }}
                      </p>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <div>
        <what-we-do></what-we-do>
      </div>
      <div>
        <conference-centre></conference-centre>
      </div>
      <div>
        <scotland-house-become-member></scotland-house-become-member>
      </div>
      <div>
        <residents-and-members></residents-and-members>
      </div>
      <div>
        <scotland-house-team></scotland-house-team>
      </div>
    </div>
  </div>
</template>

<script>
import TopNavigation from "../../layout/top-navigation";
import WhatWeDo from "../../pages/landing/scotlandhouse/what-we-do";
import ConferenceCentre from "../../pages/landing/scotlandhouse/conference-centre";
import ScotlandHouseBecomeMember from "../../pages/landing/scotlandhouse/scotland-house-become-member";
import ResidentsAndMembers from "../../pages/landing/scotlandhouse/residents-and-members";
import ScotlandHouseTeam from "../../pages/landing/scotlandhouse/scotland-house-team";
export default {
  name: "scotland-house-brussels",
  metaInfo: {
    title: "Scotland House Brussels",
    meta: [
      {
        name: "description",
        content: "Scotland House Brussels",
      },
    ],
  },
  components: {
    TopNavigation,
    WhatWeDo,
    ConferenceCentre,
    ScotlandHouseBecomeMember,
    ResidentsAndMembers,
    ScotlandHouseTeam
  },
  data() {
    return {
      text: {
        headingText: "Scotland House Brussels",
        headingSub:
          "Scotland House Brussels provides the window to Scotland for EU and wider European and international partners in Brussels. It is a deeply integrated presence providing services to support Scotland’s economic growth, diplomatic engagement, and cultural promotion at the EU level. It also provides a conference centre, hot desks, meeting space and co-working spaces for our members.",
      },
    };
  },
};
</script>
