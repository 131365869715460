<!-- App Component -->
<template>
  <div id="app">
    <div class="sr-only">
      <vue-announcer />
    </div>
    <v-skip-links ref="skiplinks"></v-skip-links>
    <router-view v-if="app.authIsReady" />
    <div v-loading.fullscreen.lock="!app.authIsReady"></div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import gsApi from '@/services/gs.api'
import store from "./store"
import SkipLinks from "@/components/partials/skip-links";

export default {
  name: 'App',
  metaInfo: {
    title: 'Home',
    titleTemplate: '%s - Scotland Europa'
  },
  components: {
    'v-skip-links': SkipLinks
  },
  data () {
    return {
      isReady: false
    }
  },
  computed: {
    ...mapState(['app'])
  },
  watch: {
    $route (to) {
      this.$nextTick(() => {
        const title = to.meta.title
        this.setRouteAnnouncement(title)
        this.setFocusToSkiplinks()
        this.setAriaCurrent()
      })

      if (store.state.user.isLogged) {
        gsApi.notifications.index({
          client_id: 2
        }).then(function (response) {
          store.commit("UPDATE_NOTS", response.data.meta.total_not_read_count);
        })
      }
    }
  },
  methods: {
    setFocusToSkiplinks () {
      this.$refs.skiplinks.focusSelf();
    },
    setRouteAnnouncement (pagetitle) {
      this.$announcer.set(`Navigated to ${pagetitle}`)
    },
    setAriaCurrent () {
      this.$nextTick(() => {
        const oldCurrents = this.$el.querySelectorAll("[aria-current]")
        oldCurrents && oldCurrents.forEach(current => { current.removeAttribute("aria-current") })

        const newCurrents = this.$el.querySelectorAll(".router-link-exact-active")
        newCurrents && newCurrents.forEach(current => { current.setAttribute("aria-current", "page") })
      })
    }
  }
}
</script>
<style lang="scss">
.announcer{
  position: absolute!important;
  width: 1px!important;
  height: 1px!important;
  padding: 0!important;
  margin: -1px!important;
  overflow: hidden!important;
  clip: rect(0,0,0,0)!important;
  border: 0!important;
  left: 1px!important;
}
</style>
