<template>
  <div class="white-background-section">
    <div class="container">
      <el-row>
        <div class="involve-heading-text mt-3">
          <div class="involve-data">
            <div>
              <el-col
                class
                :md="24"
                :lg="24"
              >
                <div class="left-text sh-team-left-text">
                  <h1 class="involve-title">
                    {{ text.heading }}
                  </h1>
                  <p class="involve-sub-text font-weight-bold">
                    {{ text.listStart }}
                  </p>
                  <div class>
                    <ul class="involve-list">
                      <li
                        v-for="point in bulletPoints"
                        :key="point.text"
                        v-html="point.text"
                      ></li>
                    </ul>
                  </div>
                  <p class="involve-sub-text font-weight-bold goverment-team-p">
                    {{ text.subListStart }}
                  </p>
                  <p class="involve-sub-text mb-4">
                    There are also a number of Scottish Government employees
                    working from Scotland House Brussels in partnership with our
                    Scotland Europa team. <a
                      href="https://www.gov.scot/publications/brussels-office-contact-details/"
                      target="_blank"
                    >You can find their contact details on
                      the Scottish government website. <span class="sr-only">this link will open in a new window</span><svg-link-purple class="ml-1"/></a>
                  </p>
                </div>
              </el-col>
            </div>
          </div>
        </div>
      </el-row>
    </div>
  </div>
</template>

<script>
import svgLinkPurple from "@/components/assets/svg-link-purple";
export default {
  components: {
    svgLinkPurple
  },
  data() {
    return {
      text: {
        heading: "The Scotland House team",
        listStart: "Scotland Europa team",
        subListStart: "Scottish Government team",
      },
      bulletPoints: [
        {
          text: "Sarah English: Head of Brussels Office: <a href='mailto:Sarah.English@scotent.co.uk'>Sarah.English@scotent.co.uk</a>, +32 2 282 83 14",
        },
        {
          text: "James Sharples: Senior EU Policy & Funding Executive: <a href='mailto:James.Sharples@scotent.co.uk'>James.Sharples@scotent.co.uk</a>, +32 2 282 83 12",
        },
        {
          text: "Marija Ivoninaite: Senior EU Policy & Funding Executive: <a href='mailto:Marija.Ivoninaite@scotent.co.uk'>Marija.Ivoninaite@scotent.co.uk</a>, +32 2 282 83 09",
        },
        {
          text: "Valentine Willmann: Senior EU Policy & Funding Executive: <a href='mailto:Valentine.Willmann@scotent.co.uk'>Valentine.Willmann@scotent.co.uk</a>, +32 2 282 83 06",
        },
        {
          text: "Lorna Murphy: Policy and Communications Executive: <a href='mailto:Lorna.Murphy@scotent.co.uk'>Lorna.Murphy@scotent.co.uk</a>, +32 2 282 83 13",
        },
        {
          text: "Lucie McAnea: Policy and Communications Executive: <a href='mailto:Lucie.McAnea@scotent.co.uk'>Lucie.McAnea@scotent.co.uk</a>, +32 2 282 83 10",
        },
        {
          text: "Helena De Raedemaeker: Scotland House Coordinator - Conference Centre & Facilities: <a href='mailto:Helena.DeRaedemaeker@scotent.co.uk'>Helena.DeRaedemaeker@scotent.co.uk</a>, +32 2 282 83 15",
        },
        {
          text: "Egbert Flipse, Finance: <a href='mailto:Egbert.Flipse@scotent.co.uk'>Egbert.Flipse@scotent.co.uk</a>, +32 2 282 83 04",
        },
        {
          text: "Rebecca Gaff: EU Policy & Events Stagiaire: <a href='mailto:Rebecca.Gaff@scotent.co.uk'>Rebecca.Gaff@scotent.co.uk</a>, +32 2 282 83 18",
        },
        {
          text: "Olivia de Hennin: EU Policy & Events Stagiaire: <a href='mailto:Olivia.deHennin@scotent.co.uk'>Olivia.deHennin@scotent.co.uk</a>, +32 2 282 83 19",
        },
      ],
    };
  },
};
</script>
<style>
.sh-team-left-text {
    margin-bottom: 65px !important;
}
.goverment-team-p {
    margin-top: 30px !important;
    margin-bottom: 20px !important;
}
.invlove-img {
  /*  margin-bottom: 50px;*/
}
.left-text {
  margin-bottom: 50px;
}
</style>
