<template>
  <div
    v-loading.fullscreen.lock="loading"
    element-loading-background="rgba(255, 255, 255, 0.8)"
  >
    <div
      v-if="!showZeroData"
      class="recent-activity-section"
    >
      <ul
        ref="recent-activity-buttons"
        class="recent-activity-buttons"
      >
        <li v-if="isProfileOwner">
          <el-button
            :class="{ 'active-state': currentTabName === eventsAndWebinarsTab.name }"
            class="search-btn"
            @click="handleTabChange(eventsAndWebinarsTab)"
          >
            <p>Events</p>
          </el-button>
        </li>
        <li>
          <el-button
            :class="{ 'active-state': currentTabName === communityFeedTab.name }"
            class="search-btn"
            @click="handleTabChange(communityFeedTab)"
          >
            <p>Community feed</p>
          </el-button>
        </li>
      </ul>
      <el-row v-if="isProfileOwner && currentTabName === eventsAndWebinarsTab.name">
        <div v-if="eventsAndWebinarsTab.data.length > 0">
          <el-col>
            <event-card
              v-for="activity in eventsAndWebinarsTab.data"
              :key="activity.id"
              :is-profile-owner="isProfileOwner"
              :activity="activity"
              @deleteActivity="handleIntentionToDelete"
            />
          </el-col>
        </div>
        <div
          v-else
          class="activity-zero-data"
        >
          <br class="d-block" />
          <p v-if="!loading && !eventsAndWebinarsTab.data.length">
            No events and webinars.
          </p>
        </div>
      </el-row>

      <el-row v-if="currentTabName === communityFeedTab.name">
        <div
          v-if="communityFeedTab.data.length > 0"
          class="community-cards"
        >
          <posts-card
            :is-profile-owner="isProfileOwner"
            :activities="communityFeedTab.data"
            @deleteActivity="handleIntentionToDelete"
          />
        </div>
        <div
          v-else
          class="activity-zero-data"
        >
          <p v-if="!loading && !communityFeedTab.data.length">
            No community feed posts.
          </p>
        </div>
      </el-row>
    </div>
    <div
      v-else
      class="activity-zero-data"
    >
      <p>No activity yet.</p>
    </div>

    <el-row
      id="recent-activity-pagination"
      class="pagination-help-section"
    >
      <el-col
        class="text-center"
        :span="24"
      >
        <el-button
          v-if="!onLastPage && !loading"
          class="load-more-btn load-more-activities-btn"
          @click.native="getRecentActivity"
        >
          Load more
        </el-button>
      </el-col>
    </el-row>

    <el-row>
      <el-col
        class="text-center"
        :span="24"
      >
        <el-button
          v-if="showScrollToTop"
          type="text"
          class="scroll-to-bottom"
          @click.native="scrollToTop"
        >
          Scroll to top
        </el-button>
      </el-col>
    </el-row>

    <el-dialog
      v-if="showActivityDeleteDialog"
      id="delete-activity-dialog"
      ref="confirmDeleteDialog"
      :show-close="false"
      custom-class="confirm-dialog"
      :visible.sync="showActivityDeleteDialog"
      @opened="dialogOpen('confirmDeleteDialog')"
    >
      <el-row>
        <el-col
          :span="24"
          class="text-center"
        >
          <h1 class="mt-0">
            Warning
          </h1>
          <p class="fs-19">
            Are you sure you want to delete this activity?
          </p>
        </el-col>
      </el-row>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="secondary"
          @click="showActivityDeleteDialog = false"
        >Cancel</el-button>
        <el-button
          v-loading.lock="blockToDelete"
          type="primary"
          element-loading-background="rgba(255, 255, 255, 0.8)"
          :disabled="blockToDelete"
          @click="deleteActivity(activityToDelete)"
        >Yes, delete activity</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from '@/store';
import gsApi from '@/services/gs.api'
// import NewsCard from '@/components/partials/recent-activity-cards/news-card'
import PostsCard from '@/components/partials/recent-activity-cards/community-feed-card'
// import HelpRequestCard from '@/components/partials/recent-activity-cards/help-request-card'
// import ProjectCard from "@/components/partials/recent-activity-cards/project-card"
import EventCard from "@/components/partials/recent-activity-cards/event-card"

const ACTIVITIES = {
  EVENT_BOOKED: 2,
  EVENT_ATTENDED: 3,
  POST_CREATED: 6,
};

export default {
  name: 'RecentActivity',
  components: {
    PostsCard,
    'event-card': EventCard,
  },
  props: {
    isGlobalScotProfile: {
      type: Boolean,
      default: false
    },
    isStaffProfile: {
      type: Boolean,
      default: false
    },
    isProfileOwner: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      /*spotlightsTab: {
        name: 'spotlights',
        data: [],
        activityTypes: [ACTIVITIES.SPOTLIGHT_MADE, ACTIVITIES.USER_FEATURED_CASE_STUDY, ACTIVITIES.USER_FEATURED_FEATURES],
      },
      helpRequestsTab: {
        name: 'helpRequests',
        data: [],
        activityTypes: [ACTIVITIES.HELP_REQUEST_MADE, ACTIVITIES.HELP_REQUEST_RECEIVED],
      },*/
      communityFeedTab: {
        name: 'communityFeed',
        data: [],
        activityTypes: [ACTIVITIES.POST_CREATED],
      },
      eventsAndWebinarsTab: {
        name: 'eventsAndWebinars',
        data: [],
        activityTypes: [ACTIVITIES.EVENT_BOOKED, ACTIVITIES.EVENT_ATTENDED],
      },
      /*projectsTab: {
        name: 'projects',
        data: [],
        activityTypes: [ACTIVITIES.PROJECT_PARTICIPATED],
      },*/
      currentTabName: 'eventsAndWebinars',
      page: 1,
      per_page: 15,
      totalResults: 0,
      onLastPage: false,
      showZeroData: false,
      showActivityDeleteDialog: false,
      activityToDelete: null,
      blockToDelete: false,
    };
  },
  computed: {
    ...mapGetters([
      'isExpert', 'navigatedFrom', 'scrollToActivityHistory'
    ]),
    shouldScrollToTab() {
      return this.scrollToActivityHistory.tabName !== null;
    },
    initParams() {
      if (this.isProfileOwner) {
        return {
          user_id: this.$route.params.id,
          activity_types: [
            ACTIVITIES.EVENT_BOOKED,
            ACTIVITIES.EVENT_ATTENDED,
            ACTIVITIES.POST_CREATED
          ]
        }
      } else {
        return {
          user_id: this.$route.params.id,
          activity_types: [
            ACTIVITIES.POST_CREATED
          ]
        }
      }
    },
    showScrollToTop() {
      return this[`${this.currentTabName}Tab`].data.length > 10;
    },
  },
  watch: {
    "$route.params.id": {
      handler: function(newVal, oldVal) {
        if(newVal != oldVal) {
          this.loading = true;
          if (this.shouldScrollToTab) {
            this.handleTabChange(this[`${this.scrollToActivityHistory.tabName}Tab`]);
            this.$nextTick(() => {
              this.$refs['recent-activity-buttons'].scrollIntoView();
            });
          } else {
            this.getRecentActivityCount();
            this.setCurrentTab()
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  beforeDestroy () {
    this.$store.dispatch('clearScrollToActivityHistory');
  },
  methods: {
    setCurrentTab() {
      this.currentTabName = this.eventsAndWebinarsTab.name;
      if (!this.isProfileOwner) {
        this.currentTabName = this.eventsAndWebinarsTab.name;
      }
    },
    handleTabChange(tab) {
      tab.data = [];
      this.currentTabName = tab.name;
      this.page = 1;
      this.per_page = 15;
      this.totalResults = 0;
      this.getRecentActivity()
    },
    getRecentActivity() {
      const tab = this[`${this.currentTabName}Tab`];
      this.loading = true;

      gsApi.users.recentActivity( {
        user_id: this.$route.params.id,
        activity_types: tab.activityTypes,
        page: this.page,
      })
        .then(response => {
          this.totalResults = response.data.meta.pagination.total

          if (response.data.meta.pagination.current_page !== response.data.meta.pagination.total_pages) {
            this.onLastPage = false
            this.page++
          } else {
            this.onLastPage = true
          }

          this[`${this.currentTabName}Tab`].data = this[`${this.currentTabName}Tab`].data.concat(response.data.data);
          this.showZeroData = this[`${this.currentTabName}Tab`] <= 0
          this.loading = false;
        }).catch(() => {
          this.loading = false
        });
    },
    getRecentActivityCount() {
      if (!this.loading) {
        this.loading = true
      }
      let params = this.initParams;
      gsApi.users.recentActivity(params)
        .then(response => {
          if (response.data.data.length > 0) {
            this.getRecentActivity();
          } else {
            this.showZeroData = true;
            this.loading = false;
            this.onLastPage = true;
          }
        }).catch(() => {
          this.loading = false
        });
    },
    scrollToTop() {
      this.$refs['recent-activity-buttons'].scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    },
    handleNavigatedToChat(payload) {
      store.dispatch('updateNavigatedFrom', {
        name: 'activity-history',
      });
    },
    handleIntentionToDelete(activityId) {
      this.activityToDelete = activityId;
      this.showActivityDeleteDialog = true;
    },
    deleteActivity(activityId) {
      this.blockToDelete = true;
      const activityIndex = this[`${this.currentTabName}Tab`].data.findIndex(i => i.id === activityId);
      gsApi.users
        .deleteActivity(activityId)
        .then((response) => {
          this.showActivityDeleteDialog = false;
          this.blockToDelete = false;
          this[`${this.currentTabName}Tab`].data.splice(activityIndex, 1);
        })
        .catch((error) => {
          this.showActivityDeleteDialog = false;
          this.blockToDelete = false;
          this.$alert(
            "An unknown error occurred. Please try again later.",
            "Error",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal",
            }
          );
        })
        .finally(() => {
          this.showActivityDeleteDialog = false;
          this.blockToDelete = false;
        })
    },
  },
};
</script>

<style lang="scss">
#recent-activity-pagination .load-more-activities-btn {
  margin: -80px auto 40px;
}

.scroll-to-bottom:hover {
  text-decoration: underline;
}

#delete-activity-dialog .el-dialog .el-dialog__body {
  overflow-x: hidden;
}

#delete-activity-dialog .dialog-footer {
  display: flex;
  justify-content: center;
  @media screen and (max-width: 425px) {
    display: block;
    .el-button {
      white-space: normal;
      width: 100%;
      margin: 4px 0;
    }
  }
}

.community-cards{
  .author-name{
    font-size: 1rem;
    color: #A8308C;
    line-height: 1.125rem;
  }
}
</style>
