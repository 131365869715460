<template>
  <div class="signup-select-page page">
    <el-row
      :gutter="0"
      class="m-0"
    >
      <top-navigation></top-navigation>
      <el-col
        :span="24"
        class="article-featured px-0"
      >
        <div class>
          <div class="signup-select-page__header small-header">
            <div class="header__content__bg"></div>
            <div class="header__image__bg signup-select-bg"></div>
            <div class="header__content container">
              <el-row>
                <el-col
                  :md="24"
                  :lg="12"
                >
                  <div class="header__content__wrapper">
                    <h1
                      id="main-content"
                      class="header__title"
                    >
                      Join Scotland Europa
                    </h1>
                    <p class="header__content-info mb-3">
                      Thinking of joining Scotland Europa? Create your account
                      today and start accessing our membership services, or
                      speak to us about joining if your organisation isn't a
                      member yet.
                    </p>
                    <p class="header__signin-link">
                      Already have an account?
                    </p>
                    <el-button
                      class="btn-text join-home-btn"
                      type="primary"
                      @click="$router.push({ name: 'Login' })"
                    >
                      Sign in
                    </el-button>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>

    <div class="signup-select-page__body">
      <div class="container">
        <el-row>
          <el-col
            :sm="24"
            :md="12"
            :lg="12"
          >
            <div class="signup-select-card">
              <div class="signup-select-card__content">
                <span class="se-heading-m signup-select-card__title">Existing member organisations
                </span>

                <p class="se-body-l-screen">
                  Our member organisations get unlimited individual user
                  accounts for their staff, so you can create an account today
                  to start accessing our services.
                </p>

                <p class="se-body-l-screen">
                  Join as an individual member to:
                </p>

                <ul class="pl-5 mb-4">
                  <li>Access exclusive news and alerts</li>
                  <li>
                    Sign up for email alerts about EU updates that interest you
                  </li>
                  <li>Explore our full list of events</li>
                  <li>Network with other members</li>
                  <li>Explore or post in our community feed</li>
                </ul>

                <p class="se-body-l-screen">
                  To create your account, just complete our registration form,
                  making sure to use your email address that's associated with
                  your member organisation.
                </p>

                <router-link
                  class="
                    signup-select-btn
                    el-button el-button--primary
                    ss-create-account-btn
                  "
                  to="/sign-up"
                >
                  <span>Create an account</span>
                </router-link>
              </div>
            </div>
          </el-col>

          <el-col
            :sm="24"
            :md="12"
            :lg="12"
          >
            <div class="signup-select-card">
              <div class="signup-select-card__content">
                <span class="se-heading-m signup-select-card__title">Membership for your organisation</span>

                <p class="se-body-l-screen">
                  Think your organisation could benefit from our services? Our
                  members get:
                </p>

                <ul class="pl-5 mb-4">
                  <li>
                    Latest updates and alerts on EU policy, funding,
                    announcements and more
                  </li>
                  <li>
                    Access to in-depth analysis of EU policies and legislative
                    changes
                  </li>
                  <li>A chance to have your voice heard on EU issues</li>
                  <li>
                    Opportunities to network in Europe and meet EU officials
                  </li>
                  <li>
                    Access to our extensive network of contacts across Europe
                  </li>
                  <li>And more!</li>
                </ul>

                <p class="se-body-l-screen">
                  If you're interested in joining, contact us today and one of
                  our team will get in touch to discuss potential membership
                  packages.
                </p>

                <router-link
                  class="
                    signup-select-btn
                    el-button el-button--primary
                    ss-get-in-touch-btn
                  "
                  to="/contact-us"
                >
                  <span>Get in touch</span>
                </router-link>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import TopNavigation from "../../layout/top-navigation";
export default {
  name: "SignupSelect",
  metaInfo: {
    title: "Join Scotland Europa",
    meta: [
      {
        name: "description",
        content:
          "Are you a Scottish business looking for support or a business leader happy to offer your expertise? Join the Scotland Europa today.",
      },
    ],
  },
  components: {
    TopNavigation,
  },
};
</script>

<style lang="scss">
.festive-delay-text {
  font-size: $font-size-22;
  margin-bottom: 25px;
  margin-left: 10px;
  font-style: italic;
  font-weight: 600;
  color: #bf3a27;
}

.join-home-btn {
  margin: 35px 0px 30px 0px;
}
</style>
