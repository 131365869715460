<!-- Home Component -->
<template>
  <div class="about-page">
    <el-row
      :gutter="0"
      class="m-0"
    >
      <top-navigation></top-navigation>
      <el-col
        :span="24"
        class="article-featured px-0"
      >
        <div class>
          <div class="about-page__header">
            <div class="header__content__bg"></div>
            <div class="header__content container">
              <el-row>
                <el-col
                  :md="24"
                  :lg="12"
                >
                  <div class="header__content__wrapper">
                    <h1
                      id="main-content"
                      class="header__title"
                    >
                      {{ text.headingText }}
                    </h1>
                    <p class="header__content-info">
                      {{ text.headingSub }}
                    </p>
                    <div class="number-section">
                      <p class="header__content-info">
                        {{ text.headingNumber }}
                      </p>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
        <div class="white-background-section">
          <contact-form></contact-form>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import gsApi from "@/services/gs.api";
import store from "@/store";
import { mapState } from "vuex";
import PlaySvg from "../../components/assets/svg-play";
import router from "@/router";
import TopNavigation from "../../layout/top-navigation";
import ContactForm from "../../components/partials/contact-form";
import { Loading } from "element-ui";
export default {
  name: "Contact",
  metaInfo: {
    title: "Contact us",
    meta: [{
      name: 'description',
      content: "Got a question for the Scotland Europa team? Contact us via the website or give us a call."
    }]
  },
  components: {
    "svg-play": PlaySvg,
    ContactForm,
    TopNavigation,
    Loading
  },
  computed: {
    ...mapState(["user"])
  },
  data() {
    return {
      mainLoader: false,
      inVerifiableState: store.state.inVerifiableState,
      groups: [{ id: 1, name: "COMING SOON" }],
      selectedGroup: null,
      topFeedItems: [],
      loading: null,
      text: {
        headingText: "Contact Us",
        headingSub:
          "Got a question about the work we do or about membership? Get in touch with our team today. We’ll aim to respond to all enquiries within 3 working days.",
        headingNumber: "Complete our enquiry form to submit an enquiry. You can also contact our team directly – you’ll find their contact details on this page. ",
      }
    };
  },

  created() {
    this.loading = true;
  },
  updated() {
    this.loading = false;
  },
  methods: {},
  methods: {}
};
</script>
<style>
.featured-desc {
  margin: auto 100px auto 100px;
}
.featured-title {
  font-weight: 400;
  padding-bottom: 30px;
}
img {
  width: 100%;
  height: auto;
}
.number-section {
  margin: 20px 0 20px 0;
}
</style>
