<template>
  <slide-panel @panelclose="closeMenu" title="Menu">
    <div class="hr mb-3"></div>
      <div class="profile-section justify-content-between d-flex" @click="goToProfile()">
          <div class="d-flex">
            <el-avatar v-if="user.avatar" shape="circle" :size="30" :src="user.avatar" alt="Profile picture"></el-avatar>
            <el-avatar v-else shape="circle" :size="30" alt="" :src="require('../../../../static/img/default-image.png')"></el-avatar>
            <p>View profile</p>
          </div>
          <a href="#">
            <svg-purple-arrow-right class="arrow"></svg-purple-arrow-right>
          </a>
      </div>
    <div class="hr mt-3"></div>

    <ul class="menu-items mt-3">
      <li @click="closeMenu" class="el-menu-item">
        <router-link :to="{ name: 'Feed', params: { isActiveFilter: 1 } }">
          <span><svg-feed></svg-feed></span>Dashboard
        </router-link>
      </li>
      <li @click="closeMenu" class="el-menu-item">
        <router-link :to="{ name: 'Pulse' }">
          <span><svg-pulse></svg-pulse></span>Community feed
        </router-link>
      </li>
      <li @click="closeMenu" class="el-menu-item">
        <router-link :to="{ name: 'Members' }">
          <span><svg-find-expert></svg-find-expert></span>Members
        </router-link>
      </li>
      <li @click="closeMenu" class="el-menu-item">
        <router-link :to="{ name: 'Events and Webinars' }">
          <span><svg-news></svg-news></span>Events & webinars
        </router-link>
      </li>
      <li @click="closeMenu" class="el-menu-item">
        <router-link :to="{ name: 'News and Features' }">
          <span><svg-news-purple></svg-news-purple></span>News & resources
        </router-link>
      </li>
      <li @click="closeMenu" class="el-menu-item">
        <router-link :to="{ name: 'My Chats' }">
          <span><svg-chat class="chat-icon"></svg-chat></span>Messages
        </router-link>
      </li>
      <li v-if="isExpert" @click="closeMenu" class="el-menu-item">
        <router-link :to="{ name: 'Projects' }">
          <span><svg-news-purple></svg-news-purple></span>Projects
        </router-link>
      </li>
    </ul>

    <div class="hr mt-3 mb-3"></div>

    <ul class="menu-links">
      <li @click="closeMenu">
        <router-link @click="closeMenu" to="/settings">Settings</router-link>
      </li>
      <!--li @click="closeMenu">
        <router-link :to="{ name: 'Become Member' }">Become a Member</router-link>
      </!--li-->
      <li @click="closeMenu">
        <router-link :to="{ name: 'Contact Us' }">Help</router-link>
      </li>
    </ul>

    <div class="logout-link">
      <a href="#"  @click="logout">Logout <svg-purple-arrow-right class="arrow"></svg-purple-arrow-right></a>
    </div>
  </slide-panel>
</template>

<script>
  import store from '@/store'
  import { mapState } from "vuex"
  import Feed from '@/components/assets/svg-feed'
  import Event from '@/components/assets/svg-event'
  import News from '@/components/assets/svg-news'
  import NewsPurple from '@/components/assets/svg-news-purple'
  import Pulse from '@/components/assets/svg-pulse'
  import Chat from '@/components/assets/svg-chat-menu'
  import FindExpert from '@/components/assets/svg-find-expert'
  import SlidePanel from '@/components/slide-panel/slide-panel'
  import Challenges from '@/components/assets/svg-challenges-menu'
  import PurpleArrowRight from '@/components/assets/svg-purple-arrow-right'
  import RolePermissionMixin from '@/mixins/RolePermissionMixin'
   import gsApi from '@/services/gs.api'
  import helpers from '@/helpers/index'

  export default {
    name: 'mobile-menu',
    mixins: [RolePermissionMixin],
    components: {
      'svg-feed': Feed,
      'svg-chat': Chat,
      'svg-event': Event,
      'svg-news': News,
      'svg-news-purple': NewsPurple,
      'svg-pulse': Pulse,
      'svg-challenges': Challenges,
      'svg-find-expert': FindExpert,
      'svg-purple-arrow-right': PurpleArrowRight,
      'slide-panel': SlidePanel
    },
    data(){
      return {
        isTrader: false,
        isExpert: false,
      }
    },
    mounted(){
      this.checkRole()
    },
    computed: {
      ...mapState(['user']),
    },
    methods: {

      checkRole() {
        if (this.isRole('trader'))
          this.isTrader = true;

        if (this.isRole('expert'))
          this.isExpert = true
      },
      goToProfile() {
        if (helpers.isExpert(this.user)) {
          this.closeMenu()
          this.$router.push({name: 'SE Profile', params: {id: this.user.id}})
        } else if(helpers.isMember(this.user)){
          this.closeMenu()
          this.$router.push({name: 'NonGlobalScot Profile', params: {id: this.user.id}})
        }else{
          this.closeMenu()
          this.$router.push({name: 'Staff Profile', params: {id: this.user.id}})
        }
      },
      closeMenu() {
        this.$emit('close')
      },

        logout() {
          gsApi.auth.logout(this.user.token).then(()=>{
            store.commit('USER_LOGOUT')
            this.$nextTick(()=>{
              this.$router.push({name: "Home"});
            })
          })

        },
    },
  }
</script>
