const enumHelper = {
  BusinessTypeEnum: {
    TRADER: 1,
    EXPERT: 2,
    EXPERT_OTHER: 3
  },
  FileTypeEnum: {
    FILE: 1,
    DOCUMENT: 2
  },
  AllowedTypes: {
    IMG_JPEG: "image/jpeg",
    IMG_JPG: "image/jpg",
    IMG_PNG: "image/png",
    PDF: "application/pdf",
    VIDEO_OGG: "video/ogg",
    VIDEO_MPEG: "video/mpeg",
    VIDEO_WEBM: "video/webm",
    VIDEO_MP4: "video/mp4",
    APP_POWERPOINT: "application/vnd.ms-powerpoint",
    APP_OFFICE_PRESENTATION:
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    APP_DOC_PRESENTATION: "application/vnd.oasis.opendocument.presentation",
    APP_WORD: "application/msword",
    APP_DOC:
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    APP_TEXT: "application/vnd.oasis.opendocument.text",
    APP_EXCEL: "application/vnd.ms-excel",
    APP_SHEET:
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    APP_SPREADSHEET: "application/vnd.oasis.opendocument.spreadsheet"
  },
  FollowAction: {
    FOLLOW: "follow",
    UNFOLLOW: "unfollow"
  },
  FilesExtension: {
    DOC: "doc",
    DOCX: "docx",
    JPG: "jpg",
    PNG: "png",
    JPEG: "jpeg",
    MP4: "mp4",
    WEBM: "webm",
    OGV: "ogv",
    OGG: "ogg",
    ODT: "odt",
    ODP: "odp",
    PDF: "pdf",
    PPT: "ppt",
    XLS: "xls",
    XLSX: "xlsx"
  },
  MFAType:{
    GOOGLE:1,
    PHONE:2,
    EMAIL:3
  }
};

export default Object.freeze(enumHelper);
