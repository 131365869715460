<template>
  <svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
    <path
      d="M56.5461 46.0288C56.5461 48.3359 55.8695 50.5911 54.6019 52.5094C53.3343 54.4276 51.5325 55.9226 49.4246 56.8054C47.3167 57.6881 44.9973 57.9189 42.7596 57.4686C40.522 57.0183 38.4666 55.9071 36.8535 54.2756C35.2405 52.644 34.1421 50.5653 33.6974 48.3025C33.2526 46.0397 33.4815 43.6944 34.355 41.5631C35.2285 39.4318 36.7075 37.6104 38.6048 36.3291C40.5021 35.0478 42.7325 34.3641 45.0141 34.3647C48.0727 34.3661 51.0056 35.5955 53.168 37.7828C55.3305 39.97 56.5456 42.936 56.5461 46.0288V46.0288Z"
      stroke="#A8308C"
      stroke-width="2"
      stroke-linejoin="round"
    />
    <path
      d="M53.167 54.2761L62.314 63.5255"
      stroke="#A8308C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M31.657 63.5254C25.5936 63.5254 19.6664 61.7073 14.6249 58.301C9.58337 54.8946 5.65398 50.0531 3.33362 44.3886C1.01327 38.7241 0.406178 32.491 1.58909 26.4776C2.77199 20.4642 5.69177 14.9405 9.97923 10.6051C14.2667 6.26965 19.7293 3.31722 25.6761 2.12107C31.623 0.924933 37.7871 1.53878 43.3889 3.8851C48.9908 6.23142 53.7788 10.2048 57.1474 15.3027C60.516 20.4006 62.314 26.3942 62.314 32.5254"
      stroke="#A8308C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M25.3021 51.0979V43.8072L10.8831 32.1432L17.377 21.9372H33.9531V13.1885L29.627 10.2722L42.2111 2.98254"
      stroke="#A8308C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M53.663 11.4167L48.622 20.8795H43.577L38.53 25.983"
      stroke="#A8308C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template><script>
export default {};
</script>
