<template>
  <el-row class="m-0 follower-followable">
    <el-col
      :span="17"
      class="follower-followable-item"
    >
      <div class="d-flex follower-followable-item pb-2 pt-2 followers-section">
        <div
          class="clickable"
          @click="$router.push({name: getPathName, params: { id: item.id }})"
        >
          <user-avatar
            avatar-classes="follower-followable-item-img flex-shrink-0"
            shape="circle"
            :is-global-scot="helpers.isExpert(item) "
            :author-exist="!!item"
            width="60px"
            :size="60"
            :src="item.avatar"
            :alt="`${item.full_name} Profile picture`"
          ></user-avatar>
        </div>
        <div
          class="full-name clickable"
          @click="$router.push({name: getPathName, params: { id: item.id }})"
        >
          <span class="sr-only">{{ item.full_name }}</span>
          <span
            tabindex="0"
            @keyup.enter="$router.push({name: getPathName, params: { id: item.id }})"
          >{{ item.full_name }}</span>
        </div>
      </div>
      <div
        v-if="item.organisation"
        class="d-flex"
      >
        <div class="position">
          {{ ` ${item.organisation.name} | ${item.role}` }}
        </div>
        <div
          id="fake-space"
          class="ml-auto flex-shrink-0"
        ></div>
      </div>
    </el-col>
    <el-col :span="7">
      <el-button
        v-loading="startAction"
        class="pull-right"
        :type="item.followable.has_followed ? 'button' : 'primary'"
        size="small"
        @click="followUnfollowProfile()"
      >
        {{ item.followable.has_followed ? "Unfollow" : "Follow" }}
      </el-button>
    </el-col>
  </el-row>
</template>
<script>
import gsApi from "@/services/gs.api";
import helpers from '@/helpers/index'
import enumHelper from '@/helpers/enumHelper'
import UserAvatar from "@/components/core/user-avatar";
export default {
  components: {
    UserAvatar,
  },
  props: {
    item: { type: Object, required: true }
  },
  data(){
    return {
      helpers,
      startAction : false
    }
  },
  computed: {
    getPathName() {
      return helpers.isExpert(this.item) ? "SE Profile" : helpers.isMember(this.item) ? "NonGlobalScot Profile" : "Staff Profile"
    }
  },
  methods:{
    followUnfollowProfile() {
      this.startAction = true;
      let action = this.item.followable.has_followed ? enumHelper.FollowAction.UNFOLLOW : enumHelper.FollowAction.FOLLOW;
      const data = {
        id : this.item.id,
        module : "User",
        entity : "User"
      }
      gsApi.followable.followUnfollow(data, action).then((response)=>{
        this.item.followable.has_followed = response.data.has_followed;
        if(action == enumHelper.FollowAction.FOLLOW){
          this.$alert(`Your are now successfully following ${this.item.full_name}.`, 'Success', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
        }else{
          this.$alert(`Your are successfully unfollow ${this.item.full_name}.`, 'Success', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
          // remove the item from the list
          this.$emit("removeItem", this.item.id)
        }

      }).catch((response)=>{
        if (response.status == 422) {
          let firstError =
            response.data.errors[Object.keys(response.data.errors)[0]]
          this.$alert(firstError[0], 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
        } else {
          this.$alert('Sorry, your request could not be processed', `Error ${error.response.data.error.status_code} - ${error.response.data.error.message}`, { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
        }
      }).finally(()=>{
        this.startAction = false;
      })
    }
  }
};
</script>
