<!-- T's & C's Component -->
<template>
  <div class="terms-page">
    <section aria-label="Term and conditions">
      <div class="container text-center">
        <h1 class="text-white text-center">
          Terms of use
        </h1>
      </div>
    </section>
    <div class="container">
      <el-row>
        <el-col
          :md="24"
          :lg="6"
          :xl="6"
        >
          <div class="terms-policies">
            <terms-policies></terms-policies>
          </div>
        </el-col>

        <el-col
          :md="24"
          :lg="18"
          :xl="18"
        >
          <div class="terms-conditions">
            <h2
              id="main-content"
              class="title"
            >
              {{ ourTerms.title }}
            </h2>
            <p>
              These Terms Of Use (together with the
              <router-link to="/privacy-policy">
                privacy notice
              </router-link>
              and the
              <router-link to="/cookie-policy">
                cookies policy
              </router-link>)
              tell you the terms on which you may use this website.
            </p>
            <p>
              By using this website, you confirm that you accept these terms of
              use and that you agree to follow them. If you do not agree to
              these terms of use, you are not allowed to use this website.
            </p>
          </div>

          <div class="terms-conditions">
            <h2 class="sub-title">
              {{ resposibleText.title }}
            </h2>
            <p v-html="resposibleText.firstSub"></p>
            <p v-html="resposibleText.secondSub"></p>
            <p>{{ resposibleText.thirdSub }}</p>
          </div>
          <div class="terms-conditions">
            <h2 class="sub-title">
              {{ makeChangeToTerms.title }}
            </h2>
            <p>{{ makeChangeToTerms.firstSub }}</p>
          </div>
          <div class="terms-conditions">
            <h2 class="sub-title">
              {{ transferAgreement.title }}
            </h2>
            <p>{{ transferAgreement.firstSub }}</p>
          </div>

          <div class="terms-conditions">
            <h2 class="sub-title">
              {{ informationOnThis.title }}
            </h2>
            <p>{{ informationOnThis.firstSub }}</p>
            <p>{{ informationOnThis.secondSub }}</p>
            <p>{{ informationOnThis.thirdSub }}</p>
            <p>{{ informationOnThis.fourthSub }}</p>
          </div>
          <div class="terms-conditions">
            <h2 class="sub-title">
              {{ notReplyInformation.title }}
            </h2>
            <p>{{ notReplyInformation.firstSub }}</p>
            <p>{{ notReplyInformation.secondSub }}</p>
          </div>
          <div class="terms-conditions">
            <h2 class="sub-title">
              {{ accessingThisWebsite.title }}
            </h2>
            <p>{{ accessingThisWebsite.firstSub }}</p>
            <p>{{ accessingThisWebsite.secondSub }}</p>
          </div>
          <div class="terms-conditions">
            <h2 class="sub-title">
              {{ yourAccount.title }}
            </h2>
            <p>
              {{ yourAccount.firstSubPartOne }}
              <router-link to="/privacy-policy">
                Privacy Notice
              </router-link>
              {{ yourAccount.firstSubPartTwo }}
            </p>
            <p>{{ yourAccount.secondSub }}</p>
            <p v-html="yourAccount.thirdSub"></p>
            <p>{{ yourAccount.fourthSub }}</p>
          </div>
          <div class="terms-conditions">
            <h2 class="sub-title">
              {{ copyright.title }}
            </h2>
            <p>{{ copyright.firstSub }}</p>
            <p>{{ copyright.secondSub }}</p>
            <p>{{ copyright.thirdSub }}</p>
            <ul>
              <li
                v-for="(point, index) in copyright.bulletPoints"
                :key="index"
              >
                {{ point.text }}
              </li>
            </ul>
          </div>
          <div class="terms-conditions">
            <h2 class="sub-title">
              {{ noTextOrData.title }}
            </h2>
            <p>{{ noTextOrData.firstSub }}</p>
            <p>{{ noTextOrData.secondSub }}</p>
            <p>{{ noTextOrData.thirdSub }}</p>
            <p>{{ noTextOrData.fourthSub }}</p>
          </div>
          <div class="terms-conditions">
            <h2 class="sub-title">
              {{ acceptableUse.title }}
            </h2>
            <p>{{ acceptableUse.firstSub }}</p>
            <p>{{ acceptableUse.secondSub }}</p>
            <p>{{ acceptableUse.thirdSub }}</p>
          </div>
          <div class="terms-conditions">
            <h2 class="sub-title">
              {{ uploadingSection.title }}
            </h2>
            <p>{{ uploadingSection.firstSub }}</p>
            <p>{{ uploadingSection.secondSub }}</p>
            <p>
              {{ uploadingSection.thirdSub
              }}<router-link to="/privacy-policy">
                Privacy Notice.
              </router-link>
            </p>
            <p>{{ uploadingSection.fourthSub }}</p>
            <p>{{ uploadingSection.fifthSub }}</p>
            <p>{{ uploadingSection.sixthSub }}</p>
            <p>{{ uploadingSection.seventhSub }}</p>
          </div>

          <div class="terms-conditions">
            <h2 class="sub-title">
              {{ rightsGivingSection.title }}
            </h2>
            <p>{{ rightsGivingSection.firstSub }}</p>
            <ul>
              <li
                v-for="(point, index) in rightsGivingSection.bulletPoints"
                :key="index"
              >
                {{ point.text }}
              </li>
            </ul>
          </div>
          <div class="terms-conditions">
            <h2 class="sub-title">
              {{ thirdPartySection.title }}
            </h2>
            <p>{{ thirdPartySection.firstSub }}</p>
            <p>{{ thirdPartySection.secondSub }}</p>
            <p>{{ thirdPartySection.thirdSub }}</p>
          </div>
          <div class="terms-conditions">
            <h2 class="sub-title">
              {{ linkingSection.title }}
            </h2>
            <p>{{ linkingSection.firstSub }}</p>
            <p>{{ linkingSection.secondSub }}</p>
            <p>{{ linkingSection.thirdSub }}</p>
            <p>{{ linkingSection.fourthSub }}</p>
            <p>{{ linkingSection.fifthSub }}</p>
            <p>{{ linkingSection.sixthSub }}</p>
          </div>
          <div class="terms-conditions">
            <h2 class="sub-title">
              {{ virusSection.title }}
            </h2>
            <p>{{ virusSection.firstSub }}</p>
            <p>{{ virusSection.secondSub }}</p>
          </div>
          <div class="terms-conditions">
            <h2 class="sub-title">
              {{ disclaimerSection.title }}
            </h2>
            <p>{{ disclaimerSection.firstSub }}</p>
            <p>{{ disclaimerSection.secondSub }}</p>
            <p>{{ disclaimerSection.thirdSub }}</p>
            <p>{{ disclaimerSection.fourthSub }}</p>
          </div>
          <div class="terms-conditions">
            <h2 class="sub-title">
              {{ generalSection.title }}
            </h2>
            <p>{{ generalSection.firstSub }}</p>
            <p>{{ generalSection.secondSub }}</p>
          </div>
          <div class="terms-conditions">
            <h2 class="sub-title">
              {{ applicableLawSection.title }}
            </h2>
            <p>{{ applicableLawSection.firstSub }}</p>
            <p>{{ applicableLawSection.secondSub }}</p>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import TermsPolicies from "../../components/partials/terms-policies";

export default {
  name: "TermsConditions",
  metaInfo: {
    title: "Terms and conditions",
    meta: [
      {
        name: "description",
        content:
          "Read the terms and conditions for the Scotland Europa website.",
      },
    ],
  },
  components: {
    TermsPolicies,
  },
  data() {
    return {
      ourTerms: {
        title: "Our Terms of Use",
        byUsing:
          "By using this website, you confirm that you accept these terms of use and that you agree to follow them. If you do not agree to these terms of use, you are not allowed to use this website.",
      },
      resposibleText: {
        title: "Who we are and how to contact us",
        firstSub:
          'This website is operated by Scotland Europa (<b>"SE"</b>, <b>"we"</b>). Scotland Europa is a subsidiary of Scottish Enterprise, established under the Enterprise and New Towns (Scotland) Act 1990 and having a principal office at Atrium Court, 50 Waterloo Street, Glasgow G2 6HQ.',
        secondSub:
          'To contact us, please email <a href="mailto:information.desk@scotent.co.uk" >information.desk@scotent.co.uk</a>.',
        thirdSub:
          "These terms of use tell you the terms on which you may use this website. By using this website, you confirm that you accept these terms of use and that you agree to comply with them. If you do not agree to these terms of use, you should not use this website.",
      },
      makeChangeToTerms: {
        title: "We may make changes to these terms",
        firstSub:
          "SE reserves the right to change these terms of use at any time by amending this page. Any new terms will be displayed on this website. It is your responsibility to check these terms of use each time you wish to use this website to verify such variations. ",
      },
      transferAgreement: {
        title: "We may transfer this agreement to someone else",
        firstSub:
          "SE may transfer our rights and obligations under these terms to another organisation. We will always tell you in writing if this happens and we will ensure that the transfer will not affect your rights under the contract.",
      },
      informationOnThis: {
        title: "Information on this website",
        firstSub:
          "We are the owner or the licensee of all intellectual property rights in our site, and in the material published on it. Those works are protected by copyright laws and treaties around the world. All such rights are reserved.",
        secondSub:
          "You may print off one copy, and may download extracts, of any page(s) from our site for your personal use and you may draw the attention of others within your organisation to content posted on our site.",
        thirdSub:
          "The information contained in this website may include opinions or views which, unless expressly stated otherwise, are not those of SE or any associated company of SE or any person SE is vicariously liable or responsible for.",
        fourthSub:
          "Information on this website may be changed or updated without notice. SE may also make improvements and / or changes in the products and / or services and programs described in this information at any time without notice.",
      },
      notReplyInformation: {
        title: "Do not rely on information on this site",
        firstSub:
          "The content on this website is provided for general information purposes only and does not constitute advice. You should seek the advice of an appropriately qualified person in your home jurisdiction before taking, or refraining from, any action on the basis of the content on this website.",
        secondSub:
          "Although SE makes reasonable efforts to ensure the information on this website to be true and accurate, we can give no assurances or warranty regarding the accuracy, currency or applicability of any of the contents in relation to specific situations and particular circumstances.",
      },
      accessingThisWebsite: {
        title: "Accessing this website",
        firstSub:
          "This website is made available to you free of charge. SE does not guarantee that this website, or any information on it, will always be available or be uninterrupted. SE reserves the right to suspend, withdraw or discontinue all or any part of this website without notice. SE will not be liable to you if for any reason this website is unavailable at any time or for any period.",
        secondSub:
          "You are responsible for making all arrangements necessary for you to have access to this website. Please note telephone and other connection charges may apply to your use of this website. For details of charges you should contact your telephone company and internet provider.",
      },
      yourAccount: {
        title: "Accessing this website",
        firstSubPartOne:
          "If you choose to register for a Members' Area user account on this website, you will be required to give SE certain information. The ",
        firstSubPartTwo: "details how SE will use your information.",
        secondSub:
          "You must treat any chosen password, user identification code, or any other piece of information required as part of the security procedures for your account, as confidential. You must not share these details with any third party and you will be held responsible for any activity carried out under your username, password or unique identification code.",
        thirdSub:
          "If you know or suspect that anyone other than you has unauthorized access to your account, you must promptly notify us at <a href='mailto:scotland.europa@scotent.co.uk'>scotland.europa@scotent.co.uk</a> or call <a href='tel:00 32 2 282 83 04'>00 32 2 282 83 04</a>.",
        fourthSub:
          "SE reserves the right to disable any account at any time if, in our sole opinion, either these terms of use have not been complied with or a third party has gained unauthorised access to your account.",
      },
      copyright: {
        title: "Copyright",
        firstSub:
          "The contents of this website (including all website design, text, graphics, the selection and arrangement thereof and all software compilations, underlying source code, software and all other material) are protected by copyright owned by SE or its content and technology providers except insofar as individually stated on particular material or items.",
        secondSub:
          "You may view the site and download any part(s) of it to a personal computer (or other device which is capable of accessing the internet) for personal viewing for private purposes only.",
        thirdSub: "You are not permitted without our permission:",
        bulletPoints: [
          {
            text: "To store it or any part of it other than for the purposes set out in this paragraph",
          },
          {
            text: "Print out copies except for your own viewing for private purposes or for the purposes of your business (other than re-publishing such material for commercial purposes which you must not do without our permission)",
          },
          {
            text: "Reproduce, copy or transmit it (or any part of it) in any other way for any purpose or in any other medium. You are also not entitled to alter the content in any way.",
          },
        ],
      },
      noTextOrData: {
        title: "No text or data mining or web scraping",
        firstSub:
          "You shall not conduct, facilitate, authorise or permit any text or data mining or web scraping in relation to our site or any services provided via, or in relation to, our site. This includes using (or permitting, authorising or attempting the use of): ",
        secondSub:
          'Any "robot", "bot", "spider", "scraper" or other automated device, program, tool, algorithm, code, process or methodology to access, obtain, copy, monitor or republish any portion of the site or any data, content, information or services accessed via the same.',
        thirdSub:
          "Any automated analytical technique aimed at analysing text and data in digital form to generate information which includes but is not limited to patterns, trends and correlations.",
        fourthSub:
          "This clause shall not apply insofar as (but only to the extent that) we are unable to exclude or limit text or data mining or web scraping activity by contract under the laws which are applicable to us.",
      },
      acceptableUse: {
        title: "Acceptable Use",
        firstSub:
          "When using and accessing this website you may not cause damage, or deny or prohibit accessibility to this website in any way that is unlawful or illegal. Any instance of fraudulent or harmful behaviour is strictly prohibited. Using and accessing this website in order to copy, store, host, transmit, send, use, publish or distribute material that could be considered damaging to computer software is prohibited in all forms. You may not conduct or perform any systematic or automated data collections in relation to this website. Unsolicited transmission of commercial communications by you via this website is prohibited.",
        secondSub:
          "You are not permitted to use this website to transfer personal data outside the United Kingdom.",
        thirdSub:
          "You are prohibited from posting or transmitting to or from the site any unlawful, threatening, libellous, defamatory, obscene, pornographic, or other material that would violate any law.",
      },
      uploadingSection: {
        title: "Uploading content to this website",
        firstSub:
          "This website is not a secure means of communication and we cannot guarantee the security of any information you supply to us using this website. For that reason, you should not submit or send to us via this website any patentable ideas or patent applications, advertising or marketing suggestions, prototypes or any information, written or oral, which you regard as confidential or commercially sensitive or valuable.",
        secondSub:
          "Any material, information or other communication you may transmit, post or upload to this website other than your personal information will be considered non-confidential and non-proprietary, except when SE states otherwise. Subject to such legal requirements as may be in force from time to time to protect the personal data of you and any third party, SE and its designees will be free to copy, disclose, distribute, incorporate or otherwise use the communications in all data, images, sounds, text and other things embodied therein for any and all commercial or non-commercial purposes. By transmitting, posting or uploading any such content you are deemed to grant SE a non-exclusive, royalty free, perpetual and transferable license to do so.",
        thirdSub:
          "You represent and warrant that any content you supply to us using this website is and shall be your own original work and has been lawfully provided to us and that you have all necessary consents to provide this to us. You agree that you waive all moral rights you may have in any such content but that any personal data you supply with your content may, if we choose to do so, be used by us as described in our ",
        fourthSub:
          "SE has the right to disclose your identity to any third party who is claiming that any content posted or uploaded by you to this website is defamatory, constitutes a violation of their intellectual property rights, or of their right to privacy.",
        fifthSub:
          "SE shall not be responsible, or liable to any third party for the content or accuracy of any content posted or uploaded by you or any other user of this website.",
        sixthSub:
          "SE reserve the right to monitor, edit, review or remove discussions, postings, transmissions, bulletin boards and similar communications that may exist on this website from time to time. SE reserves the right to remove any material uploaded by you to this website if, in our opinion, the material uploaded to this website by you does not comply with the content standards set out in the Acceptable Use section of these terms or has been the subject of complaint from any third party.",
        seventhSub:
          "You are solely responsible for securing and backing up your content.",
      },
      rightsGivingSection: {
        title: "Rights you are giving us to use material you upload",
        firstSub:"When you upload or post content to our site, you grant us the following rights to use that content:",
        bulletPoints: [
          {
            text: "a worldwide, non-exclusive, royalty-free, transferable licence to use, reproduce, distribute, prepare derivative works of, display, and perform that user-generated content in connection with the service provided by the website and across different media including to promote the site or the service forever;",
          },
          {
            text: "a worldwide, non-exclusive, royalty-free, transferable licence for other users, partners or advertisers to use the content for their purposes forever.",
          },
        ],
      },
      thirdPartySection:{
        title:"Third Party website links",
        firstSub:"Any links to third party websites are provided solely for the purpose of your convenience. Links made to other sites are made at your own risk and SE accepts no liability for any linked sites.",
        secondSub:"When you access a third party website through a link on this website, even if the third party website contains the SE logo, please understand that it is independent from SE and SE has no control over the content of any third party websites. Further, a link to a third party website does not mean that SE endorses or accepts any responsibility for the content or the use of such third party websites.",
        thirdSub:"SE has not tested any content, software feature or links found on any third party sites linked to and does not give any representation regarding the quality, safety, suitability or reliability of any of them or any of the material contained in them. You must take your own precautions to ensure what is selected for use is free of such items as viruses, worms, Trojan horses and other items of a destructive nature."
      },
      linkingSection:{
        title: "Linking to our site",
        firstSub:"You may link to our home page, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it.",
        secondSub:"You must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists.",
        thirdSub:"You must not establish a link to our site in any website that is not owned by you.",
        fourthSub:"Our site must not be framed on any other site, nor may you create a link to any part of our site other than the home page.",
        fifthSub:"We reserve the right to withdraw linking permission without notice.",
        sixthSub: "The website in which you are linking must comply in all respects with the content standards set out in these website terms.",
      },
      virusSection:{
        title:"Viruses",
        firstSub:"This website is not guaranteed to be free from any computer bugs or viruses. You are responsible for configuring your information technology, computer programmes and platform to access our site. You should use your own virus protection software.",
        secondSub:"You must not misuse this website by knowingly introducing viruses, Trojans, worms, logic bombs or other material which is malicious or technologically harmful. You must not attempt to gain unauthorised access to the server on which this website is stored or any server, computer or database connected to this website. You must not attack this website via a denial-of-service attack or a distributed denial-of-service attack. By breaching this provision, you would commit a criminal offence under the Computer Misuse Act 1990. SE will report any such breach to the relevant law enforcement authorities and we will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use this website will cease immediately."
      },
      disclaimerSection:{
        title: "Disclaimer",
        firstSub:"SE accepts no responsibility and, to the fullest extent permitted by law, excludes all liability in connection with browsing this site, use of information on this site and downloading any material from it including, but not limited to, any liability for errors, inaccuracies, omissions or misleading or defamatory statements. In addition, none of the content of this website will form any part of any contract between you and SE or constitute an offer by SE. ",
        secondSub:"To the fullest extent permitted by law, SE exclude liability for any claims, losses, demands or damages of any kind whatsoever with respect to any information and / or services provided on our website including but not limited to direct, indirect, incidental or consequential loss or damages, compensatory damages or loss of profits or data whether based on a breach of contract or warranty, delict or tort (including negligence), product liability or otherwise.",
        thirdSub:"SE will not be liable for any loss or damage we cause which we could not reasonably anticipate when you started using the website, for example if you lose revenue, salary, profits or reputation as a result of your use of the website and/or the acts or omissions of any third party such as other users of the website or any other indirect or consequential loss or damage you may incur in relation to the website and its content.",
        fourthSub:"Nothing in these terms of use shall operate to exclude or limit SE's liability for either: (i) death or personal injury arising from negligence; or (ii) fraudulent misrepresentation."
      },
      generalSection:{
        title:"General",
        firstSub:"SE shall have no liability to you for any breach of these terms of use caused by any event or circumstance beyond our reasonable control including, without limitation, strikes, lock-outs and other industrial disputes, breakdown of systems or network access, flood, fire, explosion or accident.",
        secondSub:"If any part of these terms of use is unenforceable (including any provision in which we exclude or limit our liability to you) the enforceability of any other part of these terms of use will not be affected. If we choose not to enforce any right that we have against you at any time, then this does not prevent us from later deciding to exercise or enforce that right."
      },
      applicableLawSection:{
        title:"Applicable Law",
        firstSub:"These terms of use and all matters related to use of this website shall be governed and construed in accordance with the laws of Scotland and all parties irrevocably submit to the exclusive jurisdiction of the Scottish courts in respect of such matters.",
        secondSub:"If anything on this website is construed as being contrary to the laws applicable in any other country, then this website is not intended to be accessed by persons from that country and any persons who are subject to such laws shall not be entitled to use this website unless they can satisfy SE that such use would be lawful."
      }
    };
  },
  methods: {
    scrollTo(event) {
      event.preventDefault();

      var location = this.$refs["acceptable-use"];
      location.scrollIntoView();
    },
  },
};
</script>
<style lang="scss" scoped>
ul li {
  // list-style: none;
  font-style: normal;
  font-weight: normal;
  font-size: $font-size-19;
  line-height: $line-height-28;
  margin-bottom: 10px;
}
.text-info {
  color: #17a2b9;
}
</style>

<style lang="scss">
.sub-title {
  font-style: normal;
  font-weight: 500;
  font-size: $font-size-30;
  line-height: $line-height-42;
  color: black;
  padding-bottom: 10px;
}
.terms-conditions p {
  font-style: normal;
  font-weight: normal;
  font-size: $font-size-19;
  line-height: $line-height-28;
  padding-bottom: 30px;
}

.text-white.banner-title {
  margin-left: 20%;
  font-weight: 400;
  margin-bottom: 30px;
}
.banner-sub {
  color: white;
  margin-left: 20%;
  width: 400px;
  font-size: $font-size-19;
  line-height: $line-height-28;
}
</style>
