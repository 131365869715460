<template>
  <div data-name="community-feed-posts">
    <el-col :sm="24" :md="12" :lg="8" :xl="8" v-for="activity in activities" :key="activity.id">
      <article>
        <el-card class="box-card member-card questions-card" shadow="always">
          <activity-menu-dropdown v-if="isProfileOwner" classes="activity-menu-position" @delete="$emit('deleteActivity', activity.id)" />
          <div class="questions-header">
            <div>
              <user-avatar
                shape="circle"
                :isGlobalScot="activity.trackable.author && activity.trackable.author.is_global_scot"
                :authorExist="!!activity.trackable.author"
                width="40px"
                :size="40"
                :src="activity.trackable.author_avatar_url"
                :alt="activity.trackable.author ? activity.trackable.author.name : '' + 'Profile picture'"
              ></user-avatar>
            </div>
            <div>
              <p class="author-name">{{activity.trackable.author.name}}</p>
              <p>{{activity.trackable.created_at_time_diff }}</p>
            </div>
          </div>
<!--          <dynamic-link v-line-clamp="3" id="dynamic-post" class="sub-text" :content="activity.trackable.content"></dynamic-link>-->
            <div class="sub-text" v-html="$options.filters.domPurify(activity.trackable.content)"></div>

          <div class="question-card-icons">
            <div class="question-card-icon">
              <svg-chat-purple></svg-chat-purple>
              <p class="ml-2 replies-count-text">{{ activity.trackable.reply_count }} Replies</p>
            </div>
            <div>
              <router-link class="community-feed-card-link pull-right" :to="{ name: 'Pulse', params: {id: activity.trackable.id}}">
                <p class="link-text mr-2">Go To Post</p>
                <span class="sr-only">to Community Post</span>
                <svg-purple-arrow-right class="arrow reply-arrow"></svg-purple-arrow-right>
              </router-link>
            </div>
          </div>
        </el-card>
      </article>
    </el-col>
  </div>
</template>
<script>
import PurpleArrowRight from "@/components/assets/svg-purple-arrow-right";
import ChatPurple from "@/components/assets/svg-chat-purple";
import UserAvatar from "@/components/core/user-avatar";
import DynamicLink from "@/components/platform/pulse/dynamic-link";
import ActivityMenuDropdown from "@/components/partials/recent-activity-cards/activity-menu-dropdown.vue";

export default {
  components: {
    "svg-purple-arrow-right": PurpleArrowRight,
    "svg-chat-purple": ChatPurple,
    UserAvatar,
    DynamicLink,
    'activity-menu-dropdown': ActivityMenuDropdown
  },
  props: {
    activities: {
      type: Array
    },
    isProfileOwner: {
      type: Boolean,
      default: false
    }
  },
  methods: {},
};
</script>

<style lang="scss">
.questions-header {
  display: grid;
  grid-template-columns: 0.4fr 1.6fr;
  margin-bottom: 5%;
}

.sub-text {
  word-break: break-word!important;
  line-height: $line-height-24;
  font-size: $font-size-19;
  font-weight: 390;
  overflow-y: auto;
  text-overflow: ellipsis;
  height: 75px;
}
#dynamic-post p .clickable {
  cursor: pointer;
}
#dynamic-post p .mention {
  color: $primary;
}
div[data-name="community-feed-posts"] {
  .questions-card {
    margin-top: 0;
    margin-bottom: 3rem;

    .sub-text {
      overflow-y: auto !important;
    }
  }

  .author-name {
    @media screen and (max-width: 375px) {
      margin-left: 10px;
    }
  }

  .replies-count-text {
    @media screen and (max-width: 425px) {
      font-size: $font-size-14;
    }
  }

  .link-text {
    font-style: normal;
    font-weight: 420;
    font-size: $font-size-19;
    line-height: $line-height-20;
    text-decoration: underline;
    color: $primary;
    @media screen and (max-width: 425px) {
      font-size: $font-size-14;
    }
  }

  .reply-arrow {
    margin-right: 0;
  }

  .community-feed-card-link {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}


</style>
