<template>
  <div class="white-background-section">
    <div class="container">
      <el-row>
        <div class="involve-heading-text mt-5">
          <div class="involve-data">
            <div>
              <el-col class :md="24" :lg="12">
                <div class="left-text pr-2 mb-0">
                  <h1 class="involve-title">{{ text.heading }}</h1>
                  <p class="involve-sub-text">{{ text.listStart }}</p>
                  <div class>
                    <ul class="involve-list">
                      <li v-for="point in bulletPoints" :key="point.text">
                        {{ point.text }}
                      </li>
                    </ul>
                  </div>

                  <p class="involve-sub-text mb-1" v-html="text.subText"></p>
                </div>
              </el-col>
            </div>
            <div>
              <el-col :md="24" :lg="12">
                <div class="invlove-img mt-5">
                  <img
                    alt="conference centre"
                    src="../../../../static/img/conference-centre-seats.jpg"
                  />
                </div>
              </el-col>
            </div>
          </div>
        </div>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      text: {
        heading: "Become a member",
        listStart: "We are pleased to be able to offer a new type of membership package for partners to utilise our co-working space at the heart of the Brussels European Quarter. If you're an occasional visitor to Brussels, a Scotland House Brussels membership could provide you with:",
        subText: "If you're interested in a Scotland House membership, contact Helena De Raedemaker for more information at <ahref='mailto:Helena.DeRaedemaker@scotent.co.uk'>Helena.DeRaedemaker@scotent.co.uk</a> or by phone at +32 2 282 83 15. "
      },
      bulletPoints: [
        { text: "An ideal touch down area between EU institutional or project meetings" },
        { text: "A place to host meetings" },
        { text: "An opportunity to network with Scotland House Brussels staff and other visiting partners" },
        { text: "An opportunity to participate in Scotland House Brussels' political, economic, and cultural events throughout the year" },
      ],
    };
  },
};
</script>
<style>
.invlove-img {
  /*  margin-bottom: 50px;*/
}
.left-text {
  margin-bottom: 50px;
}
</style>
