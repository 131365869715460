<template>
  <section
    class="footer-join"
    aria-label="Contact us"
  >
    <div class="container">
      <el-row>
        <el-col
          :xl="24"
          :lg="24"
          :md="24"
          :sm="24"
          :xs="24"
          class="head"
        >
          <h2 class="text-white footer-text">
            {{ footerText.heading }}
          </h2>
          <p class="text-white footer-text">
            Contact the Scotland Europa team today to ask a question or find out more about membership.
          </p>
        </el-col>
        <router-link :to="{ name: 'Contact Us' }">
          <el-button
            class="join-btn-bottom"
            type="primary"
          >
            Contact Scotland Europa
          </el-button>
        </router-link>
      </el-row>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      footerText: {
        heading: "Need to get in touch?",
      }
    };
  },
};
</script>
<style lang="scss">
.footer-text {
  text-align: center;
  margin-top: 30px;
}
h1 {
  font-size: $font-size-30;
  font-weight: 420;
  margin-bottom: 20px;
}
.footer-text {
  padding-top: 10px;
  padding-bottom: 10px;
}
.join-btn-bottom {
  margin: auto;
}
.give-feedback p {
  color: white;
  margin-left: 10px;
  /* float: left; */
  text-decoration: underline;
}
.give-feedback {
  text-align: center;
  padding-top: 45px;
}

  .footer-text a {
    text-decoration: underline;
    color:white;
  }

</style>
