<template>
  <focus-lock>
    <el-dialog
      ref="bookA121TimeSlot"
      @opened="dialogOpen('bookA121TimeSlot')"
      aria-describedby="dialogDescription"
      custom-class="book-time-slot-dialog"
      :show-close="true"
      :visible.sync="dialogVisible"
      element-loading-background="rgba(255, 255, 255, 0.8)"
      title="Please confirm you want to sign up for this event"
      :before-close="onClose"
    >
      <div class="book-time-slot-body">
        <p class="title">Choose a time slot</p>
        <p class="sub-title">Please select a time slot for this event (GMT)</p>

        <div class="mt-5">
          <el-form v-loading.lock="mainLoader">
            <el-form-item prop="timeSlotDate" class="is-no-asterisk">
              <template slot="label">
                <span class="sr-only">Choose a date from the following</span>
              </template>
              <v-select
                id="timeSlotDate"
                v-model="selectedEventDay"
                placeholder="Choose one"
                :options="dateOptions"
                :searchable="false"
                :clearable="false"
                autocomplete="false"
                label="label"
                :select-on-tab="true"
                :close-on-select="true"
                class="time-slot-date"/>
            </el-form-item>

            <el-row :span="24" :gutter="20" class="time-slots" v-if="selectedEventDay && filteredSlots.length">
              <el-col :lg="4" :md="6" :sm="12" :xs="12" v-for="slot in filteredEventDay.slots.data"
                      :key="`slot-${slot.id}`">
                <el-button v-html="timeSlotLabel(slot, filteredEventDay.slot_length)" class="time-slot" v-if="! slot.is_booked && slot.blocked === 0" @click="pickATimeSlot(slot)">
            
                </el-button>
                <el-button class="time-slot time-slot__unavailable"
                           v-else-if="slot.is_booked && isBookedByOtherUser(slot)" disabled>Unavailable
                </el-button>
                <el-button class="time-slot time-slot__booked" v-html="timeSlotLabel(slot, filteredEventDay.slot_length)"
                           v-else-if="slot.is_booked && ! isBookedByOtherUser(slot)">
                  
                </el-button>
              </el-col>
            </el-row>
            <el-row :span="24" :gutter="20" class="time-slots" v-else>
              <p class="sub-title">There are no available time slots for this date.</p>
            </el-row>

            <div class="button-wrap d-flex events-dialog-buttons confirm-time-button-container">
              <el-button
                :disabled="! selectedSlot"
                class="events-dialog-confirm"
                type="primary"
                @click.prevent="submit"
                :loading="confirmingTimeSlotBooking"
              >
                Confirm time
              </el-button>
            </div>
          </el-form>
        </div>
      </div>
    </el-dialog>
  </focus-lock>
</template>

<script>
import gsApi from '@/services/gs.api'
import moment from 'moment'
import {mapGetters} from 'vuex'

export default {
  name: 'BookA121TimeSlotDialog',

  props: {
    visible: {
      type: Boolean,
      default: false
    },
    eventHost: {
      type: Object,
      default: null,
    },
    editMode: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {
      mainLoader: false,
      dialogVisible: false,
      selectedEventDay: null,
      confirmingTimeSlotBooking: false,
      selectedSlot: null,
      eventDays: [],
      dateOptions: [],
      value: ''
    }
  },

  computed: {
    ...mapGetters(['user']),
    filteredEventDay () {
      if (this.selectedEventDay && this.eventDays.length) {
        return this.eventDays.find(eventDay => eventDay.id === this.selectedEventDay.id)
      }

      if (this.eventDays.length) {
        return this.eventDays[0]
      }

      return []
    },
    filteredSlots () {
      if (this.filteredEventDay) {
        if (this.filteredEventDay.slots.data.length) {
          return this.filteredEventDay.slots.data.filter(slot => {
            return slot.blocked === 0 && !this.isBookedByOtherUser(slot)
          })
        }
      }
      return []
    },
    oldSlot () {
      let slot = null;
      if (this.editMode && this.eventDays.length) {
        /* Get the first slot for the specified GS which has the current user as a guest */
        const filteredDays = this.eventDays.filter(day => day.slots && day.slots.data && day.slots.data.length)

        if (filteredDays.length) {
          slot = filteredDays
            .flatMap(day => {
              return day.slots.data
            })
            .filter(slot => slot.guest && slot.guest.id === this.user.id)[0]
        }
      }

      return slot;
    }
  },

  methods: {
    onClose (e) {
      if (!this.confirmingTimeSlotBooking) {
        this.dialogVisible = false
        this.$emit('closed')
      }
    },

    pickATimeSlot (slot) {
      slot.is_booked = true
      this.selectedSlot = slot

      // Remove other booked slots, so only one is selected at one time
      this.filteredSlots.forEach(timeSlot => {
        if (timeSlot.id !== slot.id && slot.is_booked && !this.isBookedByOtherUser(slot)) {
          timeSlot.is_booked = false
        }
      })
    },

    removeBookedSlot (slot) {
      slot.is_booked = false
    },

    submit () {
      this.confirmingTimeSlotBooking = true;
      if (this.editMode) {
        this.updateBookedSlot()
        return
      }

      this.bookA121TimeSlot()
    },

    bookA121TimeSlot () {
      gsApi.events
        .bookASlot(this.selectedEventDay.id, this.selectedSlot.id, {
          guest_user_id: this.user.id
        })
        .then((response) => {
          this.confirmingTimeSlotBooking = false;
          this.onClose()

          this.selectedSlot.end_time = moment(this.selectedSlot.start_time, 'HH:mm')
            .add(this.selectedEventDay.slot_length, 'minutes')
            .format('HH:mm')

          this.$emit('timeSlotBooked', this.selectedSlot)
          console.log(response, 'Booked a slot')
        })
        .catch((error) => {
          console.log(error, 'Errors booking a slot')
          if (error.response && error.response.status === 422 && error.response.data) {
            let firstError =
              error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            this.$alert(
              firstError[0],
              'Error',
              {
                confirmButtonText: 'Close',
                showClose: false,
                customClass: 'notification-modal'
              }
            )
          } else {
            this.$alert(
              'Sorry, your request could not be processed',
              `Error ${error.response.status} - ${error.response.data.message}`, {
                confirmButtonText: 'Close',
                showClose: false,
                customClass: 'notification-modal'
              })
          }
        })
        .finally(() => {
          this.confirmingTimeSlotBooking = false
        })

    },

    updateBookedSlot () {
      if (! this.oldSlot && !this.oldSlot.id) {
        return;
      }

      gsApi.events.changeBookedSlot(this.oldSlot.id, {
        guest_user_id: this.user.id,
        new_slot_id: this.selectedSlot.id,
      })
        .then((response) => {
          this.confirmingTimeSlotBooking = false
          this.onClose()

          this.selectedSlot.end_time = moment(this.selectedSlot.start_time, 'HH:mm')
            .add(this.selectedEventDay.slot_length, 'minutes')
            .format('HH:mm')

          this.$emit('timeSlotBooked', this.selectedSlot)
        })
        .catch((error) => {
          console.log(error, 'Errors booking a slot')
          if (error.response && error.response.status === 422 && error.response.data) {
            let firstError =
              error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            this.$alert(
              firstError[0],
              'Error',
              {
                confirmButtonText: 'Close',
                showClose: false,
                customClass: 'notification-modal'
              }
            )
          } else {
            this.$alert(
              'Sorry, your request could not be processed',
              `Error ${error.response.status} - ${error.response.data.message}`, {
                confirmButtonText: 'Close',
                showClose: false,
                customClass: 'notification-modal'
              })
          }
        })
        .finally(() => {
          this.confirmingTimeSlotBooking = false
        })
    },

    fetchHostEventDays () {
      this.mainLoader = true
      gsApi.events
        .getHostEventDays(this.$route.params.slug, this.eventHost.id)
        .then((response) => {
          if (response.data.data.length) {
            this.dateOptions = response.data.data.flatMap(date => {
              return {
                id: date.id,
                label: moment(date.date).format('Do MMMM YYYY'),
                value: date.date,
                slot_length: date.slot_length,
              }
            });

            /* If editing - find the first booked slot, and the event day that slot belongs to */
            if (this.editMode) {
              const dateWithBookedSlot = response.data.data.filter(date => {
                return date.slots.data.filter(slot => {
                  return slot.is_booked && slot.guest && slot.guest.id === this.user.id;
                }).length > 0;
              })[0];

              this.selectedEventDay = dateWithBookedSlot ?
                this.dateOptions.find(option => option.id === dateWithBookedSlot.id) : this.dateOptions[0];

            } else {
              this.selectedEventDay = this.dateOptions[0];
            }

            this.eventDays = response.data.data
          }
        })
        .catch((errors) => {
          console.log(errors, 'Error: BookA121TimeSlotDialog.vue - getHostEventDays()')
        })
        .finally(() => {
          this.mainLoader = false
        })
    },

    isBookedByOtherUser (slot) {
      return slot.guest && slot.guest.id !== this.user.id
    },
    timeSlotLabel (slot, meeting_length) {
      const slotStartTime = moment(slot.start_time);
      const slotEndTime = slotStartTime.clone()
        .add(meeting_length, 'minutes')
        .format('HH:mm')

      return `${slotStartTime.format('HH:mm')} - ${slotEndTime}`
    },
  },

  watch: {
    visible (value) {
      this.dialogVisible = this.visible
    }
  },
  created () {
    this.dialogVisible = this.visible
    this.fetchHostEventDays()
  }
}
</script>

<style lang="scss">
.events-general-dialog {
  .body {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .event-dialog-title {
    font-style: normal;
    font-weight: 420;
    font-size: $font-size-35;
    line-height: $line-height-45;
    /* identical to box height, or 129% */

    text-align: center;

    color: #000000;
    @media screen and(max-width: 425px) {
      font-size: 1.4rem;
    }
  }

  .event-dialog-text {
    font-style: normal;
    font-weight: 390;
    font-size: $font-size-24;
    line-height: $line-height-35;
    text-align: center;
    color: #000000;
    padding-top: 1.375rem;
    max-width: 586px;
    width: 100%;
  }

  .events-dialog-buttons {
    width: 100%;

    .events-dialog-cancel {
      width: 100%;
      max-width: 231px;
    }

    .events-dialog-confirm {
      width: 100%;
      max-width: 231px;
    }

    @media screen and(max-width: 425px) {
      flex-direction: column;
      width: 100%;

      .events-dialog-confirm {
        width: 100%;
        margin: 0.625rem 0 0;
      }
    }
  }

  .sign-up-buttons {
    padding-top: 2rem;
    @media screen and(max-width: 425px) {
      padding-top: 1rem
    }
  }
}

.book-time-slot-dialog {
  width: 1066px !important;
  min-height: 422px;

  .time-slot-date{
    width: 300px;
  }

  @media screen and(max-width: 1024px) {
    width: 768px;
  }

  @media screen and(max-width: 768px) {
    width: 425px;
  }

  @media screen and(max-width: 425px) {
    width: 372px;
  }

  @media screen and(max-width: 375px) {
    width: 320px;
  }

  .el-dialog__body {
    padding-left: 3.625rem;
    padding-right: 3.625rem;
    padding-top: 0;
  }

  .title {
    font-style: normal;
    font-weight: 420;
    font-size: $font-size-19;
    line-height: $line-height-24;
    color: #000000;
  }

  .sub-title {
    margin-top: 14px;
    font-style: normal;
    font-weight: 390;
    font-size: $font-size-24;
    line-height: $line-height-24;
    color: #000000;
  }

  .confirm-time-button-container {
    margin-left: auto;
    margin-top: 78px;
  }
}

.time-slots {
  .el-button {
    border: none;
    font-weight: 400;
    font-size: 1.15rem;
    line-height: $line-height-24;
    color: #304445;
    height: 69px;
    @media screen and(max-width: 375px) {
      font-size: $font-size-14
    }
  }

  .time-slot {
    font-style: normal;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    width: 100%;
  }

  .time-slot__booked {
    background: #A8308C;
    color: #FFFFFF;
    border-radius: 7px;
  }

  .time-slot__unavailable {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
    color: #304445;
    opacity: 0.75;
  }

  .time-slot__blocked {
    color: #B5BCBD;
    border-radius: 7px;
  }
}

</style>
