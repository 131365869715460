<template>
  <div class="accessibility-page">
    <section aria-label="Accessibility">
      <div class="container text-center">
        <h1 class="text-white text-center">
          Accessibility statement
        </h1>
        <!--p
          class="banner-sub"
        >Here you’ll find everything you need to know about our Terms of Service and Policies.</!p-->
      </div>
    </section>
    <div class="container">
      <el-row>
        <el-col
          :md="24"
          :lg="6"
          :xl="6"
        >
          <div class="terms-policies">
            <terms-policies></terms-policies>
          </div>
        </el-col>
        <el-col
          :md="24"
          :lg="18"
          :xl="18"
        >
          <div class="accessibility">
            <h2
              id="main-content"
              class="title"
            >
              {{ accessText.title }}
            </h2>
          </div>
          <div class="accessibility">
            <p>{{ accessText.sub }}</p>
            <p>
              We are committed to making this website accessible, in accordance with the Public Sector Bodies
              (Websites and Mobile Applications) (No. 2) Accessibility Regulations 2018. This website is partially
              compliant with the <a
                href="https://www.w3.org/TR/WCAG21/"
                target="_blank"
              >Web Content Accessibility Guidelines version 2.1 <span class="sr-only">this link will open in a new window</span><svg-link-purple class="ml-1" /></a> AA standard, due to the non-
              compliances listed on this page.
            </p>
            <!--             <p>{{accessText.thirdSub}}</p>
            <p>
              <a href="https://abilitynet.org.uk/" target="_blank">AbilityNet</a> has advice on making your device easier to use if you have a disability.
            </p> -->
            <p>This statement was last reviewed on 10 November 2022.</p>

            <h2 class="sub-title">
              How we approach accessibility
            </h2>
            <p>We regularly test our websites with disabled users to identify and address any issues. We have an in-house accessibility lab and testing team, who test new features and changes to the site for accessibility issues.</p>
            <p>As well as aiming to build our website in a way that’s easy for all users to use and navigate, we’re also working towards making the language we use as simple as possible to understand by using plain English principles.</p>
            <p>
              <a
                href="https://abilitynet.org.uk/"
                target="_blank"
              >AbilityNet <span class="sr-only">this link will open in a new window</span><svg-link-purple class="ml-1" /></a> has advice on making your device easier to use if you have a disability.
            </p>

            <h2 class="sub-title">
              Changes we’ve made
            </h2>
            <p>The following defects were identified during earlier development and have been addressed:</p>
            <ul>
              <li>
                <p>Some pages were using an incorrect heading hierarchy. We’ve reviewed the content across our pages to make sure the correct headings are used</p>
              </li>
              <li>
                <p>The focus order on our ‘Join the network’ page was wrong – this has been updated</p>
              </li>
              <li>
                <p>Some input fields were labelled or coded incorrectly, making it difficult for screen readers to understand what they are. We’ve updated these to contain the correct labels</p>
              </li>
              <li>
                <p>Assistive technology may previously have found it difficult to distinguish between members of a chat. We’ve made a number of updates to make this area more accessible</p>
              </li>
              <li>
                <p>We’ve added a &lt;nav&gt; tag around our main navigation so assistive technology can understand its purpose</p>
              </li>
              <li>
                <p>Notifications can now be accessed by keyboard</p>
              </li>
              <li>
                <p>During registration, if a user selected ‘no’ to ‘Is this address correct?’, the ‘yes’ button became disabled. We’ve now fixed this so that users can change their answer</p>
              </li>
            </ul>


            <div class="accessibility">
              <h2 class="sub-title">
                {{ accessText.problemsTitle }}
              </h2>
              <p> {{ accessText.problemsSub }} </p>
              <ul>
                <li v-for="point in accessText.bulletPoints">
                  <p>{{ point.text }}</p>
                </li>
              </ul>
            </div>
            <div class="accessibility">
              <h2 class="sub-title">
                What to do if you cannot access parts of this website
              </h2>
              <p>
                If you need information on this website in a different format like accessible PDF, large print, easy
                read, audio recording or braille, you can contact us using these contact details:
              </p>
              <ul>
                <li>
                  <p>
                    <a href="mailto:enquiries@scotent.co.uk">enquiries@scotent.co.uk</a>
                  </p>
                </li>
                <li>
                  <p>
                    <a
                      aria-label="Click to call +44 (0) 141 468 5835"
                      href="tel:+44 (0) 141 468 5835"
                      class="se-tel"
                    >+44 (0) 141 468 5835</a>
                  </p>
                </li>
                <li>
                  <p>If you're deaf or hearing impaired you can use your textphone to contact us through Relay UK, or you can search for Relay UK on the App Store for iPhone or iPad and at Google Play for Android</p>
                </li>
              </ul>

              <p>
                Or you can submit an enquiry using our <router-link :to="{ name: 'Contact Us' }">
                  enquiry form
                </router-link>.
              </p>
            </div>
            <div class="accessibility">
              <h2 class="sub-title">
                Reporting a problem
              </h2>
              <p> You can use the same contact details to report an accessibility problem on this website. </p>
              <p>
                If you've contacted us regarding accessibility and are still dissatisfied with our decision or the way we
                dealt with your complaint, you can ask the Scottish Public Services Ombudsman (SPSO) to look at it.
              </p>
              <p>The SPSO cannot normally look at:</p>

              <ul>
                <li
                  v-for="(point , index) in SPSObulletPoints"
                  :key="index"
                >
                  <p>{{ point.text }}</p>
                </li>
              </ul>
              <p>
                <a
                  href="https://www.spso.org.uk/contact-us"
                  target="_blank"
                >Contact the SPSO <span class="sr-only">this link will open in a new window</span><svg-link-purple class="ml-1" /></a>
              </p>
            </div>
            <div class="accessibility">
              <h2 class="sub-title">
                Enforcement procedure
              </h2>
              <p>
                The Equality and Human Rights Commission (EHRC) is responsible for enforcing the Public Sector
                Bodies (Websites and Mobile Applications) (No. 2) Accessibility Regulations 2018 (the ‘accessibility
                regulations’). If you’re not happy with how we respond to your complaint, contact the <a
                  href="https://www.equalityadvisoryservice.com/app/ask"
                  target="_blank"
                >Equality
                  Advisory and Support Service (EASS) <span class="sr-only">this link will open in a new window</span><svg-link-purple class="ml-1" /></a>.
              </p>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import TermsPolicies from "../../components/partials/terms-policies";
import svgLinkPurple from "@/components/assets/svg-link-purple";
export default {
  name: 'AccessibilityPage',
  metaInfo: {
    title: "Accessibility statement",
    meta: [{
      name: 'description',
      content: "Read the accessibility statement for the Scotland Europa website."
    }]
  },
  components: {
    TermsPolicies,
    svgLinkPurple
  },
  data() {
    return {
      accessText: {
        title: "Accessibility statement for the Scotland Europa website",
        sub:
          "This website is run by Scottish Enterprise. We want to make our services accessible to everyone who could benefit from them, irrespective of any disability they may have.",
        thirdSub:
          "We also test our websites with disabled users to identify and address any issues. We have an in-house accessibility lab and testing team, who test new features and changes to the site for accessibility issues. We’ve also made the website text as simple as possible to understand.",
        problemsTitle:"Accessibility problems we’ve found",
        problemsSub:"Some accessibility defects have come to our attention during testing of this Beta site. The following defects will be resolved by 18th June 2024:",
        bulletPoints:[
          {text:"The three main cards on the user dashboard will not reflow in a single column when you change the size of the browser window. They give a three button carousel instead. This can be confusing and unexpected"},
          {text:"Line height or spacing of text may not resize as expected as it is stated in pixels"},
          {text:"Live video streams (such as webinars) do not have captions"},
          {text:"You cannot skip to the main content when using a screen reader"},
          {text:"Not all links have a description that assistive technology can use"},
          {text:"Some buttons aren’t coded as buttons and don’t have a label, making it difficult for assistive technology to understand what they are"},
          {text:"Some content does not get announced when it appears, including new chat notifications, some error messages, some lookup functionality and some pop-up boxes"},
          {text:"Some error messages appear before any information has been entered into the input field"},
          {text:"Input focus is visually difficult to notice as the emphasis style is too subtle. The focus colour uses a mix of CSS and browser defaults. This may cause confusion to users who have set up their browsers to suit their individual need"},
          {text:"The combo-box is hard to use for users with limited mobility and has poor usability using screen readers"},
          {text:"The Beta Banner is outside of the “MAIN” landmark and may announce itself inconsistently on different browsers"},
          {text:"The “Back” button will clear previous forms and searches. This is not announced"},
        ]
      },
      SPSObulletPoints:[
        {text:"A complaint that has not completed our complaints procedure (so please make sure it has done so, before contacting the SPSO)"},
        {text:"Events that happened, or that you became aware of, more than a year ago"},
        {text:"A matter that has been or is being considered in court"}
      ]
    };
  }
};
</script>
<style lang="scss">
.sub-title {
  font-style: normal;
  font-weight: 500;
  font-size: $font-size-30;
  line-height: $line-height-42;
  color: black;
  padding-bottom: 10px;
}
.accessibility p {
  font-style: normal;
  font-weight: normal;
  font-size: $font-size-19;
  line-height: $line-height-28;
  padding-bottom: 30px;
}

.text-white.banner-title {
  margin-left: 20%;
  font-weight: 400;
  margin-bottom: 30px;
}
.banner-sub {
  color: white;
  margin-left: 20%;
  width: 400px;
  font-size: $font-size-19;
  line-height: $line-height-28;
}
</style>
