<template>
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
        <g id="Group 14">
            <g id="noun_167973_cc">
                <path id="Shape" fill-rule="evenodd" clip-rule="evenodd" d="M0 18.8652C0 8.44629 8.44617 0 18.8652 0C29.2843 0 37.7303 8.44629 37.7303 18.8652C37.7303 29.2841 29.2843 37.7303 18.8652 37.7303C8.44617 37.7303 0 29.2841 0 18.8652ZM14.2162 12.805V24.5284L25.8048 19.2731L14.2162 12.805Z" transform="translate(0.134766 0.131165)" fill="white"/>
            </g>
            <path id="Path" fill-rule="evenodd" clip-rule="evenodd" d="M0 13.6596V0L13.5025 7.53632L0 13.6596Z" transform="translate(13 12)" fill="#DB0011"/>
        </g>
    </svg>

</template>

<script>
    export default {
        name: "svg-play"
    }
</script>