<template>
  <svg width="61" height="65" viewBox="0 0 61 65" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
    <path
      d="M16.054 20.1916C21.3449 20.1916 25.634 15.9676 25.634 10.7569C25.634 5.54632 21.3449 1.32227 16.054 1.32227C10.7631 1.32227 6.474 5.54632 6.474 10.7569C6.474 15.9676 10.7631 20.1916 16.054 20.1916Z"
      stroke="#A8308C"
      stroke-width="2"
      stroke-linejoin="round"
    />
    <path
      d="M10.58 59.5029V63.322H21.529V51.3071"
      stroke="#A8308C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M29.858 34.9079C30.728 31.8751 31.1494 28.7347 31.109 25.5835H1C1 36.5525 5.23398 42.949 10.58 45.8011"
      stroke="#A8308C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M43.4261 9.40969C45.6937 9.40969 47.532 7.59926 47.532 5.36598C47.532 3.1327 45.6937 1.32227 43.4261 1.32227C41.1584 1.32227 39.3201 3.1327 39.3201 5.36598C39.3201 7.59926 41.1584 9.40969 43.4261 9.40969Z"
      stroke="#A8308C"
      stroke-width="2"
      stroke-linejoin="round"
    />
    <path
      d="M45.914 21.2937C48.342 19.9977 50.269 17.0914 50.269 12.1041H36.583C36.583 17.0914 38.507 19.9967 40.938 21.2937"
      stroke="#A8308C"
      stroke-width="2"
      stroke-linejoin="round"
    />
    <path
      d="M1 63.3222L25.635 39.0609L39.321 49.8438L59.85 28.2761"
      stroke="#A8308C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M42.058 28.2791H59.85V45.801"
      stroke="#A8308C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template><script>
export default {};
</script>
