<template>
  <div
    class="user-avatar"
    :style="{'width':width}"
  >
    <el-avatar
      :shape="shape"
      :size="size"
      :src="src ? src : authorExist ? defaultSrc :require('../../../static/img/no-avatar.png')"
      :alt="alt"
      :class="[avatarClasses]"
    >
      <img :src="defaultSrc" alt="" />
    </el-avatar>
  </div>
</template>
<script>
export default {
  props: {
    authorExist:{type:Boolean, default:true},
    shape: {
      type: String,
      default:"circle"
    },
    size: {
      type: Number,
      default:45
    },
    src: {
      type: String,
      default:""
    },
    alt: {
      type: String,
      default:""
    },
    defaultSrc: {
      type: String,
      default: require('../../../static/img/default-image.png')
    },
    isGlobalScot: { type: Boolean, default: false },
    avatarClasses: {
      type: String,
      default:""
    },
    chevronClass:{
      type: String,
      default:""
    },
    width:{
      type: String,
      default:null
    },
  },
  computed:{
    transform(){
      return `translateY(${this.size*3}%) translateX(-74%)`
    }
  }
};
</script>