<template>
  <div
    v-loading.fullscreen.lock="mainLoader"
    class="signup-ngs-page page"
    element-loading-background="rgba(255, 255, 255, 0.8)"
  >
    <div :class="`signup-ngs-page__header step-${currentStep}`">
      <div class="header__content__image"></div>
      <div class="header__content__bg"></div>
      <div class="header__content container">
        <el-row>
          <el-col
            :md="24"
            :lg="12"
          >
            <div
              id="main-content"
              class="header__content__wrapper"
            >
              <p
                class="header__title"
                v-html="heroText"
              ></p>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <div class="signup-ngs-page__body">
      <div class="container">
        <div class="row">
          <div class="col-md-24 col-lg-20 col-xl-16">
            <!-- Step 1 Personal and Company details -->
            <div
              v-show="currentStep === 1"
              class="body__content"
            >
              <validation-errors
                v-if="validationErrors.length && currentStep === 1"
                :errors="validationErrors"
              ></validation-errors>

              <el-form
                id="step1Form"
                ref="step1Form"
                :model="step1Form.form"
                :rules="step1Form.rules"
                autocomplete="false"
                @submit.native.prevent
                @validate="handleFormFieldInput('step1Form')"
              >
                <h1 class="se-heading-m step-title">
                  <span class="color-primary">Step {{ currentStep }} / 3</span>
                  Personal and contact details
                </h1>

                <div class="step-hr"></div>

                <div class="row">
                  <div class="col-md-24 col-lg-16">
                    <label for="title">
                      <span aria-hidden="true">Title<span
                        class="is-custom-asterisk"
                        aria-hidden="true"
                      >*</span></span><br />
                      <label
                        class="sr-only"
                        for="title"
                      >Please select a title</label>
                      <span class="el-form-item__info">This won't be publicly displayed on your profile</span>
                      <span
                        class="sr-only"
                        aria-live="polite"
                        :hidden="
                          !hasInlineValidationErrorFor('step1Form', 'title')
                        "
                        v-text="
                          getInlineValidationErrorFor('step1Form', 'title')
                        "
                      ></span>
                    </label>
                    <el-form-item
                      prop="title"
                      class="is-no-asterisk"
                    >
                      <single-select
                        id="title"
                        v-model="step1Form.form.title"
                        :options="titles"
                        name="title"
                        class="half-content"
                        aria-required="true"
                      ></single-select>
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-24 col-lg-16">
                    <label for="first_name">
                      First name
                      <span
                        class="sr-only"
                        aria-live="polite"
                        :hidden="
                          !hasInlineValidationErrorFor(
                            'step1Form',
                            'first_name'
                          )
                        "
                        v-text="
                          getInlineValidationErrorFor('step1Form', 'first_name')
                        "
                      ></span>
                    </label>
                    <span
                      class="is-custom-asterisk"
                      aria-hidden="true"
                    >*</span><br />
                    <el-form-item prop="first_name">
                      <el-input
                        id="first_name"
                        v-model="step1Form.form.first_name"
                        type="text"
                        maxlength="64"
                        autocomplete="false"
                        aria-required="true"
                        @blur="
                          step1Form.form.first_name =
                            step1Form.form.first_name.trim()
                        "
                      />
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-24 col-lg-16">
                    <label for="last_name">
                      Last name
                      <span
                        class="sr-only"
                        aria-live="polite"
                        :hidden="
                          !hasInlineValidationErrorFor('step1Form', 'last_name')
                        "
                        v-text="
                          getInlineValidationErrorFor('step1Form', 'last_name')
                        "
                      ></span>
                    </label>
                    <span
                      class="is-custom-asterisk"
                      aria-hidden="true"
                    >*</span><br />
                    <el-form-item prop="last_name">
                      <el-input
                        id="last_name"
                        v-model="step1Form.form.last_name"
                        maxlength="64"
                        autocomplete="false"
                        aria-required="true"
                        @blur="
                          step1Form.form.last_name =
                            step1Form.form.last_name.trim()
                        "
                      />
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-24 col-lg-16">
                    <label for="contact_number">
                      Contact number
                      <span
                        class="is-custom-asterisk"
                        aria-hidden="true"
                      >*</span>
                      <br />
                      <span class="el-form-item__info">This won't be publicly displayed on your profile</span>
                    </label>
                    <span
                      class="sr-only"
                      aria-live="polite"
                      :hidden="
                        !hasInlineValidationErrorFor(
                          'step1Form',
                          'contact_number'
                        )
                      "
                      v-text="
                        getInlineValidationErrorFor(
                          'step1Form',
                          'contact_number'
                        )
                      "
                    ></span>
                    <el-form-item
                      prop="contact_number"
                      class="is-no-asterisk"
                    >
                      <el-input
                        id="contact_number"
                        v-model="step1Form.form.contact_number"
                        type="Number"
                        class="w-lg-75"
                        maxlength="20"
                        autocomplete="false"
                        aria-required="true"
                        @blur="
                          step1Form.form.contact_number =
                            step1Form.form.contact_number.trim()
                        "
                      />
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-24 col-lg-16">
                    <label for="email">
                      Email address
                      <span
                        class="is-custom-asterisk"
                        aria-hidden="true"
                      >*</span>
                      <br />
                      <span class="el-form-item__info">This won't be publicly displayed on your profile. This
                        must be a work email address from a Scotland Europa
                        member organisation.</span>
                      <span
                        class="sr-only"
                        aria-live="polite"
                        :hidden="
                          !hasInlineValidationErrorFor('step1Form', 'email')
                        "
                        v-text="
                          getInlineValidationErrorFor('step1Form', 'email')
                        "
                      ></span>
                    </label>
                    <el-form-item
                      prop="email"
                      class="is-no-asterisk"
                    >
                      <el-input
                        id="email"
                        v-model="step1Form.form.email"
                        type="email"
                        autocomplete="false"
                        aria-required="true"
                        @blur="
                          step1Form.form.email = step1Form.form.email.trim()
                        "
                      />
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-24 col-lg-16">
                    <label for="email_confirmation">
                      Confirm email address
                      <span
                        class="is-custom-asterisk"
                        aria-hidden="true"
                      >*</span>
                      <span
                        class="sr-only"
                        aria-live="polite"
                        :hidden="
                          !hasInlineValidationErrorFor(
                            'step1Form',
                            'email_confirmation'
                          )
                        "
                        v-text="
                          getInlineValidationErrorFor(
                            'step1Form',
                            'email_confirmation'
                          )
                        "
                      ></span>
                    </label>
                    <el-form-item
                      prop="email_confirmation"
                      class="is-no-asterisk"
                    >
                      <el-input
                        id="email_confirmation"
                        v-model="step1Form.form.email_confirmation"
                        type="email"
                        autocomplete="false"
                        aria-required="true"
                        @blur="
                          step1Form.form.email_confirmation =
                            step1Form.form.email_confirmation.trim()
                        "
                      />
                    </el-form-item>
                  </div>
                </div>
              </el-form>

              <div class="submit-button-wrapper mt-4">
                <el-button
                  type="primary"
                  native-type="submit"
                  @click="submitStep1Form"
                >
                  Continue
                </el-button>
              </div>
            </div>
            <!-- Step 1 Personal and Company details End -->

            <!-- Step 2 About you -->
            <div
              v-show="currentStep === 2"
              class="body__content"
            >
              <el-button
                type="text"
                size="medium"
                class="step-goback-button align-self-start color-black"
                @click="gotoStep(1)"
              >
                <span aria-hidden="true">&lt; Back</span>
                <span class="sr-only">Go back a step</span>
              </el-button>

              <validation-errors
                v-if="validationErrors.length && currentStep === 2"
                :errors="validationErrors"
              ></validation-errors>

              <el-form
                id="step2Form"
                ref="step2Form"
                :model="step2Form.form"
                :rules="step2Form.rules"
                autocomplete="false"
                @submit.native.prevent
                @validate="handleFormFieldInput('step2Form')"
              >
                <h1 class="se-heading-m step-title">
                  <span class="color-primary">Step {{ currentStep }} / 3</span>
                  About You
                </h1>

                <div class="step-hr"></div>

                <h2 class="se-heading-m mb-2">
                  Where are you primarily based for work?
                </h2>

                <el-row>
                  <el-col
                    :md="24"
                    :lg="18"
                  >
                    <label for="location_id">
                      <span aria-hidden="true">Select a location</span>
                      <span class="sr-only">Please Select a location</span>
                      <span
                        class="is-custom-asterisk"
                        aria-hidden="true"
                      >*</span><br />
                      <span
                        class="sr-only"
                        aria-live="polite"
                        :hidden="
                          !hasInlineValidationErrorFor(
                            'step2Form',
                            'location_id'
                          )
                        "
                        v-text="
                          getInlineValidationErrorFor(
                            'step2Form',
                            'location_id'
                          )
                        "
                      ></span>
                    </label>

                    <el-form-item prop="location_id">
                      <single-select
                        id="location_id"
                        v-model="step2Form.form.location_id"
                        :options="locations"
                        name="location_id"
                        aria-required="true"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row v-if="showOtherLocation">
                  <el-col
                    :md="24"
                    :lg="18"
                  >
                    <label for="other_location">
                      <span aria-hidden="true">Please enter the country you’re located in</span>
                      <span class="sr-only">Please enter the country you’re located in</span>
                      <span
                        class="is-custom-asterisk"
                        aria-hidden="true"
                      >*</span><br />
                      <span
                        class="sr-only"
                        aria-live="polite"
                        :hidden="
                          !hasInlineValidationErrorFor(
                            'step2Form',
                            'other_location'
                          )
                        "
                        v-text="
                          getInlineValidationErrorFor(
                            'step2Form',
                            'other_location'
                          )
                        "
                      ></span>
                    </label>
                    <el-form-item prop="other_location">
                      <el-input
                        v-model="step2Form.form.other_location"
                        maxlength="255"
                        aria-required="true"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>

                <div class="step-hr mt-4"></div>
                <h2 class="se-heading-m mb-2">
                  Which organisation do you work for?
                </h2>
                <el-row>
                  <el-col
                    :md="24"
                    :lg="18"
                  >
                    <label for="organisation_id">
                      <span aria-hidden="true">Select an organisation</span>
                      <span
                        class="is-custom-asterisk"
                        aria-hidden="true"
                      >*</span><br />
                      <span
                        class="sr-only"
                        aria-live="polite"
                        :hidden="
                          !hasInlineValidationErrorFor(
                            'step2Form',
                            'organisation_id'
                          )
                        "
                        v-text="
                          getInlineValidationErrorFor(
                            'step2Form',
                            'organisation_id'
                          )
                        "
                      ></span>
                    </label>
                    <el-form-item prop="organisation_id">
                      <single-select
                        id="organisation_id"
                        v-model="step2Form.form.organisation_id"
                        :options="organisations"
                        name="organisation_id"
                        aria-required="true"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col
                    :md="24"
                    :lg="18"
                  >
                    <label for="role">
                      <span aria-hidden="true">Your role at the company</span>
                      <span
                        class="is-custom-asterisk"
                        aria-hidden="true"
                      >*</span><br />
                      <span
                        class="sr-only"
                        aria-live="polite"
                        :hidden="
                          !hasInlineValidationErrorFor('step2Form', 'role')
                        "
                        v-text="
                          getInlineValidationErrorFor('step2Form', 'role')
                        "
                      ></span>
                    </label>
                    <el-form-item prop="role">
                      <el-input
                        id="role"
                        v-model="step2Form.form.role"
                        aria-required="true"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>

                <div class="step-hr mt-4"></div>

                <h2 class="se-heading-m mb-2">
                  Tell us a bit more about yourself and what you’d like to get
                  from the network (optional)
                </h2>

                <p class="se-body-l-screen mb-4">
                  We’re looking for a short bio to help us and others get to
                  know you. You can edit this any time after registering.
                </p>

                <el-row>
                  <el-col
                    :md="24"
                    :lg="20"
                  >
                    <label for="experience">
                      <span
                        class="sr-only"
                        aria-live="polite"
                        :hidden="
                          !hasInlineValidationErrorFor(
                            'step2Form',
                            'experience'
                          )
                        "
                        v-text="
                          getInlineValidationErrorFor('step2Form', 'experience')
                        "
                      ></span>
                    </label>
                    <el-form-item prop="experience">
                      <el-input
                        id="experience"
                        v-model="step2Form.form.experience"
                        type="textarea"
                        maxlength="1500"
                        :rows="5"
                        aria-required="true"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col
                    :xs="24"
                    class="mt-n4 mb-4"
                  >
                    <span class="se-body-l-screen color-light-gray">You have
                      {{ 1500 - step2Form.form.experience.length }} characters
                      remaining</span>
                  </el-col>
                </el-row>

                <div class="step-hr mt-4"></div>

                <div class="submit-button-wrapper mt-4">
                  <el-button
                    type="primary"
                    native-type="submit"
                    @click="submitStep2Form"
                  >
                    Continue
                  </el-button>
                </div>
              </el-form>
            </div>
            <!-- Step 2 About you -->

            <!-- Step 3 About you -->
            <div
              v-show="currentStep === 3"
              class="body__content"
            >
              <el-button
                type="text"
                size="medium"
                class="step-goback-button align-self-start color-black"
                @click="gotoStep(2)"
              >
                <span aria-hidden="true">&lt; Back</span>
                <span class="sr-only">Go back a step</span>
              </el-button>

              <validation-errors
                v-if="validationErrors.length && currentStep === 3"
                :errors="validationErrors"
              ></validation-errors>

              <el-form
                id="step3Form"
                ref="step3Form"
                :model="step3Form.form"
                :rules="step3Form.rules"
                autocomplete="false"
                @submit.native.prevent
                @validate="handleFormFieldInput('step3Form')"
              >
                <h1 class="se-heading-m step-title">
                  <span class="color-primary">Step {{ currentStep }} / 3</span>
                  Your interests and preferences
                </h1>

                <div class="step-hr"></div>
                <fieldset>
                  <legend>
                    <h2 class="se-heading-m mb-2">
                      What type of updates are you interested in?
                      <span
                        class="is-custom-asterisk"
                        aria-hidden="true"
                      >*</span>
                    </h2>
                  </legend>
                  <el-row>
                    <el-col
                      :md="24"
                      :lg="20"
                      class="d-flex flex-column"
                    >
                      <label
                        class="se-form-label-l-screen mb-4"
                        for="group_ids"
                      >
                        <span>We’ll make sure to send you an alert when we share an
                          update that’s relevant to you. If you don’t want to
                          receive email updates from us, you can leave this
                          section blank. You can update these preferences
                          anytime.</span>
                        <span
                          class="sr-only"
                          aria-live="polite"
                          :hidden="
                            !hasInlineValidationErrorFor(
                              'step3Form',
                              'group_ids'
                            )
                          "
                          v-text="
                            getInlineValidationErrorFor(
                              'step3Form',
                              'group_ids'
                            )
                          "
                        ></span>
                      </label>
                      <el-form-item
                        id="group_ids"
                        prop="group_ids"
                      >
                        <el-checkbox-group v-model="step3Form.form.group_ids">
                          <div class="row">
                            <div
                              v-for="(group, index) in groups"
                              id="form3_group_ids"
                              :key="index"
                              class="col-md-24 col-lg-12 mb-4"
                            >
                              <el-checkbox
                                :key="group.id"
                                name="form3_group_ids"
                                :label="group.id"
                              >
                                {{ group.name }}
                              </el-checkbox>
                            </div>
                          </div>
                        </el-checkbox-group>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </fieldset>

                <div class="step-hr"></div>
                <fieldset>
                  <legend>
                    <h2 class="se-heading-m mb-2">
                      What topics are you interested in?
                      <span
                        class="is-custom-asterisk"
                        aria-hidden="true"
                      >*</span>
                    </h2>
                  </legend>
                  <el-row>
                    <el-col
                      :md="24"
                      :lg="20"
                      class="d-flex flex-column"
                    >
                      <label
                        class="se-form-label-l-screen mb-4"
                        for="expertise"
                      >
                        <span>This will help to personalise the updates you receive
                          and your experience in the network. If you don’t want to
                          receive email updates from us, you can leave this
                          section blank. You can update these preferences
                          anytime.</span>
                        <span
                          class="sr-only"
                          aria-live="polite"
                        ></span>
                      </label>
                      <el-form-item
                        id="expertise"
                        prop="expertise"
                      >
                        <el-checkbox-group v-model="step3Form.form.expertise">
                          <div class="row">
                            <div
                              v-for="(topic, index) in expertises"
                              id="form3_topic_ids"
                              :key="index"
                              class="col-md-24 col-lg-12 mb-4"
                            >
                              <el-checkbox
                                :key="topic.id"
                                name="form3_topics"
                                :label="topic.id"
                              >
                                {{ topic.name }}
                              </el-checkbox>
                            </div>
                          </div>
                        </el-checkbox-group>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </fieldset>

                <div class="step-hr mt-4"></div>
                <div class="row">
                  <div class="col-md-24 col-lg-20">
                    <label for="terms_agreed">
                      <span
                        class="sr-only"
                        aria-live="polite"
                        :hidden="
                          !hasInlineValidationErrorFor(
                            'step3Form',
                            'terms_agreed'
                          )
                        "
                        v-text="
                          getInlineValidationErrorFor(
                            'step3Form',
                            'terms_agreed'
                          )
                        "
                      ></span>
                    </label>
                    <el-form-item prop="terms_agreed">
                      <el-checkbox-group
                        id="terms_agreed"
                        v-model="step3Form.form.terms_agreed"
                      >
                        <el-checkbox
                          label="confirmed"
                          name="terms_agreed"
                        >
                          I acknowledge that I have read and understand the
                          <router-link
                            :to="{ name: 'Privacy Policy' }"
                            class="text__link"
                            target="_blank"
                          >
                            privacy policy
                            <span class="sr-only">this link will open in a new window</span>
                          </router-link>
                          and
                          <router-link
                            :to="{ name: 'Cookie Policy' }"
                            class="text__link"
                            target="_blank"
                          >
                            cookies policy.<span class="sr-only">this link will open in a new window</span>
                          </router-link><span
                            class="is-custom-asterisk"
                            aria-hidden="true"
                          >*</span>
                        </el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-24 col-lg-20">
                    <label for="globalscot_terms_agreed">
                      <span
                        class="sr-only"
                        aria-live="polite"
                        :hidden="
                          !hasInlineValidationErrorFor(
                            'step3Form',
                            'globalscot_terms_agreed'
                          )
                        "
                        v-text="
                          getInlineValidationErrorFor(
                            'step3Form',
                            'globalscot_terms_agreed'
                          )
                        "
                      ></span>
                    </label>
                    <el-form-item prop="globalscot_terms_agreed">
                      <el-checkbox-group
                        id="globalscot_terms_agreed"
                        v-model="step3Form.form.globalscot_terms_agreed"
                      >
                        <el-checkbox
                          label="confirmed"
                          name="globalscot_terms_agreed"
                        >
                          I acknowledge that I have read and understand the
                          <router-link
                            :to="{ name: 'Terms & Conditions' }"
                            class="text__link"
                            target="_blank"
                          >
                            terms and conditions
                            <span class="sr-only">this link will open in a new window</span>
                          </router-link>
                          for use of this site.<span
                            class="is-custom-asterisk"
                            aria-hidden="true"
                          >*</span>
                        </el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </div>
                </div>

                <div class="submit-button-wrapper mt-4">
                  <el-button
                    type="primary"
                    native-type="submit"
                    @click="submitStep3Form"
                  >
                    Complete registration
                  </el-button>
                </div>
              </el-form>
            </div>
            <div
              v-show="currentStep === 4"
              class="body__content"
            >
              <h1 class="se-heading-xl-40 mb-4">
                Thanks for registering with Scotland Europa
              </h1>

              <p class="se-body-l-screen-19 mb-4">
                We’ve sent you an email confirmation. We’ll email you once your
                account has been approved, which may take up to 3 business days.
              </p>

              <div class="d-flex align-items-start justify-content-start mb-4">
                <el-button
                  type="primary"
                  @click.prevent="$router.push({ name: 'Home' })"
                >
                  Back to homepage
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gsApi from "@/services/gs.api";
import validationErrors from "@/components/partials/validation-errors";
import { validate } from "email-validator";

export default {
  name: "SignupGs",
  metaInfo: {
    title: "Create an account",
    meta: [
      {
        name: "description",
        content:
          "Are you a successful business person with connections to Scotland and keen to offer your expertise to Scottish businesses? Apply to become a Scotland Europa today.",
      },
    ],
  },
  components: {
    validationErrors,
  },
  data: function () {
    let validateEmail = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Enter a valid email address"));
      } else if (validate(value) == false) {
        callback(new Error("Enter a valid email address"));
      } else {
        callback();
      }
    };
    let validateEmailConfirmation = (rule, value, callback) => {
      if (value === "") {
        callback(
          new Error(
            "Email address doesn’t match. Make sure the email address in both email fields match."
          )
        );
      } else if (value !== this.step1Form.form.email) {
        callback(
          new Error(
            "Email address doesn’t match. Make sure the email address in both email fields match."
          )
        );
      } else {
        callback();
      }
    };
    let validateContactNumber = (rule, value, callback) => {
      let reg =
        /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i;
      if (value === "") {
        callback(new Error("Enter a valid contact number"));
      } else if (value.length < 7) {
        callback(new Error("Contact number may not be less than 7 characters"));
      } else if (value.length > 20) {
        callback(
          new Error("Contact number may not be greater than 20 characters")
        );
      } else if (reg.test(value) == false) {
        callback(new Error("Enter a valid contact number"));
      } else {
        callback();
      }
    };

    const validateMetaCharacters = (rule, value, callback) => {
      const reg = /^[0-9a-zA-Z_' \-]*$/i;

      if (value === "") {
        callback(new Error(rule.customMessage));
      } else if (reg.test(value) == false) {
        callback(new Error("Only alpha numeric characters are accepted"));
      } else {
        callback();
      }
    };

    return {
      currentStep: 1,
      mainLoader: false,
      validationErrors: [],
      countries: [],
      states: [],
      groups: [],
      organisations: [],
      locations: [],
      referrers: [],
      expertises: [],
      activities: [],
      subGroups: [],
      titles: [
        { id: 1, label: "Mr" },
        { id: 2, label: "Mrs" },
        { id: 3, label: "Ms" },
        { id: 4, label: "Miss" },
        { id: 6, label: "Dr" },
        { id: 7, label: "Prof" },
        { id: 8, label: "Sir" },
        { id: 9, label: "Dame" },
        { id: 10, label: "Reverend" },
        { id: 11, label: "Duke" },
        { id: 12, label: "Duchess" },
        { id: 5, label: "Other" },
        { id: 13, label: "Mx" },
      ],

      step1Form: {
        form: {
          title: "",
          first_name: "",
          last_name: "",
          contact_number: "",
          email: "",
          email_confirmation: "",
        },
        rules: {
          title: [
            {
              required: true,
              message: "Select a title from the dropdown",
              trigger: "change",
            },
          ],
          first_name: [
            {
              validator: validateMetaCharacters,
              customMessage: "First name is required ",
            },
          ],
          last_name: [
            {
              validator: validateMetaCharacters,
              customMessage: "Last name is required",
            },
          ],
          email: [{ validator: validateEmail, required: true }],
          email_confirmation: [
            { validator: validateEmailConfirmation, required: true },
          ],
          contact_number: [
            { validator: validateContactNumber, required: true },
          ],
        },
        addressesLoading: false,
        disableAddressLookup: false,
        selectedAvailableMailingAddress: null,
        availableMailingAddresses: [],
        inlineValidationErrors: [],
      },

      step2Form: {
        // Step 2
        form: {
          location_id: null,
          other_location: null,
          organisation_id: null,
          role: "",
          experience: "",
        },
        rules: {
          location_id: [
            {
              required: true,
              message: "Select a location from the list",
              trigger: "change",
            },
          ],
          other_country: [
            {
              validator: validateMetaCharacters,
              customMessage: "Last name is required",
            },
          ],
          organisation_id: [
            {
              required: true,
              message: "Select an Organisation from the list",
              trigger: "change",
            },
          ],
          role: [
            {
              required: true,
              message: "Enter your role at the organisation",
              trigger: "change",
            },
            {
              max: 255,
              message: "role may not be greater than 255 characters",
              trigger: "change",
            },
          ],
          experience: [
            {
              max: 1500,
              message:
                "Business Experience may not be greater than 1500 characters",
              trigger: "change",
            },
          ],
        },
        inlineValidationErrors: [],
      },
      // Step 3
      step3Form: {
        form: {
          group_ids: [],
          expertise: [],
          terms_agreed: [],
          globalscot_terms_agreed: [],
        },
        rules: {
          group_ids: [
            {
              type: "array",
              required: true,
              message: "Select at least one of types",
              trigger: "change",
            },
          ],
          expertise: [
            {
              type: "array",
              required: true,
              message: "Select at least one of topics",
              trigger: "change",
            },
          ],
          terms_agreed: [
            {
              type: "array",
              required: true,
              message: "Please confirm you agree with our policies",
              trigger: "change",
            },
          ],
          globalscot_terms_agreed: [
            {
              type: "array",
              required: true,
              message:
                "Please confirm you agree with the scotland europa terms & conditions",
              trigger: "change",
            },
          ],
        },
        inlineValidationErrors: [],
      },
    };
  },
  computed: {
    heroText: function () {
      if (this.currentStep == 1) {
        return "“We really couldn’t do our work with the European Commission and our European partners as well as we do without Scotland Europa’s assistance”";
      } else if (this.currentStep == 2) {
        return "We’ve been giving Scottish interests a strong voice in the EU since 1992";
      } else if (this.currentStep == 3) {
        return "We keep our members up to date with the latest events, opportunities, news and policy changes from the EU";
      }
      return "Registration complete!";
    },
    showOtherLocation() {
      if (!this.step2Form.form.location_id) return false;
      const locationFound = this.locations.find(
        (location) => location.id == this.step2Form.form.location_id
      );
      return locationFound && locationFound.is_other;
    },
  },
  created() {
    this.getRegisterMeta();

    if (this.$route.query.email) {
      this.step1Form.form.email = this.$route.query.email;
    }
  },
  methods: {
    submitStep1Form() {
      this.$refs["step1Form"].validate((valid) => {
        if (valid) {
          this.mainLoader = true;

          gsApi.globalscot
            .validateStepOne(this.step1Form.form)
            .then((response) => {
              this.validationErrors = [];
              this.mainLoader = false;
              this.gotoStep(2);
            })
            .catch((error) => {
              this.validationErrors = [];
              this.mainLoader = false;

              if (
                error.response &&
                error.response.status == 422 &&
                error.response.data
              ) {
                let errors = error.response.data.errors;
                for (let [key, value] of Object.entries(errors)) {
                  let obj = {};
                  obj.id = key;
                  obj.message = value[0];
                  this.validationErrors.push(obj);
                }
                window.scrollTo({ top: 0, behavior: "smooth" });
              } else {
                this.$alert(
                  "There are errors in the form, please fix these errors and try again.",
                  "Error",
                  {
                    confirmButtonText: "Close",
                    showClose: false,
                    customClass: "notification-modal",
                  }
                );
              }
            });
        } else {
          this.validationErrors = [];
          this.$refs.step1Form.fields.forEach((field) => {
            if (field.validateState == "error") {
              let obj = {};
              obj.id = field.labelFor;
              obj.message = field.validateMessage;
              this.validationErrors.push(obj);
            }
          });
          this.$nextTick(() => {
            document
              .getElementById("formErrorBlock")
              .scrollIntoView({ behavior: "smooth", block: "start" });
            let text = document
              .getElementById("step1Form")
              .getElementsByClassName("el-form-item__error")[0].textContent;
            this.$announcer.set(text);

            // (i)	Focus should be set on the first error in the list (doc : Scotland_Europa_Accessibility_Audit)
            if (this.validationErrors.length > 0) {
              document
                .getElementById(`error_${this.validationErrors[0].id}`)
                .focus({ focusVisible: true });
            }
          });
        }
      });
    },
    submitStep2Form() {
      this.$refs["step2Form"].validate((valid) => {
        if (valid) {
          this.mainLoader = true;

          gsApi.globalscot
            .validateStepTwo(this.step2Form.form)
            .then((response) => {
              this.validationErrors = [];
              this.mainLoader = false;
              this.gotoStep(3);
            })
            .catch((error) => {
              this.validationErrors = [];
              this.mainLoader = false;

              if (
                error.response &&
                error.response.status == 422 &&
                error.response.data
              ) {
                let errors = error.response.data.errors;
                for (let [key, value] of Object.entries(errors)) {
                  let obj = {};
                  obj.id = key;
                  obj.message = value[0];
                  this.validationErrors.push(obj);
                }
                window.scrollTo({ top: 0, behavior: "smooth" });
              } else {
                this.$alert(
                  "There are errors in the form, please fix these errors and try again.",
                  "Error",
                  {
                    confirmButtonText: "Close",
                    showClose: false,
                    customClass: "notification-modal",
                  }
                );
              }
            });
        } else {
          this.validationErrors = [];
          this.$refs.step2Form.fields.forEach((field) => {
            if (field.validateState == "error") {
              let obj = {};
              obj.id = field.labelFor;
              obj.message = field.validateMessage;
              this.validationErrors.push(obj);
            }
          });
          this.$nextTick(() => {
            document
              .getElementById("formErrorBlock")
              .scrollIntoView({ behavior: "smooth", block: "start" });
            let text = document
              .getElementById("step2Form")
              .getElementsByClassName("el-form-item__error")[0].textContent;
            this.$announcer.set(text);
          });
        }
      });
    },
    submitStep3Form() {
      this.$refs["step3Form"].validate((valid) => {
        if (valid) {
          const formData = new FormData();

          // Step 1
          formData.append("title", this.step1Form.form.title);
          formData.append("first_name", this.step1Form.form.first_name);
          formData.append("last_name", this.step1Form.form.last_name);
          formData.append("contact_number", this.step1Form.form.contact_number);
          formData.append("email", this.step1Form.form.email);
          formData.append(
            "email_confirmation",
            this.step1Form.form.email_confirmation
          );

          // Step 2
          formData.append("location_id", this.step2Form.form.location_id);
          formData.append(
            "other_location",
            this.$options.filters.domPurify(
              this.step2Form.form.other_location,
              { ALLOWED_TAGS: [] }
            )
          );

          formData.append(
            "organisation_id",
            this.step2Form.form.organisation_id
          );

          formData.append(
            "role",
            this.$options.filters.domPurify(this.step2Form.form.role, {
              ALLOWED_TAGS: [],
            })
          );
          formData.append(
            "experience",
            this.$options.filters.domPurify(this.step2Form.form.experience, {
              ALLOWED_TAGS: [],
            })
          );

          // Step 3
          this.step3Form.form.group_ids.forEach((groupId, index) => {
            formData.append(`group_ids[${index}]`, groupId);
          });

          this.step3Form.form.expertise.forEach((expertise, index) => {
            formData.append(`expertise[${index}]`, expertise);
          });

          this.mainLoader = true;

          gsApi.globalscot
            .register(formData)
            .then((response) => {
              this.validationErrors = [];
              this.mainLoader = false;
              this.gotoStep(4);
            })
            .catch((error) => {
              this.validationErrors = [];
              this.mainLoader = false;

              if (
                error.response &&
                error.response.status == 422 &&
                error.response.data
              ) {
                let errors = error.response.data.errors;
                for (let [key, value] of Object.entries(errors)) {
                  let obj = {};
                  obj.id = key;
                  obj.message = value[0];
                  this.validationErrors.push(obj);
                }
                window.scrollTo({ top: 0, behavior: "smooth" });
              } else {
                this.$alert(
                  "There are errors in the form, please fix these errors and try again.",
                  "Error",
                  {
                    confirmButtonText: "Close",
                    showClose: false,
                    customClass: "notification-modal",
                  }
                );
              }
            });
        } else {
          this.validationErrors = [];
          this.$refs.step3Form.fields.forEach((field) => {
            if (field.validateState == "error") {
              let obj = {};
              obj.id = field.labelFor;
              obj.message = field.validateMessage;
              this.validationErrors.push(obj);
            }
          });
          this.$nextTick(() => {
            document
              .getElementById("formErrorBlock")
              .scrollIntoView({ behavior: "smooth", block: "start" });
            let text = document
              .getElementById("step3Form")
              .getElementsByClassName("el-form-item__error")[0].textContent;
            this.$announcer.set(text);
          });
        }
      });
    },
    gotoStep(step) {
      this.currentStep = step || 1;
      if (this.currentStep == 1) {
        this.$announcer.set("Step 1 of 3 - Personal and company details");
      } else if (this.currentStep == 2) {
        this.$announcer.set("Step 2 of 3 - About You");
      } else if (this.currentStep == 3) {
        this.$announcer.set("Step 3 of 3 Your interests and preferences ");
      } else if (this.currentStep == 4) {
        this.$announcer.set("Thanks for submitting your application");
      }
      window.scrollTo(0, 0);
    },
    gotoLink(link) {
      this.$router.push(link);
    },
    getRegisterMeta() {
      this.mainLoader = true;

      gsApi.globalscot.meta
        .index()
        .then((response) => {
          this.mainLoader = false;

          this.groups = response.data.groups.data;
          this.organisations = response.data.organisations.data;
          this.countries = response.data.countries.data;
          this.locations = response.data.locations.data;
          this.expertises = response.data.expertise.data;
          this.activities = response.data.activities.data;
        })
        .catch((error) => {
          this.mainLoader = false;
          this.$alert(
            "An unknown error occurred. Please try again later.",
            "Error",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal",
            }
          );
          this.gotoLink("/home");
        });
    },
    handleFormFieldInput(formName) {
      this[formName].inlineValidationErrors = [];
      this.$refs[formName].fields.forEach((field) => {
        if (field.validateState === "error") {
          let obj = {};
          obj.id = field.labelFor;
          obj.message = field.validateMessage;
          this[formName].inlineValidationErrors.push(obj);
        }
      });
    },
    hasInlineValidationErrorFor(formName, fieldName) {
      return this.getInlineValidationErrorFor(formName, fieldName).length > 0;
    },
    getInlineValidationErrorFor(formName, fieldName) {
      const validationError = this[formName].inlineValidationErrors.find(
        (error) => error.id === fieldName
      );
      if (!validationError) {
        return "";
      }

      return validationError.message;
    },
  },
};
</script>
<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
fieldset{
  border: none;
}
</style>