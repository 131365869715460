export default {
  data: function () {
    return {
      inlineValidationErrors: [],
    }
  },
  methods: {
    handleMultipleFormsFieldInput(formName) {
      this[formName].inlineValidationErrors = [];
      this.$refs[formName].fields.forEach(field => {
        if (field.validateState === 'error') {
          let obj = {};
          obj.id = field.labelFor;
          obj.message = field.validateMessage;
          this[formName].inlineValidationErrors.push(obj);
        }
      });
    },
    handleSingleFormFieldInput(formName) {
      this.inlineValidationErrors = [];
      this.$refs[formName].fields.forEach(field => {
        if (field.validateState === 'error') {
          let obj = {};
          obj.id = field.labelFor;
          obj.message = field.validateMessage;
          this.inlineValidationErrors.push(obj);
        }
      });
    },
    hasInlineValidationErrorFor(fieldName, formName = null) {
      return this.getInlineValidationErrorFor(fieldName, formName).length > 0;
    },
    getInlineValidationErrorFor(fieldName, formName = null) {
      let inlineErrors = [];

      if (formName !== null) {
        inlineErrors = this[formName].inlineValidationErrors;
      } else {
        inlineErrors = this.inlineValidationErrors;
      }

      const validationError = inlineErrors.find(error => error.id === fieldName);
      if (! validationError) {
        return '';
      }

      return validationError.message;
    },
  }
}
