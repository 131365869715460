<template>
  <div
    id="modal-dialog"
  >
    <Trap>
      <el-dialog
        v-if="modals.pulse.post"
        ref="postDialog"
        :visible="modals.pulse.isOpen"
        custom-class="pulse-dialog-from-notification pulse-dialog pulse"
        fullscreen
        @opened="dialogOpen('postDialog')"
        @close="closePulseModal"
      >
        <pulse-post
          :id="`header-post-${modals.pulse.post.id}`"
          :post="modals.pulse.post"
          :pulsepost-id="modals.pulse.pulsepostId"
        ></pulse-post>
      </el-dialog>

      <el-dialog
        v-if="modals.request.request"
        ref="requestDialog"
        :visible="modals.request.isOpen"
        fullscreen
        custom-class="request-dialog"
        @opened="dialogOpen('requestDialog')"
        @close="closeRequestModal"
      >
        <help-request
          ref="helpRequestRef"
          :help-request="modals.request.request"
          @on-decline="openRequestDeclinedDialog($event)"
          @clicked="closeRequestModal"
        >
        </help-request>
      </el-dialog>

      <el-dialog
        v-if="modals.project.post"
        ref="projectPostDialog"
        :visible="modals.project.isOpen"
        custom-class="pulse-dialog-from-notification pulse-dialog pulse"
        fullscreen
        @opened="dialogOpen('projectPostDialog')"
        @close="closeProjectPostModal"
      >
        <project-post
          :id="`post-${modals.project.post.id}`"
          :post="modals.project.post"
          :open-reply-for-post="modals.project.post.id"
          :project-post-id="modals.project.projectPostId"
        ></project-post>
      </el-dialog>
    </Trap>
    <focus-lock>
      <el-dialog
        v-if="modals.requestDeclined.isOpen"
        ref="requestDeclineDialog"
        :show-close="false"
        custom-class="request-decline-modal"
        :visible="modals.requestDeclined.isOpen"
        :title="`Request declined. We’ll let ${modals.requestDeclined.member_name} know you can’t offer support for this request.They’ll still be able to request support from you in the future.`"
        @opened="dialogOpen('requestDeclineDialog')"
      >
        <div class="text-center success-dialog">
          <svg-success-border></svg-success-border>
          <h2 class="mt-3">
            Request declined
          </h2>
          <p class="mt-4 mb-1">
            We’ll let {{ modals.requestDeclined.member_name }} know you can’t offer support for this request.
          </p>
          <p class="mt-1 mb-4">
            They’ll still be able to request support from you in the future.
          </p>
          <el-button
            class="mx-auto mt-5"
            @click="closeRequestDeclinedDialog"
          >
            Close
          </el-button>
        </div>
      </el-dialog>
    </focus-lock>
  </div>
</template>

<script>
import store from "@/store";
import { mapState } from "vuex";
import Trap from 'vue-focus-lock';
import PulsePost from '@/components/platform/pulse/pulse-post';
import ProjectPost from "@/components/platform/projects/project-post";
import HelpRequest from '@/components/partials/help-request.vue';
import SuccessBorder from '@/components/assets/svg-success-border';

import NotificationsNav from '@/components/slide-panel/panels/notifications-panel'

export default {
  components: {
    'Trap': Trap,
    'pulse-post': PulsePost,
    'project-post': ProjectPost,
    'help-request': HelpRequest,
    'notifications-nav': NotificationsNav,
    'svg-success-border': SuccessBorder,

  },

  data () {
    return {
      memberName: null,
      requestDeclinedDialogVisible: false,
    }
  },

  computed: {
    ...mapState(['modals']),
  },

  methods: {
    openRequestDeclinedDialog(memberName) {
      store.commit('OPEN_REQUEST_DECLINED_MODAL', {
        member_name: memberName,
        isOpen: true
      });
      document.getElementById('content').setAttribute("aria-hidden", "true");
    },
    closePulseModal() {
      store.dispatch('closePulseModal');
      this.handleClosed();
    },
    closeProjectPostModal() {
      store.dispatch('closeProjectPostModal');
      this.handleClosed();
    },
    closeRequestModal() {
      store.dispatch('closeRequestModal');
      this.$refs.helpRequestRef.resetAcceptForm()
      this.handleClosed();
    },
    closeRequestDeclinedDialog() {
      store.dispatch('closeRequestDeclinedModal');
      this.handleClosed();
    },
    handleClosed() {
      document.getElementById('content').removeAttribute("aria-hidden");
    }
  },
}
</script>
