<script>
import store from '@/store'

export default {
	data () {
		return {
			roles: store.state.user.roles,
			permissions: store.state.permissions
		}
	},
  	methods: {
		checkRoleOrPermission (list, values) {
			if(list)
				return list.some(item => values.includes(item))
		},
		isRole (roleToCheck) {
			return this.checkRoleOrPermission(this.roles, [roleToCheck])
		},
		hasRoles (rolesToCheck) {
			return this.checkRoleOrPermission(this.roles, rolesToCheck)
		},
		isPermission (permissionToCheck) {
			return this.checkRoleOrPermission(this.permissions, [permissionToCheck])
		},
		hasPermissions (permissionsToCheck) {
			return this.checkRoleOrPermission(this.permissions, permissionsToCheck)
		},
		hasRolesAndPermissions (roles, permissions) {
			return this.hasRoles(roles) && hasPermissions(permissions)
		}
  	}
}
</script>
