<template>
  <focus-lock>
    <el-dialog
      ref="signUpForEventDialog"
      id="signUpForEventDialog"
      @opened="dialogOpen('signUpForEventDialog')"
      aria-describedby="dialogDescription"
      custom-class="events-general-dialog"
      :show-close="false"
      :visible.sync="dialogVisible"
      element-loading-background="rgba(255, 255, 255, 0.8)"
      title="Please confirm you want to sign up for this event"
      @close="onClose($event)"
    >
      <div class="body" >
        <h2 id="event-dialog-title" class="event-dialog-title">Please confirm you want to sign up for this event</h2>

        <div class="button-wrap d-flex events-dialog-buttons sign-up-buttons">
          <el-button class="events-dialog-cancel" @click="onClose($event)">
            Cancel
          </el-button>
          <el-button
            class="events-dialog-confirm"
            type="primary"
            @click.prevent="signUpForEvent()"
            :loading="mainLoader"
          >
            Yes
          </el-button>
        </div>
      </div>
    </el-dialog>
  </focus-lock>
</template>

<script>
import store from '@/store'
import gsApi from '@/services/gs.api'

export default {
  name: 'SignUpForEventDialog',

  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      mainLoader: false,
      dialogVisible: false
    }
  },

  methods: {
    onClose (e) {
      this.dialogVisible = false
      this.$emit('closed')
    },

    signUpForEvent () {
      this.mainLoader = true

      gsApi.events.attend(this.$route.params.slug)
        .then(response => {
          this.$emit('signedUp', {

          });
        }).catch(error => {
        this.mainLoader = false;
        if (error.response && error.response.status == 422) {
          this.$alert("Unable to attend event, this event is full. Check back later in case spaces open up. ", "Error", {
            confirmButtonText: "Close",
            showClose: false,
            customClass: "notification-modal"
          })
        } else if (error.response && error.response.status == 403) {
          this.$alert("Unable to attend event, you are already attending this event.", "Error", {
            confirmButtonText: "Close",
            showClose: false,
            customClass: "notification-modal"
          })
        } else {
          this.$alert("Unable to attend event, please try again later.", "Error", {
            confirmButtonText: "Close",
            showClose: false,
            customClass: "notification-modal"
          })
        }
      }).finally(() => {
        this.mainLoader = false;
        this.onClose()
      })
    },
  },

  watch: {
    visible (value) {
      this.dialogVisible = this.visible
    }
  },
  created () {
    this.dialogVisible = this.visible
  }
}
</script>

<style lang="scss">
#signUpForEventDialog {
  min-height: 422px;
}

.events-general-dialog {
  .body {
    padding-top: 3rem; 
    padding-bottom: 4rem;
  }
  .event-dialog-title {
    font-style: normal;
    font-weight: 420;
    font-size: $font-size-35;
    line-height: $line-height-45;
    /* identical to box height, or 129% */

    text-align: center;

    color: #000000;
    @media screen and(max-width: 425px) {
      font-size: 1.4rem;
    }
  }

  .events-dialog-buttons {
    width: 100%;

    .events-dialog-cancel {
      width: 100%;
      max-width: 231px;
    }

    .events-dialog-confirm {
      width: 100%;
      max-width: 231px;
    }
  }

  .sign-up-buttons {
    padding-top: 2rem;
    @media screen and(max-width: 425px) {
      padding-top: 1rem
    }
  }
}

</style>
