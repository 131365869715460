<!-- Home Component -->
<template>
  <div class="home-page">
    <el-row
      :gutter="0"
      class="m-0"
    >
      <top-navigation></top-navigation>
      <el-col
        :span="24"
        class="article-featured px-0"
      >
        <section aria-label="Introduction section">
          <div class="home-page__header">
            <div class="header__content__bg"></div>
            <div
              class="header__image__bg home-org-bg d-flex align-items-center"
            >
              <!-- <img src="static/img/gs-20-years-home.png" class="gs-home-img" /> -->
            </div>
            <div class="header__content container">
              <el-row>
                <el-col
                  :md="24"
                  :lg="12"
                >
                  <div class="header__content__wrapper">
                    <h1
                      id="main-content"
                      class="header__title"
                    >
                      {{ text.headingText }}
                    </h1>
                    <p class="header__content-info">
                      {{ text.headingSub }}
                    </p>
                    <el-button
                      class="btn-text join-home-btn"
                      type="primary"
                      @click="$router.push({ name: 'About' })"
                    >
                      About us and what we do
                    </el-button>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </section>
      </el-col>
    </el-row>
    
    <what-you-get></what-you-get>
    
    <!-- <div>
      <who-members :members="users"></who-members>
    </div> -->
    
    <in-the-spotlight :articles="articles"></in-the-spotlight>
    
    <!-- <div>
      <connect-digital-platform></connect-digital-platform>
    </div> -->
    <up-and-coming-events :events="events"></up-and-coming-events>
    <!--div class="white-background-section">
      <div class="container">
        <el-row>
          <el-col :md="24">
            <h2 class="funding-portal-title">
              Scottish Funding Portal
            </h2>
          </el-col>
          <el-col
            :md="24"
            :lg="24"
          >
            <div>
              <h3 class="community-sub-text text-black">
                Interested in accessing European funding? The funding portal can
                help you find European funding that Scottish organisations can
                still access in 2021-2027.
              </h3>
              <el-button
                class="btn-text community-btn"
                size="small"
                @click="openFundingLink"
              >
                Explore the Scottish Funding Portal
              </el-button>
            </div>
          </el-col>
        </el-row>
      </div>
    </!--div-->
  </div>
</template>

<script>
import gsApi from "@/services/gs.api";
import store from "@/store";
import { mapState } from "vuex";
import WhatYouGet from "../../components/platform/home/what-you-get";
//import WhoMembers from "../../components/platform/home/who-members";
//import ConnectDigitalPlatform from "../../components/platform/home/connect-digital-platform";
import UpAndComingEvents from "../../components/platform/home/up-and-coming-events";
import InTheSpotlight from "../../components/platform/home/in-the-spotlight";
//import router from "@/router";
import TopNavigation from "../../layout/top-navigation";
//import { Loading } from "element-ui";

export default {
  name: "HomePage",
  metaInfo: {
    title: "Scotland Europa: Networking Scotland in Europe",
    meta: [
      {
        name: "description",
        content:
          "Scotland Europa is a membership-based organisation that promotes Scotland's interests across the institutions of the European Union and to the representatives of Europe's regions and Member States.",
      },
    ],
  },
  components: {
    WhatYouGet,
    //WhoMembers,
    //ConnectDigitalPlatform,
    UpAndComingEvents,
    InTheSpotlight,
    TopNavigation,
    //Loading,
  },
  data() {
    return {
      mainLoader: false,
      inVerifiableState: store.state.inVerifiableState,
      groups: [{ id: 1, name: "COMING SOON" }],
      selectedGroup: null,
      topFeedItems: [],
      loading: null,
      text: {
        headingText: "Networking Scotland in Europe",
        headingSub:
          "Scotland Europa is a membership-based organisation that promotes Scotland's interests across the institutions of the European Union and to the representatives of Europe's regions and Member States. With experienced and specialised staff based in Brussels and Scotland, Scotland Europa fosters strong links between Scotland and Europe, providing intelligence, policy analysis and strategic funding advice.",
      },
      users: [],
      articles: [],
      events: [],
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  created() {
    this.loading = true;
    this.getPublicInfo();
  },
  updated() {
    this.loading = false;
  },
  methods: {
    openFundingLink() {
      window.open("https://funding-portal.scot/", "_blank");
    },
    getPublicInfo() {
      gsApi.users
        .public()
        .then((response) => {
          this.articles = response.data.articles.data.slice(0, 2);
          this.users = response.data.users.data.slice(0, 4);
          this.events = response.data.events.data.slice(0, 3);
          this.mainLoader = false;
        })
        .catch((error) => {
          this.mainLoader = false;
        });
    },
  },
};
</script>
<style>
.featured-desc {
  margin: auto 100px auto 100px;
}
.featured-title {
  font-weight: 400;
  padding-bottom: 30px;
}
img {
  width: 100%;
  height: auto;
}
.join-home-btn {
  margin: 35px 0px 30px 0px;
}
</style>
