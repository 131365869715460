import { render, staticRenderFns } from "./gs-personal-details-form.vue?vue&type=template&id=7ef89b85"
import script from "./gs-personal-details-form.vue?vue&type=script&lang=js"
export * from "./gs-personal-details-form.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports