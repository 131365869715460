<!-- Index Component -->
<template>
  <div class="wrapper new-ui">
    <v-skip-links></v-skip-links>
    <!-- <v-beta-bar /> -->
    <v-l-header></v-l-header>
    <main id="content" role="main">
      <div id="notfound">
        <div class="notfound">
          <div class="notfound-404">
            <h1>404</h1>
          </div>
          <h2>the page you are looking could not be found</h2>
          <div class="notfound-search">
            <el-button @click="$router.push({name: 'Home'})" type="primary">Back to home</el-button>
          </div>
        </div>
      </div>
    </main>
    <v-get-in-touch></v-get-in-touch>
    <v-footer></v-footer>
  </div>
</template>

<script>
import Footer from "../../layout/footer";
import JoinHub from "../../layout/join-hub";
import GetInTouch from "../../layout/get-in-touch";
import LandingHeader from "../../layout/l-header-2";
import SkipLinks from "@/components/partials/skip-links";
// import BetaBar from "@/components/partials/beta-bar";

export default {
  components: {
    "v-skip-links": SkipLinks,
    // "v-beta-bar": BetaBar,
    "v-l-header": LandingHeader,
    "v-footer": Footer,
    "v-join-hub": JoinHub,
    "v-get-in-touch": GetInTouch
  }
};
</script>
<style lang="scss">
#notfound {
  position: relative;
  height: 80vh;
  background: #f6f6f6;
  .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    max-width: 767px;
    width: 100%;
    line-height: $line-height-24;
    padding: 50px 40px;
    text-align: center;
    background: #fff;
    -webkit-box-shadow: 0 15px 15px -10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 15px 15px -10px rgba(0, 0, 0, 0.1);
    .notfound-404 {
      position: relative;
      height: 180px;
      h1 {
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        font-size: $font-size-120;
        font-weight: 700;
        margin: 0px;
        color: #A8308C;
        text-transform: uppercase;
      }
    }
    h2 {
      font-size: $font-size-22;
      font-weight: 400;
      color: #151515;
      margin-top: 30px;
      margin-bottom: 50px;
    }
    .notfound-search {
      position: relative;
      width: 100%;

      > button {
        margin: auto;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .notfound h2 {
    font-size: $font-size-18;
  }
}

@media only screen and (max-width: 480px) {
  .notfound .notfound-404 h1 {
    font-size: $font-size-141;
  }
}
</style>
