<template>
  <el-card class="member-card box-card text-center h-100">
    <user-avatar
      shape="square"
      :size="74"
      :src="organisation.logoFile.large_thumb"
      :alt="organisation ? organisation.name : '' + 'Organisation Logo'"
      :default-src="require('../../../../static/img/no-logo.png')"
      avatar-classes="organisation-logo cards"
    ></user-avatar>
    <div class="name-position">
      <h3 class="text-truncate name">
        {{ organisation.name }}
      </h3>

      <div
        class="d-flex justify-content-center"
      >
        <p class="text-truncate">
          <a :href="organisation.url">{{ organisation.url }}</a>
        </p>
      </div>
    </div>
    <hr class="mt-3 mb-3" />
    <div class="location-sectors">
      <div class="d-flex justify-content-center">
        <div><svg-map-pin-small></svg-map-pin-small></div>
        <p class="text-truncate">
          {{ helpers.formatedAddress(organisation.address) }}
        </p>
      </div>
    </div>
    <div
      class="view-profile-link"
    >
      <router-link
        :to="{ name: 'Organisation Profile', params: { slug: organisation.slug, showBackToSearch } }"
      >
        <span>
          View
          <span class="sr-only">
            {{ organisation.name }}'s
          </span>
          profile
        </span>
        <svg-purple-arrow-right class="arrow" />
      </router-link>
    </div>
  </el-card>
</template>

<script>
import MapPinSmall from '@/components/assets/svg-map-pin-small'
import PurpleArrowRight from '@/components/assets/svg-purple-arrow-right'
import UserAvatar from '@/components/core/user-avatar'
import helpers from '@/helpers/index'
export default {
  name: 'OrganisationCard',
  components: {
    'svg-map-pin-small': MapPinSmall,
    'svg-purple-arrow-right': PurpleArrowRight,
    UserAvatar
  },
  props: {
    organisation: {
      type: Object,
      required: true
    },
    showBackToSearch:{
      type: Boolean,
      default: true
    }
  },
  data(){
    return{
      helpers
    }
  }
};
</script>
<style lang="scss" scoped>
  .vertical-line{
    height: 25px;
    margin: -3px 5px;
   position: relative;
  }
 </style>
