<template>
  <div>
    <div class="back-to-search-section-public">
      <div class="container">
        <router-link
          :to="{ name: 'Business Events Webinars' }"
          class="back-to-search-link"
        >
          <span><svg-white-arrow-left class="mr-1"></svg-white-arrow-left> Back to Events & webinars</span>
        </router-link>
      </div>
    </div>
    <div class="image-container">
      <div class="container">
        <el-image
          :src="imagePath"
          :alt="altText"
          fit="cover"
          class="hidden-sm-and-down"
        >
          <div
            slot="error"
            class="image-slot"
          >
            <el-image
              :src="defaultImagePath"
              :alt="altText"
              fit="cover"
              class="hidden-sm-and-down"
              rel="image_src"
            >
            </el-image>
          </div>
        </el-image>
      </div>
      <el-image
        :src="imagePath"
        :alt="altText"
        fit="cover"
        class="hidden-md-and-up"
        rel="image_src"
      >
        <div
          slot="error"
          class="image-slot"
        >
          <el-image
            :src="defaultImagePath"
            :alt="altText"
            fit="cover"
            class="hidden-md-and-up"
            rel="image_src"
          >
          </el-image>
        </div>
      </el-image>
    </div>
  </div>
</template>

<script>
import WhiteArrowLeft from '@/components/assets/svg-white-arrow-left'

export default {
  name: 'NewsHeader',
  components: {
    'svg-white-arrow-left': WhiteArrowLeft,
  },
  props: {
    image: {
      type: Object,
      default: null
    },
    altText: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
    }
  },
  computed: {
    imagePath() {
      return this.image?.path
    },
    defaultImagePath(){
      return this.image?.large_thumb
    }
  },
  created() {
    let body = document.body
    body.classList.add("white-bg")
  },
  methods: {

  }
}
</script>

