<template>
  <focus-lock>
    <el-dialog
      ref="feedbackDialog"
      custom-class="feedback-modal"
      :show-close="false"
      :fullscreen="true"
      :visible.sync="visible"
      :before-close="onClose"
      @opened="dialogOpen('feedbackDialog')"
    >
      <div class="container header">
        <el-row>
          <el-col>
            <div class="d-flex">
              <h2 class="text-break">
                {{ title }}
              </h2>
              <button
                type="button"
                aria-label="Close"
                class="close ml-auto"
                @click="onClose"
              >
                <i class="el-dialog__close el-icon el-icon-close"></i>
              </button>
            </div>
          </el-col>
        </el-row>
      </div>

      <slot name="header"></slot>

      <hr class="mt-4 mb-4" />

      <div class="review-section container">
        <el-row>
          <el-col :md="24">
            <p class="title">
              <slot name="title">
                Leave Feedback
              </slot>
            </p>
          </el-col>
          <el-col
            :md="24"
            :lg="14"
          >
            <validation-errors
              v-if="validationErrors.length"
              :errors="validationErrors"
            ></validation-errors>
          </el-col>
          <el-col
            :md="24"
            :lg="14"
          >
            <el-form
              id="feedbackForm"
              ref="feedbackForm"
              v-loading="loading"
              :model="feedbackForm"
              :rules="feedbackForm.rules"
              autocomplete="false"
              @submit.native.prevent
            >
              <el-form-item
                prop="rating"
                class="is-no-asterisk rating-form-item"
              >
                <el-rate
                  v-model.number="feedbackForm.rating"
                  void-color="#A8308C"
                  :colors="ratingColors"
                  @change="onRatingSelected"
                ></el-rate>
              </el-form-item>
              <p>(1 = bad, 5 = great)</p>
              <el-form-item
                prop="feedback"
                class="is-no-asterisk mt-4"
                :error="feedbackError"
              >
                <template slot="label">
                  <slot name="textarea-label">
                    Feedback
                  </slot>
                  <br />
                  <span
                    class="el-form-item__info"
                  >Remember not to include any sensitive or confidential information.</span>
                  <span
                    class="sr-only"
                    aria-live="polite"
                    :hidden="! hasInlineValidationErrorFor('feedback')"
                    v-text="getInlineValidationErrorFor('feedback')"
                  ></span>
                </template>
                <el-input
                  id="feedback"
                  v-model="feedbackForm.feedback"
                  placeholder
                  type="textarea"
                  :rows="5"
                  maxlength="500"
                  aria-required="true"
                ></el-input>
              </el-form-item>
              <div class="mt-n4 mb-4">
                <span
                  class="se-body-l-screen color-light-gray"
                >You have {{ 500 - feedbackForm.feedback.length }} characters remaining</span>
              </div>

              <el-button
                class="mt-3 mb-3 submit-btn"
                type="primary"
                @click="submitFeedback"
              >
                Submit feedback
              </el-button>
            </el-form>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </focus-lock>
</template>

<script>
import gsApi from "@/services/gs.api";
import validationErrors from '@/components/partials/validation-errors'
import inlineValidationErrorsMixin from "@/mixins/inlineValidationErrorsMixin";
export default {
  components: {
    validationErrors
  },
  mixins: [inlineValidationErrorsMixin],
  props: {
    title: {
      type: String,
      required: true
    },
    module: {
      type: String,
      required: true
    },
    entity: {
      type: String,
      required: true
    },
    entityID: {
      type: Number,
      required: true
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    let validateRating = (rule, value, callback) => {
      if (!value) {
        callback(new Error("Please select a rating"));
      } else {
        callback();
      }
    };

    return {
      validationErrors: [],
      loading: false,
      feedbackForm: {
        rating: null,
        feedback: "",
        rules: {
          rating: [{ validator: validateRating, trigger: "change" }]
        }
      },
      feedbackError: null,
      ratingColors: ["#A8308C", "#A8308C", "#A8308C", "#A8308C", "#A8308C"],
      dialogVisible: false
    };
  },
  methods: {
    onRatingSelected() {
      this.$refs["feedbackForm"].validateField("rating");
    },
    submitFeedback() {
      this.$refs["feedbackForm"].validate(valid => {
        if (valid) {
          // need to add a laoder to the modal, that is true here and set to false in the gsApi then/error

          // gsApi api call here, change to whatever the api endpoint is etc - i am passing a type through of: helpRequest not sure how this being handled on backend?
          // I imagine it will be somthing like this with it being polymorphic, i created this feedback component in a way it can be used with the other entities.

          // gsApi.feedback.create({})
          //  }).then((response) => {
          // on success close this modal
          // probably emit an event here
          // this.$emit('feedback-submitted') // look for the event lisner on <feedback> in selected-chat.vue for what to do next
          //  }).catch((error) => {
          //    //handle-error
          //  })

          gsApi.feedback
            .submit({
              review: this.feedbackForm.feedback,
              rating: this.feedbackForm.rating,
              module: this.module,
              entity: this.entity,
              entity_id: this.entityID
            })
            .then(response => {
              this.validationErrors = []
              this.$emit("submitted");
              this.onClose();
            })
            .catch(error => {
              this.validationErrors = []
              this.feedbackError =
                error.response.data.errors.review[0] ||
                error.response.data.errors.error[0];
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.validationErrors = [];
          this.$refs.feedbackForm.fields.forEach(field => {
            if(field.validateState == 'error') {
              let obj = {}
              obj.id = field.labelFor;
              obj.message = field.validateMessage;
              this.validationErrors.push(obj);
            }
          });
          this.$nextTick(() => {
            document.getElementById('formErrorBlock').scrollIntoView({ behavior: 'smooth', block: 'start' })
            let text = document.getElementById("feedbackForm").getElementsByClassName('el-form-item__error')[0].textContent
            this.$announcer.set(text)
          })
        }
      });
    },
    onClose() {
      this.$emit("close");
    }
  }
};
</script>
