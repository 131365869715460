<template>
  <section
    class="white-background-section"
    aria-label="Latest events and webinars"
  >
    <div class="container">
      <div class="heading-text mb-30 py-0 d-flex justify-content-between">
        <h2 class="event-title">
          {{ eventText.heading }}
        </h2>
      </div>

      <el-row>
        <div
          v-if="events.length == 0"
          class="no-events-available"
        >
          <h2>{{ nothingAvailable }}</h2>
        </div>
        <div v-else>
          <event-card
            v-for="event in events"
            :key="event.id"
            :event="event"
            :is-public="true"
          />
        </div>
      </el-row>
    </div>
  </section>
</template>
<script>
import EventCard from "@/components/platform/events-webinars/events-card";
export default {
  components: {
    EventCard
  },
  props: {
    events: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      eventText: {
        heading: "Latest events and webinars"
      },
      nothingAvailable: "No events and webinars available"
    };
  }
};
</script>
<style lang="scss">
.no-events-available h2 {
  font-size: $font-size-28;
  text-align: center;
  margin: 50px;
  font-weight: 400;
}
</style>
