<template>
  <el-col :sm="24" :md="12" :lg="8" :xl="8" class="news-card-col">
    <el-card class="news-card box-card text-center">
      <div class="news-img">
        <el-image :alt="altText" :src="imageForArticle" fit="cover"></el-image>

        <div v-if="article.type_name == articleTypes.spotlight" class="avatar">
          <!--el-avatar v-if="avatar" :size="140" :src="avatar" shape="circle" alt="Profile picture">
            <img src="/static/img/default-image.png" alt="Profile picture" />
          </el-avatar-->
          <user-avatar
            shape="circle"
            :isGlobalScot="article.spotlightUser.is_global_scot"
            :authorExist="!!article.spotlightUser"
            :size="140"
            width="140px"
            :src="avatar"
            :alt="article.spotlightUser ? article.spotlightUser.first_name : '' + 'Profile picture'"
          ></user-avatar>
        </div>

        <span class="news-type">{{ article.type_name }}</span>
      </div>
      <div class="title-position">
        <h3 class="text-truncate">{{ article.title }}</h3>
        <p class="text-truncate">{{ article.description }}</p>
      </div>
      <div class="read-more-btn-container">
        <el-button type="primary" class="read-more-btn" @click="readMore(article.slug)">Read more</el-button>
      </div>
    </el-card>
  </el-col>
</template>

<script>
import { mapState } from "vuex";
import UserAvatar from "@/components/core/user-avatar";
export default {
  name: "public-articles-card",
  components: {
    UserAvatar
  },
  props: {
    article: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      articleTypes: {
        news: "News",
        spotlight: "Spotlight",
        feature: "Feature",
        event: "Event",
        casestudy: "Case Study"
      }
    };
  },
  computed: {
    ...mapState(["user"]),
    imageForArticle() {
      return this.article.mediaFile && this.article.mediaFile.large_thumb
        ? this.article.mediaFile.large_thumb
        : null;
    },
    altText() {
      return (this.article.mediaFile && this.article.mediaFile.alt_attribute) ? this.article.mediaFile.alt_attribute: ''
    },
    avatar() {
      return this.article.spotlightUser && this.article.spotlightUser.avatar
        ? this.article.spotlightUser.avatar
        : null;
    }
  },
  methods: {
    readMore(slug) {
      this.$router.push({ name: "Public Articles", params: { slug: slug } });
    }
  }
};
</script>
