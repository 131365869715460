<template>
  <article>
    <el-card v-loading.lock="blockToDelete" class="mt-4 pulse-post-card">
      <pulse-report-form
        :visible="submittingPostReport"
        title="Report Post"
        module="Post"
        entity="Post"
        :entity-i-d="post.id"
        @close="submittingPostReport = false"
      ></pulse-report-form>
      <div class="px-4">
        <el-row>
          <el-col :sm="23" :xl="23" :xs="23">
            <el-row>
              <el-col :sm="3" :xl="2" :xs="4" class="ml-1">
                <el-popover
                  popper-class="author-card-popover"
                  placement="top-start"
                  width="290"
                  trigger="hover"
                  @show="fetchUserData"
                >
                  <div
                    v-loading="loadingUser"
                    class="author-card-popover-loader"
                  >
                    <author-card
                      v-if="!loadingUser"
                      :member="postAuthor"
                    ></author-card>
                  </div>
                  <user-avatar
                    slot="reference"
                    shape="circle"
                    :is-global-scot="post.author && post.author.is_global_scot"
                    :author-exist="!!post.author"
                    width="45px"
                    :size="45"
                    :src="post.author_avatar_url"
                    :alt="
                      post.author ? post.author.name : '' + 'Profile picture'
                    "
                  ></user-avatar>
                </el-popover>
              </el-col>
              <el-col :sm="6" :xl="7" :xs="19" class="ml-1">
                <el-popover
                  v-if="post.author"
                  popper-class="author-card-popover"
                  placement="top-start"
                  width="290"
                  trigger="hover"
                  @show="fetchUserData"
                >
                  <div
                    v-loading="loadingUser"
                    class="author-card-popover-loader"
                  >
                    <author-card
                      v-if="postAuthor"
                      :member="postAuthor"
                    ></author-card>
                  </div>
                  <p slot="reference" class="clickable">
                    <strong v-if="post.author">{{ post.author.name }}</strong>
                  </p>
                </el-popover>

                <span class="pulse-time-ago">{{
                  post.created_at_time_diff
                }}</span>
              </el-col>

              <el-col :sm="14" :xs="24">
                <div
                  class="tags text-left text-sm-right mt-2 mt-sm-0 d-flex justify-content-end"
                >
                  <a
                    v-for="(tag, index) in tags.slice(0, 2)"
                    :key="index"
                    class="d-inline-block pulse-option active px-2 ml-1 mb-1 rounded"
                    >{{ tag.name }}</a
                  >
                  <a
                    v-if="tags.length > 2"
                    class="d-inline-block pulse-option active px-2 rounded ml-1 mb-1 text-dark"
                    @click="isOpen = !isOpen"
                    >+{{ tags.length - 2 }}</a
                  >
                </div>
              </el-col>
              <div v-show="isOpen" class="other-tags float-right">
                <a
                  v-for="(tag, index) in tags.slice(2)"
                  :key="index"
                  class="d-inline-block pulse-option active px-2 ml-1 mb-1 rounded"
                >
                  {{ tag.name }}
                </a>
              </div>
            </el-row>
          </el-col>
          <el-col :sm="1" :xl="1" :xs="1" class="pull-right">
            <el-dropdown
              class="el-dropdown-hover"
              :class="{ 'el-dropdown-grey-background': listActionShown }"
              @visible-change="getListActionStatus"
            >
              <span class="el-dropdown-link"
                ><span class="sr-only">{{
                  post.author && user.id == post.author.id
                    ? "manage post menu"
                    : "report post menu"
                }}</span
                ><svg-three-dots></svg-three-dots>
              </span>
              <el-dropdown-menu
                slot="dropdown"
                :class="{
                  'only-one-child': post.author && user.id != post.author.id,
                }"
              >
                <el-dropdown-item
                  v-if="post.author && user.id != post.author.id"
                  @click.native="reportPost()"
                >
                  Report
                </el-dropdown-item>
                <el-dropdown-item
                  v-if="post.author && user.id == post.author.id"
                  @click.native="editPost(post)"
                >
                  Edit
                </el-dropdown-item>
                <el-dropdown-item
                  v-if="user.id == post.author.id"
                  @click.native="showPulseDeleteConfirmDialog = true"
                >
                  Delete
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div class="mt-3 pb-3 content">
              <dynamic-link :content="post.content"></dynamic-link>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <div
              v-if="post.links && post.links.data.length"
              class="mt-3 pb-3 content"
            >
              <div
                v-for="(item, linkIndex) in post.links.data"
                :key="linkIndex"
              >
                <div v-if="!item.isClosed" class="link-preview d-block">
                  <div class="preview-card">
                    <img
                      v-if="item.image"
                      class="preview-link-image d-block"
                      :src="item.image"
                      :alt="item.title"
                    />
                    <a
                      :href="item.url"
                      target="_blank"
                      :aria-label="item.title"
                      class="preview-info d-block"
                    >
                      <span
                        class="preview-url-text"
                        v-text="item.providerUrl ? item.providerUrl : item.url"
                      ></span>
                      <span
                        class="preview-url-title d-block"
                        v-text="item.title ? item.title : item.url"
                      ></span>
                      <span class="sr-only"
                        >this link will open in a new window</span
                      >
                    </a>
                    <a
                      href="javascript:void(0)"
                      aria-label="close link preview"
                      class="preview-close-btn"
                      @click.prevent="closePreview(item)"
                    >
                      <svg-close-gray-bg-white-border></svg-close-gray-bg-white-border>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row v-if="post.documentFile">
          <el-col :span="24" class="mb-3">
            <!-- content -->
            <div
              class="attached-document clickable d-inline-block px-2 px-sm-3 py-3 rounded w-100"
            >
              <div class="d-flex align-items-center">
                <img
                  :src="helpers.getFileIcon(post.documentFile.extension, true)"
                  alt=""
                />
                <div class="document-info ml-3 w-100">
                  <div class="download align-items-center">
                    <a
                      class="gs-link fs-19"
                      :href="post.documentFile.path"
                      download
                      >{{ post.documentFile.original_name }}</a
                    >
                    <svg-download-file
                      class="ml-2 pull-right"
                    ></svg-download-file>
                  </div>
                  <p>
                    {{ post.documentFile.extension.toUpperCase() }},
                    {{ getReadableFilesize(post.documentFile.size) }}
                  </p>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <el-row v-if="post.mediaFile">
        <el-col :span="24">
          <img
            v-if="post.mediaFile.media_type === 'image'"
            class="w-100 d-block mb-0"
            :src="post.mediaFile.path"
            :alt="
              post.author
                ? post.author.name
                : '' + ' uploaded image for post on feed '
            "
          />
          <video
            v-if="post.mediaFile.media_type === 'video'"
            class="w-100 d-block mb-0"
            :src="post.mediaFile.path"
            controls
            :alt="post.author ? post.author.name : '' + ' uploaded video'"
          ></video>
        </el-col>
      </el-row>

      <div class="px-4">
        <el-row v-if="!post.mediaFile">
          <el-col :span="24">
            <hr class="mt-3" />
          </el-col>
        </el-row>
        <!-- Likes -->
        <el-row class="pt-2 likeable">
          <el-col :span="12">
            <svg-likes-counter></svg-likes-counter>
            <span class="likes-counter">{{
              `${post.likes.likes} Like${post.likes.likes > 1 ? "s" : ""}`
            }}</span>
          </el-col>
          <el-col :span="12" class="d-flex justify-content-end">
            <el-button
              v-if="!post.likes.has_liked"
              v-loading="likeablePostLoad"
              :disabled="likeablePostLoad"
              size="small"
              class="clickable svg-like like"
              @click="likeablePost('like')"
            >
              <svg-like></svg-like> <small>Like</small>
            </el-button>
            <el-button
              v-else-if="post.likes.has_liked"
              v-loading="likeablePostLoad"
              :disabled="likeablePostLoad"
              type="primary"
              size="small"
              class="clickable svg-like unlike"
              @click="likeablePost('unlike')"
            >
              <svg-unlike />
              <small>Liked</small>
            </el-button>
          </el-col>
        </el-row>
        <!-- <el-row
          v-if="commentsMeta.pagination"
          class="mt-4"
        >
          <el-col
            class="d-flex"
            :span="12"
          >
            <svg-message-dialog
              class="clickable"
              @click.native="replying = !replying"
            />
            <span
              class="ml-2 clickable"
              @click="replying = !replying"
            >{{
              commentsMeta.pagination.total | formatRepliesCount
            }}</span>
          </el-col>
          <el-col
            :span="12"
            class="text-right"
          >
            <a
              v-if="!replying"
              class="gs-link clickable fs-19"
              tabindex="0"
              @click="replying = true"
              @keyup.enter="replying = true"
            >
              Reply
              <svg-arrow-right class="ml-2"></svg-arrow-right>
            </a>

            <a
              v-if="replying"
              tabindex="0"
              class="clickable fs-19"
              @click="replying = false"
              @keyup.enter="replying = false"
            >&times;</a>
          </el-col>
          <el-col
            class="mt-2"
            :span="24"
          >
            <pulse-comment-form
              v-if="replying && !post.author.suspended"
              :reply-to-id="post.id"
              :module="'post'"
              :entity="'post'"
              @replied="repliedToPost"
            ></pulse-comment-form>
            <pulse-comment
              v-for="(comment, index) in comments"
              v-if="replying"
              :key="comment.id"
              :comment="comment"
              @replied="repliedToPost"
              @commentDeleted="fetchComments"
              @commentUpdated="fetchComments"
            ></pulse-comment>
          </el-col>
          <el-col
            v-if="replying && nextLink"
            class="mt-4 text-center"
            :span="24"
          >
            <hr class="d-block" />
            <a
              class="fs-19 gs-link mt-3 d-inline-block clickable"
              @click="showMore"
            >Show more</a>
          </el-col>
        </el-row> -->
      </div>
      <focus-lock>
        <el-dialog
          ref="confirmDeleteDialog"
          :show-close="false"
          custom-class="confirm-dialog"
          :visible.sync="showPulseDeleteConfirmDialog"
          title="Warning.Are you sure you want to delete this post?"
          @opened="dialogOpen('confirmDeleteDialog')"
        >
          <el-row>
            <el-col :span="24" class="text-center">
              <h1 class="mt-0">Warning</h1>
              <p class="fs-19">Are you sure you want to delete this post?</p>
            </el-col>
          </el-row>
          <span slot="footer" class="dialog-footer d-flex justify-content-end">
            <el-button
              type="secondary"
              @click="showPulseDeleteConfirmDialog = false"
              >Cancel</el-button
            >
            <el-button
              v-loading.lock="blockToDelete"
              type="primary"
              element-loading-background="rgba(255, 255, 255, 0.8)"
              :disabled="blockToDelete"
              @click="deletePost"
              >Yes, delete post</el-button
            >
          </span>
        </el-dialog>
      </focus-lock>
    </el-card>
  </article>
</template>
<script>
import UserAvatar from "@/components/core/user-avatar";
import PulseReportForm from "@/components/platform/pulse/pulse-report-form";
import PulseComment from "@/components/platform/pulse/pulse-comment";
import PulseCommentForm from "@/components/platform/pulse/pulse-comment-form";
import SvgDownloadFile from "@/components/assets/svg-download-file";
import SvgMessageDialog from "@/components/assets/svg-chat-purple";
import SvgArrowRight from "@/components/assets/svg-arrow-right";
import SvgThreeDots from "@/components/assets/svg-three-dots";
import SvgLike from "@/components/assets/svg-like";
import SvgUnlike from "@/components/assets/svg-unlike";
import SvgLikesCounter from "@/components/assets/svg-likes-counter";
import AuthorCard from "./author-card";
import gsApi from "@/services/gs.api";
import _ from "lodash";
import filesize from "filesize";
import { mapGetters } from "vuex";
import DynamicLink from "./dynamic-link.vue";
import helpers from "@/helpers/index";
import SvgCloseGrayBgWhiteBorder from "@/components/assets/svg-close-gray-bg-white-border";

export default {
  name: "PulsePost",
  components: {
    PulseComment,
    PulseCommentForm,
    SvgDownloadFile,
    SvgMessageDialog,
    SvgArrowRight,
    SvgThreeDots,
    SvgLike,
    SvgUnlike,
    SvgLikesCounter,
    PulseReportForm,
    AuthorCard,
    DynamicLink,
    UserAvatar,
    SvgCloseGrayBgWhiteBorder,
  },
  filters: {
    formatRepliesCount(count) {
      if (parseInt(count) === 1) {
        return count + " reply";
      }

      return count + " replies";
    },
  },
  props: ["post", "openReplyForPost", "index", "pulsepostId"],
  data() {
    return {
      helpers,
      likeablePostLoad: false,
      showPulseDeleteConfirmDialog: false,
      comments: [],
      commentsMeta: {},
      replying: false,
      nextLink: null,
      listActionShown: false,
      blockToDelete: false,
      submittingPostReport: false,
      isOpen: false,
      loadingUser: false,
      postAuthor: null,
    };
  },
  computed: {
    ...mapGetters(["user"]),
    tags() {
      return _.shuffle(
        _.concat(this.post.sectors.data, this.post.expertise.data)
      );
    },
  },
  watch: {
    pulsepostId: function (newValue, oldValue) {
      if (newValue != oldValue) {
        //this.fetchComments();
      }
    },
  },
  mounted() {
    this.openReplyForPostIdSelected();
    //this.fetchComments();
  },
  methods: {
    repliedToPost() {
      //this.fetchComments();
      if (this.pulsepostId) {
        this.$root.$emit("POST_COMMENT_RELOAD", this.post);
      }
    },
    getReadableFilesize(sizeInBytes) {
      return filesize(parseInt(sizeInBytes), { base: 10 });
    },
    fetchComments() {
      gsApi.comment
        .index({
          module: "Post",
          entity: "Post",
          id: this.post.id,
          order_by: "commentable__commentables.created_at",
          order: "desc",
          per_page: 10,
        })
        .then((response) => {
          this.comments = response.data.data;
          this.commentsMeta = response.data.meta;
          if (response.data.meta.pagination.links) {
            this.nextLink = response.data.meta.pagination.links.next;
          }
        });
    },
    showMore() {
      if (this.nextLink !== null) {
        gsApi.comment.index({}, this.nextLink).then((response) => {
          this.comments = this.comments.concat(response.data.data);
          this.commentsMeta = response.data.meta;
          if (response.data.meta.pagination.links) {
            this.nextLink = response.data.meta.pagination.links.next;
          }
          if (
            response.data.meta.pagination.current_page ===
            response.data.meta.pagination.total_pages
          ) {
            this.nextLink = null;
          }
        });
      }
    },
    openReplyForPostIdSelected() {
      if (this.openReplyForPost === this.post.id) {
        this.replying = true;
        let post = document.getElementById(`post-${this.openReplyForPost}`);
        post.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    },
    editPost(post) {
      this.$emit("editPost", post);
    },
    deletePost() {
      this.blockToDelete = true;

      gsApi.post
        .delete(this.post.id)
        .then((response) => {
          this.$emit("deletePost", this.index);
          this.showPulseDeleteConfirmDialog = false;
          this.blockToDelete = false;
          this.$alert("You successfully deleted your post", "Success", {
            confirmButtonText: "Close",
            showClose: false,
            customClass: "notification-modal",
          });
        })
        .catch((error) => {
          this.blockToDelete = false;

          this.$alert(
            "An unknown error occurred. Please try again later.",
            "Error",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal",
            }
          );
        });
    },
    reportPost() {
      this.submittingPostReport = true;
    },
    getListActionStatus(value) {
      this.listActionShown = value;
    },
    fetchUserData() {
      if (this.postAuthor) return;
      this.loadingUser = true;
      gsApi.users
        .entityAuthor(this.post.author.id)
        .then((response) => {
          this.postAuthor = response.data;
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.status == 422 &&
            error.response.data
          ) {
            let firstError =
              error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ];
            this.$alert(
              "Sorry, your request could not be processed",
              firstError[0],
              {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal",
              }
            );
          } else {
            this.$alert(
              "An unknown error occurred. Please try again later.",
              "Error",
              {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal",
              }
            );
          }
        })
        .finally(() => {
          this.loadingUser = false;
        });
    },
    likeablePost(action) {
      this.likeablePostLoad = true;
      let data = {
        module: "Post",
        entity: "Post",
        id: this.post.id,
      };
      gsApi.likeable[action](data)
        .then((response) => {
          this.post.likes = response.data;
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.status == 422 &&
            error.response.data
          ) {
            let firstError =
              error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ];
            this.$alert(
              "Sorry, your request could not be processed",
              firstError[0],
              {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal",
              }
            );
          } else {
            this.$alert(
              "An unknown error occurred. Please try again later.",
              "Error",
              {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal",
              }
            );
          }
        })
        .finally(() => {
          this.likeablePostLoad = false;
        });
    },
    closePreview(item) {
      this.post.links.data = this.post.links.data.filter((i) => i !== item);
    },
  },
};
</script>
<style lang="scss">
.author-card-popover {
  height: max-content;
  &.el-popover {
    min-height: 140px;
    padding: 0;
  }
  .author-card-popover-loader {
    width: 100%;
    height: max-content;
    min-height: 138px;
  }
}

.link-preview {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  .linkified {
    word-break: break-all;
    text-decoration: underline;
  }
  .preview-card {
    margin-top: 0.75rem;
    position: relative;
  }
  .preview-close-btn {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 0.625rem;
    margin-right: 0.625rem;
    background: transparent;
  }
  .preview-link-image {
    width: 100%;
    max-height: 227px;
    object-fit: cover;
  }
  .preview-info {
    background: #eaeaea;
    min-height: 118px;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .preview-url-text {
    word-break: break-all;
    font-style: normal;
    font-weight: 390;
    font-size: $font-size-19;
    line-height: $line-height-28;
    color: #585857;
  }
  .preview-url-title {
    font-style: normal;
    font-weight: 420;
    font-size: $font-size-19;
    line-height: $line-height-42;
    word-break: break-word;
    color: #000000;
  }
}
</style>
