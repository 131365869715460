<template>
  <div class="forgot-password-page page">
    <header class="forgot-password-page__header">
      <div class="header__content__bg"></div>
      <div
        class="header__content container d-flex flex-column align-items-stretch justify-content-center"
      >
        <div class="row">
          <div class="col-md-24 col-lg-12">
            <div
              class="header__content__wrapper d-flex flex-column align-items-start justify-content-center"
            >
              <h1 class="header__title">Reset Password</h1>
            </div>
          </div>
        </div>
      </div>
    </header>

    <main class="forgot-password-page__body">
      <div class="container">
        <div class="row">
          <div class="col-md-24 col-lg-12">
            <validation-errors
              v-if="validationErrors.length"
              :errors="validationErrors"
            ></validation-errors>
            <el-form
              :model="resetForm"
              :rules="rules"
              id="resetForm"
              ref="resetForm"
            >
              <div class="body__content">
                <h1 class="body__title">Enter your new password</h1>

                <el-form-item label="New Password" prop="new_password">
                  <el-input
                    type="password"
                    v-model="resetForm.new_password"
                    placeholder="Your new password"
                    label="Please enter your new password in this field"
                    aria-required="true"
                  ></el-input>
                </el-form-item>

                <el-form-item
                  label="Confirm new password"
                  prop="confirmPassword"
                >
                  <el-input
                    type="password"
                    v-model="resetForm.confirmPassword"
                    placeholder="Confirm new password"
                    label="Please confirm your new password in this field"
                    aria-required="true"
                  ></el-input>
                </el-form-item>

                <div class="send-button-wrapper">
                  <el-button
                    type="primary"
                    native-type="submit"
                    id="submit"
                    @click.prevent="submitForm('resetForm')"
                    :loading="isLoading"
                    class="send-button"
                  >
                    Submit
                  </el-button>
                </div>
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import store from "@/store";
import gsApi from "@/services/gs.api";
import validationErrors from "@/components/partials/validation-errors";
import {mapGetters} from 'vuex';
export default {
  name: "reset-password",
  metaInfo: {
    title: "Reset password"
  },
  components: {
    validationErrors
  },
  computed:{
    ...mapGetters(["user"]),
  },
  data() {
    let validatePassword = (rule, value, callback) => {
      let reg = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])[a-zA-Z0-9#?!@$%^&*-]{8,}$/;
      if (value === "") {
        callback(new Error("Enter a new password"));
      } else if (reg.test(value) == false) {
        callback(
          new Error(
            "Enter a valid new password. It must be more than 8 characters long and should contain at least 1 uppercase, 1 lowercase, 1 numeric and 1 special character (#?!@$%^&*-)"
          )
        );
      } else {
        callback();
      }
    };

    let validatePasswordConfirmation = (rule, value, callback) => {
      if (value === "" || value !== this.resetForm.new_password) {
        callback(
          new Error(
            "This doesn't match the new password you entered. Try confirming your new password again."
          )
        );
      } else {
        callback();
      }
    };
    return {
      resetForm: {
        new_password: "",
        confirmPassword: ""
      },
      rules: {
        new_password: [{ validator: validatePassword, required: true }],
        confirmPassword: [
          { validator: validatePasswordConfirmation, required: true }
        ]
      },
      isLoading: false,
      validationErrors: []
    };
  },
  methods: {
    submitForm(formName) {
      this.isLoading = true;
      this.$refs[formName].validate(valid => {
        if (valid) {
          gsApi.auth
            .reset(
              {
                password: this.resetForm.new_password,
                password_confirmation: this.resetForm.confirmPassword
              },
              this.$route.params.id,
              this.$route.params.code
            )
            .then(response => {
              this.isLoading = false;
              this.validationErrors = [];

              this.$alert(
                "Password has been reset. You can now login with your new password",
                "Password has been reset",
                {
                  confirmButtonText: "Close",
                  showClose: false,
                  customClass: "notification-modal"
                }
              );
              this.$router.push("/login");
            })
            .catch(error => {
              this.validationErrors = [];
              this.isLoading = false;

              if (error.response && error.response.data) {
                if (error.response.status == 422) {
                  let errors = error.response.data.errors;
                  for (let [key, value] of Object.entries(errors)) {
                    let obj = {};
                    obj.id = key;
                    obj.message = value[0];
                    this.validationErrors.push(obj);
                  }
                } else if (
                  error.response.status == 403 &&
                  error.response.data.error
                ) {
                  this.$alert(error.response.data.error.message, "Warning", {
                    confirmButtonText: "Close",
                    showClose: false,
                    customClass: "notification-modal"
                  });
                } else {
                  this.$alert(
                    "An unknown error occurred. Please try again later.",
                    "Error",
                    {
                      confirmButtonText: "Close",
                      showClose: false,
                      customClass: "notification-modal"
                    }
                  );
                }
                window.scrollTo({ top: 0, behavior: "smooth" });
              } else {
                this.$alert(
                  "An unknown error occurred. Please try again later.",
                  "Error",
                  {
                    confirmButtonText: "Close",
                    showClose: false,
                    customClass: "notification-modal"
                  }
                );
              }
            });
        } else {
          this.isLoading = false;
          this.validationErrors = [];
          this.$refs.resetForm.fields.forEach(field => {
            if (field.validateState == "error") {
              let obj = {};
              obj.id = field.labelFor;
              obj.message = field.validateMessage;
              this.validationErrors.push(obj);
            }
          });
          this.$nextTick(() => {
            document
              .getElementById("formErrorBlock")
              .scrollIntoView({ behavior: "smooth", block: "start" });
            let text = document
              .getElementById("resetForm")
              .getElementsByClassName("el-form-item__error")[0].textContent;
            this.$announcer.set(text);
          });
        }
      });
    },
    logout() {
      gsApi.auth.logout(this.user.token).then(() => {
        store.commit("USER_LOGOUT");
      });
    }
  },
  created() {
    if (this.user.isLogged) {
      this.logout();
    }
  }
};
</script>
