<template>
  <section
    class="white-background-section"
    aria-label="What Scotland House Brussels do"
  >
    <div class="container">
      <el-row>
        <div class="involve-heading-text mt-5">
          <div class="involve-data">
            <div>
              <el-col
                class
                :md="24"
                :lg="24"
              >
                <div class="left-text">
                  <h2 class="involve-title">
                    {{ text.heading }}
                  </h2>
                  <p
                    class="involve-sub-text first-sub"
                    v-html="text.sub_1"
                  ></p>
                  <p class="involve-sub-text">
                    {{ text.listStart }}
                  </p>
                  <div class>
                    <ul class="involve-list">
                      <li
                        v-for="point in bulletPoints"
                        :key="point.text"
                      >
                        {{ point.text }}
                      </li>
                    </ul>
                  </div>
                </div>
              </el-col>
            </div>
          </div>
        </div>
      </el-row>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      text: {
        heading: "What we do",
        sub_1: `Scotland has had a long-established presence in Brussels, including 20 years in Scotland House Brussels at Rond-Point Schuman. In line with the <a href='https://www.gov.scot/policies/europe/innovation-and-investment-hubs/' target='_blank'>Scottish Government’s newly-established network <span class="sr-only">this link will open in a new window</span><svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" class="ml-1">
                <path d="M6.5 3.69189H0.5V15.6919H12.5V9.72217" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3.5 12.707L15.5 0.707031" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.5 0.707031H15.5V6.70703" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                </svg></a> across the EU, we have renewed our own Scotland House Brussels to provide us with an even more effective platform to enhance our commitment to playing an active role in Europe and support our collaborative approach to working with Scottish, EU and International stakeholders.`,
        listStart: "The partners of Scotland House Brussels are:"
      },
      bulletPoints: [
        { text: "Scotland Europa" },
        { text: "The Scottish Government" },
        { text: "Scottish Enterprise" },
        { text: "Scotland House Brussels has well established teams covering diplomatic engagement, economic development, and cultural promotion."},
      ]
    };
  }
};
</script>

<style>
.invlove-img {
/*  margin-bottom: 50px;*/
}
.left-text {
  margin-bottom: 50px;
}
</style>
