<template>
  <focus-lock>
    <el-form
      ref="form"
      class="submit-post position-relative"
      :model="form"
      label-width="120px"
    >
      <el-card
        v-loading.lock="loading"
        class="box-card"
        element-loading-background="rgba(255, 255, 255, 0.8)"
      >
        <div
          slot="header"
          class="clearfix "
        >
          <span class="fs-24 text-white">{{ editMode ? "Edit Post" : "Create Post" }}</span>
          <a
            id="pulse-form-close"
            href="#"
            class="pull-right"
          >
            <strong
              class="fs-24 text-white clickable"
              @click="confirmCloseForm"
            >&#10799;</strong>
          </a>
        </div>
        <el-row>
          <el-col
            :span="24"
            class="px-2 py-2"
          >
            <pulse-editor
              v-model="form.content"
              placeholder="Ask a question, start a discussion or share something with the network"
              @characters="updateCharCount"
            ></pulse-editor>
            <span class="float-right">{{ charCount }} / {{ maxChars }}</span>
          </el-col>
        </el-row>
        <el-row v-if="errors.content.length">
          <el-col
            :span="24"
            class="text-right mt-2"
          >
            <p
              v-for="(error, index) in errors.content"
              :key="index"
              class="text-danger"
            >
              {{ error }}
              <span
                aria-live="polite"
                class="sr-only"
              >{{ error }}</span>
            </p>
          </el-col>
        </el-row>
        <el-row class="mt-3">
          <el-col :span="24">
            <hr />
          </el-col>
        </el-row>
        <el-row>
          <el-col
            :span="15"
            class="d-flex mt-3 clickable"
          >
            <svg-plus
              v-show="!addingTags"
              @click.native="addingTags = true"
            ></svg-plus>
            <svg-substract
              v-show="addingTags"
              @click.native="addingTags = false"
            ></svg-substract>
            <span
              tabindex="0"
              class="ml-4 fs-19"
              @click="addingTags = !addingTags"
              @keyup.enter="addingTags = !addingTags"
            >Add tags</span>
          </el-col>
        </el-row>
        <el-row
          v-show="addingTags"
          class="mt-3"
        >
          <el-col :span="24">
            <p class="fs-20">
              <strong>Update types</strong>
            </p>
            <el-checkbox-group
              v-model="form.sectors"
              class="mt-4"
            >
              <el-row>
                <el-col
                  v-for="(update_type, index) in updateTypes"
                  :key="index"
                  class="mb-3"
                  :span="12"
                >
                  <el-checkbox :label="update_type.id">
                    {{ update_type.name }}
                  </el-checkbox>
                </el-col>
              </el-row>
            </el-checkbox-group>
            <p class="fs-20">
              <strong>Topics</strong>
            </p>
            <el-checkbox-group
              v-model="form.expertise"
              class="mt-4"
            >
              <el-row>
                <el-col
                  v-for="(topic, index) in topics"
                  :key="index"
                  class="mb-3"
                  :span="12"
                >
                  <el-checkbox :label="topic.id">
                    {{ topic.name }}
                  </el-checkbox>
                </el-col>
              </el-row>
            </el-checkbox-group>
          </el-col>
          <el-col :span="24">
            <a
              v-for="(tag, index) in selectedTags"
              :key="index"
              href="#"
              class="pulse-option active mt-1 d-inline-block px-2 rounded"
            >
              Chemicals
            </a>
          </el-col>
        </el-row>
        <el-row class="mt-3">
          <el-col :span="24">
            <hr />
          </el-col>
        </el-row>
        <el-row>
          <el-col
            v-if="attachedFile.id"
            :xs="24"
            :sm="24"
            :md="24"
            :lg="24"
            class="mt-2"
          >
            <div
              class="attached-document clickable d-inline-block p-2 rounded w-100"
            >
              <div class="d-flex align-items-center">
                <div class="document-info ml-sm-3 w-100">
                  <div class="download align-items-center position-relative">
                    <a
                      class="gs-link fs-19"
                      :href="attachedFile.medium_thumb"
                      download
                    >
                      {{ attachedFile.filename }}
                    </a>
                    <p
                      class="d-inline-block fs-20 float-right"
                      @click="removeCurrentFile(enumHelper.FileTypeEnum.FILE)"
                    >
                      &times;
                    </p>
                  </div>
                <!-- <p>{{ attachedFile.media_type.toUpperCase() }}</p> -->
                </div>
              </div>
            </div>
          </el-col>
          <el-col
            v-if="attachedDocument.id"
            :xs="24"
            :sm="24"
            :md="24"
            :lg="24"
            class="mt-2"
          >
            <div
              class="attached-document clickable d-inline-block p-2 rounded w-100"
            >
              <div class="d-flex align-items-center">
                <div class="document-info ml-sm-3 w-100">
                  <div class="download align-items-center position-relative">
                    <a
                      class="gs-link fs-19"
                      :href="attachedDocument.medium_thumb"
                      download
                    >
                      {{ attachedDocument.filename }}
                    </a>
                    <p
                      class="d-inline-block fs-20 float-right"
                      @click="removeCurrentFile(enumHelper.FileTypeEnum.DOCUMENT)"
                    >
                      &times;
                    </p>
                  </div>
                <!-- <p>{{ attachedFile.media_type.toUpperCase() }}</p> -->
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row class="mt-4 mt-sm-3">
          <el-col
            element-loading-background="rgba(255, 255, 255, 0.8)"
            :xs="24"
            :sm="16"
            class="d-flex"
          >
            <el-upload
              v-if="!attachedFile.id"
              ref="upload"
              :disabled="loadingMedia"
              action=""
              :http-request="onFileUpload"
              :multiple="false"
              list-type="picture-card"
              class="selected-upload"
              :auto-upload="true"
            >
              <div
                class="add-media clickable d-flex align-items-center justify-content-center"
              >
                <div class="pulse-add-media-link">
                  <svg-add-media class="w-30-px"></svg-add-media>
                  <span class="gs-link ml-2">Photo/Video</span>
                </div>
              </div>
            </el-upload>
            <el-upload
              v-if="!attachedDocument.id"
              ref="upload"
              :disabled="loadingMedia"
              action=""
              :http-request="onDocumentUpload"
              :multiple="false"
              list-type="picture-card"
              class="selected-upload ml-3"
              :auto-upload="true"
            >
              <div
                class="add-media clickable d-flex align-items-center justify-content-center"
              >
                <div class="pulse-add-media-link">
                  <svg-add-media class="w-30-px"></svg-add-media>
                  <span class="gs-link ml-2">Document</span>
                </div>
              </div>
            </el-upload>
          </el-col>
          <el-col
            class="text-right pull-right"
            :xs="24"
            :sm="8"
          >
            <el-button
              :disabled="loadingMedia"
              plain
              type="primary px-5 py-2 d-inline min-height-initial w-100 w-sm-auto mt-4 mb-3 mb-sm-0 mt-sm-0"
              @click.prevent="submitPost"
            >
              <p>Post</p>
            </el-button>
          </el-col>
        </el-row>
        <el-row v-if="loadingMedia">
          <el-col
            :xs="24"
            :sm="12"
            :md="12"
            :lg="12"
          >
            <el-progress
              :percentage="percentage"
              color="#A8308C"
            ></el-progress>
          </el-col>
        </el-row>
        <el-row v-if="errors.media.length">
          <el-col
            :span="24"
            class="text-right mt-2"
          >
            <p
              v-for="(error, index) in errors.media"
              :key="index"
              class="text-danger"
            >
              {{ error }}
            </p>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
  </focus-lock>
</template>

<script>
//TODO: loading state, validation
import _ from "lodash";
import store from "@/store";
import gsApi from "@/services/gs.api";
import SvgPlus from "@/components/assets/svg-plus";
import SvgAddMedia from "@/components/assets/svg-add-media";
import SvgSubstract from "@/components/assets/svg-substract";
import PulseEditor from "@/components/platform/pulse/pulse-editor";
import enumHelper from '@/helpers/enumHelper';
import Trap from 'vue-focus-lock';
import FocusLock from '../../common/FocusLock.vue';
const ALLOWED_DOCUMENT_TYPES = [
  "application/pdf",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.oasis.opendocument.presentation",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.oasis.opendocument.text",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.oasis.opendocument.spreadsheet"
];
const ALLOWED_MEDIA_TYPES = [
  "image/jpeg",
  "image/png",
  "video/mpeg",
  "video/webm",
  "video/mp4",
  "video/ogg"
];
const MAX_FILE_SIZE = 200 * 1024 * 1024; // 200mb

export default {
  name: "PulseForm",
  components: {
    SvgAddMedia,
    SvgPlus,
    SvgSubstract,
    PulseEditor,
    'Trap' : Trap
  },
  props: [
    //"categories",
    "updateTypes",
    "topics",
    "submittingPost",
    "editMode",
    "post"
  ],
  data() {
    return {
      percentage:1,
      enumHelper,
      isMounted: false,
      loadingMedia: false,
      loading: false,
      addingTags: false,
      form: {
        //category_id: null,
        content: "",
        sectors: [],
        expertise: [],
        media_file_id: null,
        document_file_id: null,
        hashtags: [],
        links: [],
      },
      errors: {
        //category_id: [],
        content: [],
        media: []
      },
      attachedFile: {},
      attachedDocument: {},
      tributeOptions: {
        values: (text, cb) => {
          this.searchUsers(text, users => cb(users));
        },
        selectTemplate: item => {
          return "@" + item.original.value;
        }
      },
      tribute: null,
      selectedTags: [],
      maxChars: 1500,
      charCount: 0
    };
  },
  mounted() {
    document.getElementById("pulse-form-close").focus();
  },
  created() {
    if (this.editMode) {
      this.initalizeForm();
    }
  },
  /*computed: {
    computedCategories() {
      return _.filter(this.categories, n => {
        return n.id !== 0;
      });
    }
  },*/
  methods: {
    updateCharCount(value) {
      this.charCount = value;

      if (this.charCount > this.maxChars) {
        this.errors.content = [
          "Content must be less than or equal to " +
            this.maxChars +
            " characters long."
        ];
      } else {
        this.errors.content = [];
      }
    },
    searchUsers(text, cb) {
      gsApi.members
        .search({
          search: text,
          per_page: 4
        })
        .then(response => {
          let users = [];
          for (var i = response.data.data.length - 1; i >= 0; i--) {
            let user = response.data.data[i];
            users.push({
              key: user.full_name,
              value: user.full_name
            });
          }
          cb(users);
        });
    },
    onFileUpload(request) {

      this.errors.media = [];

      if (!this.validateMediaUpload(request.file)) return;

      this.percentage = 1;
      this.loadingMedia = true;
      let interval = 0;

      const formData = new FormData();
      formData.append("file", request.file);

      gsApi.post.media
        .create(formData,(progress) => {
          interval = setInterval(()=>{
            if(this.percentage < 100){
              this.percentage+= 1;
            }
          },15 )
        })
        .then(response => {
          this.attachedFile = response.data.data;
          this.form.media_file_id = response.data.data.id;
        })
        .catch(error => {
          let message = "Something went wrong. Please try again later.";

          if (error.code === "ECONNABORTED") {
            (message =
              "The upload took too long at the current time, please try again later."),
            "Error";
          } else if (error.status == 422) {
            let errorData = error.data.errors;
            let errors = [];
            for (let item in errorData) {
              errors.push(errorData[item][0]);
            }
            message = errors.join(" ");
          } else if (error.status == 413) {
            message = `File is too large. Must be below 200MB!`;
          }

          this.$alert(message, "Error", {
            confirmButtonText: "Close",
            showClose: false,
            customClass: "notification-modal"
          });
        })
        .finally(() => {
          this.loadingMedia = false;
          clearInterval(interval);
        });
    },
    onDocumentUpload(request) {

      this.errors.media = [];

      if (!this.validateDocumentUpload(request.file)) return;

      this.loadingMedia = true;
      let interval = 0;
      this.percentage = 1;


      const formData = new FormData();
      formData.append("file", request.file);

      gsApi.post.media
        .create(formData,(progress) => {
          interval = setInterval(()=>{
            if(this.percentage < 100){
              this.percentage+= 1;
            }
          },15 )
        })
        .then(response => {
          this.attachedDocument = response.data.data;
          this.form.document_file_id = response.data.data.id;
        })
        .catch(error => {
          let message = "Something went wrong. Please try again later.";

          if (error.code === "ECONNABORTED") {
            (message =
              "The upload took too long at the current time, please try again later."),
            "Error";
          } else if (error.status == 422) {
            let errorData = error.data.errors;
            let errors = [];
            for (let item in errorData) {
              errors.push(errorData[item][0]);
            }
            message = errors.join(" ");
          } else if (error.status == 413) {
            message = `File is too large. Must be below 200MB!`;
          }

          this.$alert(message, "Error", {
            confirmButtonText: "Close",
            showClose: false,
            customClass: "notification-modal"
          });
        })
        .finally(() => {
          this.loadingMedia = false;
          clearInterval(interval);
        });
    },
    validateDocumentUpload(file) {
      if (ALLOWED_DOCUMENT_TYPES.indexOf(file.type) == -1) {
        this.errors.media = [
          "The file must be a file of type: pdf, ppt, pptx, doc, docx, xls, xlsx, odt, odp, ods."
        ];
        return false;
      }
      if (MAX_FILE_SIZE < file.size) {
        this.errors.media = ["File size must be less than 200MB."];
        return false;
      }
      return true;
    },
    validateMediaUpload(file) {
      if (ALLOWED_MEDIA_TYPES.indexOf(file.type) == -1) {
        this.errors.media = [
          "The file must be a file of type: jpg, png, jpeg, mp4, ogv, webm"
        ];
        return false;
      }
      if (MAX_FILE_SIZE < file.size) {
        this.errors.media = ["File size must be less than 200MB."];
        return false;
      }
      return true;
    },
    validateForm() {
      let passesValidation = true;

      /*if (!this.form.category_id) {
        this.errors.category_id = [
          "Please select a category from the options."
        ];
        passesValidation = false;
      } else {
        this.errors.category_id = [];
      }*/

      if (this.charCount > this.maxChars) {
        this.errors.content = [
          "Content must be less than or equal to " +
            this.maxChars +
            " characters long."
        ];
        passesValidation = false;
      }

      if (!this.form.content) {
        this.errors.content = ["The content field is required."];
        passesValidation = false;
      }

      return passesValidation;
    },

    submitPost() {
      if (!this.validateForm()) return;

      this.form.hashtags = this.extractHashtags();
      this.form.links = this.extractLinks();
      this.form.content = this.$options.filters.domPurify(this.form.content, {ALLOWED_TAGS: []})
      
      this.loading = true;
      if (this.editMode) {
        gsApi.post
          .update(this.form.id, this.form)
          .then(response => {
            setTimeout(() => {
              this.closeForm();
              this.loading = false;
              this.submitted();
              window.scrollTo(0, 0);
            }, 500);
          })
          .catch(error => {
            this.closeForm();
            this.loading = false;
            this.$alert(
              "An unknown error occurred. Please try again later.",
              "Error",
              {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal"
              }
            );
          });
      } else {
        gsApi.post
          .create(this.form)
          .then(response => {
            setTimeout(() => {
              this.closeForm();
              this.loading = false;
              this.submitted();
              window.scrollTo(0, 0);
            }, 500);
          })
          .catch(error => {
            this.closeForm();
            this.loading = false;
            this.$alert(
              "An unknown error occurred. Please try again later.",
              "Error",
              {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal"
              }
            );
          });
      }
    },
    confirmCloseForm() {
      if (
        this.form.content ||
        //this.form.category_id ||
        this.form.expertise.length ||
        this.form.sectors.length
      ) {
        this.$confirm(
          " Are you sure you want to cancel this post?",
          "Warning",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning"
          }
        )
          .then(() => {
            this.closeForm();
          })
          .catch(() => {});
      } else {
        this.closeForm();
      }
    },
    closeForm() {
      this.$emit("closing");
    },
    submitted() {
      this.$emit("submitted");
    },
    removeCurrentFile(type) {
      this.$confirm(" Are you sure you want to remove this file?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning"
      })
        .then(() => {
          if(type == enumHelper.FileTypeEnum.FILE){
            (this.form.media_file_id = null),(this.attachedFile = {});
          } else if (type == enumHelper.FileTypeEnum.DOCUMENT){
            (this.form.document_file_id = null),(this.attachedDocument = {});
          }
        })
        .catch(() => {});
    },
    initalizeForm() {
      this.form = {
        id: this.post.id,
        //category_id: this.post.category ? this.post.category.id : 0,
        content: this.post.content,
        sectors: this.post.sectors.data
          ? this.post.sectors.data.map(x => x.id)
          : [],
        expertise: this.post.expertise.data
          ? this.post.expertise.data.map(x => x.id)
          : [],
        media_file_id: this.post.mediaFile ? this.post.mediaFile.id : null,
        document_file_id: this.post.documentFile? this.post.documentFile.id: null
      };
      if (this.post.mediaFile) {
        this.attachedFile = {
          id: this.post.mediaFile.id,
          medium_thumb: this.post.mediaFile.medium_thumb,
          filename: this.post.mediaFile.original_name
        };
      }
      if (this.post.documentFile) {
        //--- include document information
        this.attachedDocument = {
          id: this.post.documentFile.id,
          medium_thumb: this.post.documentFile.medium_thumb,
          filename: this.post.documentFile.original_name
        };
      }
      this.isMounted = true;
    },
    extractHashtags() {
      const regex = /data-hashtag="(?:|\s)#([a-zA-Z\d]+)"/g;
      const array = [...this.form.content.matchAll(regex)];
      const hashtags = array.map(i => `#${i[1]}`);
      return [...new Set(hashtags)];
    },
    extractLinks() {
      const regex = /((http|https):\/\/)?[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/gi;
      const matched = [...this.form.content.matchAll(regex)];
      const links = matched.map(i => {
        /*if (i[0].startsWith('https://www.')) {
          i[0] = i[0].replace('https://www.', '');
        }

        if (i[0].startsWith('https://')) {
          i[0] = i[0].replace('https://', "");
        }

        if (i[0].startsWith('www.')) {
          i[0] = i[0].replace('www.', "");
        }*/

        if (i[0].includes('&nbsp;')) {
          i[0] = i[0].replace('&nbsp;', "");
        }

        return i[0]; //`https://${i[0]}`;
      });

      return [...new Set(links)].slice(0, 3);
    },
  }
};
</script>
