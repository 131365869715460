<template>
  <section
    class="footer-join"
    aria-label="ready to join"
  >
    <div class="container">
      <el-row>
        <el-col
          :xl="24"
          :lg="24"
          :md="24"
          :sm="24"
          :xs="24"
          class="head"
        >
          <h1 class="text-white footer-text">
            {{ footerText.heading }}
          </h1>
        </el-col>
        <el-col
          :xl="24"
          :lg="24"
          :md="24"
          :sm="24"
          :xs="24"
        >
          <p class="content-std text-white footer-text join-sub-text">
            {{ footerText.sub }}
          </p>
        </el-col>
        <el-button
          class="join-btn-bottom"
          type="primary"
          @click="$router.push({ name: 'Signup Select Type' })"
        >
          Join Scotland Europa
        </el-button>
        <p class="text-white footer-text">
          Or call <a
            class="call-link"
            href="tel:00 32 2 282 8304"
          > 00 32 2 282 8304 </a> (Mon - Fri 8:30-5:30pm)
        </p>
      </el-row>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      footerText: {
        heading: "Ready to join?",
        sub:
          "It doesn’t matter how big or small your business is – as long as you have ambitions to grow and trade internationally, the Scotland Europa network can help."
      }
    };
  }
};
</script>
<style>
.footer-text {
  text-align: center;
  margin-top: 30px;
}
h1 {
  font-weight: 400;
}
.footer-text {
  padding-top: 10px;
  padding-bottom: 10px;
}
.join-btn-bottom {
  margin: auto;
}
.give-feedback p {
  color: white;
  margin-left: 10px;
  /* float: left; */
  text-decoration: underline;
}
.give-feedback {
  text-align: center;
  padding-top: 45px;
}
</style>
