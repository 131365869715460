<template>
  <focus-lock>
    <el-dialog
      ref="pulseReportFormDialog"
      v-loading.lock="loading"
      custom-class="report-modal new-ui"
      :before-close="onClose"
      :visible.sync="visible"
      :title="title"
      :close-on-click-modal="false"
      :append-to-body="appendToBody"
      @opened="dialogOpen('pulseReportFormDialog')"
    >
      <el-row>
        <el-col :md="24">
          <p class="title">
            Are you sure you want to report this post ?
          </p>
        </el-col>
      </el-row>
      <hr class="mt-3 mb-3" />
      <el-row>
        <el-col :md="24">
          <p class="description">
            We'll review it first so it won't be automatically removed. Only the
            Scotland Europa admin team will see who reported the post and the reason.
          </p>
        </el-col>
      </el-row>
      <el-row>
        <el-col
          :md="24"
          :lg="24"
          class="mb-3"
        >
          <p class="description"></p>
        </el-col>
        <el-col
          :md="24"
          :lg="24"
        >
          <el-form
            id="formReport"
            ref="formReport"
            :model="reportForm"
            :rules="rules"
            autocomplete="false"
            @submit.native.prevent
          >
            <el-form-item prop="reason_id">
              <template slot="label">
                <slot name="reason-label">
                  Tell us why you want to report this post
                </slot>
              </template>
              <v-select
                v-model="reportForm.reason_id"
                placeholder="Select a reason"
                :options="reasons"
                label="name"
                :searchable="false"
                :clearable="false"
                class="my-2"
                :reduce="option => option.id"
                autocomplete="false"
              />
            </el-form-item>

            <el-form-item
              v-if="reportForm.reason_id && reportForm.reason_id == 5"
              prop="content"
              :error="reportError"
            >
              <template slot="label">
                <slot name="textarea-label">
                  Other - Please describe
                </slot>
                <span
                  class="sr-only"
                  aria-live="polite"
                  :hidden="!reportError"
                  v-text="reportError"
                ></span>
              </template>
              <el-input
                id="description"
                v-model="reportForm.content"
                placeholder
                type="textarea"
                :rows="5"
                maxlength="500"
                aria-required="true"
              ></el-input>
            </el-form-item>
            <div
              v-if="reportForm.reason_id && reportForm.reason_id == 5"
              class="mt-n4 mb-3"
            >
              <span class="se-body-l-screen color-light-gray">You have {{ 500 - reportForm.content.length }} characters
                remaining</span>
            </div>
          </el-form>
        </el-col>
      </el-row>
      <el-row class="mt-auto">
        <hr class="mb-3" />
        <el-button
          class="submit-btn pull-right"
          size="medium"
          type="primary"
          @click="submitReport"
        >
          Report
        </el-button>
      </el-row>
    </el-dialog>
  </focus-lock>
</template>

<script>
import { mapGetters } from "vuex";
import gsApi from "@/services/gs.api";
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    visible: {
      type: Boolean,
      default: false
    },
    module: {
      type: String,
      required: true
    },
    entity: {
      type: String,
      required: true
    },
    entityID: {
      type: Number,
      required: true
    },
    appendToBody:{
      type:Boolean,
      default : false
    }
  },
  data() {
    var validateContent = (rule, value, callback) => {
      if (
        this.reportForm.reason_id &&
        this.reportForm.reason_id == 5 &&
        value === ""
      ) {
        callback(new Error("Please provide a description"));
      } else if (
        this.reportForm.reason_id &&
        this.reportForm.reason_id == 5 &&
        value.trim().length < 10
      ) {
        callback(
          new Error("Please provide a desciption about least 10 characters")
        );
      } else {
        callback();
      }
    };
    return {
      loading: false,
      reportForm: {
        reason_id: null,
        content: "",
        module: this.module,
        entity: this.entity,
        entity_id: this.entityID
      },
      rules: {
        reason_id: [
          {
            required: true,
            message: "Select a reason for reporting this post",
            trigger: "blur"
          }
        ],
        content: [{ validator: validateContent, trigger: "blur" }]
      },
      reportError: null
    };
  },
  computed: {
    ...mapGetters(["reasons"])
  },
  methods: {
    submitReport() {
      this.$refs.formReport.validate(valid => {
        if (valid) {
          this.loading = true;
          gsApi.report
            .submit(this.reportForm)
            .then(response => {
              this.onClose();
              this.$alert('Report successfully sent', 'Success', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
            })
            .catch(error => {
              this.onClose();

              this.$alert(
                "An unknown error occurred. Please try again later.",
                "Error",
                {
                  confirmButtonText: "Close",
                  showClose: false,
                  customClass: "notification-modal"
                }
              );
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    onClose() {
      this.reportForm.reason_id =  null,
      this.reportForm.content = "",
      this.$emit("close");
    }
  }
};
</script>
