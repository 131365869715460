<template>
  <div
    v-loading.fullscreen.lock="mainLoader"
    class="news-page body__content"
    element-loading-background="rgba(255, 255, 255, 0.8)"
  >
    <div class="search-section">
      <div class="container">
        <el-row>
          <el-col :sm="24">
            <h1>News & Resources</h1>
            <div class="search-btn-container">
              <ul class="search-buttons">
                <li
                  v-for="(type, index) in types"
                  :key="index"
                >
                  <el-button
                    :class="{ 'active-state': search.type == type.name }"
                    class="search-btn"
                    @click="handleSortChange(type)"
                  >
                    <p>{{ type.label }}</p>
                  </el-button>
                </li>
              </ul>
            </div>
          </el-col>
        </el-row>

        <el-form
          id="searchForm"
          ref="searchForm"
          :model="search"
          @submit.native.prevent
        >
          <el-row>
            <el-col
              :sm="24"
              :md="12"
            >
              <el-form-item
                label="Keyword search"
                prop="keyword"
                class="hidden-sm-and-down"
              >
                <el-input
                  id="keyword"
                  v-model="search.keyword"
                  :placeholder="!isAlertTypeSelected ? 'Search for keywords, type, content...' : 'Search for keywords, topic, content...' "
                  auto-complete="no"
                  maxlength="255"
                >
                </el-input>
              </el-form-item>
              <el-form-item
                label=""
                prop="keyword"
                class="hidden-md-and-up"
              >
                <el-input
                  id="keyword"
                  v-model="search.keyword"
                  :placeholder="!isAlertTypeSelected ? 'Search for keywords, type, content...' : 'Search for keywords, topic, content...'"
                  auto-complete="no"
                  maxlength="255"
                  @keyup.enter.native="searchItems"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col
              :sm="24"
              :md="12"
            >
              <div class="search-news">
                <el-button
                  class="search-news-button"
                  type="primary"
                  @click="searchItems"
                >
                  Search
                </el-button>
              </div>
            </el-col>
          </el-row>
          <el-row class="hidden-sm-and-down">
            <!--el-col :sm="24" :md="12" :lg="6">
              <el-form-item prop="filter" class="is-no-asterisk">
                <template slot="label">
                  <span aria-hidden="true">Filter</span>
                  <span class="sr-only">Choose from the following</span>
                </template>
                <v-select
                  v-model="search.filter"
                  placeholder="Choose from the following"
                  :options="filterOptions"
                  :searchable="true"
                  :clearable="false"
                  :reduce="filterOption => filterOption.key"
                  inputId="filter"
                  autocomplete="false"
                  label="name"
                  class="half-content" />
              </el-form-item>
	          </el-col-->
            <el-col
              :sm="24"
              :md="12"
              :lg="6"
            >
              <el-form-item
                prop="sortby"
                class="is-no-asterisk"
              >
                <template slot="label">
                  <span aria-hidden="true">Sort by</span>
                  <span class="sr-only">Choose from the following</span>
                </template>
                <v-select
                  v-model="search.sortby"
                  placeholder="Choose from the following"
                  :options="sortOptions"
                  :searchable="true"
                  :clearable="false"
                  :reduce="(sortOption) => sortOption.name"
                  input-id="sortby"
                  autocomplete="false"
                  label="name"
                  class="half-content"
                />
              </el-form-item>
            </el-col>
            <el-col
              v-if="!isAlertTypeSelected"
              :sm="24"
              :md="12"
              :lg="6"
            >
              <el-form-item
                prop="updateType"
                class="is-no-asterisk"
              >
                <template slot="label">
                  <span aria-hidden="true">Type</span>
                  <span class="sr-only">Please select a type</span>
                </template>
                <v-select
                  v-model="search.update_type"
                  placeholder="Choose from the following"
                  :options="updateTypes"
                  :searchable="true"
                  :clearable="false"
                  :reduce="(updateType) => updateType.id"
                  input-id="updateType"
                  autocomplete="false"
                  label="name"
                  class="half-content"
                />
              </el-form-item>
            </el-col>
            <el-col
              :sm="24"
              :md="12"
              :lg="6"
            >
              <el-form-item
                prop="topic"
                class="is-no-asterisk"
              >
                <template slot="label">
                  <span aria-hidden="true">Topic</span>
                  <span class="sr-only">Please select a topic</span>
                </template>
                <v-select
                  v-model="search.topic"
                  placeholder="Choose from the following"
                  :options="topics"
                  :searchable="true"
                  :clearable="false"
                  :reduce="(topic) => topic.id"
                  input-id="topic"
                  autocomplete="false"
                  label="name"
                  class="half-content"
                />
              </el-form-item>
            </el-col>
            <el-col
              :sm="24"
              :md="12"
              :lg="6"
            >
              <el-button
                type="primary"
                class="float-right update-results-btn"
                @click="searchItems"
              >
                Update results
              </el-button>
              <el-button
                type="text"
                class="reset-btn float-right"
                @click="resetSearch"
              >
                Reset
              </el-button>
            </el-col>
          </el-row>
          <el-row class="hidden-md-and-up">
            <!--el-col :sm="24">
              <el-form-item
                prop="view"
                class="is-no-asterisk"
              >
                <template slot="label">
                  <span aria-hidden="true">View</span>
                  <span class="sr-only">Please select a view type</span>
                </template>
                <v-select
                  v-model="search.filter"
                  :value="search.filter"
                  placeholder="Choose from the following"
                  :options="filterOptions"
                  :searchable="false"
                  :clearable="false"
                  :reduce="(filterOption) => filterOption.key"
                  label="name"
                  input-id="view"
                  autocomplete="false"
                  class="half-content"
                  @input="searchItems"
                />
              </el-form-item>
            </!--el-col-->
            <el-col
              :sm="24"
              class="hidden-md-and-up"
            >
              <el-button
                class="more-filters-btn"
                size="medium"
                @click="openArticlesFilter"
              >
                View More filters
              </el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>

    <div
      v-if="showZeroData"
      :id="showZeroData ? 'main-content' : ''"
      class="zero-data text-center mt-5"
    >
      <div class="container">
        <el-row>
          <el-col :sm="24">
            <p>
              {{ `We could not find any ${!isAlertTypeSelected ? 'news or resources' : 'alerts'} with your search criteria.
              Please update your search and try again.` }}
            </p>
          </el-col>
        </el-row>
      </div>
    </div>

    <div
      v-if="articles.length > 0"
      :id="articles.length > 0 ? 'main-content' : ''"
      class="results-section"
    >
      <div class="container">
        <div class="mt-4">
          <el-row>
            <news-card 
              v-for="article in articles"
              :key="article.id"
              :article="article"
            />
          </el-row>
        </div>
      </div>
    </div>

    <div 
      v-if="alerts.length"
      :id="alerts.length ? 'main-content' : ''"
      class="results-section"
    >
      <div class="container">
        <div class="mt-4">
          <el-row>
            <alert-card 
              v-for="alert in alerts"
              :key="alert.id"
              :alert="alert"
            />
          </el-row>
        </div>
      </div>
    </div>

    <div 
      class="
      pagination-help-section"
    >
      <div class="container">
        <el-button
          v-if="!onLastPage"
          class="load-more-btn"
          size="medium"
          @click="loadMore"
        >
          Load more
        </el-button>
      </div>
    </div>

    <news-filter
      v-if="articlesFilterOpen"
      v-model="search"
      :topics="topics"
      :update-types="updateTypes"
      :sort-options="sortOptions"
      :type="search.type"
      :is-alert-type-selected="isAlertTypeSelected"
      @close="closeArticlesFilter"
      @search="searchItems(true)"
      @reset="resetSearch(true)"
    ></news-filter>
  </div>
</template>

<script>
import store from "../../store";
import gsApi from "@/services/gs.api";
import AlertCard from "@/components/platform/email-alerts/alert-card";
import NewsCard from "@/components/platform/news-features/news-card";
import NewsFilter from "@/components/slide-panel/panels/news-filter";

export default {
  name: "NewsAndResources",
  metaInfo: {
    title: "News & Resources",
  },
  components: {
    "alert-card": AlertCard,
    "news-filter": NewsFilter,
    "news-card" : NewsCard
  },
  data() {
    return {
      mainLoader: false,
      page: 1,
      perPage: 6,
      totalResults: 0,
      showZeroData: false,
      onLastPage: false,
      search: {
        type: "News",
        sortby: null,
        keyword: "",
        filter: null,
        topic: null,
        update_type: null,
      },
      filterOptions: [
        {
          key: 0,
          name: "News",
        },
        {
          key: 1,
          name: "Publications",
        },
        {
          key: 3,
          name: "CaseStudies",
        },
      ],
      sortOptions: [
        {
          key: 0,
          name: "A-Z",
        },
        {
          key: 1,
          name: "Z-A",
        },
        {
          key: 2,
          name: "Latest",
        },
      ],
      articles: [],
      alerts:[],
      topics: [],
      updateTypes: [],
      articlesFilterOpen: false,
      types: [
        { label: "News", name: "News" },
        { label: "Alerts", name: "Alert" },
        { label: "Publications", name: "Publications" },
        { label: "Case Studies", name: "CaseStudies" },
      ],
    };
  },
  computed: {
    isAlertTypeSelected(){
      return this.search.type == "Alert"
    }
  },
  created() {
    /*if (!store.state.user.isLogged) {
          router.push('/login')
      }*/
    if (store.state.user.isLogged) {
      this.getRegisterMeta();
    }
  },
  methods: {
    getRegisterMeta() {
      this.mainLoader = true;

      gsApi.globalscot.meta
        .index()
        .then((response) => {
          this.updateTypes = response.data.groups.data;
          this.topics = response.data.expertise.data; // TODO: update by updateTypes
          this.searchItems();
        })
        .catch((error) => {
          console.log(error);
          this.mainLoader = false;
          this.$alert(
            "An unknown error occurred. Please try again later.",
            "Error",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal",
            }
          );
        });
    },
    resetSearch(isMobile) {
      if (isMobile) {
        this.closeArticlesFilter();
      }

      this.search = {
        ...this.search,
        ...{
          sortby: null,
          keyword: "",
          topic: null,
          update_type: null,
        }
      }

      this.searchItems();
    },

    searchItems(isMobile) {
      if (isMobile) {
        this.closeArticlesFilter();
      }

      this.page = 1;
      this.articles = [];
      this.alerts = [];
      if(this.isAlertTypeSelected){
        this.fetchAlerts()
      }else{
        this.fetchArticles();
      }
    },

    fetchArticles() {
      if (!this.mainLoader) {
        this.mainLoader = true;
      }

      //-- get selected search type
      // only one type is required now : news
      let current_type = this.filterOptions.find(
        (f) => f.name == this.search.type
      );
      this.search.filter = current_type ? current_type.key : null;

      let params = {
        page: this.page,
        per_page: this.perPage,
        group: this.search.update_type,
        topic:this.search.topic,
        search: this.search.keyword,
        type_id: this.search.filter,
        sort_by: this.search.sortby,
        status_id: 1,
      };

      gsApi.articles
        .search(params)
        .then((response) => {
          this.mainLoader = false;

          this.totalResults = response.data.meta.pagination.total;

          if (
            response.data.meta.pagination.current_page <
            response.data.meta.pagination.total_pages
          ) {
            this.onLastPage = false;
            this.page++;
          } else {
            this.onLastPage = true;
          }

          this.articles = this.articles.concat(response.data.data);

          this.showZeroData = this.articles.length > 0 ? false : true;
        })
        .catch((error) => {
          this.mainLoader = false;
        });
    },
    fetchAlerts(){
      if (!this.mainLoader) {
        this.mainLoader = true;
      }
      let params = {
        page: this.page,
        per_page: this.perPage,
        topic: this.search.topic,
        search: this.search.keyword,
        sort_by: this.search.sortby,
        status_id: 3, // only sent email Alerts
      };
      gsApi.alerts
        .search(params)
        .then((response) => {
          this.mainLoader = false;

          this.totalResults = response.data.meta.pagination.total;

          if (
            response.data.meta.pagination.current_page <
            response.data.meta.pagination.total_pages
          ) {
            this.onLastPage = false;
            this.page++;
          } else {
            this.onLastPage = true;
          }

          this.alerts = this.alerts.concat(response.data.data);

          this.showZeroData = this.alerts.length > 0 ? false : true;
        })
        .catch((error) => {
          this.mainLoader = false;
        });
    },
    loadMore(){
      this.isAlertTypeSelected ? this.fetchAlerts() : this.fetchArticles()
    },
    openArticlesFilter() {
      this.articlesFilterOpen = true;
    },
    closeArticlesFilter() {
      this.articlesFilterOpen = false;
    },
    handleSortChange(sort) {
      this.search.type = sort.name;
      this.searchItems();
    },
  },
};
</script>
