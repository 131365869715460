<template>
  <focus-lock>
    <el-dialog
      ref="enableMultiFactoryAuth"
      :show-close="true"
      :visible.sync="dialogVisible"
      custom-class="multi-factor-dialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onClose()"
    >
      <div class="body">
        <h2 class="mb-2">Google Authenticator</h2>
        <div v-if="step1">
          <p>You'll need to install the Google Authenticator app on your smart phone or tablet first. Once you’ve done this, in the Google Authenticator app, click the ‘+’ (iOS) or open the app settings and select ‘Add Account’ (Android), then click Next below
          </p>

          <div class="d-flex mt-4 w-100 justify-content-center">
            <el-button
              :disabled="codeLoading"
              class="mb-2 w-50 mx-0 mr-1"
              @click="onClose()"
            >
              Cancel
            </el-button>
            <el-button
              :disabled="codeLoading"
              :loading="codeLoading"
              type="primary"
              class="mb-2 w-50 ml-1 mr-0"
              @click="getSarted()"
            >
              Next
            </el-button>
          </div>
        </div>
        <div v-if="step2">
          <p>Use the Google Authenticator app to scan this QR code:</p>
          <div class="text-center py-3" v-html="googleQRCode.svg"></div>
          <p>Or enter this key:</p>
          <p>{{ googleQRCode.url }}</p>
          <p>Enter the 6-digit passcode the app gives you here:</p>
          <el-form
            id="googleAuthenticatorForm"
            ref="googleAuthenticatorForm"
            v-loading="codeLoading"
            :model="form"
            autocomplete="false"
            :rules="rules"
            class="mt-0"
            @submit.native.prevent
          >
            <el-form-item
              prop="code"
              class="is-no-asterisk mt-2 mb-0"
              :error="verificationCodeError"
            >
              <template slot="label">
                <slot name="textarea-label"> Verification Code </slot>
                <br />
                <span
                  class="sr-only"
                  aria-live="polite"
                  :hidden="!hasInlineValidationErrorFor('code')"
                  v-text="getInlineValidationErrorFor('code')"
                ></span>
              </template>
              <el-input
                id="code"
                v-model="form.code"
                name="verification code"
                placeholder="Verification code"
                :max-length="6"
                aria-required="true"
              ></el-input>
            </el-form-item>
            <el-button
              class="mt-3 mb-3 submit-btn mx-auto"
              type="primary"
              @click="submitActivation"
            >
              Submit
            </el-button>
          </el-form>
        </div>
      </div>
    </el-dialog>
  </focus-lock>
</template>
<script>
import gsApi from "@/services/gs.api";
import inlineValidationErrorsMixin from "@/mixins/inlineValidationErrorsMixin";
export default {
  mixins: [inlineValidationErrorsMixin],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogVisible: false,
      step1: true,
      step2: false,
      googleQRCode: null,
      codeLoading: false,
      form: {
        code: null,
      },
      verificationCodeError: null,
      rules: {
        code: [
          {
            required: true,
            message: "Verification code is required",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    visible() {
      this.dialogVisible = this.visible;
    },
  },
  created() {
    this.dialogVisible = this.visible;
  },

  methods: {
    onClose() {
      this.dialogVisible = false;
      this.$emit("closed");
    },
    async getSarted() {
      this.codeLoading = true;
      // Enable the Two Factor Authentication
      const authReponse = await gsApi.mfa
        .twoFactorGoogleAuthentication()
        .catch((error) => {
          this.codeLoading = false;
          return false;
        });

      if (!authReponse) return;

      // Generate the QR Code
      const qrResponse = await gsApi.mfa.generateQRCode().catch((error) => {
        this.codeLoading = false;
        return false;
      });

      if (!qrResponse) return;

      const secretParam = new URLSearchParams(
        new URL(qrResponse.data.url).search
      );

      this.googleQRCode = {
        svg: qrResponse.data.svg,
        url: secretParam.get("secret"),
      };

      this.step1 = false;
      this.step2 = true;

      this.codeLoading = false;
    },
    submitActivation() {
      this.$refs.googleAuthenticatorForm.validate(async (valid) => {
        if (valid) {
          this.codeLoading = true;

          const twoFactorConfirmedResponse = await gsApi.mfa
            .confirmedTwoFactorGoogleAuthentication(this.form)
            .catch((error) => {

              this.verificationCodeError =
                error?.response?.data?.errors?.code[0];

              return false;
            });

          if (!twoFactorConfirmedResponse) {
            this.codeLoading = false;
            return;
          }

          this.$emit('refresh-user-multifactor-methods')

          this.verificationCodeError = null;
          // refresh the user data to get all available methods
          this.onClose();

          this.$alert(
            `<i class="el-icon el-icon-circle-check"></i>
                <p class='title mb-5 mt-2'>Multi-factor authentication enabled</p>
                <p class='method-name mb-2' >GOOGLE AUTHENTICATOR</p>
                <p class='message'>Verification has successfully been added to your account</p>
                `,
            "",
            {
              dangerouslyUseHTMLString: true,
              confirmButtonText: "Close",
              showClose: true,
              customClass: "notification-modal message-modal",
            }
          );

        }
      });
    },
  },
};
</script>
