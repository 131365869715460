<template>
  <div
    v-loading.fullscreen.lock="mainLoader"
    class="request-help-form"
    element-loading-background="rgba(255, 255, 255, 0.8)"
  >
    <div class="container">
      <h2 class="title">
        Request help from
      </h2>
      <el-row class="hidden-sm-and-up">
        <el-col
          :xs="5"
          :lg="4"
        >
          <user-avatar
            shape="circle"
            :is-global-scot="helpers.isExpert(globalScot)"
            :author-exist="!!globalScot"
            width="60px"
            :size="60"
            :src="globalScot ? globalScot.avatar : null"
            :alt="`${globalScot.full_name} 'Profile picture'`"
            avatar-classes="mt-2"
          ></user-avatar>
        </el-col>
        <el-col
          :xs="19"
          :lg="20"
        >
          <div class="user-info flex">
            <div class="d-flex justify-content-between">
              <p>{{ globalScot.full_name }}</p>
            </div>
            <p>{{ primaryBusiness.role }} | {{ primaryBusiness.name }}</p>
            <div class="location d-flex">
              <p class="d-flex">
                <svg-map-pin-gradient class="mr-2 map-pin"></svg-map-pin-gradient>
                {{ location }}
              </p>
              <span class="ml-3 mr-3">|</span>
              <p
                v-if="helpers.isExpert(globalScot)"
                class="d-flex"
              >
                <svg-gs-profile-icon class="mr-2 gs-globe"></svg-gs-profile-icon>GlobalScot for, {{ memberFor }}
              </p>
              <p
                v-else-if="helpers.isMember(globalScot)"
                class="d-flex"
              >
                <svg-member-profile-icon class="mr-2 gs-globe"></svg-member-profile-icon> Member for {{ memberFor }}
              </p>
              <p
                v-else
                class="d-flex"
              >
                <svg-staff-profile-icon class="mr-2 gs-globe"></svg-staff-profile-icon> Team Scotland for {{ memberFor }}
              </p>
            </div>
          </div>
        </el-col>
      </el-row>
      <div class="d-flex hidden-xs-only">
        <user-avatar
          shape="circle"
          :is-global-scot="helpers.isExpert(globalScot)"
          :author-exist="!!globalScot"
          :size="74"
          width="120px"
          avatar-classes="mt-2"
          :src="globalScot ? globalScot.avatar : null"
          :alt="`${globalScot.full_name} 'Profile picture'`"
        ></user-avatar>
        <div class="user-info">
          <div class="d-flex justify-content-between title-name">
            <p>{{ globalScot.full_name }}</p>
          </div>
          <p>{{ primaryBusiness.role }} | {{ primaryBusiness.name }}</p>
          <div class="location d-flex">
            <p class="d-flex">
              <svg-map-pin-gradient
                class="mr-2 map-pin"
              ></svg-map-pin-gradient>
              {{ location }}
            </p>
            <span class="ml-3 mr-3">|</span>
            <p
              v-if="helpers.isExpert(globalScot)"
              class="d-flex"
            >
              <svg-gs-profile-icon class="mr-2 gs-globe"></svg-gs-profile-icon>GlobalScot for, {{ memberFor }}
            </p>
            <p
              v-else-if="helpers.isMember(globalScot)"
              class="d-flex"
            >
              <svg-member-profile-icon class="mr-2 gs-globe"></svg-member-profile-icon> Member for {{ memberFor }}
            </p>
            <p
              v-else
              class="d-flex"
            >
              <svg-staff-profile-icon class="mr-2 gs-globe"></svg-staff-profile-icon> Team Scotland for {{ memberFor }}
            </p>
          </div>
        </div>
      </div>
      <hr />
      <el-form
        id="requestHelp"
        ref="requestHelp"
        :model="requestHelp"
        :rules="requestHelp.rules"
        autocomplete="false"
        @submit.native.prevent
        @validate="handleRequestHelpFormFieldInput"
      >
        <el-row class="form-header">
          <el-col
            :sm="24"
            :md="18"
            :lg="13"
          >
            <h3 class="request-title">
              Request details
            </h3>
            <span
              class="form-details"
            >Please complete the form to provide information about what support you’re looking for. {{ globalScot.full_name }} will also be able to see your profile for some more information about you.</span>
          </el-col>
        </el-row>
        <validation-errors
          v-if="validationErrors.length"
          :errors="validationErrors"
        ></validation-errors>
        <el-row>
          <el-col
            :sm="24"
            :md="12"
            :lg="8"
          >
            <el-form-item prop="subject">
              <template slot="label">
                Request subject
                <span
                  class="sr-only"
                  aria-live="polite"
                  :hidden="! hasInlineValidationErrorFor('subject')"
                  v-text="getInlineValidationErrorFor('subject')"
                ></span>
              </template>
              <el-input
                id="subject"
                v-model="requestHelp.subject"
                auto-complete="no"
                aria-required="true"
                maxlength="64"
                autocomplete="false"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col
            :sm="24"
            :md="18"
            :lg="13"
          >
            <el-form-item
              prop="purpose"
              class="form-group"
            >
              <span
                class="characters-length"
              >You have {{ 1000 - (requestHelp.purpose.length) }} characters remaining</span>
              <template
                slot="label"
              >
                Tell {{ globalScot.first_name }} what you’re looking to do and how you’re hoping they can help
                <span
                  class="sr-only"
                  aria-live="polite"
                  :hidden="! hasInlineValidationErrorFor('purpose')"
                  v-text="getInlineValidationErrorFor('purpose')"
                ></span>
              </template>
              <el-input
                id="purpose"
                v-model="requestHelp.purpose"
                type="textarea"
                aria-required="true"
                auto-complete="no"
                :rows="5"
                maxlength="1000"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col
            :sm="24"
            :md="18"
            :lg="13"
          >
            <el-form-item
              prop="groups"
              class="is-no-asterisk"
            >
              <template slot="label">
                <span aria-hidden="true">
                  Which sector does this relate to?
                  <span class="is-custom-asterisk">*</span>
                </span>
                <br />
                <span
                  class="sr-only"
                >Please select one or more aspects your business needs help with</span>
                <span class="el-form-item__info">You can select more than one</span>
                <span
                  class="sr-only"
                  aria-live="polite"
                  :hidden="! hasInlineValidationErrorFor('groups')"
                  v-text="getInlineValidationErrorFor('groups')"
                ></span>
              </template>
              <v-select
                v-model="requestHelp.groups"
                :options="groups"
                placeholder="Please select"
                :multiple="true"
                :clearable="false"
                label="name"
                input-id="groups"
                autocomplete="false"
                :select-on-tab="true"
                :close-on-select="false"
                @search:blur="$refs.requestHelp.validateField('groups')"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col
            :sm="24"
            :md="18"
            :lg="13"
          >
            <el-form-item
              prop="expertise"
              class="is-no-asterisk"
            >
              <template slot="label">
                <span aria-hidden="true">
                  Expertise you’re looking for
                  <span class="is-custom-asterisk">*</span>
                </span>
                <br />
                <span class="sr-only">Please select one or more expertise you needs help with</span>
                <span class="el-form-item__info">You can select more than one</span>
                <span
                  class="sr-only"
                  aria-live="polite"
                  :hidden="! hasInlineValidationErrorFor('expertise')"
                  v-text="getInlineValidationErrorFor('expertise')"
                ></span>
              </template>
              <v-select
                v-model="requestHelp.expertise"
                :options="expertise"
                placeholder="Please select"
                :multiple="true"
                :clearable="false"
                label="name"
                input-id="expertise"
                autocomplete="false"
                :select-on-tab="true"
                :close-on-select="false"
                @search:blur="$refs.requestHelp.validateField('expertise')"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item
          v-if="$store.state.user.roles[0] != 'staff'"
          prop="business"
          class="mt-4"
        >
          <template slot="label">
            <span aria-hidden="true">Which business is this request for?</span>
            <span class="is-custom-asterisk">*</span>
            <br />
            <span class="sr-only">Please select business you needs help with</span>
            <span
              class="sr-only"
              aria-live="polite"
              :hidden="! hasInlineValidationErrorFor('user_business_id')"
              v-text="getInlineValidationErrorFor('user_business_id')"
            ></span>
          </template>
          <el-radio-group
            v-model="requestHelp.user_business_id"
            aria-label="Select a business"
          >
            <div
              v-for="(business, index) in businesses"
              :key="index"
              class="col-md-24 mt-3 px-0"
            >
              <el-radio :label="business.id">
                <span>{{ business.name }}</span>
              </el-radio>
            </div>
          </el-radio-group>
        </el-form-item>
        <el-row v-if="$store.state.user.roles[0] == 'staff'">
          <el-col
            :sm="24"
            :md="18"
            :lg="13"
          >
            <el-form-item prop="team_scotland_business">
              <template slot="label">
                Which business is this request for?
                <span
                  class="sr-only"
                  aria-live="polite"
                  :hidden="! hasInlineValidationErrorFor('team_scotland_business')"
                  v-text="getInlineValidationErrorFor('team_scotland_business')"
                ></span>
              </template>
              <el-input
                id="team_scotland_business"
                v-model="requestHelp.team_scotland_business"
                auto-complete="no"
                aria-required="true"
                maxlength="500"
                autocomplete="false"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item
          prop="terms_agreed"
          class="terms-conditions"
        >
          <label for="terms_agreed">
            <span
              class="sr-only"
              aria-live="polite"
              :hidden="! hasInlineValidationErrorFor('terms_agreed')"
              v-text="getInlineValidationErrorFor('terms_agreed')"
            ></span>
            <input
              id="terms_agreed"
              v-model="requestHelp.terms_agreed"
              type="checkbox"
              tabindex="0"
              name="terms_agreed"
            />
            I’ve read and accept the
            <router-link
              :to="{ name: 'Terms & Conditions'}"
              class="text__link"
              target="_blank"
            >terms and conditions <span class="sr-only">this link will open in a new window</span></router-link>
          </label>
        </el-form-item>
        <el-row class="form-actions">
          <el-col
            :sm="24"
            :md="18"
            :lg="13"
          >
            <el-button
              type="primary"
              native-type="submit"
              @click="submitForm"
            >
              Submit request
            </el-button>
            <el-button @click="onDialogClose">
              Cancel
            </el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import MapPinGradient from "@/components/assets/svg-map-pin";
import gsApi from "@/services/gs.api";
import validationErrors from "@/components/partials/validation-errors";
import UserAvatar from "@/components/core/user-avatar";
import helpers from "@/helpers/index";
import SvgGsProfileicon from '@/components/assets/svg-gs-profile-icon';
import SvgMemberProfileIcon from '@/components/assets/svg-member-profile-icon'
import SvgStaffProfileIcon from "@/components/assets/svg-staff-profile-icon";
import inlineValidationErrorsMixin from "@/mixins/inlineValidationErrorsMixin";
export default {
  name: "RequestHelpComponent",
  components: {
    "svg-map-pin-gradient": MapPinGradient,
    "svg-staff-profile-icon": SvgStaffProfileIcon,
    'svg-member-profile-icon': SvgMemberProfileIcon,
    'svg-gs-profile-icon':SvgGsProfileicon,
    validationErrors,
    UserAvatar
  },
  mixins: [inlineValidationErrorsMixin],
  props: ["globalScot"],
  data() {
    return {
      helpers,
      validationErrors: [],
      mainLoader: false,
      groups: [],
      expertise: [],
      businesses: this.$store.state.user.roles[0] == 'staff' ? null : this.$store.state.user.businesses,
      requestHelp: {
        groups: "",
        group_ids: [],
        expertise: "",
        expertise_ids: [],
        subject: "",
        purpose: "",
        user_business_id: this.$store.state.user.roles[0] == 'staff' ? null : this.$store.state.user.businesses[0].id,
        terms_agreed: [],
        team_scotland_business: null,
        rules: {
          subject: [
            { required: true, message: "Add a request subject", trigger: "blur" },
            {
              max: 64,
              message: "Subject may not be greater than 255 characters",
              trigger: "blur"
            }
          ],
          team_scotland_business: [
            { required: true, message: "Enter the name of the business you're requesting help for", trigger: "blur" },
            {
              max: 500,
              message: "Business may not be greater than 500 characters",
              trigger: "blur"
            }
          ],
          purpose: [
            { required: true, message: "Tell the GlobalScot more about the support you're looking for", trigger: "blur" },
            {
              max: 1000,
              message: "Purpose may not be greater than 1000 characters",
              trigger: "blur"
            }
          ],
          groups: [
            {
              type: "array",
              required: true,
              message: "Select the sector this request relates to",
              trigger: "change"
            }
          ],
          expertise: [
            {
              type: "array",
              required: true,
              message: "Select the expertise you're looking for from the GlobalScot ",
              trigger: "change"
            }
          ],
          terms_agreed: [
            {
              type: "array",
              required: true,
              message: "Check the box to accept the terms and conditions ",
              trigger: "change"
            }
          ]
        }
      }
    };
  },
  computed: {
    primaryBusiness() {
      let primary = this.globalScot.businesses.data.find(b => b.is_primary);
      return primary ? primary : this.globalScot.businesses.data[0];
    },
    location() {
      if (this.globalScot.expertProfile.state && this.globalScot.expertProfile.country) {
        return this.globalScot.expertProfile.state.name + ', ' + this.globalScot.expertProfile.country.name
      } else if (this.globalScot.expertProfile.country) {
        return this.globalScot.expertProfile.country.name
      } else if (this.globalScot.expertProfile.state) {
        return this.globalScot.expertProfile.state.name
      }
      return '';
    },
    memberFor() {
      let memberFor = moment(this.globalScot.created_at.date).fromNow(true);
      return memberFor;
    }
  },
  created() {
    this.getRegisterMeta();
  },
  methods: {
    onDialogClose() {
      this.$emit("clicked");
    },

    getRegisterMeta() {
      gsApi.globalscot.meta
        .index()
        .then(response => {
          this.groups = response.data.groups.data;
          this.expertise = response.data.expertise.data;
        })
        .catch(() => {
          this.$alert(
            "An unknown error occurred. Please try again later.",
            "Error",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal"
            }
          );
        });
    },
    submitForm() {
      this.$refs["requestHelp"].validate(valid => {
        if (valid) {
          const data = {};

          this.requestHelp.groups.forEach(item => {
            this.requestHelp.group_ids.push(item.id);
          });

          this.requestHelp.expertise.forEach(item => {
            this.requestHelp.expertise_ids.push(item.id);
          });

          data.subject = this.requestHelp.subject;
          data.purpose = this.requestHelp.purpose;
          data.group_ids = this.requestHelp.group_ids;
          data.expertise_ids = this.requestHelp.expertise_ids;
          data.expert_id = this.globalScot.id;
          data.user_business_id = this.requestHelp.user_business_id;
          data.team_scotland_business = this.requestHelp.team_scotland_business;

          this.mainLoader = true;

          gsApi.member
            .requestHelp(data)
            .then(response => {
              this.mainLoader = false;
              (this.validationErrors = []), this.$emit("clicked");
              this.$emit("success");
            })
            .catch(error => {
              this.validationErrors = [];
              this.mainLoader = false;

              if (
                error.response &&
                error.response.status == 422 &&
                error.response.data
              ) {
                let errors = error.response.data.errors;
                for (let [key, value] of Object.entries(errors)) {
                  let obj = {};
                  obj.id = key;
                  obj.message = value[0];
                  this.validationErrors.push(obj);
                }
                window.scrollTo({ top: 0, behavior: "smooth" });
              } else {
                this.$alert(
                  "There are errors in the form, please fix these errors and try again.",
                  "Error",
                  {
                    confirmButtonText: "Close",
                    showClose: false,
                    customClass: "notification-modal"
                  }
                );
              }
            });
        } else {
          this.validationErrors = [];
          this.$refs.requestHelp.fields.forEach(field => {
            if (field.validateState == "error") {
              let obj = {};
              obj.id = field.labelFor;
              obj.message = field.validateMessage;
              this.validationErrors.push(obj);
            }
          });
          this.$nextTick(() => {
            document
              .getElementById("formErrorBlock")
              .scrollIntoView({ behavior: "smooth", block: "start" });
            let text = document
              .getElementById("requestHelp")
              .getElementsByClassName("el-form-item__error")[0].textContent;
            this.$announcer.set(text);
          });
        }
      });
    },
    handleRequestHelpFormFieldInput() {
      this.handleSingleFormFieldInput('requestHelp')
    }
  }
};
</script>
<style lang="scss">

.user-info .title-name p , .request-title{
  font-weight: 500 ;
  font-size: $font-size-30;
  line-height: $line-height-42;
  color:black;
}

</style>
