<!-- Index Component -->
<template>
  <div class="wrapper new-ui">
    <v-skip-links></v-skip-links>
    <v-l-header></v-l-header>
    <main
      id="content"
      role="main"
    >
      <div class="unsubscribe-container">
        <div
          v-loading="processing"
          class="unsubscribe"
        >
          <div class="unsubscribe-title">
            <h1>
              {{ unsubscribed ? "Unsubscribed successfully" : "Unsubscribe" }}
            </h1>
          </div>
          <h2>
            {{
              unsubscribed
                ? "You have been successfully unsubscribed from the email alerts"
                : "We are sorry to see you go"
            }}
          </h2>
          <div class="unsubscribe-actions">
            <p v-if="!unsubscribed">
              Are you sure you wish to unsubscribe from the email alerts ?
            </p>
            <div class="d-flex w-100 justify-content-center pt-5">
              <el-button
                type="primary"
                @click="$router.push({ name: 'Home' })"
              >
                {{ unsubscribed ? "Back to Home" : "No" }}
              </el-button>
              <el-button
                v-if="!unsubscribed"
                type="default"
                @click="unsubscribeFromEmailAlert"
              >
                Yes
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </main>
    <v-get-in-touch></v-get-in-touch>
    <v-footer></v-footer>
  </div>
</template>

<script>
import Footer from "../../layout/footer";
import GetInTouch from "../../layout/get-in-touch";
import LandingHeader from "../../layout/l-header-2";
import SkipLinks from "@/components/partials/skip-links";
import gsApi from "@/services/gs.api";
export default {
  components: {
    "v-skip-links": SkipLinks,
    "v-l-header": LandingHeader,
    "v-footer": Footer,
    "v-get-in-touch": GetInTouch,
  },
  data() {
    return {
      processing: false,
      unsubscribed: this.$route.query.completed,
    };
  },
  methods: {
    unsubscribeFromEmailAlert() {
      this.processing = true;
      gsApi.alerts
        .unsubscribe(this.$route.query.email)
        .then(() => {
          this.addParamsToLocation({ completed: true });
          this.unsubscribed = true;
        })
        .catch((error) => {
          this.$alert(
            error?.response?.data?.error?.message ?? "An Error occured while trying to unsubscribe your email from email alerts ",
            "",
            {
              confirmButtonText: "Try again",
              customClass: "notification-modal message-modal",
            }
          );
        })
        .finally(() => {
          this.processing = false;
        });
    },
    addParamsToLocation(params) {
      history.pushState(
        {},
        null,
        this.$route.path +
          "?" +
          Object.keys(params)
            .map((key) => {
              return (
                encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
              );
            })
            .join("&")
      );
    },
  },
};
</script>
<style lang="scss">
.unsubscribe-container {
  position: relative;
  height: 80vh;
  background: #f6f6f6;
  .unsubscribe {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    max-width: 767px;
    width: 100%;
    line-height: $line-height-24;
    padding: 50px 40px;
    text-align: center;
    background: #fff;
    -webkit-box-shadow: 0 15px 15px -10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 15px 15px -10px rgba(0, 0, 0, 0.1);
    .unsubscribe-title {
      position: relative;
      height: 80px;
      h1 {
        font-size: 2.125rem;
        font-weight: 700;
        margin: 0px;
        color: #a8308c;
        text-transform: uppercase;
        text-align: center;
      }
    }
    h2 {
      font-size: $font-size-24;
      font-weight: 400;
      color: #151515;
      margin-top: 30px;
      margin-bottom: 50px;
      line-height: $line-height-32;
    }
    .unsubscribe-actions {
      position: relative;
      width: 100%;

      > button {
        margin: auto;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .unsubscribe h2 {
    font-size: $font-size-18;
  }
}

@media only screen and (max-width: 480px) {
  .unsubscribe .unsubscribe-title h1 {
    font-size: $font-size-36;
  }
}
</style>
