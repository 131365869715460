<!-- Membership Component -->
<template>
  <div class="about-page about-network-page">
    <top-navigation></top-navigation>
    <section class="article-featured px-0" aria-label="About Scotland Europa membership">
          <div class>
            <div class="about-page__header small-header membership-header">
              <div class="header__content__bg"></div>
              <div class="header__image__bg about-network-bg"></div>
              <div class="header__content container">
                <el-row>
                  <el-col :md="24" :lg="12">
                    <div class="header__content__wrapper">
                      <h1 id="main-content" class="header__title">
                        {{ text.headingText }}
                      </h1>
                      <p class="header__content-info">
                        {{ text.headingSub }}
                      </p>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
        </section>
      <membership-benifits></membership-benifits>
      
   
  </div>
</template>

<script>
import TopNavigation from "../../layout/top-navigation";
import MembershipBenifits from "../../pages/landing/about/membership-benifits";
import { mapState } from "vuex";
export default {
  name: "membership",
  metaInfo: {
    title: "About Scotland Europa membership",
    meta: [
      {
        name: "description",
        content:
          "Scotland Europa provides a hub for Scottish organisations who aim for a stronger, strategic positioning in European affairs.",
      },
    ],
  },
  computed: {
    ...mapState(["user"]),
  },
  components: {
    TopNavigation,
    MembershipBenifits,
  },
  data() {
    return {
      text: {
        headingText: "About Scotland Europa membership",
        headingSub:
          "Scotland Europa provides a hub for Scottish organisations who aim for a stronger, strategic positioning in European affairs. Our team of EU policy and funding experts offer insights into EU public affairs, bringing careful analysis and fresh interpretation of European issues affecting Scottish interests. We work with our members, the Scottish Government and wider stakeholders to raise Scotland's profile in the EU, and in Europe more broadly, and to increase the level of expertise and knowledge of European issues within Scotland.",
      },
      users: [],
    };
  },
};
</script>
