<!-- News & Resources Component -->
<template>
  <div
    v-loading.fullscreen.lock="mainLoader"
    class="case-studies-page  white-background-section"
    element-loading-background="rgba(255, 255, 255, 0.8)"
  >
    <el-row
      :gutter="0"
      class="m-0"
    >
      <top-navigation></top-navigation>

      <el-col
        :span="24"
        class="case-studies-header px-0"
      >
        <div class>
          <div class="case-studies-page__header small-header">
            <div class="header__content__bg"></div>
            <div class="header__image__bg case-studies-bg"></div>
            <div class="header__content container">
              <el-row>
                <el-col
                  :md="24"
                  :lg="12"
                >
                  <div class="header__content__wrapper">
                    <h1
                      id="main-content"
                      class="header__title"
                    >
                      {{ text.headingText }}
                    </h1>
                    <p class="header__content-info">
                      {{ text.headingSub }}
                    </p>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </el-col>

      <div class="container">
        <el-col>
          <el-form
            id="searchForm"
            ref="searchForm"
            class="form"
            :model="search"
            @submit.native.prevent
          >
            <el-row>
              <el-col
                :sm="24"
                :md="12"
              >
                <el-form-item
                  label="Keyword search"
                  prop="keyword"
                  class="hidden-sm-and-down"
                >
                  <el-input
                    id="keyword"
                    v-model="search.keyword"
                    placeholder="Search for keywords, location, people..."
                    auto-complete="no"
                    maxlength="255"
                  >
                  </el-input>
                </el-form-item>

                <el-form-item
                  label=""
                  prop="keyword"
                  class="hidden-md-and-up"
                >
                  <el-input
                    id="keyword"
                    v-model="search.keyword"
                    placeholder="Search for keywords, location, people..."
                    auto-complete="no"
                    maxlength="255"
                    @keyup.enter.native="searchArticles"
                  >
                  </el-input>
                </el-form-item>
              </el-col>

              <el-col
                class="search-case-studies"
                :sm="24"
                :md="12"
              >
                <el-button
                  class="events-btn"
                  type="primary"
                  @click="searchArticles"
                >
                  <p>Search</p>
                </el-button>
              </el-col>
            </el-row>

            <el-row>
              <el-col
                :sm="24"
                :md="8"
                :lg="5"
              >
                <el-form-item
                  prop="sortby"
                  class="is-no-asterisk"
                >
                  <template slot="label">
                    <span aria-hidden="true">Sort by</span>
                    <span class="sr-only">Choose from the following</span>
                  </template>
                  <v-select
                    v-model="search.sortby"
                    placeholder="Choose from the following"
                    :options="sortOptions"
                    :searchable="true"
                    :clearable="false"
                    :reduce="sortOption => sortOption.name"
                    input-id="sortby"
                    autocomplete="false"
                    label="name"
                    class="half-content"
                  />
                </el-form-item>
              </el-col>

              <el-col
                :sm="24"
                :md="8"
                :lg="5"
              >
                <el-form-item
                  prop="secto"
                  class="is-no-asterisk"
                >
                  <template slot="label">
                    <span aria-hidden="true">Type</span>
                    <span class="sr-only">Please select a type</span>
                  </template>
                  <v-select
                    v-model="search.sector"
                    placeholder="Choose from the following"
                    :options="sectors"
                    :searchable="true"
                    :clearable="false"
                    :reduce="sector => sector.id"
                    input-id="sector"
                    autocomplete="false"
                    label="name"
                    class="half-content"
                  />
                </el-form-item>
              </el-col>
              <el-col
                :sm="24"
                :md="8"
                :lg="6"
              >
                <el-form-item
                  prop="secto"
                  class="is-no-asterisk"
                >
                  <template slot="label">
                    <span aria-hidden="true">Topic</span>
                    <span class="sr-only">Please select a topic</span>
                  </template>
                  <v-select
                    v-model="search.topic"
                    placeholder="Choose from the following"
                    :options="topics"
                    :searchable="true"
                    :clearable="false"
                    :reduce="topic => topic.id"
                    input-id="topic"
                    autocomplete="false"
                    label="name"
                    class="half-content"
                  />
                </el-form-item>
              </el-col>
              <el-col
                :sm="24"
                :md="12"
                :lg="8"
              >
                <el-button
                  type="primary"
                  class="events-btn float-right  update-results-btn"
                  @click="searchArticles"
                >
                  Update Results
                </el-button>

                <el-button
                  type="text"
                  class="reset-btn float-left"
                  @click="resetSearch"
                >
                  Reset
                </el-button>
              </el-col>
            </el-row>

            <el-row class="hidden-md-and-up">
              <el-col
                :sm="24"
                class="hidden-md-and-up"
              ></el-col>
            </el-row>
          </el-form>
        </el-col>
      </div>
    </el-row>

    <div
      v-if="showZeroData"
      class="zero-data text-center mt-5"
    >
      <div class="container">
        <el-row>
          <el-col :sm="24">
            <p>We could not find any news or resources with your search criteria. Please update your search and try again.</p>
          </el-col>
        </el-row>
      </div>
    </div>

    <div
      v-if="articles.length > 0"
      class="results-section"
    >
      <div class="container">
        <div class="mt-4">
          <el-row>
            <articles-card
              v-for="article in articles"
              :key="article.id"
              :article="article"
            />
          </el-row>
        </div>
      </div>
    </div>

    <div class="pagination-help-section">
      <div class="container center-btn">
        <el-button
          v-if="!onLastPage"
          class="load-more-btn"
          @click="fetchArticles"
        >
          Load more
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import gsApi from "@/services/gs.api";
import { mapState } from "vuex";
import TopNavigation from "../../layout/top-navigation";
import ArticlesCardPublic from "@/components/platform/news-features/public-articles-card";
export default {
  name: "CaseStudiesPublic",
  metaInfo: {
    title: "News & Resources",
    meta: [
      {
        name: "description",
        content:
          "Get the latest news and updates from the EU and Scotland, read our publications, and explore our case studies to see how we’ve been representing Scotland in Europe. Become a member for full access to our news and alerts section – including bulletin updates on policies, announcements and funding, which aren’t always covered by our public news stories.",
      },
    ],
  },
  components: {
    TopNavigation,
    "articles-card": ArticlesCardPublic,
  },
  data() {
    return {
      mainLoader: false,
      page: 1,
      perPage: 6,
      totalResults: 0,
      showZeroData: false,
      onLastPage: false,
      search: {
        sortby: null,
        keyword: "",
        sector: null,
        topic:null
      },
      articles: [],
      sectors: [],
      topics:[],
      sortOptions: [
        {
          key: 0,
          name: "A-Z",
        },
        {
          key: 1,
          name: "Z-A",
        },
        {
          key: 2,
          name: "Latest",
        },
      ],
      text: {
        headingText: "News & Resources",
        headingSub:
          "Get the latest news and updates from the EU and Scotland, read our publications, and explore our case studies to see how we’ve been representing Scotland in Europe. Become a member for full access to our news and alerts section – including bulletin updates on policies, announcements and funding, which aren’t always covered by our public news stories.  ",
      },
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  created() {
    this.getRegisterMeta();
  },
  methods: {
    getRegisterMeta() {
      this.mainLoader = true;

      gsApi.globalscot.meta
        .index()
        .then((response) => {
          this.sectors = response.data.groups.data;
          this.topics = response.data.expertise.data;
          this.fetchArticles();
        })
        .catch((error) => {
          this.mainLoader = false;
          this.$alert(
            "An unknown error occurred. Please try again later.",
            "Error",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal",
            }
          );
        });
    },
    searchArticles() {
      this.page = 1;
      this.articles = [];
      this.fetchArticles();
    },
    resetSearch() {
      this.search = {
        sortby: null,
        keyword: "",
        sector:null,
        topic:null
      };

      this.searchArticles();
    },
    fetchArticles() {
      if (!this.mainLoader) {
        this.mainLoader = true;
      }

      let params = {
        page: this.page,
        per_page: this.perPage,
        status_id: 1,
        sort_by: this.search.sortby,
        search: this.search.keyword,
        group:this.search.sector,
        topic:this.search.topic
      };

      gsApi.articles
        .public(params)
        .then((response) => {
          this.mainLoader = false;

          this.totalResults = response.data.meta.pagination.total;

          if (
            response.data.meta.pagination.current_page !==
            response.data.meta.pagination.total_pages
          ) {
            this.onLastPage = false;
            this.page++;
          } else {
            this.onLastPage = true;
          }

          this.articles = this.articles.concat(response.data.data);
          this.showZeroData = this.articles.length > 0 ? false : true;
        })
        .catch((error) => {
          this.mainLoader = false;
        });
    },
  },
};
</script>

<style lang="scss">
.featured-desc {
  margin: auto 100px auto 100px;
}

.featured-title {
  font-weight: 400;
  padding-bottom: 30px;
}

img {
  width: 100%;
  height: auto;
}

.white-background-section {
  background-color: white;
}

.events-btn {
  min-height: 40px !important;
  margin-top: 33px;
}

.reset-btn {
  margin-right: 30px;
  margin-top: 20px;
  color: black;
  margin-left: auto;
  margin-right: auto;
}

.form {
  margin-top: 50px;
}

.center-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 1.25rem;
}
</style>
