var render = function render(){var _vm=this,_c=_vm._self._c;return _c('slide-panel',{staticClass:"notifications-nav",attrs:{"is-mobile":false,"is-notification":true,"slide-right":true,"close-as-text":false,"title":"Notifications"},on:{"panelclose":_vm.closeMenu}},[_c('div',[(!_vm.isLoading && _vm.notifications.length)?_c('div',{staticClass:"actions"},[_c('el-button',{on:{"click":_vm.readAll}},[_vm._v(" Mark all as read ")]),_c('span',{staticClass:"line"},[_vm._v("|")]),_c('el-button',{on:{"click":_vm.deleteAll}},[_vm._v(" Delete all ")])],1):_vm._e(),_c('div',{staticClass:"notifications-list"},[(_vm.notifications.length)?_c('div',{staticClass:"help-requests"},[_c('ul',_vm._l((_vm.notifications),function(notification){return _c('li',{key:notification.id,class:{ 'new-not': !notification.is_read },on:{"click":function($event){return _vm.read(notification)}}},[_c('router-link',{staticClass:"chat-message-notif",attrs:{"to":"#"}},[_c('div',{staticClass:"notif-block"},[_c('div',{staticClass:"notif-img"},[(notification.sender && notification.sender.avatar)?_c('div',{staticClass:"user-img",style:({
                      'background-image': `url( ${notification.sender.avatar} )`,
                    })}):(
                      notification.action == 'profile-updated-by-admin'
                    )?_c('div',{staticClass:"user-img",style:({
                      'background-image': `url( ${notification.entity.avatar} )`,
                    })}):(
                      notification.action == 'challenge-member-joined' ||
                        notification.action == 'challenge-updated' ||
                        notification.action ==
                        'challenge-participant-removed' ||
                        notification.action == 'challenge-invite'
                    )?_c('div',{staticClass:"user-img",style:(notification.entity && notification.entity.mediaFile ? {'background-image': `url( ${ notification.entity.mediaFile.small_thumb } )`} : null)}):(notification.action === 'event-published' ||
                      notification.action === 'user-registered-for-event' ||
                      notification.action === 'event-cancelled')?_c('div'):_c('div',{staticClass:"user-img default"})]),(
                    notification.action ==
                      'chat-message-notification-dispatched'
                  )?_c('span',{staticClass:"message-title"},[_c('strong',[_vm._v(_vm._s(notification.sender.full_name)+":")]),_vm._v(" "+_vm._s(notification.entity ? notification.entity.body : "")+" ")]):(
                    notification.action ==
                      'chat-message-file-notification-dispatched'
                  )?_c('span',{staticClass:"message-title",domProps:{"innerHTML":_vm._s(notification.title)}}):(
                    notification.action ==
                      'help-request-accepted-notification-dispatched'
                  )?_c('span',{staticClass:"message-title"},[_vm._v(" "+_vm._s(notification.sender.full_name)+" responded to your help request ")]):(
                    notification.action ==
                      'chat-low-engagement-notification-dispatched'
                  )?_c('span',{staticClass:"message-title"},[_vm._v(" Your chat with "),_c('strong',[_vm._v(_vm._s(notification.sender.full_name))]),_vm._v(" has gone quiet. Let us know if you're still working together. ")]):(
                    notification.action ==
                      'help-request-declined-notification-dispatched'
                  )?_c('span',{staticClass:"message-title"},[_vm._v(" Sorry, "+_vm._s(notification.sender.full_name)+" has declined your request, '"+_vm._s(notification.entity.subject)+"'. Try searching for another GlobalScot to help you. ")]):(
                    notification.action ==
                      'help-request-notification-dispatched'
                  )?_c('span',{staticClass:"message-title"},[_vm._v(" "+_vm._s(notification.sender.full_name)+" has submitted a help request. ")]):(
                    notification.action ==
                      'help-request-ended-notification-dispatched' ||
                      notification.action ==
                      'help-request-prompt-end-notification-dispatched'
                  )?_c('span',{staticClass:"message-title"},[_vm._v(" "+_vm._s(notification.title)+" ")]):(notification.action == 'user-likes-your-post')?_c('span',{staticClass:"message-title",domProps:{"innerHTML":_vm._s(notification.title)}}):(
                    notification.action == 'profile-updated-by-admin' ||
                      notification.action == 'activity-comment' ||
                      notification.action == 'challenge-member-joined' ||
                      notification.action == 'challenge-updated' ||
                      notification.action == 'challenge-participant-removed' ||
                      notification.action == 'challenge-invite' ||
                      notification.action ==
                      'help-request-no-feedback-notification' ||
                      notification.action ==
                      'help-request-ended-notification-dispatched'
                  )?_c('span',{staticClass:"message-title"},[_vm._v(" "+_vm._s(notification.title)+" ")]):(notification.title != '')?_c('span',{staticClass:"message-title"},[_vm._v(_vm._s(notification.title))]):(notification.message != '')?_c('span',{staticClass:"message-title"},[_vm._v(_vm._s(notification.message))]):_vm._e()])])],1)}),0)]):_vm._e(),(!_vm.isLoading && !_vm.notifications.length)?_c('ul',{staticClass:"mt-4"},[_c('li',[_c('router-link',{staticClass:"chat-message-notif",attrs:{"to":"#"}},[_c('div',{staticClass:"notif-block"},[_c('div',{staticClass:"notif-img"},[_c('svg-list')],1),_c('h2',{staticClass:"message-title"},[_vm._v(" No new notifications ")])])])],1)]):_vm._e()])]),(_vm.isLoading)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"container notifications-loader"}):_vm._e(),_c('div',{staticClass:"pagination-section"},[(!_vm.onLastPage && !_vm.isLoading)?_c('el-button',{staticClass:"load-more-btn",attrs:{"size":"medium"},on:{"click":function($event){return _vm.fetchNotifications()}}},[_vm._v(" Load more ")]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }