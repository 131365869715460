<template>
  <div
    v-loading="mainLoader"
    class="gs-personal-details-container"
    element-loading-background="rgba(255, 255, 255, 1)"
  >
    <div
      v-if="!mainLoader"
      class="personal-details"
    >
      <h2>Personal details</h2>
      <el-form
        id="submitForm"
        ref="submitForm"
        :model="form"
        :rules="rules"
        @submit.prevent.native="submitGsForm"
        @validate="handleSubmitFormFieldInput"
      >
        <el-row>
          <el-col :sm="24">
            <el-form-item
              v-loading="removeAvatarLoader"
              prop="image"
              element-loading-background="rgba(255, 255, 255, 0.8)"
            >
              <div
                v-if="currentAvatar"
                class="d-flex upload-photo"
              >
                <el-avatar
                  v-if="currentAvatar"
                  shape="circle"
                  :size="140"
                  :src="currentAvatar"
                  alt="Profile picture"
                ></el-avatar>

                <el-upload
                  ref="upload"
                  action=""
                  :multiple="false"
                  list-type="picture-card"
                  class="selected-upload"
                  :auto-upload="false"
                  :on-change="onFileChange"
                  :disabled="avatarLoader"
                >
                  <div slot="default">
                    <el-button class="replace-button">
                      {{ customAvatar ? 'Replace' : 'Browse files' }}
                    </el-button>
                  </div>

                  <div
                    slot="file"
                    slot-scope="{file}"
                  ></div>
                </el-upload>
                <span
                  v-if="customAvatar"
                  class="primary-text-underline"
                  @click="removeSelectedAvatar"
                >Remove</span>
              </div>
              <span class="upload-photo-text">Upload a profile photo - Optional</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="avatarLoader">
          <el-col
            :xs="24"
            :sm="12"
            :md="12"
            :lg="12"
          >
            <el-progress
              :percentage="percentage"
              color="#A8308C"
            ></el-progress>
          </el-col>
        </el-row>
        <div class="step-hr mb-4"></div>
        <el-row>
          <el-col
            :xl="12"
            :lg="12"
            :md="18"
            :sm="18"
          >
            <validation-errors
              v-if="validationErrors.length"
              :errors="validationErrors"
            ></validation-errors>
            <el-form-item
              prop="title"
              class="is-no-asterisk form-group title"
            >
              <template slot="label">
                <span aria-hidden="true">Title<span class="is-custom-asterisk">*</span></span><br />
                <span class="sr-only">Please select a title</span>
                <span class="el-form-item__info">This won't be publicly displayed on your profile</span>
                <span
                  class="sr-only"
                  aria-live="polite"
                  :hidden="! hasInlineValidationErrorFor('title')"
                  v-text="getInlineValidationErrorFor('title')"
                ></span>
              </template>
              <v-select
                id="title"
                v-model="form.title"
                placeholder="Please select"
                :options="titles"
                :searchable="false"
                :clearable="false"
                :reduce="title => title.id"
                input-id="title"
                class="half-content"
                autocomplete="false"
              >
              </v-select>
            </el-form-item>
            <el-form-item
              prop="first_name"
              class="form-group first-name"
            >
              <template slot="label">
                First name
                <span
                  class="sr-only"
                  aria-live="polite"
                  :hidden="! hasInlineValidationErrorFor('first_name')"
                  v-text="getInlineValidationErrorFor('first_name')"
                ></span>
              </template>
              <el-input
                id="first_name"
                v-model="form.first_name"
                placeholder="Your first name"
                maxlength="64"
                auto-complete="no"
                aria-required="true"
                @blur="form.first_name = form.first_name.trim()"
              />
            </el-form-item>
            <el-form-item
              prop="last_name"
              class="form-group last-name"
            >
              <template slot="label">
                Last name
                <span
                  class="sr-only"
                  aria-live="polite"
                  :hidden="! hasInlineValidationErrorFor('last_name')"
                  v-text="getInlineValidationErrorFor('last_name')"
                ></span>
              </template>
              <el-input
                id="last_name"
                v-model="form.last_name"
                placeholder="Your last name"
                maxlength="64"
                auto-complete="no"
                aria-required="true"
                @blur="form.last_name = form.last_name.trim()"
              />
            </el-form-item>
            <el-form-item
              prop="contact_number"
              class="is-no-asterisk form-group contact-number"
            >
              <template slot="label">
                Contact number<span class="is-custom-asterisk">*</span><br />
                <span class="el-form-item__info">This won't be publicly displayed on your profile</span>
                <span
                  class="sr-only"
                  aria-live="polite"
                  :hidden="! hasInlineValidationErrorFor('contact_number')"
                  v-text="getInlineValidationErrorFor('contact_number')"
                ></span>
              </template>
              <el-input
                id="contact_number"
                v-model="form.contact_number"
                placeholder="Contact number"
                maxlength="20"
                auto-complete="no"
                aria-required="true"
                @blur="form.contact_number = form.contact_number.trim()"
              />
            </el-form-item>
            <div class="step-hr mb-4"></div>
          </el-col>
        </el-row>
        <el-row>
          <el-col
            :xl="12"
            :lg="12"
            :md="18"
            :sm="18"
          >
            <span class="group-header">Where are you primarily based for work?</span>
            <el-form-item
              prop="location_id"
              class="is-no-asterisk form-group"
            >
              <template slot="label">
                <span aria-hidden="true">Select a location<span class="is-custom-asterisk">*</span></span>
                <span class="sr-only">Please select a location</span>
                <span
                  class="sr-only"
                  aria-live="polite"
                  :hidden="! hasInlineValidationErrorFor('location_id')"
                  v-text="getInlineValidationErrorFor('location_id')"
                ></span>
              </template>
              <v-select
                v-model="form.location_id"
                placeholder="Please select"
                :options="locations"
                :searchable="true"
                :clearable="false"
                :reduce="location => location.id"
                label="name"
                input-id="location_id"
                autocomplete="false"
              />
            </el-form-item>
            <el-form-item
              v-if="showOtherLocation"
              prop="other_location"
              class="is-no-asterisk form-group"
            >
              <template slot="label">
                <span aria-hidden="true">Please enter the country you’re located in<span class="is-custom-asterisk">*</span></span>
                <span class="sr-only">Please enter the country you’re located in</span>
                <span
                  class="sr-only"
                  aria-live="polite"
                  :hidden="! hasInlineValidationErrorFor('other_location')"
                  v-text="getInlineValidationErrorFor('other_location')"
                ></span>
              </template>
              <el-input v-model="form.other_location" />
            </el-form-item>
          </el-col>
        </el-row>
        <div class="step-hr mb-4"></div>
        <el-row>
          <el-col
            :xl="16"
            :lg="16"
            :md="24"
            :sm="24"
          >
            <el-form-item
              prop="experience"
              class="is-no-asterisk form-group experience"
            >
              <span class="characters-length">You have {{ 5000 - (form.experience.length) }} characters remaining</span>
              <template slot="label">
                <span aria-hidden="true">Tell us a bit more about yourself and what you’d like to get from the network (optional)</span><br />
                <span class="el-form-item__info experience-header d-block">We’re looking for a short bio to help us and others get to know you. You can edit this any time after registering.</span>
                <span
                  class="sr-only"
                  aria-live="polite"
                  :hidden="! hasInlineValidationErrorFor('experience')"
                  v-text="getInlineValidationErrorFor('experience')"
                ></span>
              </template>
              <el-input
                id="experience"
                v-model="form.experience"
                type="textarea"
                aria-required="true"
                auto-complete="no"
                :rows="5"
                maxlength="5000"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="step-hr mb-4"></div>
        <div class="d-flex form-actions">
          <el-button
            class="btn-cancel"
            @click="$router.push({name: 'SE Profile', params: { id: user.id }})"
          >
            Cancel
          </el-button>
          <el-button
            type="primary"
            native-type="submit"
            class="btn-submit"
          >
            Save changes
          </el-button>
        </div>
      </el-form>
    </div>

    <cropper-upload
      v-if="cropperFile"
      :visible="showCropperDialog"
      :cropper-file="cropperFile"
      @upload="onCropperUpload"
      @close="onCropperDialogClose"
    ></cropper-upload>
  </div>
</template>

<script>
import store from '@/store'
import { mapState } from "vuex"
import gsApi from '@/services/gs.api'
import loqateApi from '@/services/loqate.api'
import CropperUpload from '@/components/cropper-upload/cropper-upload'
import validationErrors from '@/components/partials/validation-errors'
import inlineValidationErrorsMixin from '@/mixins/inlineValidationErrorsMixin'
export default {
  name: "PersonalDetails",

  components: {
    validationErrors,
    'cropper-upload': CropperUpload,
  },
  mixins: [inlineValidationErrorsMixin],

  data() {
    let validateContactNumber = (rule, value, callback) => {
      let reg = /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i

      let length = value.length;

      if (value==='') {
        callback(new Error('Contact number is required'))
      } else if (length < 7) {
        callback(new Error('Contact number may not be less than 7 characters'))
      } else if (length > 20) {
        callback(new Error('Contact number may not be greater than 20 characters'))
      } else if (reg.test(value)==false) {
        callback(new Error('Contact number field must be valid'))
      } else {
        callback()
      }
    };

    const validateMetaCharacters = (rule, value, callback)=>{
      const reg =/^[0-9a-zA-Z_' \-]*$/i;

      if (value==='') {
        callback(new Error(rule.customMessage))
      }
      else if(reg.test(value) == false){
        callback(new Error('Only alpha numeric characters are accepted'))
      }else{
        callback()
      }
    };

    return {
      percentage : 1,
      cropperFile: null,
      showCropperDialog: false,
      validationErrors: [],
      mainLoader: false,
      avatarLoader: false,
      removeAvatarLoader:false,
      currentAvatar: store.state.user.avatar ? store.state.user.avatar : '',
      customAvatar: store.state.user.hasCustomAvatar,
      locations: [],
      form: {},
      rules: {
        title: [
          { required: true, message: 'Please select a title', trigger: 'change' },
        ],
        first_name: [
          { validator:validateMetaCharacters, customMessage: "First name is required "},
        ],
        last_name: [
          {validator:validateMetaCharacters , customMessage: "Last name is required"},
        ],
        contact_number: [
          { validator: validateContactNumber, required: true },
        ],
        nationality_id: [
          { required: true, message: 'Nationality is required', trigger: 'change' },
        ],
        country_id: [
          { required: true, message: 'Country is required', trigger: 'change' },
        ],
        state_id: [
          { required: true, message: 'State is required', trigger: 'change' },
        ],
        experience: [
          { required: true, message: 'Business Experience is required', trigger: 'blur' },
          { max: 5000, message: 'Business Experience may not be greater than 5000 characters', trigger: 'change' },
        ],
        address: {
          post_code: [
            { required: true, message: 'Post code/Zip code is required', trigger: 'blur' },
            { max: 30, message: 'Post code/Zip code may not be greater than 30 characters', trigger: 'blur' },
          ],
          address_1: [
            { required: true, message: 'Address line 1 is required', trigger: 'blur' },
            { max: 255, message: 'Address line 1 may not be greater than 255 characters', trigger: 'blur' },
          ],
          address_2: [
            { max: 255, message: 'Address line 2 may not be greater than 255 characters', trigger: 'blur' },
          ],
          town: [
            { required: true, message: 'Town, City or State is required', trigger: 'blur' },
            { max: 100, message: 'Town, City or State may not be greater than 100 characters', trigger: 'blur' },
          ],
          county: [
            { max: 100, message: 'Region may not be greater than 100 characters', trigger: 'blur' },
          ],
          country_id: [
            { required: true, message: 'Country is required', trigger: 'change' },
          ],
        }
      },
      titles: [
        {id: 1, label: 'Mr'},
        {id: 2, label: 'Mrs'},
        {id: 3, label: 'Ms'},
        {id: 4, label: 'Miss'},
        {id: 6, label: 'Dr'},
        {id: 7, label: 'Prof'},
        {id: 8, label: 'Sir'},
        {id: 9, label: 'Dame'},
        {id: 10, label: 'Reverend'},
        {id: 11, label: 'Duke'},
        {id: 12, label: 'Duchess'},
        {id: 5, label: 'Other'},
        {id: 13, label: "Mx"},
      ],
      addressesLoading: false,
      disableAddressLookup: false,
      selectedAvailableMailingAddress: null,
      availableMailingAddresses: []
    }
  },
  computed: {
    ...mapState(['user']),
    showOtherLocation(){
      if(!this.form.location_id) return false;
      const locationFound =  this.locations.find(location => location.id == this.form.location_id);
      return (locationFound && locationFound.is_other);
    }
  },
  created() {
    this.getRegisterMeta()
    this.initForm()
  },
  methods: {
    // Start of upload / cropper methods
    removeSelectedAvatar() {
      this.removeAvatarLoader = true

      gsApi.member.user.removeAvatar()
        .then(response => {
          this.removeAvatarLoader = false

          this.currentAvatar = response.data.avatar
          this.customAvatar = response.data.has_custom_avatar

          store.commit('USER_AVATAR_CHANGE', response.data)
          this.$alert(response.data.message, 'Success', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
        })
        .catch(error => {
          console.log(error)
          this.removeAvatarLoader = false
          this.$alert('An unknown error occurred. Please try again later.', 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
        })
    },
    onFileChange(file) {
      if (this.validateAvatarUpload(file)) {
        this.cropperFile = file
        this.showCropperDialog = true
      }
    },
    onCropperUpload(croppedFileData) {
      this.onAvatarUpload(croppedFileData.file)
    },
    onAvatarUpload (file) {
      const formData = new FormData()

      formData.append("avatar", file)

      this.showCropperDialog = false
      this.cropperFile = null
      this.percentage = 1;
      this.avatarLoader = true
      let interval = 0;
      gsApi.member.user.updateAvatar(formData,(progress) => {
        interval = setInterval(()=>{
          if(this.percentage < 100){
            this.percentage+= 1;
          }
        },15 )
      })
        .then(response => {
          this.currentAvatar = response.data.avatar
          this.customAvatar = response.data.has_custom_avatar

          store.commit('USER_AVATAR_CHANGE', response.data)
          this.$alert(response.data.message, 'Success', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
        })
        .catch(error => {
          console.log(error)
          this.$alert('An unknown error occurred. Please try again later.', 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
        })
        .finally(() => {
          this.avatarLoader = false;
          clearInterval(interval);
        });
    },
    onCropperDialogClose() {
      this.showCropperDialog = false
      this.cropperFile = null
    },
    validateAvatarUpload(file) {
      let type = (file.raw && file.raw.type) ? file.raw.type : file.type
      let size = (file.raw && file.raw.size) ? file.raw.size : file.size

      const isJPG = type === 'image/jpeg';
      const isPNG = type === 'image/png';
      const isLt6M = size / 1024 / 1024 < 6;

      if (!isJPG && !isPNG) {
        this.$alert('Avatar must be in a jpeg or png format.', 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
      } else if (!isLt6M) {
        this.$alert('Avatar size can not exceed 6MB.', 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
      }

      return (isJPG || isPNG) && isLt6M;
    },
    // End of upload / cropper methods

    onFindAddress (e) {
      e.preventDefault()
      this.findAddresses(this.form.address.post_code)
    },
    findAddresses (value, containerId='') {
      this.addressesLoading = true
      loqateApi.find(value, containerId, '')
        .then(response => {
          this.addressesLoading = false

          if (response.data && response.data.Items && response.data.Items.length > 0) {
            if (response.data.Items[0].Error) {
              this.$alert('Failed to find any addresses using postcode provided. Please enter mailing address details instead.', 'No addresses found', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
            } else if (response.data.Items[0].Type != "Address") {
              this.findAddresses(value, response.data.Items[0].Id)
            } else if (!response.data.Items[0].Error) {
              this.form.address.selectedAvailableMailingAddress = null
              this.form.address.address_1 = ''
              this.form.address.address_2 = ''
              this.form.address.town = ''
              this.form.address.county = ''
              this.form.address.country_id = null

              this.disableAddressLookup = true
              this.availableMailingAddresses = response.data.Items
            }
          } else {
            this.availableMailingAddresses = []
            this.$alert('Failed to find any addresses using postcode provided. Please enter mailing address details instead.', 'No addresses found', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
          }
        })
        .catch(error => {
          this.addressesLoading = false
          this.availableMailingAddresses = []
          this.$alert('Failed to find any addresses using postcode provided. Please enter mailing address details instead.', 'No addresses found', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
        })
    },
    onAvailableMailingAddressSelected (address) {
      this.retrieveAddress(address.Id)
    },
    retrieveAddress (id) {
      loqateApi.retrieve(id)
        .then(response => {
          /*if (response.data && response.data.Items && response.data.Items.length == 1 && !response.data.Items[0].Error) {
           this.disableAddressLookup = true

            let address = response.data.Items[0]
            this.form.address.address_1 = address.Line1
            this.form.address.address_2 = address.Line2
            this.form.address.post_code = address.PostalCode
            this.form.address.town = address.City
            this.form.address.county = address.AdminAreaName

            let country = this.countries.find(c => (c.code == address.CountryIso2 || c.code == address.CountryIso3))
            if (country) {
              this.form.address.country_id = country.id
            }
          } else {
            this.$alert('Failed to automatically use the selected address. Please enter mailing address details instead.', 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
          }*/
        })
        .catch(error => {
          this.$alert('Failed to automatically use the selected address. Please enter mailing address details instead.', 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
        })
    },
    getRegisterMeta() {
      gsApi.globalscot.meta.index()
        .then(response => {
          if (store.state.user.expertProfile.location) {
            this.form.location_id = store.state.user.expertProfile.location.id;
          }
          if (store.state.user.expertProfile.other_location) {
            this.form.other_location = store.state.user.expertProfile.other_location;
          }
          this.locations = response.data.locations.data;
        }).catch((error) => {
          console.log(error)
          this.$alert('An unknown error occurred. Please try again later.', 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
        })
    },
    submitGsForm() {
      let self = this;
      this.$refs['submitForm'].validate((valid) => {
        if (valid) {
          const formData = new FormData()

          formData.append('title', this.form.title)
          formData.append('first_name', this.$options.filters.domPurify(this.form.first_name, {ALLOWED_TAGS: []}))
          formData.append('last_name', this.form.last_name)
          formData.append('contact_number', this.form.contact_number)
          formData.append('location_id', this.form.location_id)
          formData.append('other_location', this.showOtherLocation ? this.form.other_location : "")
          formData.append('experience', this.$options.filters.domPurify(this.form.experience, {ALLOWED_TAGS: []}))

          this.mainLoader = true

          gsApi.globalscot.user.updatePersonalInfo(formData)
            .then(response => {
              self.$store.commit('UPDATE_GS_PERSONAL_DETAILS', response.data);
              console.log(response.data)
              this.initForm(response.data)
              this.mainLoader = false;
              this.validationErrors = [];
              this.$alert('You successfully updated your profile', 'Success', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
            })
            .catch(error => {
              this.validationErrors = [];
              this.mainLoader = false

              if (error.response && error.response.status == 422 && error.response.data) {
                let errors = error.response.data.errors;
                for (let [key, value] of Object.entries(errors)) {
                  let obj = {}
                  obj.id = key;
                  obj.message = value[0];
                  this.validationErrors.push(obj);
                }
                window.scrollTo({top: 0, behavior: 'smooth'});
              } else {
                this.$alert('There are errors in the form, please fix these errors and try again.', 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
              }
            })
        } else {
          this.validationErrors = [];
          this.$refs.submitForm.fields.forEach(field => {
            if(field.validateState == 'error') {
              let obj = {}
              obj.id = field.labelFor;
              obj.message = field.validateMessage;
              this.validationErrors.push(obj);
            }
          });
          this.$nextTick(() => {
            document.getElementById('formErrorBlock').scrollIntoView({ behavior: 'smooth', block: 'start' })
            let text = document.getElementById("submitForm").getElementsByClassName('el-form-item__error')[0].textContent
            this.$announcer.set(text)
          })
        }
      });
    },
    getStatesFromCountry(countryId, eraseStateId = true) {
      if (eraseStateId) {
        this.form.state_id = null
      }

      this.mainLoader = true

      gsApi.state.index(countryId)
        .then(response => {
          this.mainLoader = false
          this.states = response.data.data
        })
    },
    handleSubmitFormFieldInput () {
      this.handleSingleFormFieldInput('submitForm');
    },
    initForm(userResponse){
      this.form= {
        title: store.state.user.title ? store.state.user.title : null,
        first_name: store.state.user.firstName ? store.state.user.firstName : '',
        last_name: store.state.user.lastName ? store.state.user.lastName : '',
        avatar: null,
        contact_number: store.state.user.expertProfile ? store.state.user.expertProfile.contact_number : null,
        location_id: userResponse && userResponse.expertProfile ? userResponse.expertProfile.location_id :
          store.state.user.expertProfile ? store.state.user.expertProfile.location_id : null,
        other_location:  userResponse && userResponse.expertProfile ? userResponse.expertProfile.other_location : store.state.user.expertProfile 
          ? store.state.user.expertProfile.other_location : '',
        experience: store.state.user.expertProfile ? store.state.user.expertProfile.experience : '',
      }
    }
  }
}
</script>
