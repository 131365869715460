<template>
  <section
    class="white-background-section"
    aria-label="Reasons for joining"
  >
    <div class="container">
      <div class="heading-text mb-30 py-0 d-flex justify-content-between">
        <h2 class="what-you-get-title mt-4">
          {{ whatYouGetSectionText.heading }}
        </h2>
      </div>
      <el-row>
        <el-col
          v-for="(card, index) in boxCards"
          :key="index"
          class="card-container"
          :sm="24"
          :md="12"
          :lg="6"
          :xl="6"
        >
          <el-card
            class="box-card"
            shadow="always"
          >
            <div class="what-you-get-card">
              <div class="what-you-get-card-icon">
                <svg-connections
                  v-if="index === 3"
                  class="gs-icon"
                ></svg-connections>
                <svg-planet-with-magnify
                  v-if="index === 1"
                  class="icon"
                ></svg-planet-with-magnify>
                <svg-diverse-community
                  v-if="index === 2"
                  class="icon"
                ></svg-diverse-community>
                <svg-insider-insights
                  v-if="index === 0"
                  class="icon"
                ></svg-insider-insights>
              </div>
              <div>
                <h3 class="what-you-get-sub-text">
                  {{ card.text }}
                </h3>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>

      <el-button
        class="btn-text about-membership-btn"
        size="small"
        @click="$router.push({name: 'Membership'})"
      >
        Find out more about membership
      </el-button>

      <proud-community></proud-community>

      <!--div>
        <get-support></get-support>
      </div-->
    </div>
  </section>
</template>
<script>
import ConnectionsIcon from "@/components/assets/svg-connections";
import PlanetMagnifyIcon from "@/components/assets/svg-planet-with-magnify";
import DiverseCommunityIcon from "@/components/assets/svg-diverse-community";
import InsiderInsightsIcon from "@/components/assets/svg-insider-insights";
import ProudCommunity from "../../partials/proud-community";
export default {
  components: {
    "svg-connections": ConnectionsIcon,
    "svg-planet-with-magnify": PlanetMagnifyIcon,
    "svg-diverse-community": DiverseCommunityIcon,
    "svg-insider-insights": InsiderInsightsIcon,
    ProudCommunity,
  },
  data() {
    return {
      whatYouGetSectionText: {
        heading: "Why join Scotland Europa?"
      },
      boxCards: [
        {
          text: "Updates and analysis of relevant EU policy developments"
        },
        {
          text: "EU funding and project support"
        },
        {
          text: "Learning opportunities and capacity-building to help achieve your EU ambitions"
        },
        {
          text: "Access to our extensive networks and contacts in the EU"
        }
      ]
    };
  }
};
</script>
<style>
</style>
