<template><svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
<circle cx="22.7573" cy="22.7573" r="22.7573" fill="white"/>
<path d="M37.4829 23.2034L16.0643 35.5694L16.0643 10.8373L37.4829 23.2034Z" fill="url(#paint0_linear)"/>
<defs>
<linearGradient id="paint0_linear" x1="29.8703" y1="33.5665" x2="8.09999" y2="5.13429" gradientUnits="userSpaceOnUse">
<stop stop-color="#A8308C"/>
<stop offset="0.517454" stop-color="#EA6576"/>
<stop offset="1" stop-color="#F7F248"/>
</linearGradient>
</defs>
</svg></template><script>export default {}</script>
