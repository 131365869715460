<template>
  <section
    class="white-background-section white-background-section-padding-fix"
    aria-label="Latest news articles"
  >
    <div class="container">
      <div class="heading-text mb-30 py-0 d-flex justify-content-between">
        <h2 class="spotlight-title">
          {{ spotlightText.heading }}
        </h2>
      </div>

      <el-row>
        <div
          v-if="articles.length == 0"
          class="no-gs-available"
        >
          <h2>{{ nothingAvailable }}</h2>
        </div>
        <div v-else>
          <news-card
            v-for="article in articles"
            :key="article.id"
            :article="article"
            :is-public="true"
          />
        </div>
      </el-row>
    </div>
  </section>
</template>
<script>
import NewsCard from "@/components/platform/news-features/news-card";
export default {
  components: {
    NewsCard
  },
  props: ["articles"],
  data() {
    return {
      spotlightText: {
        heading: "Latest news"
      },
      nothingAvailable: "No news available"
    };
  }
};
</script>
<style lang="scss">
.no-gs-available h2 {
  font-size: $font-size-28;
  text-align: center;
  margin: 50px;
  font-weight: 400;
}
</style>
