<!-- Content Component -->

<template>
    <main id="content" role="main">
        <router-view></router-view>
    </main>
</template>

<script>
    export default {}
</script>