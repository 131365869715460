<template>
  <el-row class="m-0 colleague-card d-flex">
    <el-col
      :span="17"
      class="colleague-card-item  my-2"
    >
      <div class="d-flex pb-2 pt-2">
        <div
          class="clickable"
          @click="
            $router.push({ name: getPathName, params: { id: colleague.id } })
          "
        >
          <user-avatar
            avatar-classes="flex-shrink-0"
            shape="circle"
            :is-global-scot="true"
            :author-exist="!!colleague"
            width="45px"
            :size="45"
            :src="colleague.avatar"
            :alt="`${colleague.full_name} Profile picture`"
          ></user-avatar>
        </div>
        <div
          class="full-name clickable"
          @click="
            $router.push({ name: getPathName, params: { id: colleague.id } })
          "
        >
          <span class="sr-only">{{ colleague.full_name }}</span>
          <span
            tabindex="0"
            @keyup.enter="
              $router.push({ name: getPathName, params: { id: colleague.id } })
            "
          >{{ colleague.full_name }}</span>
        </div>
      </div>
      <div class="d-flex">
        <div class="position">
          {{ ` ${colleague.organisation_name} | ${colleague.role}` }}
        </div>
        <div
          id="fake-space"
          class="ml-auto flex-shrink-0"
        ></div>
      </div>
    </el-col>
    <el-col
      :span="7"
      class="view-profile-link"
    >
      <router-link
        :to="{
          name: getPathName,
          params: { id: colleague.id, showBackToSearch: true },
        }"
      >
        View profile
        <svg-purple-arrow-right class="arrow"></svg-purple-arrow-right>
      </router-link>
    </el-col>
  </el-row>
</template>
<script>
import UserAvatar from "@/components/core/user-avatar";
import SvgPurpleArrowRight from "@/components/assets/svg-purple-arrow-right";
export default {
  components:{
    UserAvatar,
    SvgPurpleArrowRight,
  },
  props: {
    colleague: {
      type:Object,
      required:true,
      default:()=>{}
    },
  },
  computed: {
    getPathName() {
      return "SE Profile"
    }
  },
};
</script>
