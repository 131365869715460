import Vue from 'vue'
import Vuex from 'vuex'
import gsApi from '@/services/gs.api'
import createPersistedState from 'vuex-persistedstate'
import enumHelper from "@/helpers/enumHelper";
Vue.use(Vuex)

function getInitialState()
{
  return {
    app: {
      authIsReady: false,
      redirectUrl: null,
      loggedOutBySuspension: false,
      loggedOutByClosedAccount: false,
      closedAccountEmailAddress: null,
      suspendedAccountEmailAddress : null,
    },
    user: {
      isLogged: false,
      id: null,
      token: null,
      email: null,
      title: null,
      firstName: null,
      lastName: null,
      fullName: null,
      avatar: null,
      customAvatar: null,
      hasCustomAvatar: null,
      groups: null,
      address: null,
      //businesses: [],
      expertProfile: null,
      traderProfile: null,
      staffProfile: null,
      roles: null,
      unreadNots: null,
      activated: false,
      activated_message: null,
      //mailing_address_id: null,
      awaiting_verification_email : null,
      timezone: null,
      availableMfaMethods:null,
      needsConfirmation:false,
    },
    unreadNots: 0,
    selectedChat: {
      id: null,
      isAuthor: null,
      type: null,
      updatedAt: null,
      members: [],
      lastMessage: null,
      helpRequest: null,
      challenge: null
    },
    search: {
      type: "members",
      keyword: "",
      organisation:null,
      //country: null,
      //sector: null,
      //expertise: null,
      view: "list",
      order_by: '',
      order: '',
    },
    reasons: [],
    showNotificationsPanel: false,
    showNotificationModal: false,
    modals: {
      pulse: {
        isOpen: false,
        post: null,
        pulsePostId: null,
      },
      project: {
        isOpen: false,
        post: null,
        projectPostId: null,
      },
      request: {
        request: null,
        isOpen: false,
      },
      requestDeclined: {
        member_name: null,
        isOpen: false,
      }
    },
    hashtagFilter: null,
    navigatedFrom: {
      name: null,
    },
    scrollToActivityHistory: {
      tabName: null,
    },
    bookingSlotDetailsModal: {
      event_host_id: null,
      isOpen: false,
    }
  };
}

const state = getInitialState()

const mutations = {
  USER_LOGIN_UNVERIFIED(state, data) {
    state.user.isLogged = false;
    state.user.id = data.id;
    state.user.email = data.email;
    state.user.token = data.token;
    state.user.activated = data.activated;
  },

  USER_LOGIN_FOR_MULTI_FACTOR(state, data) {
    state.user.needsConfirmation = true;
    state.user.isLogged = false;
    state.user.token = data.token;
  },


  UPDATE_MEMBER_SEARCH(state, data) {
    state.search.sortBy = data.sortBy;
    state.search.keyword = data.keyword;
    state.search.organisation = data.organisation;
    //state.search.sector = data.sector;
    //state.search.expertise = data.expertise;
    state.search.view = data.view;
    state.search.type = data.type;
  },

  USER_LOGIN(state, data) {
    state.user.isLogged = true;
    state.user.id = data.id;
    state.user.email = data.email;
    state.user.token = data.token;
    state.user.activated = data.activated;
    state.user.title = data.title;
    state.user.firstName = data.first_name;
    state.user.lastName = data.last_name;
    state.user.fullName = `${data.first_name} ${data.last_name}`;
    state.user.avatar = data.avatar;
    state.user.hasCustomAvatar = data.has_custom_avatar;
    state.user.groups = data.staffProfile ? null : data.groups.data;
    state.user.roles = data.roles;
    // state.user.permissions = data.permissions.data[0]
    state.user.address = data.staffProfile ? null : data.address;
    //state.user.businesses = data.staffProfile ? null : data.businesses.data;
    state.user.expertProfile = data.expertProfile;
    state.user.traderProfile = data.traderProfile;
    state.user.staffProfile = data.staffProfile;
    state.user.roles = data.roles;
    /*state.user.mailing_address_id = data.staffProfile
      ? null
      : data.mailing_address_id;
    state.user.address = data.address;*/
    state.user.awaiting_verification_email = data.awaiting_verification_email;
    state.user.timezone = data.timezone;
    state.user.availableMfaMethods = data.availableMfaMethods,
    state.user.needsConfirmation= false
  },

  USER_LOGOUT(state) {
    window.localStorage.clear();
    Object.assign(state, getInitialState());
  },

  SET_CLOSED_ACCOUNT_EMAIL_ADDRESS (state, email) {
    state.app.closedAccountEmailAddress = email
  },

  SET_SUSPNEDED_ACCOUNT_EMAIL_ADDRESS(state,email){
    state.app.suspendedAccountEmailAddress = email;
  },
  USER_LOGOUT_BY_CLOSED_ACCOUNT (state) {
    state.app.loggedOutByClosedAccount = true
    state.app.closedAccountEmailAddress = state.user.email
    window.localStorage.clear()
    Object.assign(state, getInitialState())
  },

  USER_LOGOUT_BY_SUSPENSION (state) {
    state.app.loggedOutBySuspension = true;
    state.app.suspendedAccountEmailAddress =  state.user.email
    window.localStorage.clear()
    Object.assign(state, getInitialState())
  },

  RESET_LOGGED_OUT_CLOSED_ACCOUNT (state) {
    state.app.loggedOutByClosedAccount = false
    state.app.closedAccountEmailAddress = null
  },

  RESET_LOGGED_OUT_BY_SUSPENSION (state) {
    state.app.loggedOutBySuspension = false
    state.app.suspendedAccountEmailAddress = null;
  },

  USER_AVATAR_CHANGE(state, data) {
    state.user.avatar = data.avatar;
    state.user.hasCustomAvatar = data.has_custom_avatar;
  },

  APP_AUTH_IS_READY(state, ready) {
    state.app.authIsReady = ready;
  },

  UPDATE_MEMBER_PERSONAL_DETAILS(state, data) {
    state.user.title = data.title;
    state.user.firstName = data.first_name;
    state.user.lastName = data.last_name;
    state.user.traderProfile.contactNumber = data.traderProfile.contact_number;
  },

  UPDATE_MAILING_ADDRESS(state, data) {
    state.user.mailing_address_id = data.mailing_address_id;
    state.user.address = data.address;
  },

  UPDATE_MEMBER_PLANS_AMBITIONS(state, data) {
    state.user.traderProfile.ambitions = data.traderProfile.ambitions;
    state.user.traderProfile.aspects = data.traderProfile.aspects;
    state.user.traderProfile.regions = data.traderProfile.regions;
  },

  UPDATE_GS_PERSONAL_DETAILS(state, data) {
    state.user.title = data.title;
    state.user.firstName = data.first_name;
    state.user.lastName = data.last_name;
    state.user.expertProfile.contact_number = data.expertProfile.contact_number;
    state.user.expertProfile.nationality = data.expertProfile.nationality;
    state.user.expertProfile.country = data.expertProfile.country;
    state.user.expertProfile.state = data.expertProfile.state;
    state.user.expertProfile.experience = data.expertProfile.experience;
    state.user.expertProfile.educations = data.expertProfile.educations;
    state.user.address = data.address;
  },

  UPDATE_GS_EXPERIENCE_EXPERTISE(state, data) {
    state.user.groups = data.groups.data;
    state.user.expertProfile.countries = data.expertProfile.countries;
    state.user.expertProfile.expertise = data.expertProfile.expertise;
    state.user.expertProfile.activities = data.expertProfile.activities;
  },

  UPDATE_STAFF_PERSONAL_DETAILS(state, data) {
    state.user.title = data.title;
    state.user.firstName = data.first_name;
    state.user.lastName = data.last_name;
    state.user.staffProfile.contact_number = data.staffProfile.contact_number;
    state.user.staffProfile.organization.id = data.staffProfile.organization.id;
    state.user.staffProfile.expectations = data.staffProfile.expectations;
    state.user.staffProfile.custom_organization =
      data.staffProfile.custom_organization;
    state.user.staffProfile.country = data.staffProfile.country;
    state.user.staffProfile.state = data.staffProfile.state;
  },

  UPDATE_MULTI_FACTOR_METHODS(state, data) {
    state.user.availableMfaMethods = data;
  },
  UPDATE_NOTS(state, value) {
    state.unreadNots = value;
  },

  UPDATE_SELECTED_CHAT(state, data) {
    state.selectedChat.id = data.id;
    state.selectedChat.isAuthor = data.is_author;
    state.selectedChat.type = data.type;
    state.selectedChat.updatedAt = data.updated_at;
    state.selectedChat.members = data.members;
    state.selectedChat.lastMessage = data.last_message;
    state.selectedChat.helpRequest = data.help_request
      ? data.help_request
      : null;
    state.selectedChat.challenge = data.challenge ? data.challenge : null;
  },

  END_CHAT_BY_HELP_REQUEST(state) {
    state.selectedChat.helpRequest.has_ended = true;
  },

  REQUEST_TO_END_CHAT(state) {
    state.selectedChat.helpRequest.has_been_requested_to_end = true;
  },

  FEEDBACK_HAS_BEEN_RECIEVED(state) {
    state.selectedChat.helpRequest.has_feedback = true;
  },

  UPDATE_BUSINESSES(state, businesses) {
    state.user.businesses = businesses;
  },
  REPORT_REASONS(state, data){
    state.reasons = data;
  },
  UPDATE_USER_EMAIL(state, data){
    state.user.awaiting_verification_email = data.awaiting_verification_email; // if not null => user have to activate his new address email to be used in next login
  },
  OPEN_PULSE_NOTIFICATION_MODAL(state, data) {
    state.modals.pulse.post = data.post;
    state.modals.pulse.isOpen = data.isOpen;
    state.modals.pulse.pulsePostId = data.pulsePostId;
  },
  OPEN_PROJECT_NOTIFICATION_MODAL(state, data) {
    state.modals.project.post = data.post;
    state.modals.project.isOpen = data.isOpen;
    state.modals.project.projectPostId = data.projectPostId;
  },
  OPEN_REQUEST_NOTIFICATION_MODAL(state, data) {
    state.modals.request.request = data.request;
    state.modals.request.isOpen = data.isOpen;
  },
  OPEN_REQUEST_DECLINED_MODAL(state, data) {
    state.modals.requestDeclined.member_name = data.member_name;
    state.modals.requestDeclined.isOpen = data.isOpen;
  },
  OPEN_BOOKING_SLOT_DETAILS_MODAL(state, data) {
    state.bookingSlotDetailsModal.event_host_id = data.event_host_id;
    state.bookingSlotDetailsModal.isOpen = true;
  },
  CLOSE_PULSE_NOTIFICATION_MODAL(state) {
    state.modals.pulse.post = null;
    state.modals.pulse.isOpen = false;
    state.modals.pulse.pulsePostId = null;
  },
  CLOSE_PROJECT_NOTIFICATION_MODAL(state) {
    state.modals.project.post = null;
    state.modals.project.isOpen = false;
    state.modals.project.projectPostId = null;
  },
  CLOSE_REQUEST_NOTIFICATION_MODAL(state) {
    state.modals.request.request = null;
    state.modals.request.isOpen = false;
  },
  CLOSE_REQUEST_DECLINED_MODAL(state) {
    state.modals.requestDeclined.member_name = null;
    state.modals.requestDeclined.isOpen = false;
  },
  CLOSE_BOOKING_SLOT_DETAILS_MODAL(state) {
    state.bookingSlotDetailsModal.event_host_id = null;
    state.bookingSlotDetailsModal.isOpen = false;
  },
  FILTER_POSTS_BY_SELECTED_HASHTAG(state, data) {
    state.hashtagFilter = data;
  },
  CLEAR_HASHTAG_FILTER(state) {
    state.hashtagFilter = null;
  },
  UPDATE_NAVIGATED_FROM(state, data) {
    state.navigatedFrom.name = data.name
  },
  UPDATE_SCROLL_TO_ACTIVITY_HISTORY(state, data) {
    state.scrollToActivityHistory.tabName = data.tabName;
  },
  CLEAR_SCROLL_TO_ACTIVITY_HISTORY(state) {
    state.scrollToActivityHistory.tabName = null;
  },
  UPDATE_REDIRECT_TO_URL(state,url){
    state.app.redirectUrl = url
  }
};

const actions = {
  appAuthLoading ({commit, state}) {
    return new Promise((resolve, reject) => {
      if (!state.user.isLogged && !state.user.id) {
        commit('APP_AUTH_IS_READY', true)
      }
      if (state.app.authIsReady) {
        resolve()
      } else {
        gsApi.auth.validateToken(state.user.token)
          .then((response) => {
            if (response.data.activated === true) {
              commit('USER_LOGIN', response.data)
            } else {
              commit('USER_LOGIN_UNVERIFIED', response.data)
            }
            commit('APP_AUTH_IS_READY', true)
            resolve()
          })
          .catch((error) => {
            // commit('USER_LOGOUT')
            commit('APP_AUTH_IS_READY', true)
            resolve()
          })
      }
    })
  },
  closePulseModal({commit}) {
    commit('CLOSE_PULSE_NOTIFICATION_MODAL');
  },
  closeProjectPostModal({commit}) {
    commit('CLOSE_PROJECT_NOTIFICATION_MODAL');
  },
  closeRequestModal({commit}) {
    commit('CLOSE_REQUEST_NOTIFICATION_MODAL');
  },
  closeRequestDeclinedModal({commit}) {
    commit('CLOSE_REQUEST_DECLINED_MODAL');
  },
  closeBookingSlotDetailsModal({commit}) {
    commit('CLOSE_BOOKING_SLOT_DETAILS_MODAL');
  },
  addHashtagFilter({commit}, hashtag) {
    commit('FILTER_POSTS_BY_SELECTED_HASHTAG', hashtag);
  },
  clearHashtagFilter({commit}) {
    commit('CLEAR_HASHTAG_FILTER');
  },
  updateNavigatedFrom({commit}, data) {
    commit('UPDATE_NAVIGATED_FROM', data);
  },
  updateScrollToActivityHistory({commit}, data) {
    commit('UPDATE_SCROLL_TO_ACTIVITY_HISTORY', data);
  },
  clearScrollToActivityHistory({commit}) {
    commit('CLEAR_SCROLL_TO_ACTIVITY_HISTORY');
  },
}

const getters = {
  userRequiresVerification(state) {
    return state.user.id && state.user.email && !state.user.activated;
  },
  user: ({ user }) => user,
  isExpert: ({ user }) => user.id && user.roles[0] == "expert",
  isTrader: ({ user }) => user.id && user.roles[0] == "trader",
  isStaff: ({ user }) => user.id && user.roles[0] == "staff",
  businesses: ({ user }, { isTrader }) => {
    if (isTrader) return user.businesses;
    return user.businesses.filter(business => business.type == enumHelper.BusinessTypeEnum.EXPERT);
  },
  otherBusinesses: ({ user }) => {
    return user.businesses.filter(business => business.type == enumHelper.BusinessTypeEnum.EXPERT_OTHER);
  },
  contactNumber: ({ user }, { isTrader, isExpert, isStaff }) => {
    if (isTrader) {
      return user.traderProfile.contact_number;
    } else if (isExpert) {
      return user.expertProfile.contact_number;
    } else if (isStaff) {
      return user.staffProfile.contact_number;
    }

    return "";
  },
  reasons: ({ reasons }) => reasons,
  hashtagFilter: ({ hashtagFilter }) => hashtagFilter,
  navigatedFrom: ({navigatedFrom}) => navigatedFrom,
  scrollToActivityHistory: ({scrollToActivityHistory}) => scrollToActivityHistory,
  bookingSlotDetailsModal: ({ bookingSlotDetailsModal }) => bookingSlotDetailsModal,
};

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state,
  actions,
  getters,
  mutations,
  plugins: [createPersistedState({
    paths: ['user'],
  })]
})
