<template>
  <div class="news-and-feed white-background-section mt-2">
    <div class="container">
      <div class="heading-text">
        <h2>Collaborative Projects</h2>
        <h2 class="view-pulse-feed view-page-link hidden-sm-and-down">
          <router-link :to="{name:  'Projects'}">
            View all projects
            <svg-purple-arrow-right class="arrow"></svg-purple-arrow-right>
          </router-link>
        </h2>
      </div>
      <div>
        <div class="results-section">
          <div class="mt-4">
            <el-row v-if="projects.length > 0">
              <project-card v-for="project in projects" :key="project.id" :project="project" />
            </el-row>
            <el-row v-else>
              <el-col>
                <div class="no-event-text">
                  <h1>No projects are available yet</h1>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <h2 class="view-pulse-feed view-page-link hidden-md-and-up">
        <router-link :to="{name:  'Projects'}">
          View all projects
          <svg-purple-arrow-right class="arrow"></svg-purple-arrow-right>
        </router-link>
      </h2>
    </div>
  </div>
</template>

<script>
import PurpleArrowRight from "@/components/assets/svg-purple-arrow-right";
import ProjectCard from "@/components/platform/projects/project-card";
import store from "../../store";
import router from "../../router";
import gsApi from "@/services/gs.api";

export default {
  components: {
    ProjectCard,
    "svg-purple-arrow-right": PurpleArrowRight
  },
  props: ['projects'],
  data() {
    return {}
  }
}
</script>
