<template>
  <div
    v-loading.fullscreen.lock="mainLoader"
    class="gs-profile-page body__content"
    element-loading-background="rgba(255, 255, 255, 0.8)"
  >
    <div class="back-to-search-section">
      <div class="container">
        <router-link
          v-if="showBackToSearch"
          :to="{ name: 'Members' }"
          class="back-to-search-link"
        >
          <span>
            <svg-white-arrow-left class="mr-1"></svg-white-arrow-left>Back to
            search
          </span>
        </router-link>
      </div>
    </div>
    <div v-if="organisation">
      <div class="white-background-section">
        <div class="container profile-card-container">
          <el-card class="profile-card">
            <div class="profile-card-body">
              <el-row>
                <el-col
                  :xs="5"
                  :sm="5"
                  :md="5"
                  :lg="4"
                >
                  <el-avatar
                    shape="square"
                    class="avatar organisation-logo"
                    :src="organisation.logoFile.medium_thumb"
                    alt="Organisation Logo"
                  >
                    <img src="../../../static/img/no-logo.png" alt="No Logo" />
                  </el-avatar>
                </el-col>
                <el-col
                  id="main-content"
                  :xs="19"
                  :sm="19"
                  :md="19"
                  :lg="13"
                >
                  <div class="user-info">
                    <div class="d-flex flex-column">
                      <h1>{{ organisation.name }}</h1>
                      <div class="d-flex hidden-md-and-down">
                        <a :href="organisation.url"> {{ organisation.url }}</a>
                      </div>
                    </div>

                    <div class="d-flex mb-2 mt-2">
                      <p>
                        <!--svg-vertical-line
                          class="vertical-line"
                          aria-labeledby="vertical line"
                        ></!--svg-vertical-line-->
                        <svg-map-pin-gradient
                          class="mr-2 map-pin"
                        ></svg-map-pin-gradient>
                        {{ `${organisation.address.town || organisation.address.county}, ${organisation.address.country.name}` }}
                      </p>
                    </div>

                    <!--div class="location d-flex">
                      <p class="d-flex">
                        <svg-map-pin-gradient
                          class="mr-2 map-pin"
                        ></svg-map-pin-gradient>
                        {{ organisation.address.town || organisation.address.county , organisation.address.country.name  }}
                      </p>

                      <div class="d-none d-md-block">
                        <svg-vertical-line
                          class="vertical-line"
                          aria-labeledby="vertical line"
                        ></svg-vertical-line>
                      </div>
                      <p class="d-flex">
                        {{ organisation.key_contact }}
                      </p>
                    </!--div-->
                  </div>
                </el-col>
                <el-col
                  :xs="24"
                  :sm="24"
                  :md="24"
                  :lg="7"
                >
                  <div class="organisations-section d-flex justify-content-end">
                    <p v-if="organisation.members && organisation.members.data">
                      <span>{{ organisation.members.data.length }}</span>
                      {{
                        organisation.members.data.length > 1
                          ? "Members"
                          : "Member"
                      }}
                    </p>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-card>
        </div>
        <div class="container main-content">
          <el-row>
            <el-col :lg="12">
              <div
                class="about-section"
              >
                <h1>About {{ organisation.name }}</h1>
                <p>{{ organisation.description }}</p>
              </div>
            </el-col>
            <el-col :lg="12">
              <div class="companies-connections">
                <h1>Members ({{ organisation.members.data.length }})</h1>
                <div
                  v-if="
                    organisation.members && organisation.members.data.length
                  "
                >
                  <colleague-card
                    v-for="(item, index) in getRandomeColleagues"
                    :key="index"
                    :colleague="item"
                  />
                  <a
                    v-if="organisation.members.data.length > 5"
                    href="#"
                    class="show-more-link"
                    @click.prevent="showAllMembers = true"
                  >
                    Show more
                  </a>
                </div>
                <div v-else>
                  This Organisation currently has no Members in the Scotland Europa website
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <el-dialog
      v-if="organisation"
      title="Organisation Members"
      :visible.sync="showAllMembers"
    >
      <div class="text-center success-dialog">
        <colleague-card
          v-for="(item, index) in organisation.members.data"
          :key="index"
          :colleague="item"
        />
        <el-button
          class="mx-auto mt-3"
          @click="showAllMembers = false"
        >
          Close
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import store from "@/store";
import { mapState } from "vuex";
import gsApi from "@/services/gs.api";
import WhiteArrowLeft from "@/components/assets/svg-white-arrow-left";
import ColleagueCard from "@/components/partials/colleague-card";
import helpers from "@/helpers/index";
import MapPinGradient from "@/components/assets/svg-map-pin-gradient";

export default {
  name: "OrganisationProfile",
  metaInfo: {
    title: "Organisation profile",
  },
  components: {
    "svg-white-arrow-left": WhiteArrowLeft,
    "svg-map-pin-gradient": MapPinGradient,
    ColleagueCard,
  },
  data() {
    return {
      helpers,
      isProfileOwner: false,
      organisation: null,
      mainLoader: true,
      showBackToSearch: false,
      showAllMembers: false,
    };
  },
  computed: {
    ...mapState(["user"]),
    getRandomeColleagues() {
      if (!this.organisation.members.data.length) return [];
      return JSON.parse(JSON.stringify(this.organisation.members.data)).slice(
        0,
        5
      );
    },
  },
  watch: {
    $route(to, from) {
      if (from.path !== to.path && this.user.isLogged) {
        this.mainLoader = true;
        this.fetchOrganisationProfile();
      }
    },
  },
  created() {
    if (this.$route.params.showBackToSearch) {
      this.showBackToSearch = true;
    }

    if (store.state.user.isLogged) {
      this.fetchOrganisationProfile();
    }
  },

  methods: {
    fetchOrganisationProfile() {
      this.mainLoader = true;
      gsApi.organisation
        .show(this.$route.params.slug)
        .then((response) => {
          this.mainLoader = false;
          this.organisation = response.data;
        })
        .catch((error) => {
          this.mainLoader = false;
          this.$alert(
            "Unable to load organisation profile. Please try again later.",
            "Error",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal",
            }
          );
          this.$router.push({ name: "Members" });
        });
    },
    goBackToEventPage() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss">
.vertical-line {
  top: 4px;
  margin-top: 0px !important;
  margin: 0 10px !important;
  position: relative;
  height: 18px;
}
</style>
