<template>
  <div>
    <a
      v-for="(attachedFile, index) in attachedFiles"
      :key="index"
      class="gs-link fs-19 w-100"
      :href="attachedFile.path"
      download
      target="_blank"
    >
      <div
        class="
        view
        attached-document
        clickable
        px-4
        py-3
        rounded
        w-100
        mb-1
        d-flex
      "
      >
        <div class="align-self-start mr-2">
          <img :src="attachedFile.icon" alt="" />
        </div>
        <div class="align-self-center w-100">
          <p>{{
            attachedFile.file_name
          }}</p>
        </div>
        <div class="align-self-center align-self-end "><svg-download-file class="pull-right ml-2"></svg-download-file></div>
      </div>
    </a>
  </div>
</template>
<script>
import SvgDownloadFile from "@/components/assets/svg-download-file";
export default {
  components: {
    SvgDownloadFile,
  },
  props: {
    attachedFiles: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
};
</script>