<!-- public events component -->
<template>
  <div
    v-loading.fullscreen.lock="mainLoader"
    class="events-public-page white-background-section"
    element-loading-background="rgba(255, 255, 255, 0.8)"
  >
    <el-row
      :gutter="0"
      class="m-0"
    >
      <top-navigation></top-navigation>
      <el-col
        :span="24"
        class="events-webinars-header px-0"
      >
        <div class>
          <div class="events-public-page__header">
            <div class="header__content__bg"></div>
            <div class="header__image__bg events-webinars-bg"></div>
            <div class="header__content container">
              <el-row>
                <el-col
                  :md="24"
                  :lg="12"
                >
                  <div class="header__content__wrapper">
                    <h1
                      id="main-content"
                      class="header__title"
                    >
                      {{ text.headingText }}
                    </h1>
                    <p class="header__content-info">
                      {{ text.headingSub }}
                    </p>
                    <p class="header__content-info mt-4">
                      {{ text.sub }}
                    </p>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </el-col>
      <div class="container">
        <el-col>
          <el-form
            id="searchForm"
            ref="searchForm"
            class="form"
            :model="search"
            @submit.native.prevent
          >
            <el-row>
              <el-col
                :sm="24"
                :md="12"
              >
                <el-form-item
                  label="Keyword search"
                  prop="keyword"
                  class=""
                >
                  <el-input
                    id="keyword"
                    v-model="search.keyword"
                    placeholder="Search for keywords, location, people..."
                    auto-complete="no"
                    maxlength="255"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col
                :sm="24"
                :md="12"
              >
                <el-button
                  class="events-btn"
                  type="primary"
                  @click="searchEvents"
                >
                  <p>
                    Search
                  </p>
                </el-button>
              </el-col>
            </el-row>
            <el-row class="">
              <el-col
                :sm="24"
                :md="8"
                :lg="5"
              >
                <el-form-item
                  prop="sortby"
                  class="is-no-asterisk"
                >
                  <template slot="label">
                    <span aria-hidden="true">Sort by</span>
                    <span class="sr-only">Choose from the following</span>
                  </template>
                  <v-select
                    v-model="search.sortby"
                    placeholder="Choose from the following"
                    :options="sortOptions"
                    :searchable="true"
                    :clearable="false"
                    :reduce="sortOption => sortOption.name"
                    input-id="sortby"
                    autocomplete="false"
                    label="name"
                    class="half-content"
                  >
                  </v-select>
                </el-form-item>
              </el-col>
              <el-col
                :sm="24"
                :md="8"
                :lg="5"
              >
                <el-form-item
                  prop="sector"
                  class="is-no-asterisk"
                >
                  <template slot="label">
                    <span aria-hidden="true">Type</span>
                    <span class="sr-only">Please select a type</span>
                  </template>
                  <v-select
                    v-model="search.sector"
                    placeholder="Choose from the following"
                    :options="sectors"
                    :searchable="true"
                    :clearable="false"
                    :reduce="sector => sector.id"
                    input-id="sector"
                    autocomplete="false"
                    label="name"
                    class="half-content"
                  />
                </el-form-item>
              </el-col>
              <el-col
                :sm="24"
                :md="8"
                :lg="6"
              >
                <el-form-item
                  prop="sector"
                  class="is-no-asterisk"
                >
                  <template slot="label">
                    <span aria-hidden="true">Topic</span>
                    <span class="sr-only">Please select a topic</span>
                  </template>
                  <v-select
                    v-model="search.topic"
                    placeholder="Choose from the following"
                    :options="topics"
                    :searchable="true"
                    :clearable="false"
                    :reduce="topic => topic.id"
                    input-id="topic"
                    autocomplete="false"
                    label="name"
                    class="half-content"
                  />
                </el-form-item>
              </el-col>
              <el-col
                :sm="24"
                :md="12"
                :lg="8"
              >
                <el-button
                  type="primary"
                  class="events-btn float-right  update-results-btn"
                  @click="searchEvents"
                >
                  Update results
                </el-button>

                <el-button
                  type="text"
                  class="reset-btn float-left"
                  @click="resetSearch"
                >
                  Reset
                </el-button>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
      </div>
    </el-row>
    <div
      v-if="showZeroData"
      class="zero-data text-center mt-5"
    >
      <div class="container">
        <el-row>
          <el-col :sm="24">
            <p>We could not find any events or webinars with your search criteria. Please update your search and try again.</p>
          </el-col>
        </el-row>
      </div>
    </div>

    <div
      v-if="events.length > 0"
      class="results-section"
    >
      <div class="container">
        <div class="mt-4">
          <el-row>
            <events-card-public
              v-for="event in events"
              :key="event.id"
              :event="event"
            />
          </el-row>
        </div>
      </div>
    </div>

    <div class="pagination-help-section">
      <div class="container center-btn">
        <el-button
          v-if="!onLastPage"
          class="load-more-btn"
          @click="fetchEvents"
        >
          Load more
        </el-button>
      </div>
    </div>
    <news-filter
      v-if="eventsFilterOpen"
      v-model="search"
      :sectors="sectors"
      :sort-options="sortOptions"
      @close="closeEventsFilter"
      @search="searchEvents(true)"
      @reset="resetSearch(true)"
    ></news-filter>
  </div>
</template>

<script>
import gsApi from "@/services/gs.api";
import { mapState } from "vuex"
import EventsCardPublic from '@/components/platform/events-webinars/events-card-public'
import NewsFilter from '@/components/slide-panel/panels/news-filter'
import TopNavigation from "../../layout/top-navigation";
export default {
  name: "EventsAndWebinarsPublic",
  metaInfo: {
    title: "Events and webinars",
    meta: [{
      name: 'description',
      content: "Browse our listing of free events and webinars in Scotland and around the world."
    }]
  },
  components: {
    TopNavigation,
    EventsCardPublic,
    NewsFilter,
  },

  data() {
    return {
     	mainLoader: false,
      page: 1,
      perPage: 6,
      totalResults: 0,
      showZeroData: false,
      onLastPage: false,
     	search: {
        sortby: null,
        keyword: '',
        sector: null,
        topic:null
      },
      events: [],
      sectors: [],
      topics: [],
      sortOptions: [
        {
        	key: 0,
        	name: 'A-Z'
        },
        {
        	key: 1,
        	name: 'Z-A'
        },
        {
        	key: 2,
        	name: 'Latest'
        }
      ],
      eventsFilterOpen: false,
      text: {
        headingText: "Events and webinars",
        headingSub: "Our in-person events, virtual events and online webinars can help you network with other members, make contacts in the EU, get the latest updates on EU policies and funding, and more. You’ll also be able to listen to any available podcast episodes.",
        sub:"Sign up for upcoming events, or explore past events for a flavour of what you can expect."
      },
    };
  },
  computed: {
    ...mapState(['event'])
  },
  created() {
    this.getRegisterMeta()
  },
  methods: {
    getRegisterMeta() {
      this.mainLoader = true

      gsApi.globalscot.meta.index()
        .then(response => {
          this.sectors = response.data.groups.data
          this.topics = response.data.expertise.data
          this.fetchEvents()
        }).catch(error => {
	      this.mainLoader = false
          this.$alert('An unknown error occurred. Please try again later.', 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
        })
    },
    searchEvents() {
      this.page = 1
      this.events = []
      this.fetchEvents()
    },
    resetSearch() {
      this.search = {
        sortby: null,
        keyword: '',
        sector: null,
        topics: null
      }

      this.searchEvents()
    },
    fetchEvents() {
    	if (!this.mainLoader) {
    		this.mainLoader = true
    	}

      let params = {
        page: this.page,
        per_page: this.perPage,
        status_id: 1,
        sort_by: this.search.sortby,
        search: this.search.keyword,
        type:this.search.sector,
        topic: this.search.topic,

      };

	    gsApi.events.searchPublic(params)
        .then(response => {
          this.mainLoader = false

          this.totalResults = response.data.meta.pagination.total

          if (response.data.meta.pagination.current_page !== response.data.meta.pagination.total_pages) {
            this.onLastPage = false
            this.page++
          } else {
            this.onLastPage = true
          }

          this.events = this.events.concat(response.data.data)

          this.showZeroData = this.events.length > 0 ? false : true
        }).catch(error => {
          this.mainLoader = false
        })
    },
  },
};
</script>

<style>
.featured-desc {
  margin: auto 100px auto 100px;
}

.featured-title {
  font-weight: 400;
  padding-bottom: 30px;
}

img {
  width: 100%;
  height: auto;
}

.white-background-section {
  background-color: white;
}

.events-btn {
  min-height: 40px !important;
  margin-top: 33px;
}

.form {
  margin-top:30px;
}

.center-btn{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 1.25rem
}
</style>
