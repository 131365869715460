<template><svg width="19" height="24" viewBox="0 0 19 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
<path d="M9.05601 0C4.07089 0 0 4.06969 0 9.05601C0.000940715 13.1014 5.70859 20.2786 8.14058 23.1687C8.38142 23.434 8.71821 23.6024 9.05596 23.6024C9.41722 23.6024 9.75401 23.4575 9.97134 23.1687C12.4277 20.2786 18.1119 13.1014 18.1119 9.05505C18.1354 4.06993 14.0656 0 9.05591 0H9.05601ZM9.29685 22.5909C9.22441 22.6869 9.12845 22.6869 9.05601 22.6869C9.00803 22.6869 8.91113 22.6624 8.81517 22.5909C7.80384 21.3867 5.85262 18.9783 4.11882 16.377C2.02372 13.1982 0.915427 10.6694 0.915427 9.05505C0.915427 4.55161 4.57593 0.891102 9.07937 0.891102C13.5828 0.891102 17.2433 4.55161 17.2433 9.05505C17.2433 10.645 16.1351 13.1739 14.0166 16.3527C12.2592 18.9783 10.3081 21.3867 9.29661 22.5909H9.29685Z" fill="url(#paint0_linear)"/>
<path d="M9.05607 4.76892C6.98447 4.76892 5.27515 6.45479 5.27515 8.54984C5.27515 10.6214 6.96102 12.3308 9.05607 12.3308C11.1277 12.3308 12.837 10.6449 12.837 8.54984C12.837 6.45479 11.1511 4.76892 9.05607 4.76892V4.76892ZM9.05607 11.392C7.46615 11.392 6.19057 10.1153 6.19057 8.52648C6.19057 6.93656 7.46721 5.66099 9.05607 5.66099C10.646 5.66099 11.9216 6.93762 11.9216 8.52648C11.9225 10.1155 10.6459 11.392 9.05607 11.392Z" fill="url(#paint1_linear)"/>
<defs>
<linearGradient id="paint0_linear" x1="15.6285" y1="6.29155" x2="-5.63463" y2="18.7853" gradientUnits="userSpaceOnUse">
<stop stop-color="#A8308C"/>
<stop offset="0.517454" stop-color="#EA6576"/>
<stop offset="1" stop-color="#F7F248"/>
</linearGradient>
<linearGradient id="paint1_linear" x1="11.8001" y1="6.78464" x2="4.27159" y2="12.5492" gradientUnits="userSpaceOnUse">
<stop stop-color="#A8308C"/>
<stop offset="0.517454" stop-color="#EA6576"/>
<stop offset="1" stop-color="#F7F248"/>
</linearGradient>
</defs>
</svg></template><script>export default {}</script>
