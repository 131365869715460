import store from '@/store'
import Vue from 'vue'

export default () => {
  let headers = {
    'ApiKey': process.env.VUE_APP_API_KEY,
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'TimeZone' : Vue.moment.tz.guess()
  }

  if (store.state.user.isLogged || store.state.user.needsConfirmation) {
    headers['Authorization'] = store.state.user.token
  }

  return headers
}
