<template>
  <div
    v-loading.fullscreen.lock="mainLoader"
    class="section-account-details"
    element-loading-background="rgba(255, 255, 255, 0.8)"
  >
    <h2>Account Details</h2>
    <el-form
      id="submitForm"
      ref="submitForm"
      :model="form"
      class="update-email-form"
      @submit.native.prevent
    >
      <!--el-row>
        <el-col :xs="24" :sm="4" :md="4" :lg="4">Name</el-col>
        <el-col :xs="20" :sm="16" :md="16" :lg="16">
          <div v-if="!edit.name" >{{ user.fullName }}</div>
          <div v-else>
          <el-form-item prop="firstName" >
          <el-input
            placeholder="First Name"
            v-model="form.firstName"
            name="first name"
           >

          </el-input>
          </el-form-item>
          <el-form-item prop="lastName" >
          <el-input
            placeholder="Last Name"
            v-model="form.lastName"
            name="last name"
           >

          </el-input>
          </el-form-item>
          </div>
        </el-col>
        <el-col :xs="4" :sm="4" :md="4" :lg="4">
          <el-button
            size="small"
            class="pull-right"
            @click="edit.name = !edit.name"
            >{{ edit.name ? "Cancel" : "Edit" }}</el-button>
            </el-col>
      </el-row>
      <el-divider></el-divider-->
      <el-row>
        <el-col
          :xs="24"
          :sm="4"
          :md="4"
          :lg="4"
        >
          Email address
        </el-col>
        <el-col
          :xs="20"
          :sm="16"
          :md="16"
          :lg="16"
        >
          <div v-if="!edit.email">
            <p>
              <span class="mr-2">
                {{
                  user.awaiting_verification_email
                    ? user.awaiting_verification_email
                    : user.email
                }}</span>
              <span
                v-if="user.awaiting_verification_email"
                class="tag-verification"
              >Awaiting verification</span>
            </p>
          </div>
          <div v-else>
            <el-form-item prop="email">
              <el-input
                v-model="form.email"
                type="email"
                name="email"
                placeholder="Email"
              />
            </el-form-item>
          </div>
        </el-col>
        <el-col
          :xs="4"
          :sm="4"
          :md="4"
          :lg="4"
        >
          <el-button
            size="small"
            class="pull-right btn-edit"
            @click="
              edit.email = !edit.email;
              if (edit.email) initializeForm();
            "
          >
            {{ edit.email ? "Cancel" : "Edit" }}
          </el-button>
        </el-col>
      </el-row>
      <!--el-divider></el-divider>
      <el-row>
        <el-col :xs="24" :sm="4" :md="4" :lg="4">Phone Number</el-col>
        <el-col :xs="20" :sm="16" :md="16" :lg="16">
          <div v-if="!edit.contactNumber">
            {{ contactNumber }}
          </div>
          <div v-else>
            <el-form-item prop="contact_number">
              <el-input
              placeholder="Contact number"
              v-model="form.contact_number"
              name="Contact number"
              />
            </el-form-item>
          </div>

        </el-col>
        <el-col :xs="4" :sm="4" :md="4" :lg="4"
          ><el-button
            size="small"
            class="pull-right"
            @click="edit.contactNumber = !edit.contactNumber"
            >{{ edit.contactNumber ? "Cancel" : "Edit" }}</el-button></el-col
        >
      </el-row>
      <el-divider></el-divider>
      <el-row>
        <el-col :xs="24" :sm="4" :md="4" :lg="4">Address</el-col>
        <el-col :xs="20" :sm="16" :md="16" :lg="16">
          <div v-html="userAddress" v-if="!edit.address" ></div>
          <div v-else>Edit Address</div>
        </el-col>
        <el-col :xs="4" :sm="4" :md="4" :lg="4"
          ><el-button
            size="small"
            class="pull-right"
            @click="edit.address = !edit.address"
            >{{ edit.address ? "Cancel" : "Edit" }}</el-button></el-col
        >
      </el-row-->
      <div
        v-if="edit.email"
        class="form-actions"
      >
        <el-button
          type="primary"
          class="btn-submit"
          :disabled="!form.email.length"
          @click="saveEmailForm($event)"
        >
          Save Changes
        </el-button>
      </div>
    </el-form>
    <el-divider></el-divider>
    <el-row>
      <el-col
        :xs="24"
        :sm="4"
        :md="4"
        :lg="4"
      >
        Password
      </el-col>
      <el-col
        :xs="20"
        :sm="16"
        :md="16"
        :lg="16"
      >
        <div v-if="!edit.password">
          *************
        </div>
        <div v-else>
          <account-password></account-password>
        </div>
      </el-col>
      <el-col
        :xs="4"
        :sm="4"
        :md="4"
        :lg="4"
      >
        <el-button
          size="small"
          class="pull-right btn-edit"
          @click="edit.password = !edit.password"
        >
          {{ edit.password ? "Cancel" : "Edit" }}
        </el-button>
      </el-col>
    </el-row>
    <el-divider></el-divider>
    <el-row class="pb-3">
      <el-col
        :xs="20"
        :sm="20"
        :md="20"
        :lg="20"
      >
        Multi-factor authentication
      </el-col>
      <el-col
        :xs="4"
        :sm="4"
        :md="4"
        :lg="4"
      >
        <el-button
          v-if="!user.availableMfaMethods?.data?.length"
          size="small"
          class="pull-right btn-edit"
          @click="showMultiFactorAuthentication = true"
        >
          Enable
        </el-button>
        <p
          v-else
          class="text-center"
        >
          Active
        </p>
      </el-col>
    </el-row>
    <div
      v-if="user.availableMfaMethods?.data?.length"
      v-loading="loadingTwoFactorMethods"
    >
      <multi-factor-method
        :method="getAuthenticationByType(enumHelper.MFAType.GOOGLE)"
        :type="enumHelper.MFAType.GOOGLE"
        :loading="waiting.includes(enumHelper.MFAType.GOOGLE)"
        @change="handleChange"
        @remove="handleRemove"
        @setup="handleSetup"
        @mark-as-default="handleMarkAsDefault"
      />
      <multi-factor-method
        :method="getAuthenticationByType(enumHelper.MFAType.PHONE)"
        :type="enumHelper.MFAType.PHONE"
        :loading="waiting.includes(enumHelper.MFAType.PHONE)"
        @change="handleChange"
        @remove="handleRemove"
        @setup="handleSetup"
        @mark-as-default="handleMarkAsDefault"
      />
      <multi-factor-method
        :method="getAuthenticationByType(enumHelper.MFAType.EMAIL)"
        :type="enumHelper.MFAType.EMAIL"
        :loading="waiting.includes(enumHelper.MFAType.EMAIL)"
        @change="handleChange"
        @remove="handleRemove"
        @setup="handleSetup"
        @mark-as-default="handleMarkAsDefault"
      />
    </div>
    <el-divider></el-divider>
    <el-row :gutter="12">
      <el-col :span="12">
        <el-card shadow="never">
          <h2>Suspend account</h2>
          <p>
            Suspening your account will temporarily hide your profile from the
            network and pause all notifications. You can reactive your account
            any time by logging in
          </p>
          <el-button
            class="btn-close-suspend-account"
            type="primary"
            @click="showSuspendConfirmDialog = true"
          >
            Suspend my account
          </el-button>
        </el-card>
        <suspend-account-dialog
          :visible="showSuspendConfirmDialog"
          @closed="showSuspendConfirmDialog = false"
        ></suspend-account-dialog>
      </el-col>
      <el-col :span="12">
        <el-card shadow="never">
          <h2>Close account</h2>
          <p>
            Closing your account will remove your profile, data and activity
            from the network. We’ll permanently delete all your data after 30
            days
          </p>
          <el-button
            class="btn-close-suspend-account"
            type="primary"
            @click="showClosedAccountDialog = true"
          >
            Close my account
          </el-button>
        </el-card>
        <closed-account-dialog
          :visible="showClosedAccountDialog"
          @closed="showClosedAccountDialog = false"
        ></closed-account-dialog>
      </el-col>
    </el-row>
    <enable-multi-factor-authentication-dialog
      v-if="showMultiFactorAuthentication"
      :visible="showMultiFactorAuthentication"
      :hide-button="buttonsHidden"
      @closed="showMultiFactorAuthentication = false"
      @onSetupMultiFactorAuthentication="handleSetupMultiFactoryAuthentication"
    />
    <setup-google-authentication
      v-if="showGoogleAuthentication"
      :visible="showGoogleAuthentication"
      @closed="showGoogleAuthentication = false"
      @refresh-user-multifactor-methods="onRefreshUserMultifactorMethods"
    />

    <setup-phone-authentication
      v-if="showPhoneAuthentication"
      :visible="showPhoneAuthentication"
      :contact-number="user?.expertProfile?.contact_number"
      @closed="showPhoneAuthentication = false"
      @refresh-user-multifactor-methods="onRefreshUserMultifactorMethods"
    />

    <setup-email-authentication
      v-if="showEmailAuthentication"
      :visible="showEmailAuthentication"
      @closed="showEmailAuthentication = false"
      @refresh-user-multifactor-methods="onRefreshUserMultifactorMethods"
    />
  </div>
</template>
<script>
import gsApi from "@/services/gs.api";
import AccountPassword from "./account-password";
import ClosedAccountDialog from "@/components/platform/settings/ClosedAccountDialog";
import SuspendAccountDialog from "@/components/platform/settings/SuspendAccountDialog";
import EnableMultiFactorAuthenticationDialog from "@/components/landing/EnableMultiFactorAuthenticationDialog";
import SetupGoogleAuthentication from "@/components/landing/SetupGoogleAuthentication";
import SetupPhoneAuthentication from "@/components/landing/SetupPhoneAuthentication";
import SetupEmailAuthentication from "@/components/landing/SetupEmailAuthentication";
import MultiFactorMethod from "@/components/partials/multi-factor-method";
import { mapGetters } from "vuex";
import enumHelper from "@/helpers/enumHelper";
export default {
  name: "AccountDetails",
  metaInfo: {
    title: "Account details - Settings",
  },
  components: {
    AccountPassword,
    ClosedAccountDialog,
    SuspendAccountDialog,
    EnableMultiFactorAuthenticationDialog,
    SetupGoogleAuthentication,
    SetupPhoneAuthentication,
    MultiFactorMethod,
    SetupEmailAuthentication,
  },
  data() {
    return {
      validationErrors: [],
      mainLoader: false,
      showSuspendConfirmDialog: false,
      showClosedAccountDialog: false,
      blockforAction: false,
      form: {
        email: "",
      },
      edit: {
        name: false,
        email: false,
        contactNumber: false,
        address: false,
        password: false,
      },
      enableMFA: false,
      availableMFAType: [],
      showMultiFactorAuthentication: false,
      showGoogleAuthentication: false,
      showPhoneAuthentication: false,
      showEmailAuthentication: false,
      enumHelper,
      buttonsHidden: {
        google: false,
        phone: false,
        email: false,
      },
      loadingTwoFactorMethods: false,
      waiting: [],
    };
  },
  computed: {
    ...mapGetters(["user"]),
    userAddress() {
      if (this.user.address) {
        let address = `${this.user.address.address_1} <br>`;
        address +=
          this.user.address.address_2 && this.user.address.address_2.length
            ? `${this.user.address.address_2},`
            : "";
        address += this.user.address.town.length ? this.user.address.town : "";
        address += this.user.address.county.length
          ? `<br>${this.user.address.county}`
          : "";
        address += `<br> ${this.user.address.post_code}`;
        return address;
      }
      return "";
    },
    getAuthenticationByType() {
      return (type) => {
        if (!this.user.availableMfaMethods.data.length) return null;

        return this.user.availableMfaMethods.data.find(
          (m) => m.two_factor_type == type
        );
      };
    },
  },
  created() {
    this.onRefreshUserMultifactorMethods();
    this.initializeForm();
    this.availableMFAType.push(
      {
        type: "phone",
        label: "Mobile Number",
        enabled: false,
        actions: [],
      },
      {
        type: "google",
        label: "Google Authenticator",
        enabled: false,
        actions: [],
      },
      {
        type: "email",
        label: "Backup email address",
        enabled: false,
        actions: [],
      }
    );
  },
  methods: {
    initializeForm() {
      this.form = {
        email: this.user.awaiting_verification_email
          ? this.user.awaiting_verification_email
          : this.user.email,
      };
    },
    saveEmailForm() {
      if (this.user.awaiting_verification_email) {
        if (this.form.email == this.user.email) {
          this.$alert(
            "Please verify your new email address first before you can change back to your old email or use a different new email address",
            "Error",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal",
            }
          );

          return;
        } else if (this.user.awaiting_verification_email == this.form.email) {
          this.$alert(
            "This email address is already awaiting verification, please verify it or use a different new email address",
            "Error",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal",
            }
          );

          return;
        }
      } else if (this.user.email == this.form.email) {
        this.$alert(
          "This is already your current email,  please use a different new email address",
          "Error",
          {
            confirmButtonText: "Close",
            showClose: false,
            customClass: "notification-modal",
          }
        );
        return;
      }

      this.mainLoader = true;

      const data = {};
      data.awaiting_verification_email = this.form.email;

      gsApi.users
        .updateEmail(data)
        .then((response) => {
          this.mainLoader = false;
          this.validationErrors = [];
          this.$store.commit("UPDATE_USER_EMAIL", {
            awaiting_verification_email: this.form.email,
          });
          this.edit.email = false;
          this.$alert(
            "You successfully updated your email , an activation email has been sent to your new address",
            "Success",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal",
            }
          ).then(() => {
            this.$refs.submitForm.resetFields();
          });
        })
        .catch((error) => {
          this.validationErrors = [];
          this.mainLoader = false;

          if (
            error.response &&
            error.response.status == 422 &&
            error.response.data
          ) {
            let errors = error.response.data.errors;
            for (let [key, value] of Object.entries(errors)) {
              this.validationErrors.push(value[0]);
            }

            this.$alert(
              this.validationErrors.join(),
              "Sorry, your request could not be processed",
              {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal",
              }
            );
          } else {
            this.$alert(error.response.data.message, "Error", {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal",
            });
          }
        });
    },
    handleSetupMultiFactoryAuthentication(type) {
      switch (type) {
      case enumHelper.MFAType.GOOGLE:
        this.handleGoogleAuthentication();
        break;
      case enumHelper.MFAType.PHONE:
        this.handlePhoneAuthentication();
        break;
      case enumHelper.MFAType.EMAIL:
        this.handleEmailAuthentication();
        break;
      }
    },
    handleGoogleAuthentication() {
      this.showMultiFactorAuthentication = false;
      this.showGoogleAuthentication = true;
    },
    handlePhoneAuthentication() {
      this.showMultiFactorAuthentication = false;
      this.showPhoneAuthentication = true;
    },
    handleEmailAuthentication() {
      this.showMultiFactorAuthentication = false;
      this.showEmailAuthentication = true;
    },
    async onRefreshUserMultifactorMethods() {
      // refresh the user available methods
      this.loadingTwoFactorMethods = true;
      const response = await gsApi.mfa.multiFactorMethods().catch(() => {
        this.loadingTwoFactorMethods = false;
        return false;
      });

      if (response) {
        this.$store.commit("UPDATE_MULTI_FACTOR_METHODS", response.data);
      }
      this.loadingTwoFactorMethods = false;
    },
    async handleRemove(method) {
      // can't delete a method if the user has only one method enabled
      if (this.user.availableMfaMethods.data.length == 1) {
        this.$confirm(
          `<i class="el-icon el-icon-warning-outline text-danger"></i>
                <p class='title mb-5 mt-2'>Setup alternative authentication method</p>
                <p class='method-name mb-2' >Please setup an alternative authentication method before remove one. You must have at least one authentication method setup at all times</p>
                `,
          "",
          {
            dangerouslyUseHTMLString: true,
            confirmButtonText: "Setup",
            cancelButtonText: "Cancel",
            confirmButtonClass: "modal-confirm-button ml-2 w-100",
            cancelButtonClass: "modal-cancel-button mr-2 w-100",
            showClose: true,
            customClass: "notification-modal message-modal",
          }
        )
          .then(() => {
            const btnToHide =
              method.two_factor_type == enumHelper.MFAType.GOOGLE
                ? "google"
                : method.two_factor_type == enumHelper.MFAType.PHONE
                  ? "phone"
                  : "email";

            this.$set(this.buttonsHidden, btnToHide, true);

            this.$nextTick(() => {
              this.showMultiFactorAuthentication = true;
            });
          })
          .catch(() => {});
        return;
      }

      this.$confirm("<p class='py-2'>Are you sure you want to delete this method ?</p>", "Delete Confirmation", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        confirmButtonClass: "modal-confirm-button ml-2 w-100",
        cancelButtonClass: "modal-cancel-button mr-2 w-100",
        customClass: "notification-modal message-modal",
        dangerouslyUseHTMLString: true,
      })
        .then(async () => {
          this.waiting.push(parseInt(method.two_factor_type));

          const response = await gsApi.mfa.delete(method.id).catch(error => {
            return false;
          })

          const index = this.waiting.findIndex(i => i == method.two_factor_type)
          this.waiting.splice(index, 1) 

          if(response){
            this.onRefreshUserMultifactorMethods()
          }
        })
        .catch(() => {});
    },
    handleChange(method) {
      switch (parseInt(method.two_factor_type)) {
      case enumHelper.MFAType.GOOGLE:
        this.showGoogleAuthentication = true;
        break;
      case enumHelper.MFAType.PHONE:
        this.handlePhoneAuthentication();
        break;
      case enumHelper.MFAType.EMAIL:
        this.handleEmailAuthentication();
        break;
      }
    },
    async handleMarkAsDefault(method) {
      this.loadingTwoFactorMethods = true;
      const response = await gsApi.mfa
        .markAsDefault(method.id)
        .catch((error) => {
          this.loadingTwoFactorMethods = false;
          return false;
        });

      if (response) {
        this.onRefreshUserMultifactorMethods();
      }
    },
    handleSetup(two_factor_type) {
      if (two_factor_type == enumHelper.MFAType.GOOGLE) {
        this.handleGoogleAuthentication();
        return true;
      }

      if (two_factor_type == enumHelper.MFAType.PHONE) {
        this.handlePhoneAuthentication();
        return true;
      }

      if (two_factor_type == enumHelper.MFAType.EMAIL) {
        this.handleEmailAuthentication();
        return true;
      }
    },
  },
};
</script>
<style lang="scss">
.item-height {
  height: 40px;
}
.btn-edit {
  &.el-button--small {
    min-height: 0 !important;
    padding: 5px 15px !important;
    min-width: 100px;
  }
}
.btn-setup {
  &.el-button--small {
    min-height: 0 !important;
    padding: 5px 15px !important;
    min-width: 100px;
    text-decoration: underline;
  }
}
.el-message-box__message {
  p {
    line-height: $line-height-32;
  }
  .el-icon-warning-outline {
    font-size: 3rem;
  }
}
.tag-verification {
  background-color: #fdf6ec;
  border: 1px solid #faecd8;
  color: #e6a23c;
  padding: 0 10px;
}
.tag-success {
  background-color: #f0f9eb;
  border-color: 1px solid #e1f3d8;
  color: #67c23a;
  padding: 0 10px;
}

.btn-close-suspend-account {
  margin-top: 10px;
  background: transparent !important;
  border: transparent !important;
  padding: 10px 10px !important;
  min-height: auto;
  color: #a8308c !important;
  &:hover {
    color: #700e57 !important;
  }
}
</style>
