<template>
  <!-- TODO: for flexibility this component should not determine columns within -->
  <el-col
    :sm="24"
    :md="12"
    :lg="8"
    :xl="6"
    class=""
  >
    <el-card class="member-card box-card text-center h-100">
      <user-avatar
        shape="circle"
        :is-global-scot="helpers.isExpert(member)"
        :author-exist="!!member"
        :size="74"
        :src="member.avatar"
        :alt="member ? member.fullname : '' + 'Profile picture'"
      ></user-avatar>


      <div class="name-position">
        <p class="text-truncate name">
          {{ member.full_name }}
        </p>

        <div
          class="d-flex justify-content-center"
        >
          <p class="text-truncate">
            {{ member.expertProfile.role }}
            <svg-vertical-line
              class="vertical-line"
              aria-labeledby="vertical line"
            ></svg-vertical-line>
            {{ member.expertProfile.organisation.name }}
          </p>
        </div>
      </div>
      <hr class="mt-3 mb-3" />
      <div class="location-sectors">
        <div class="d-flex justify-content-center">
          <div><svg-map-pin-small></svg-map-pin-small></div>
          <p class="text-truncate">
            {{ member.expertProfile.location_name }}
          </p>
        </div>
        <div class="sectors d-flex justify-content-center sectors-text">
          <p class="text-truncate">
            {{ sectorsText }}
          </p>
          <span v-if="userTopicsAndTypes.length > 2">+{{ userTopicsAndTypes.length - 2 }} more</span>
        </div>
      </div>
      <div
        v-if="showProfileLink"
        class="view-profile-link"
      >
        <router-link
          :to="{ name: profileLinkName, params: { id: member.id, showBackToSearch: true } }"
        >
          <span>
            View
            <span class="sr-only">
              {{ member.full_name }}'s
            </span>
            profile
          </span>
          <svg-purple-arrow-right class="arrow" />
        </router-link>
      </div>
    </el-card>
  </el-col>
</template>

<script>
import { mapState } from "vuex"
import MapPinSmall from '@/components/assets/svg-map-pin-small'
import PurpleArrowRight from '@/components/assets/svg-purple-arrow-right'
import VerticalLine from '@/components/assets/svg-vertical-line'
import UserAvatar from '@/components/core/user-avatar'
import helpers from '@/helpers/index'
export default {
  name: 'MemberCard',
  components: {
    'svg-map-pin-small': MapPinSmall,
    'svg-purple-arrow-right': PurpleArrowRight,
    'svg-vertical-line': VerticalLine,
    UserAvatar
  },
  props: {
    member: {
      type: Object,
      required: true
    },
    showProfileLink: {
      type: Boolean,
      default: true
    }
  },
  data(){
    return {
      helpers
    }
  },
  computed: {
    ...mapState(["user"]),
    sectorsText() {

      let sectors = this.userTopicsAndTypes;

      if (sectors.length >= 2 ) {
        return sectors[0].name + ", " + sectors[1].name;
      } else if (sectors.length == 1  ) {
        return sectors[0].name;
      } else {
        return "";
      }
    },
    profileLinkName() {
      return 'SE Profile';
    },
    userTopicsAndTypes(){
      let allItems = this.member.groups.data;
      if(this.member.expertProfile && this.member.expertProfile.expertise){
        return [...allItems, ...this.member.expertProfile.expertise.data]
      }
      return allItems;
    }
  }
};
</script>
<style lang="scss" scoped>
  .vertical-line{
    height: 25px;
    margin: -3px 5px;
   position: relative;
  }
</style>
