<!--Article Page-->
<template>
  <div
    v-loading.fullscreen.lock="mainLoader"
    element-loading-background="rgba(255, 255, 255, 0.8)"
    class="news-detail-page events-detail-page mb-4"
  >
    <div v-if="event">
      <events-header
        :image="event.mediaFile"
        :alt-text="altText"
      ></events-header>

      <div class="news-content content-container">
        <div class="container">
          <el-row>
            <el-col
              :md="24"
              :lg="16"
              class="left-content"
            >
              <h2
                id="main-content"
                class="news-title"
              >
                {{ event.title }}
              </h2>

              <div class="d-flex mt-3">
                <div class="type-article">
                  {{ event.type_name }}: {{ event.category_name }}
                </div>
                <div class="added-on">
                  Added: {{ event.created_at }}
                </div>
              </div>

              <el-button
                v-if="event.type == 1 && event.category == 5"
                type="primary"
                class="url-button el-button read-more-btn"
                @click="trackWebinarWatch"
              >
                Watch webinar
              </el-button>

              <el-button
                v-if="event.type == 2 && event.podcastMediaFile && !listenNowClicked"
                type="primary"
                class="url-button el-button read-more-btn"
                @click="trackPodcastListen"
              >
                Listen now
              </el-button>
              <div
                v-if="event.type == 2 && event.podcastMediaFile && listenNowClicked"
                class="mt-4"
              >
                <audio
                  controls
                  autoplay
                  class="podcast-player"
                >
                  <source :src="event.podcastMediaFile.path" />
                  Your browser does not support the audio element.
                </audio>
              </div>

              <!-- Webinar only content -->
              <div
                v-if="event.type == 1 && event.category == 4"
                class="address-information"
              >
                <el-row>
                  <el-col
                    :md="24"
                    :lg="24"
                  >
                    <div class="d-flex flex-column">
                      <div class="d-flex mt-4">
                        <svg-calendar></svg-calendar>
                        <p
                          v-if="event.start_date == event.end_date "
                          class="information-p"
                        >
                          {{ event.start_date }}
                        </p>
                        <p
                          v-else
                          class="information-p"
                        >
                          {{ event.start_date }} - {{ event.end_date }}
                        </p>
                      </div>
                      <div class="d-flex mt-3">
                        <svg-time></svg-time>
                        <p class="information-p">
                          {{ event.start_time }} - {{ event.end_time }}
                        </p>
                      </div>
                      <div class="d-flex mt-3">
                        <svg-ticket></svg-ticket>
                        <p class="information-p">
                          Free
                        </p>
                      </div>
                    </div>

                    <div
                      v-if="event.has_ended"
                      class="mt-5"
                    >
                      <div class="webianr-ended-section mt-5">
                        <p class="ended-text">
                          This event has ended.
                          <router-link
                            :to="{ name: 'Events and Webinars' }"
                            class="ended-link"
                          >
                            Back to search
                          </router-link>
                        </p>
                      </div>
                    </div>

                    <div
                      v-else-if="!event.has_spaces && !isAttendee"
                      class="mt-5"
                    >
                      <el-row>
                        <el-col :md="24">
                          <p class="">
                            <b>This webinar is full. Check back later in case spaces open up.</b>
                          </p>
                          <p class="mt-2">
                            If no spaces open up, you’ll still be able to watch the recording when the webinar ends.
                          </p>
                        </el-col>
                      </el-row>
                    </div>

                    <div v-else>
                      <div
                        v-if="!isAttendee"
                        class="mt-5"
                      >
                        <el-col>
                          <el-button
                            type="primary"
                            class="el-button signup-btn float-right mt-0"
                            @click="attendWebinar"
                          >
                            Sign up for this webinar
                          </el-button>
                        </el-col>
                      </div>

                      <div v-else>
                        <div class="webianr-attending-section mt-5">
                          <div class="webianr-attending-content d-flex justify-content-between">
                            <div class="d-flex">
                              <svg-purple-tick></svg-purple-tick>
                              <p class="attending-text">
                                You’re joining this webinar
                              </p>
                            </div>
                            <p
                              class="attending-link"
                              @click="dialogs.leaveWebinar = true"
                            >
                              Can’t attend this webinar?
                            </p>
                          </div>
                        </div>

                        <div
                          v-if="event.is_live"
                          class="webianr-url-section mt-4"
                        >
                          <div class="webianr-url-content d-flex">
                            <p class="url-text">
                              Webinar link:
                            </p>
                            <p
                              class="url-link"
                              @click="trackWebinarWatch"
                            >
                              {{ event.url }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <!-- Webinar only content -->

              <!-- Physical event -->
              <div
                v-if="event.type == 0"
                class="address-information"
              >
                <el-row>
                  <el-col
                    :md="24"
                    :lg="12"
                  >
                    <div class="d-flex mt-4">
                      <svg-map-pin></svg-map-pin>
                      <div class="address d-flex flex-column">
                        <p class="address-line-p">
                          {{ event.address_line_1 }}
                        </p>
                        <p
                          v-if="event.address_line_2"
                          class="address-line-p"
                        >
                          {{ event.address_line_2 }}
                        </p>
                        <p class="address-line-p">
                          {{ event.city }}
                        </p>
                        <p class="address-line-p">
                          {{ event.postcode }}
                        </p>
                      </div>
                    </div>
                  </el-col>
                  <el-col
                    :md="24"
                    :lg="12"
                  >
                    <div class="d-flex flex-column">
                      <div class="d-flex mt-4">
                        <svg-calendar></svg-calendar>
                        <p class="information-p">
                          {{ event.start_date }} - {{ event.end_date }}
                        </p>
                      </div>
                      <div class="d-flex mt-4">
                        <svg-time></svg-time>
                        <p class="information-p">
                          {{ event.start_time }} - {{ event.end_time }}
                        </p>
                      </div>
                    </div>
                  </el-col>
                </el-row>

                <el-row>
                  <div
                    v-if="eventIsCancelled && ! hasAttended"
                    class="mt-5"
                  >
                    <div class="webianr-ended-section mt-5">
                      <p class="ended-text">
                        This event has been cancelled.
                        <router-link
                          :to="{ name: 'Events and Webinars' }"
                          class="ended-link"
                        >
                          Back to search
                        </router-link>
                      </p>
                    </div>
                  </div>
                  <div
                    v-else-if="event.has_ended && ! hasAttended"
                    class="mt-5"
                  >
                    <div class="webianr-ended-section mt-5">
                      <p class="ended-text">
                        This event has ended.
                        <router-link
                          :to="{ name: 'Events and Webinars' }"
                          class="ended-link"
                        >
                          Back to search
                        </router-link>
                      </p>
                    </div>
                  </div>

                  <div
                    v-else-if="event.has_ended && hasAttended"
                    class="mt-5"
                  >
                    <div class="webianr-ended-section mt-5">
                      <p class="ended-text">
                        You have attended this event.
                        <router-link
                          :to="{ name: 'Events and Webinars' }"
                          class="ended-link"
                        >
                          Back to search
                        </router-link>
                      </p>
                    </div>
                  </div>
                  <div
                    v-else-if="usEventJoinable && !isAttendee"
                    class="mt-5"
                  >
                    <div
                      v-if="isInWaitingList"
                      class="webianr-attending-section mt-5"
                    >
                      <div class="event-attending-content d-flex justify-content-between align-items-center">
                        <div class="d-flex">
                          <svg-purple-tick></svg-purple-tick>
                          <p class="attending-text">
                            You are on the waiting list for this event
                          </p>
                        </div>
                        <el-button
                          v-loading="waitlingLoader"
                          :disabled="waitlingLoader"
                          type="primary"
                          @click="cancelWaitingList(event.slug)"
                        >
                          Cancel waiting list
                        </el-button>
                      </div>
                    </div>
                    <el-row v-else-if="!event.registration_closed">
                      <el-col :md="24">
                        <el-button
                          v-loading="waitlingLoader"
                          :disabled="waitlingLoader"
                          type="primary"
                          @click="joinWaitingList(event.slug)"
                        >
                          Join waiting list
                        </el-button>
                      </el-col>
                    </el-row>
                  </div>

                  <!-- Cancel my attendance -->
                  <div v-else-if="!event.has_ended && isAttendee">
                    <div class="webianr-attending-section mt-5">
                      <div class="event-attending-content d-flex justify-content-between align-items-center">
                        <div class="d-flex">
                          <svg-purple-tick></svg-purple-tick>
                          <p class="attending-text">
                            You’re going to this event
                          </p>
                        </div>
                        <el-button
                          type="primary"
                          @click="confirmCancelAttendance"
                        >
                          Cancel my attendance
                        </el-button>
                      </div>
                    </div>
                  </div>

                  <!-- Sign up for event -->
                  <div
                    v-else-if="!event.registration_closed && eventIsBookable && (!isAttendee || cancelledAttendance)"
                    class="mt-5"
                  >
                    <el-row>
                      <el-col :md="24">
                        <el-button
                          type="primary"
                          class="float-right mt-0"
                          @click="confirmSignUp"
                        >
                          Sign up for this event
                        </el-button>
                      </el-col>
                    </el-row>
                  </div>
                </el-row>
              </div>

              <hr class="content-hr" />
              <p class="news-description">
                {{ event.description }}
              </p>
              <div
                class="news-content"
                v-html="$options.filters.domPurify(content)"
              ></div>
              <div v-if="attachedFiles.length">
                <document-view :attached-files="attachedFiles" />
              </div>
              <div
                v-if="event.networkMembers.data.length > 0"
                class="featured-podcast-section"
              >
                <b>Featured in this podcast:</b>
                <div
                  v-for="member in event.networkMembers.data"
                  :key="member.id"
                  class="member-card d-flex"
                >
                  <!--el-avatar :size="40" :src="member.avatar"></el-avatar-->
                  <user-avatar
                    shape="circle"
                    :is-global-scot="member.is_global_scot"
                    :author-exist="!!member"
                    width="40px"
                    :size="40"
                    :src="member.avatar"
                    :alt="member ? member.fullname : '' + 'Profile picture'"
                  ></user-avatar>
                  <router-link
                    v-if="member.is_global_scot"
                    :to="{ name: 'SE Profile', params: { id: member.id, showBackToSearch: false } }"
                  >
                    {{ member.fullname }}
                  </router-link>
                  <router-link
                    v-else
                    :to="{ name: 'NonGlobalScot Profile', params: { id: member.id, showBackToSearch: false } }"
                  >
                    {{ member.fullname }}
                  </router-link>
                </div>
              </div>

              <div
                v-if="event.speakers.data.length > 0"
                class="featured-podcast-section"
              >
                <b>Speakers at this event:</b>
                <div
                  v-for="member in event.speakers.data"
                  :key="member.id"
                  class="member-card d-flex"
                >
                  <user-avatar
                    shape="circle"
                    :is-global-scot="member.is_global_scot"
                    :author-exist="!!member"
                    width="40px"
                    :size="40"
                    :src="member.avatar"
                    :alt="member ? member.fullname : '' + 'Profile picture'"
                  ></user-avatar>
                  <router-link
                    v-if="member.is_global_scot"
                    :to="{ name: 'SE Profile', params: { id: member.id, showBackToSearch: false } }"
                  >
                    {{ member.fullname }}
                  </router-link>
                  <router-link
                    v-else
                    :to="{ name: 'Staff Profile', params: { id: member.id, showBackToSearch: false } }"
                  >
                    {{ member.fullname }}
                  </router-link>
                </div>
              </div>

              <div v-if="showFeedback">
                <hr />
                <el-button
                  icon="plus"
                  type="primary"
                  class="mt-3"
                  @click="feedbackModalVisible = true"
                >
                  Give feedback
                </el-button>
              </div>

              <!-- 121 event -->
              <div>
                <!-- Host -->
                <div v-if="is121Event && isAttendee && is121Host">
                  <hr class="content-hr" />
                  <p class="news-description">
                    Your booking slots
                  </p>
                  <p class="news-content">
                    These are the times you are currently booked to chat with members of the network.
                  </p>

                  <div
                    v-for="day in event.hostEventDays.data"
                    :key="`dayid-${day.id}`"
                  >
                    <el-row>
                      <el-col>
                        <p class="news-date">
                          {{ dateLabel(day.date) }}
                        </p>
                      </el-col>
                    </el-row>

                    <el-row
                      :gutter="30"
                      class="host-section"
                    >
                      <el-col
                        v-for="(slot, index) in day.slots.data"
                        :key="`slotid-${slot.id}`"
                        :xl="12"
                        class="host-section-time-slot"
                      >
                        <span
                          class="label"
                          :class="slot.blocked ===1 ? 'label__blocked' : ''"
                        >
                          {{ timeSlotLabel(slot, day.slot_length) }}
                          <a
                            v-if="slot.is_booked"
                            class="linkified"
                            href="javascript:void(0)"
                            :aria-label="`View ${slot.guest.full_name} profile`"
                            @click.prevent="viewGuestProfile(slot.guest)"
                          >{{ slot.guest.full_name }}</a>
                          <span v-if="!slot.is_booked && slot.blocked === 1">Blocked</span>
                          <span v-if="!slot.is_booked && slot.blocked === 0">Free</span>
                        </span>
                        <el-button
                          v-if="slot.is_booked"
                          type="primary"
                          class="remove-guest-btn"
                          @click="confirmRemoveGuestFrom121(slot, day)"
                        >
                          Remove
                        </el-button>
                      </el-col>
                    </el-row>
                    <hr class="content-hr" />
                  </div>
                </div>
                <div v-if="is121Event && isAttendee && !is121Host">
                  <hr class="content-hr" />
                  <p class="news-description">
                    Book time with a GlobalScot
                  </p>
                  <p class="news-content">
                    You can book time with a GlobalScot to get advice on the day of the event. In order to book a slot please state that you’re going to the event.
                  </p>

                  <div
                    v-if="usersBookedSlotsCount.length > 0"
                    class="webianr-attending-section mt-5"
                  >
                    <div class="event-attending-content d-flex justify-content-between align-items-center">
                      <p class="attending-text">
                        Can't make this event?
                      </p>
                      <el-button
                        type="primary"
                        @click="confirmCancelAttendance"
                      >
                        Cancel all booked slots
                      </el-button>
                    </div>
                  </div>

                  <hr class="content-hr" />
                  <!-- Trader -->
                  <div
                    v-if="! is121Host"
                    id="gs-participants"
                  >
                    <div
                      v-for="event_host in event.one_to_one_event_hosts"
                      :key="`event-host-${event_host.id}`"
                      class="single-gs-participant"
                    >
                      <div class="meeting-slot">
                        <div class="meeting-slot-gs">
                          <el-avatar
                            :size="40"
                            :src="event_host.avatar"
                            class="avatar"
                            :alt="`${event_host.name} avatar`"
                          ></el-avatar>
                          <p class="name">
                            {{ event_host.name }}
                          </p>
                        </div>
                        <el-button
                          v-if="event_host.current_user_slot !== null"
                          type="secondary"
                          class="meeting-slot-button"
                          @click="confirmCancel121BookedTimeSlot(event_host)"
                        >
                          Cancel booked slot
                        </el-button>
                        <el-button
                          v-else
                          type="secondary"
                          class="meeting-slot-button"
                          @click="onOpenBookTimeSlot(event_host)"
                        >
                          Book a slot
                        </el-button>
                      </div>

                      <div
                        v-if="event_host.current_user_slot !== null"
                        class="webianr-attending-section mt-4"
                      >
                        <div class="booked-in-container">
                          <p class="attending-text">
                            You are booked in
                            <span class="booked-in-time">
                              {{ event_host.current_user_slot.start_time }} - {{ event_host.current_user_slot.end_time }}
                            </span>
                          </p>
                          <a
                            href="javascript:void(0)"
                            class="linkified-underline"
                            :aria-label="`Edit booked slot with ${event_host.name}`"
                            @click.prevent="onOpenBookTimeSlot(event_host, editMode = true)"
                          >Edit</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col
              :md="24"
              :lg="8"
              role="complementary"
            >
              <h4 class="related-news-title mb-3">
                Other events and webinars
              </h4>
              <el-row v-if="relatedItems.length > 0">
                <el-col
                  v-for="relatedItem in relatedItems"
                  :key="relatedItem.id"
                  :sm="24"
                  :md="12"
                  :lg="24"
                >
                  <events-card
                    :key="relatedItem.id"
                    :event="relatedItem"
                  />
                </el-col>
              </el-row>
              <el-row v-else>
                <el-col :md="24">
                  <p>No other events found</p>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>

    <feedback
      v-if="event && event.id"
      title="Event feedback"
      :visible="feedbackModalVisible"
      module="Event"
      entity="Event"
      :entity-i-d="event.id"
      @close="feedbackModalVisible = false"
      @submitted="reload()"
    >
      <template slot="header">
        <div class="container">
          <el-row>
            <el-col
              :xs="19"
              :sm="21"
              :md="21"
              :lg="22"
            >
              <div class="user-info">
                <p class="feedback-public-notice">
                  Your feedback won't appear publicly. We’ll use it to monitor the quality of your experience across the network.
                </p>
              </div>
            </el-col>
          </el-row>
        </div>
      </template>
      <template slot="title">
        Overall, how would you rate your experience with this event?
      </template>
      <template slot="textarea-label">
        Please leave a short review of your experience with this event (Optional)
      </template>
    </feedback>
    <focus-lock>
      <el-dialog
        :show-close="false"
        custom-class="event-dialog"
        :visible.sync="dialogs.leaveWebinar"
        title="Can’t make this webinar? If you can’t attend this webinar, we can give your space to someone else. You’ll be removed from the attendance list and you’ll lose access to the webinar when it becomes available."
      >
        <el-row>
          <el-col
            :span="24"
            class="text-center"
          >
            <h1 class="mt-0">
              Can’t make this webinar?
            </h1>
            <p class="fs-19">
              If you can’t attend this webinar, we can give your space to someone else. You’ll be removed from the attendance list and you’ll lose access to the webinar when it becomes available.
            </p>
          </el-col>
        </el-row>
        <span
          slot="footer"
          class="dialog-footer d-flex justify-content-end"
        >
          <el-button
            type="secondary"
            @click="dialogs.leaveWebinar = false"
          >Cancel</el-button>
          <el-button
            v-loading.lock="leaveWebinarLoading"
            type="primary"
            element-loading-background="rgba(255, 255, 255, 0.8)"
            :disabled="leaveWebinarLoading"
            @click="leaveWebinar"
          >Yes, leave webinar</el-button>
        </span>
      </el-dialog>
    </focus-lock>

    <SignUpForEventDialog
      :visible="dialogs.signUpForEvent"
      @closed="dialogs.signUpForEvent = false"
      @signedUp="onSignedUpForEvent"
    />
    <CancelEventAttendanceDialog
      v-if="event"
      :visible="dialogs.cancelEventAttendance"
      :event="event"
      :is121-host="is121Host"
      @closed="dialogs.cancelEventAttendance = false"
      @cancelledAttendance="onCancelledAttendance"
    />
    <BookA121TimeSlotDialog
      v-if="dialogs.bookA121TimeSlot"
      :visible="dialogs.bookA121TimeSlot"
      :event-host="eventHost"
      :edit-mode="bookSlotEditMode"
      @timeSlotBooked="handleSlotBooked"
      @closed="onCloseBookTimeSlot"
    />
    <Cancel121BookedTimeSlotDialog
      v-if="dialogs.cancel121BookedTimeSlot"
      :event-host="eventHost"
      :has-only-one-booked-slot="hasOnlyOneBookedSlot"
      :visible="dialogs.cancel121BookedTimeSlot"
      @slotReleased="handleSlotReleased"
      @closed="dialogs.cancel121BookedTimeSlot = false"
    />
    <RemoveGuestFrom121Dialog
      v-if="dialogs.removeGuestFrom121"
      :time-slot="slotToRemoveBookingFrom"
      :day="dayToRemoveBookingFrom"
      :visible="dialogs.removeGuestFrom121"
      @guestRemoved="handleGuestRemoved"
      @closed="dialogs.removeGuestFrom121 = false"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import store from '../../../store'
import gsApi from '@/services/gs.api'
import Feedback from '@/components/feedback/feedback'
import EventsCard from '@/components/platform/events-webinars/events-card'
import EventsHeader from '@/components/partials/events-header'
import Calendar from '@/components/assets/svg-news'
import MapPin from '@/components/assets/svg-map-pin'
import PurpleTick from '@/components/assets/svg-purple-tick'
import Time from '@/components/assets/svg-time'
import Ticket from '@/components/assets/svg-ticket'
import UserAvatar from '@/components/core/user-avatar'
import moment from 'moment';
import SignUpForEventDialog from './SignUpForEventDialog'
import CancelEventAttendanceDialog from './CancelEventAttendanceDialog'
import Cancel121BookedTimeSlotDialog from './Cancel121BookedTimeSlotDialog'
import BookA121TimeSlotDialog from './BookA121TimeSlotDialog'
import RemoveGuestFrom121Dialog from './RemoveGuestFrom121Dialog'
import DocumentView from "@/components/core/document-view";
import helpers from "@/helpers/index";
export default {
  name: 'EventsPage',
  metaInfo: {
    title: "Events & webinars"
  },
  components: {
    'events-card': EventsCard,
    'events-header': EventsHeader,
    'svg-map-pin': MapPin,
    'svg-time': Time,
    'svg-ticket': Ticket,
    'svg-purple-tick': PurpleTick,
    'svg-calendar': Calendar,
    Feedback,
    UserAvatar,
    SignUpForEventDialog,
    CancelEventAttendanceDialog,
    Cancel121BookedTimeSlotDialog,
    BookA121TimeSlotDialog,
    RemoveGuestFrom121Dialog,
    DocumentView
  },
  data() {
    return {
      event: null,
      eventHost: null,
      feedbackModalVisible: false,
      listenNowClicked: false,
      mainLoader: true,
      relatedItems: [],
      waitlingLoader: false,
      slotToRemoveBookingFrom: null,
      dayToRemoveBookingFrom: null,
      bookSlotEditMode: false,
      dialogs: {
        leaveWebinar: false,
        signUpForEvent: false,
        cancelEventAttendance: false,
        cancel121EventAttendance: false,
        cancel121BookedTimeSlot: false,
        bookA121TimeSlot: false,
        removeGuestFrom121: false,
      },
      leaveWebinarLoading: false,
      attachedFiles:[]
    }
  },
  computed: {
    ...mapGetters(['bookingSlotDetailsModal']),
    showFeedback() {
      if (this.event.has_left_feedback) { // User already left feedback for event, webinar or podcast
        return false
      }

      if (this.event.type == 0 && this.event.has_ended && this.event.feedback_enabled && this.isAttendee) { // Event
        return true
      }

      if (this.event.type == 2 && this.event.feedback_enabled) { // Podcast
        return true
      }

      if (this.event.type == 1 && this.event.category == 4 && this.isAttendee && this.event.has_ended && this.event.feedback_enabled) { // Upcoming Webinar
        return true
      }

      if (this.event.type == 1 && this.event.category == 5 && this.event.feedback_enabled) { // Previous Webinar
        return true
      }

      return false
    },
    altText() {
      return (this.event.mediaFile && this.event.mediaFile.alt_attribute) ? this.event.mediaFile.alt_attribute: null
    },
    isAttendee() {
      return this.event.current_user_event_status.length !== 0 && Object.prototype.hasOwnProperty.call(this.event.current_user_event_status, 'status')
                && this.event.current_user_event_status.status.name === 'Attendee';
    },
    isInWaitingList() {
      return this.event.current_user_event_status.length !== 0 && Object.prototype.hasOwnProperty.call(this.event.current_user_event_status, 'status')
                && this.event.current_user_event_status.status.name === 'WaitingListMember';
    },
    cancelledAttendance() {
      return this.event.current_user_event_status.length !== 0 && Object.prototype.hasOwnProperty.call(this.event.current_user_event_status, 'status')
                  && this.event.current_user_event_status.status.name === 'Cancelled';
    },
    hasAttended() {
      return this.event.current_user_event_status.length !== 0 && Object.prototype.hasOwnProperty.call(this.event.current_user_event_status, 'participated_at')
                  && this.event.current_user_event_status.participated_at !== null;
    },
    eventIsCancelled() {
      return this.event.status === 3;
    },
    eventIsBookable() {
      return !this.eventIsCancelled && this.event.has_spaces && moment().diff(this.event.book_by_string) < 0;
    },
    usEventJoinable(){
      return !this.eventIsCancelled && !this.event.has_spaces && moment().diff(this.event.book_by_string) < 0;
    },
    is121Event() {
      return this.event.type === 0 && this.event.category === 0;
    },
    is121Host() {
      return this.event.hostEventDays && this.event.hostEventDays.data && this.event.hostEventDays.data.length > 0;
    },
    usersBookedSlotsCount() {
      if (this.is121Event && !this.is121Host && Object.keys(this.event.one_to_one_event_hosts).length) {
        let slotCount = [];

        for (const [key, event_host] of Object.entries(this.event.one_to_one_event_hosts)) {
          if (event_host.current_user_slot !== null) {
            slotCount.push(event_host);
          }
        }

        return slotCount;
      }

      return [];
    },
    hasOnlyOneBookedSlot() {
      return this.usersBookedSlotsCount.length === 1;
    },
    navigatedFromBookedSlotNotification() {
      return this.bookingSlotDetailsModal.isOpen === true && this.bookingSlotDetailsModal.event_host_id !== null;
    },
    canOpenBookingDetailsModal() {
      return this.eventIsBookable && this.isAttendee && ! this.cancelledAttendance;
    },
    content(){
      let cloneDiv = document.createElement("div");
      cloneDiv.innerHTML = this.event.content;
      
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.$set(this, "attachedFiles", helpers.getAttachedFiles(cloneDiv));

      return cloneDiv.innerHTML.replaceAll('&nbsp;','')
    }
  },
  methods: {
    ...mapActions(['closeBookingSlotDetailsModal']),
    reload() {
      window.location.reload()
    },
    leaveWebinar() {
      this.leaveWebinarLoading = true

      gsApi.events.leaveWebinar(this.$route.params.slug)
        .then(response => {
          this.onCancelledAttendance();

          this.dialogs.leaveWebinar = false
          this.leaveWebinarLoading = false
        }).catch(error => {
          this.dialogs.leaveWebinar = false
          this.leaveWebinarLoading = false

          if (error.response && error.response.status == 403) {
            this.$alert("Unable to leave webinar, you are already not attending this webinar.", "Error", {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal"
            })
          } else {
            this.$alert("Unable to leave webinar, please try again later.", "Error", {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal"
            })
          }
        })
    },
    attendWebinar() {
      this.mainLoader = true

      gsApi.events.attendWebinar(this.$route.params.slug)
        .then(response => {
          this.onSignedUpForEvent();

          this.mainLoader = false
        }).catch(error => {
          this.mainLoader = false

          if (error.response && error.response.status == 422) {
            this.$alert("Unable to attend webinar, this webinar is full. Check back later in case spaces open up. ", "Error", {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal"
            })
          } else if (error.response && error.response.status == 403) {
            this.$alert("Unable to attend webinar, you are already attending this webinar.", "Error", {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal"
            })
          } else {
            this.$alert("Unable to attend webinar, please try again later.", "Error", {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal"
            })
          }
        })
    },
    confirmSignUp() {
      this.dialogs.signUpForEvent = true;
    },
    onSignedUpForEvent() {
      this.event.current_user_event_status = {
        cancelled_at: null,
        participated_at: null,
        registered_at: new Date(),
        status: { name: "Attendee", class: "attendee" },
        type: "Participant"
      };

      if (this.is121Event) {
        this.fetchEvent();
      }
      if(this.event.type == 1){
        window.open(this.event.url)
      }
    },
    confirmCancelAttendance() {
      this.dialogs.cancelEventAttendance = true;
    },
    onCancelledAttendance() {
      this.event.current_user_event_status = {
        cancelled_at: new Date(),
        participated_at: null,
        registered_at: null,
        status: { name: "Cancelled", class: "cancelled" },
        type: "Participant"
      };
    },
    confirmCancel121BookedTimeSlot(eventHost) {
      this.eventHost = eventHost;
      this.dialogs.cancel121BookedTimeSlot = true;
    },
    confirmRemoveGuestFrom121(slot, day) {
      this.slotToRemoveBookingFrom = slot;
      this.dayToRemoveBookingFrom = day;
      this.dialogs.removeGuestFrom121 = true;
    },
    handleSlotBooked(timeSlot) {
      this.mainLoader = true;
      this.fetchEvent();
    },
    handleSlotReleased() {
      this.mainLoader = true;
      this.fetchEvent();
    },
    handleGuestRemoved() {
      this.slotToRemoveBookingFrom.is_booked = false
      this.slotToRemoveBookingFrom.guest = null;
    },
    onOpenBookTimeSlot(eventHost, editMode = false) {
      this.bookSlotEditMode = editMode;
      this.eventHost = eventHost;
      this.dialogs.bookA121TimeSlot = true;
    },
    onCloseBookTimeSlot() {
      this.dialogs.bookA121TimeSlot = false;
      this.closeBookingSlotDetailsModal();
    },
    getRelatedItems() {
      let params = {
        item_id: this.event.id,
        type: 'Modules\\Event\\Entities\\Event',
        entity_type: 'event__events',
        take: 2
      }

      gsApi.articles.getRelatedItems(params)
        .then(response => {
          this.relatedItems = response.data.data
        }).catch(error => {
          this.relatedItems = []
        })
    },
    fetchEvent() {
      gsApi.events.getBySlug(this.$route.params.slug)
        .then(response => {
          this.mainLoader = false
          this.event = response.data

          this.getRelatedItems()

          if(this.$route.query.feedback && this.event.type == 0 && this.showFeedback) {
            this.feedbackModalVisible = true
          }
        }).catch(error => {
          this.mainLoader = false
          this.$router.push({name: 'Events and Webinars'})
        })
    },
    trackWebinarWatch() {
      this.mainLoader = true

      gsApi.events.trackWebinarWatch(this.$route.params.slug)
        .then(response => {
          this.mainLoader = false

          window.open(this.event.url, '_blank')
        }).catch(error => {
          this.mainLoader = false

          this.$alert(
            "Unable to retrieve webinar, please try again later.",
            "Error",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal"
            }
          )
        })
    },
    trackPodcastListen() {
      this.mainLoader = true

      gsApi.events.trackPodcastListen(this.$route.params.slug)
        .then(response => {
          this.mainLoader = false
          this.listenNowClicked = true
        }).catch(error => {
          this.mainLoader = false

          this.$alert(
            "Unable to retrieve podcast, please try again later.",
            "Error",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal"
            }
          )
        })
    },
    dateLabel(date) {
      return moment(date).format('Do MMMM')
    },
    timeSlotLabel(slot, meeting_length) {
      const slotStartTime = moment(slot.start_time);
      const slotEndTime = slotStartTime.clone()
        .add(meeting_length, 'minutes')
        .format('HH:mm')

      return `${slotStartTime.format('HH:mm')} - ${slotEndTime}`
    },
    profileLinkName(guest) {
      if (guest.role_name === 'Expert') {
        return 'SE Profile'
      } else if (guest.role_name === 'Staff') {
        return 'Staff Profile'
      }
      return 'NonGlobalScot Profile'
    },
    viewGuestProfile(guest) {
      /* So user can see go back to event link on guests profile */
      store.dispatch('updateNavigatedFrom', {
        name: 'event-view',
      });

      this.$router.push({ name: this.profileLinkName(guest), params: { id: guest.id } })
    },
    openBookingSlotDetailsDialogFromNotification() {
      this.dialogs.bookA121TimeSlot = true;
      this.eventHost = {
        id: this.bookingSlotDetailsModal.event_host_id
      }
      this.bookSlotEditMode = true;
    },
    joinWaitingList(slug){
      this.waitlingLoader = true;
      gsApi.events.joinWaitingList(slug)
        .then(response => {
          this.waitlingLoader = false;
          this.event = response.data;
        }).catch(error => {
          this.waitlingLoader = false;
          if (error.response && error.response.status == 404) {
            this.$alert(error.response.data.message, "Error", {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal"
            })
                            
            this.$router.push({name: 'Events and Webinars'})
          } else if (error.response && error.response.status == 403) {
            this.$alert(error.response.data.message, "Error", {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal"
            })
            this.fetchEvent();
          } else {
            this.$router.push({name: 'Events and Webinars'})
          }
                      
        })
    },
    cancelWaitingList(slug){
      this.waitlingLoader = true;
      gsApi.events.cancelWaitingList(slug)
        .then(response => {
          this.waitlingLoader = false;
          this.event = response.data;
        }).catch(error => {
          this.waitlingLoader = false;
          this.$router.push({name: 'Events and Webinars'})
        })
    }
  },
  watch: {
    $route(to, from) {
      this.mainLoader = true

      gsApi.events.getBySlug(to.params.slug)
        .then(response => {
          this.mainLoader = false
          this.event = response.data

          this.getRelatedItems()
        }).catch(error => {
          this.$router.push({name: 'Events and Webinars'})
        })
    },
    bookingSlotDetailsModal: {
      deep: true,

      handler() {
        if (this.navigatedFromBookedSlotNotification && this.canOpenBookingDetailsModal) {
          this.openBookingSlotDetailsDialogFromNotification();
        }
      }
    }
  },
  created() {
    /*if (!store.state.user.isLogged) {
                router.push('/login')
            } else{
                this.fetchEvent()
            }*/
    if(store.state.user.isLogged){
      this.fetchEvent();
    }

    if (this.navigatedFromBookedSlotNotification && this.canOpenBookingDetailsModal) {
      this.openBookingSlotDetailsDialogFromNotification();
    }
  }
}
</script>
