<template>
  <div
    v-loading.fullscreen.lock="mainLoader"
    class="members-page body__content"
    element-loading-background="rgba(255, 255, 255, 0.8)"
  >
    <div class="search-section">
      <div class="container">
        <el-row>
          <el-col :sm="24">
            <h1>Search for members</h1>
            <div class="search-btn-container hidden-sm-and-down">
              <ul class="search-buttons">
                <li
                  v-for="(type, index) in types" 
                  :key="index"
                >
                  <el-button
                    :class="{ 'active-state': search.type == type.name }"
                    class="search-btn"
                    @click="handleSortChange(type)"
                  >
                    <p>{{ type.label }}</p>
                  </el-button>
                </li>
              </ul>
            </div>
          </el-col>
        </el-row>
        <el-form
          id="searchForm"
          ref="searchForm"
          :model="search"
          @submit.native.prevent
        >
          <el-row>
            <el-col
              :sm="24"
              :md="12"
            >
              <el-form-item
                label="Keyword search"
                prop="keyword"
                class="hidden-sm-and-down"
              >
                <el-input
                  id="keyword"
                  v-model="search.keyword"
                  :placeholder="search.type == 'organisations' ?'Search for name, location...' :'Search for name, location, company...'"
                  auto-complete="no"
                  maxlength="255"
                  @keyup.enter.native="searchMembers"
                />
              </el-form-item>
              <el-form-item
                label=""
                prop="keyword"
                class="hidden-md-and-up"
              >
                <el-input
                  id="keyword"
                  v-model="search.keyword"
                  :placeholder="search.type == 'organisations' ?'Search for name, location...' :'Search for name, location, company...'"
                  auto-complete="no"
                  maxlength="255"
                  @keyup.enter.native="searchMembers"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col
              :sm="24"
              :md="12"
            >
              <div class="search-members">
                <el-button
                  class="search-members-button"
                  type="primary"
                  @click="searchMembers"
                >
                  Search
                </el-button>
              </div>
            </el-col>
          </el-row>
          <el-row
            v-if="search.type == 'members'"
            class="hidden-sm-and-down"
          >
            <el-col
              :sm="24"
              :md="12"
              :lg="6"
            >
              <el-form-item
                prop="organisation"
                class="is-no-asterisk"
              >
                <template slot="label">
                  <span aria-hidden="true">Organisation</span>
                  <span class="sr-only">Please select an organisation</span>
                </template>
                <v-select
                  v-model="search.organisation"
                  placeholder="Choose from the following"
                  :options="allOrganisations"
                  :searchable="true"
                  :clearable="false"
                  :reduce="(organisation) => organisation.id"
                  input-id="organisation"
                  autocomplete="false"
                  label="name"
                  class="half-content"
                />
              </el-form-item>
            </el-col>
            <!--el-col :sm="24" :md="12" :lg="6">
              <el-form-item prop="sector" class="is-no-asterisk">
                <template slot="label">
                  <span aria-hidden="true">Sector</span>
                  <span class="sr-only">Please select a sector</span>
                </template>
                <v-select
                  v-model="search.sector"
                  placeholder="Choose from the following"
                  :options="sectors"
                  :searchable="true"
                  :clearable="false"
                  :reduce="sector => sector.id"
                  inputId="sector"
                  autocomplete="false"
                  label="name"
                  class="half-content" />
                </v-select>
              </el-form-item>
	          </el-col-->
            <!--el-col :sm="24" :md="12" :lg="6">
              <el-form-item prop="expertise" class="is-no-asterisk">
                <template slot="label">
                  <span aria-hidden="true">Expertise</span>
                  <span class="sr-only">Please select an expertise</span>
                </template>
                <v-select
                  v-model="search.expertise"
                  placeholder="Choose from the following"
                  :options="expertises"
                  :searchable="true"
                  :clearable="false"
                  :reduce="expertise => expertise.id"
                  inputId="expertise"
                  autocomplete="false"
                  label="name"
                  class="half-content" />
                </v-select>
              </el-form-item>
	          </el-col-->
            <el-col
              :sm="24"
              :md="12"
              :lg="6"
            >
              <el-button
                type="primary"
                class="float-right update-results-btn"
                @click="searchMembers"
              >
                Update results
              </el-button>
              <el-button
                type="text"
                class="reset-btn float-right"
                @click="resetSearch"
              >
                Reset
              </el-button>
            </el-col>
          </el-row>
          <el-row class="hidden-md-and-up">
            <el-col :sm="24">
              <el-form-item
                prop="title"
                class="is-no-asterisk"
              >
                <template slot="label">
                  <span aria-hidden="true">View</span>
                  <span class="sr-only">Please select a view type</span>
                </template>
                <v-select
                  v-model="search.type"
                  :value="search.type"
                  placeholder="Choose from the following"
                  :options="viewOptions"
                  :searchable="false"
                  :clearable="false"
                  :reduce="(viewOption) => viewOption.key"
                  input-id="view"
                  autocomplete="false"
                  class="half-content"
                  @input="searchMembers"
                />
              </el-form-item>
            </el-col>
            <el-col
              v-if="search.type == 'members'"
              :sm="24"
              class="hidden-md-and-up"
            >
              <el-button
                class="more-filters-btn"
                size="medium"
                @click="openMembersFilter"
              >
                More filters
              </el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>

    <div
      v-if="showZeroData"
      class="zero-data text-center mt-5"
    >
      <div class="container">
        <el-row>
          <el-col :sm="24">
            <p>
              {{ search.type == "members" ? "We could not find any members with your search criteria. Please update your search and try again." 
                : " We could not find any organisations with your search criteria. Please update your search and try again." }}
            </p>
          </el-col>
        </el-row>
      </div>
    </div>

    <div
      v-if="!showZeroData"
      id="view-by"
      class="container view-by-section"
    >
      <el-form>
        <el-row>
          <!--el-col
            :sm="24"
            :md="24"
            :lg="6"
          >
            <el-form-item
              prop="title"
              class="is-no-asterisk"
            >
              <template slot="label">
                <span aria-hidden="true">Sort Results</span>
                <span class="sr-only">Sort Results</span>
              </template>
              <v-select
                v-model="sortResult"
                placeholder="Choose from the following"
                :options="sortResults"
                :searchable="true"
                :clearable="false"
                input-id="sortResult"
                autocomplete="false"
                label="name"
                class="half-content"
              />
            </el-form-item>
          </!--el-col-->
          <el-col
            :sm="24"
            :md="12"
            :lg="12"
          >
            <div class="results-text">
              Results <span>{{ totalResults }}</span>
            </div>
          </el-col>
          <!--el-col
            :sm="24"
            :md="12"
            :lg="12"
          >
            <div>
              <ul class="map-list-btn">
                <li>
                  <el-button
                    label="list"
                    class="list-button"
                    :class="{ 'active-view-button': search.view == 'list' }"
                    @click="onViewChange('list')"
                  >
                    <div class="d-flex">
                      <svg-list-sort></svg-list-sort>
                      <span class="sort-text">List</span>
                    </div>
                  </el-button>
                </li>
                <li>
                    <el-button label="map" class="map-button" :class="{'active-view-button': this.search.view == 'map' }" v-if="search.sortBy == 'Expert'" @click="onViewChange('map')">
                      <div class="d-flex"><svg-map-sort></svg-map-sort> <span class="sort-text">Map</span></div>
                    </el-button>
                  </li>
              </ul>
            </div>
          </!el-col-->
        </el-row>
      </el-form>
    </div>

    <div
      v-if="!showZeroData && search.view == 'list'"
      class="results-section"
    >
      <div class="container">
        <div class="mt-4">
          <el-row
            v-if="search.type == 'members'"
            class="card-deck"
          >
            <member-card
              v-for="member in members"
              :key="member.id"
              :member="member"
            />
          </el-row>
          <el-row
            v-else
            class="card-deck"
          >
            <el-col
              v-for="(organisation, index) in organisations"
              :key="index"
              :sm="24"
              :md="12"
              :lg="8"
              :xl="6"
            >
              <organisation-card
                :organisation="organisation"
              />
            </el-col>
          </el-row>
        </div>
      </div>

      <div class="pagination-help-section">
        <div class="container">
          <el-button
            v-if="!onLastPage"
            class="load-more-btn"
            size="medium"
            @click="loadMore"
          >
            Load more
          </el-button>
        </div>
      </div>
    </div>

    <!--div class="results-section" v-if="members.length > 0 && search.view == 'map'">
	  	<div>
	  		<div>
          <div class="map-section" v-if="loadMap && search.view == 'map'">
  	      	<map-view class="map" :markers="markers"></map-view>
        </div>

            </div>
          </div>
  	</!--div-->

    <members-filter
      v-if="membersFilterOpen"
      v-model="search"
      :organisations="allOrganisations"
      @close="closeMembersFilter"
      @search="searchMembers(true)"
      @reset="resetSearch(true)"
    ></members-filter>
  </div>
</template>

<script>
import { mapState } from "vuex";
import store from "../../store";
import gsApi from "@/services/gs.api";
//import MapView from "@/components/map-view/map-view";
//import MapSort from "@/components/assets/svg-map-sort";
//import ListSort from "@/components/assets/svg-list-sort";
import MemberCard from "@/components/platform/members/member-card";
import OrganisationCard from "@/components/platform/organisations/organisation-card";
import MembersFilter from "@/components/slide-panel/panels/members-filter";
import _ from "lodash";

export default {
  name: 'MembersList',
  metaInfo: {
    title: "Members",
  },
  components: {
    //"map-view": MapView,
    //"svg-map-sort": MapSort,
    // "svg-list-sort": ListSort,
    "member-card": MemberCard,
    "members-filter": MembersFilter,
    OrganisationCard
  },
  data() {
    return {
      mainLoader: false,
      page: 1,
      perPage: 8,
      totalResults: 0,
      showZeroData: false,
      onLastPage: false,
      loadMap: false,
      search: {
        type:"members"
      },
      viewOptions: [
        {
          key: "organisations",
          label: "Organisations",
        },
        {
          key: "members",
          label: "Members",
        },
      ],
      markers: [],
      members: [],
      allOrganisations: [],
      organisations:[],
      membersFilterOpen: false,
      types: [
        { label: "Members", name: "members" },
        { label: "Organisations", name: "organisations" },
      ],
      currentZoom: 2,
      currentBounds: null,
      sortResults: [
        {
          id: 1,
          name: "A-Z City location",
          order_by: "state_name",
          order: "asc",
        },
        {
          id: 2,
          name: "Z-A City location",
          order_by: "state_name",
          order: "desc",
        },
        {
          id: 3,
          name: "Most recently joined date",
          order_by: "created_at",
          order: "desc",
        },
        {
          id: 4,
          name: "Oldest joined date",
          order_by: "created_at",
          order: "asc",
        },
        { id: 5, name: "A-Z Surname", order_by: "last_name", order: "asc" },
        { id: 6, name: "Z-A Surname", order_by: "last_name", order: "desc" },
        { id: 7, name: "A-Z First name", order_by: "first_name", order: "asc" },
        {
          id: 8,
          name: "Z-A First name",
          order_by: "first_name",
          order: "desc",
        },
      ],
      sortResult: null,
    };
  },
  computed: {
    ...mapState(["user"]),
  },

  watch: {
    search: {
      handler(val) {
        let params = _.pickBy(val, function (o) {
          return o !== null && o !== undefined;
        });
        this.addParamsToLocation(params);
      },
      deep: true,
    },

    sortResult(val) {
      this.search.order_by = val.order_by;
      this.search.order = val.order;
      this.searchMembers();
    },
  },
  created() {
    let search = {
      keyword: this.$store.state.search.keyword,
      organisation: this.$store.state.search.organisation,
      view: this.$store.state.search.view,
      order_by: this.$store.state.search.order_by,
      order: this.$store.state.search.order,
      type: this.$store.state.search.type ? this.$store.state.search.type  : "members"
    };

    if (this.$route.query.hasOwnProperty("type")) {
      search.type = this.$route.query.type;
    }
    if (this.$route.query.hasOwnProperty("keyword")) {
      search.keyword = this.$route.query.keyword;
    }
    if (this.$route.query.hasOwnProperty("organisation")) {
      search.organisation = parseInt(this.$route.query.organisation);
    }
    if (this.$route.query.hasOwnProperty("view")) {
      search.view = this.$route.query.view;
      // search.view = 'list' // Temp until we fix the map
    }
    if (this.$route.query.hasOwnProperty("order_by")) {
      search.order_by = this.$route.query.order_by;
    }
    if (this.$route.query.hasOwnProperty("order")) {
      search.order = this.$route.query.order;
    }
    this.search = search;

    /*if (!store.state.user.isLogged) {
          router.push('/login')
      }*/
    if (store.state.user.isLogged) {
      this.getRegisterMeta();
      //this.fetchMapMarkers()
    }
  },
  methods: {
    addParamsToLocation(params) {
      history.pushState(
        {},
        null,
        this.$route.path +
          "?" +
          Object.keys(params)
            .map((key) => {
              return (
                encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
              );
            })
            .join("&")
      );
    },
    /*onViewChange(viewType) {
       this.search.view = viewType

      	if (this.search.view == 'map') {
          if (!this.loadMap) {
            this.fetchMapMarkers()
          }
      	}
        this.searchMembers()
    	},*/
    generateMarkers(markerData) {
      this.clearAllMarkers();
      markerData.data.forEach((clusterMarker) => {
        if (clusterMarker.lat && clusterMarker.lng) {
          let marker = {
            id: clusterMarker.id,
            position: {
              lat: parseFloat(clusterMarker.lat),
              lng: parseFloat(clusterMarker.lng),
            },
            stateId: clusterMarker.state_id,
            avatar: clusterMarker.avatar,
            memberId: clusterMarker.user_id,
            name: clusterMarker.fullname,
          };
          this.markers.push(marker);
        }
      });
    },
    clearAllMarkers() {
      this.$events.emit("deleteAllMarkers");
      this.markers = [];
    },
    getRegisterMeta() {
      this.mainLoader = true;

      gsApi.globalscot.meta
        .index()
        .then((response) => {
          this.allOrganisations = response.data.organisations.data; // TODO:: Return list of available organisations
          /*this.sectors = response.data.groups.data
          this.expertises = response.data.expertise.data*/

          this.search.type == 'organisations' ? this.fetchOrganisations() : this.fetchMembers()
        })
        .catch((error) => {
          this.mainLoader = false;
          this.$alert(
            "An unknown error occurred. Please try again later.",
            "Error",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal",
            }
          );
        });
    },
    resetSearch(isMobile) {
      if (isMobile) {
        this.closeMembersFilter();
      }

      this.search = {
        type:'members',
        keyword: "",
        organisation: null,
        view: "list",
        order_by: "",
        order: "",
      };

      this.sortResult = null;

      this.searchMembers();
    },
    searchMembers(isMobile) {
      if (isMobile) {
        this.closeMembersFilter();
      }

      /*if (this.search.sortBy == "All") {
        this.search.view = 'list'
      }*/

      this.page = 1;
      this.members = [];
      this.organisations=[];
      this.$store.commit('UPDATE_MEMBER_SEARCH', this.search)
      this.search.type == 'organisations' ? 
        this.fetchOrganisations() :  this.fetchMembers()
        
      //this.fetchMapMarkers()
    },
    fetchMembers() {
      if (!this.mainLoader) {
        this.mainLoader = true;
      }

      this.$store.commit("UPDATE_MEMBER_SEARCH", this.search);

      let params = {
        page: this.page,
        per_page: this.perPage,
        member_type: "Expert", //this.search.sortBy
        organisation: this.search.organisation,
        search: this.search.keyword,
        order_by: this.search.order_by,
        order: this.search.order,
      };

      gsApi.members
        .search(params)
        .then((response) => {
          this.mainLoader = false;
          this.totalResults = response.data.meta.pagination.total;

          if (
            response.data.meta.pagination.current_page !==
            response.data.meta.pagination.total_pages
          ) {
            this.onLastPage = false;
            this.page++;
          } else {
            this.onLastPage = true;
          }

          //this.members = this.members.concat(response.data.data )
          // workaround to avoid showing duplicate members
          const currentMembers = _.map(this.members, "id");

          this.members = this.members.concat(
            response.data.data.filter((el) => {
              return !currentMembers.includes(el.id);
            })
          );

          this.showZeroData = !this.members.length
        })
        .catch((error) => {
          this.mainLoader = false;
        });
    },
    fetchOrganisations(){

      if (!this.mainLoader) {
        this.mainLoader = true;
      }

      this.$store.commit("UPDATE_MEMBER_SEARCH", this.search);

      const params = {
        page: this.page,
        per_page: this.perPage,
        search: this.search.keyword,
      };

      gsApi.organisation
        .index(params)
        .then((response) => {
          this.mainLoader = false;
          this.totalResults = response.data.meta.pagination.total;

          if (
            response.data.meta.pagination.current_page !==
            response.data.meta.pagination.total_pages
          ) {
            this.onLastPage = false;
            this.page++;
          } else {
            this.onLastPage = true;
          }

          const currentOrganisation = _.map(this.organisations, "id");

          this.organisations = this.organisations.concat(
            response.data.data.filter((el) => {
              return !currentOrganisation.includes(el.id);
            })
          );

          this.showZeroData = !this.organisations.length 
        })
        .catch((error) => {
          this.mainLoader = false;
        });
    },
    loadMore(){
      this.search.type == 'organisations' ? this.fetchOrganisations() : this.fetchMembers()
    },
    /*fetchMapMarkers() {
        this.mainLoader = true

        let params = {
          member_type: this.search.sortBy,
          organisation_id: this.search.organisation,
          group_id: this.search.sector,
          expertise_id: this.search.expertise,
          search: this.search.keyword,
          view: this.search.view,
          order_by: this.search.order_by,
          order: this.search.order,
        }

        gsApi.members.mapSearch(params)
          .then(response => {
            if (response.data) {
              this.loadMap = true
              this.generateMarkers(response.data)
            } else {
              this.markers = []
            }

            this.mainLoader = false
          }).catch(error => {
            this.mainLoader = false
          })
      },*/
    openMembersFilter() {
      this.membersFilterOpen = true;
    },
    closeMembersFilter() {
      this.membersFilterOpen = false;
    },
    handleSortChange(type) {
      this.search.type = type.name;
      this.search.keyword = null;
      this.search.organisation = null;
      this.searchMembers();
    },
  },
};
</script>
<style >
.map-list-btn {
  list-style: none;
  display: inline-flex;
}
</style>
