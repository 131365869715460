<template>
  <focus-lock>
    <el-dialog
      ref="removeGuestFrom121"
      @opened="dialogOpen('removeGuestFrom121')"
      aria-describedby="dialogDescription"
      custom-class="events-general-dialog"
      :show-close="false"
      :visible.sync="dialogVisible"
      element-loading-background="rgba(255, 255, 255, 0.8)"
      title="Please confirm you want to sign up for this event"
      @close="onClose($event)"
    >
      <div class="body">
        <h2 class="event-dialog-title">Do you wish to remove this booking?</h2>
        <p class="event-dialog-text">Are you sure you wish to remove the <span>{{ timeLabel }}</span> booking by
          <a href="javascript:void(0)" class="linkified" @click.prevent="viewGuestProfile" :aria-label="`View ${guestName} profile`">{{ guestName }}</a>. When you cancel a booking the user is notified.</p>
        <div class="button-wrap d-flex events-dialog-buttons">
          <el-button class="events-dialog-cancel" @click="onClose($event)">
            Cancel
          </el-button>
          <el-button
            class="events-dialog-confirm"
            type="primary"
            @click.prevent="removeGuestFrom121()"
            :loading="mainLoader"
          >
            I can’t attend
          </el-button>
        </div>
      </div>
    </el-dialog>
  </focus-lock>
</template>

<script>
import store from '@/store'
import gsApi from '@/services/gs.api'
import moment from "moment";

export default {
  name: 'RemoveGuestFrom121Dialog',

  props: {
    visible: {
      type: Boolean,
      default: false
    },
    eventHost: {
      type: Object,
      default: null,
    },
    day: {
      type: Object,
      default: null,
    },
    timeSlot: {
      type: Object,
      default: null,
    },
    eventGuest: {
      type: Object,
      default: null,
    },
  },
  data () {
    return {
      mainLoader: false,
      dialogVisible: false
    }
  },

  computed: {
    timeLabel () {
      const slotEndTime = moment(this.timeSlot.start_time, 'HH:mm')
        .add(this.day.slot_length, 'minutes')
        .format('HH:mm');

      return `${this.timeSlot.start_time} - ${slotEndTime}`;
    },
    guestName () {
      return this.timeSlot.guest.full_name;
    },
    isGlobalScot() {
      return this.timeSlot.guest.role_name === 'Expert';
    },
    isStaff() {
      return this.timeSlot.guest.role_name === 'Staff';
    },
    profileLinkName() {
      if (this.isGlobalScot) {
        return 'SE Profile'
      } else if (this.isStaff) {
        return 'Staff Profile'
      }
      return 'NonGlobalScot Profile'
    }
  },

  methods: {
    onClose (e) {
      this.dialogVisible = false
      this.$emit('closed')
    },

    removeGuestFrom121 () {
      this.mainLoader = true
      gsApi.events
        .releaseASlot(
          this.day.id,
          this.timeSlot.id
        )
        .then((response) => {
          this.$emit('guestRemoved')
          this.onClose()
        })
        .catch((error) => {
          console.log(error, 'error cancelling booked slot')
          if (error.response && error.response.status === 422 && error.response.data) {
            let firstError =
              error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            this.$alert(
              firstError[0],
              'Error',
              {
                confirmButtonText: 'Close',
                showClose: false,
                customClass: 'notification-modal'
              }
            )
          } else {
            this.$alert(
              'Sorry, your request could not be processed',
              `Error ${error.response.status} - ${error.response.data.message}`, {
              confirmButtonText: 'Close',
              showClose: false,
              customClass: 'notification-modal'
            })
          }
        })
        .finally(() => {
          this.mainLoader = false
        })

    },
    viewGuestProfile() {
      store.dispatch('updateNavigatedFrom', {
        name: 'event-view',
      });
      this.$router.push({ name: this.profileLinkName, params: { id: this.timeSlot.guest.id } })
    }
  },

  watch: {
    visible (value) {
      this.dialogVisible = this.visible
    }
  },
  created () {
    this.dialogVisible = this.visible
  }
}
</script>

<style lang="scss">
.events-general-dialog {
  min-height: 422px;
  .body {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .event-dialog-title {
    font-style: normal;
    font-weight: 420;
    font-size: 2.1875rem;
    line-height: $line-height-45;
    /* identical to box height, or 129% */

    text-align: center;

    color: #000000;
    @media screen and(max-width: 425px) {
      font-size: 1.4rem;
    }
  }

  .event-dialog-text {
    font-style: normal;
    font-weight: 390;
    font-size: 1.5rem;
    line-height: $line-height-35;
    text-align: center;
    color: #000000;
    padding-top: 1.375rem;
    max-width: 586px;
    width: 100%;
  }

  .events-dialog-buttons {
    width: 100%;

    .events-dialog-cancel {
      width: 100%;
      max-width: 231px;
    }

    .events-dialog-confirm {
      width: 100%;
      max-width: 231px;
    }

    @media screen and(max-width: 425px) {
      flex-direction: column;
      width: 100%;

      .events-dialog-confirm {
        width: 100%;
        margin: 0.625rem 0 0;
      }
    }
  }

  .sign-up-buttons {
    padding-top: 2rem;
    @media screen and(max-width: 425px) {
      padding-top: 1rem
    }
  }
}

</style>
