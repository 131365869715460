<template>
  <div
    v-loading.fullscreen.lock="mainLoader"
    class="request-help-form"
    element-loading-background="rgba(255, 255, 255, 0.8)"
  >
    <div class="container">
      <h2 class="title">
        New help request from {{ helpRequest.trader.first_name }}
      </h2>
      <el-row>
        <el-col
          :xs="5"
          :sm="3"
          :md="3"
          :lg="2"
        >
          <user-avatar
            shape="circle"
            :is-global-scot="helpers.isExpert(helpRequest.trader)"
            :author-exist="!!helpRequest.trader"
            :size="74"
            width="74px"
            avatar-classes="avatar"
            :src="helpRequest.trader ? helpRequest.trader.avatar : null"
            :alt="helpRequest.trader ? helpRequest.trader.first_name : '' + 'Profile picture'"
          ></user-avatar>
        </el-col>
        <el-col
          :xs="19"
          :sm="21"
          :md="21"
          :lg="22"
        >
          <div class="user-info">
            <div class="d-flex justify-content-between title-name">
              <p>{{ helpRequest.trader.full_name }}</p>
            </div>
            <div
              v-if="!helpRequest.trader.roles.includes('staff')"
              class="d-flex align-items-center user-role-business"
            >
              <p>{{ helpRequest.business.role }}</p>
              <svg-vertical-divider class="mx-2 hidden-md-and-down"></svg-vertical-divider>
              <p>{{ helpRequest.business.name }}</p>
            </div>
            <div class="location d-flex mt-1 align-items-center">
              <p class="d-flex location-text">
                <svg-map-pin-gradient class="mr-2 map-pin"></svg-map-pin-gradient>
                {{ location }}
              </p>
              <svg-vertical-divider class="ml-3 mr-3 hidden-md-and-down"></svg-vertical-divider>
              <p
                v-if="helpers.isExpert(helpRequest.trader)"
                class="d-flex member-for"
              >
                <svg-gs-profile-icon class="mr-2 gs-globe"></svg-gs-profile-icon>GlobalScot for, {{ memberFor }}
              </p>
              <p
                v-else-if="helpers.isMember(helpRequest.trader)"
                class="d-flex member-for"
              >
                <svg-member-profile-icon class="mr-2 gs-globe"></svg-member-profile-icon> Member for {{ memberFor }}
              </p>
              <p
                v-else
                class="d-flex member-for"
              >
                <svg-staff-profile-icon class="mr-2 gs-globe"></svg-staff-profile-icon> Team Scotland for, {{ memberFor }}
              </p>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <hr class="mt-5" />

    <div class="container">
      <el-row>
        <el-col :lg="24">
          <h3 class="help-request-details-title">
            Help request details
          </h3>
        </el-col>
        <el-col :lg="12">
          <el-row class="help-request-details">
            <el-col :lg="12">
              <p class="sub-title">
                Subject
              </p>
              <p class="sub-attribute">
                {{ helpRequest.subject }}
              </p>
            </el-col>
            <el-col
              v-if="!helpRequest.trader.roles.includes('staff')"
              :lg="12"
            >
              <p class="sub-title business-sub-title">
                Business
              </p>
              <p class="sub-attribute">
                {{ helpRequest.business.name }}
              </p>
            </el-col>
            <el-col
              v-if="helpRequest.trader.roles.includes('staff')"
              :lg="12"
            >
              <p class="sub-title business-sub-title">
                Business
              </p>
              <p class="sub-attribute">
                {{ helpRequest.team_scotland_business }}
              </p>
            </el-col>
            <el-col :lg="24">
              <p class="sub-title mt-4">
                Request Description
              </p>
              <p class="sub-attribute purpose">
                {{ helpRequest.purpose }}
              </p>
            </el-col>
          </el-row>
        </el-col>
        <el-col
          :lg="12"
          class="show-more-section"
        >
          <p class="sub-title sector-sub-title">
            Sector:
          </p>
          <ul>
            <li>
              <div
                v-for="(sector, index) in helpRequest.groups.data"
                :key="index"
                class="sector-pill sector"
              >
                {{ sector.name }}
              </div>
            </li>
          </ul>
          <p
            v-if="!isGlobalScot && !isStaff"
            class="sub-title mt-4"
          >
            Location:
          </p>
          <list-show-more
            v-if="!isGlobalScot && !isStaff"
            :items="regions"
          >
            <template slot-scope="{ item }">
              <span>{{ item.slug }}</span>
            </template>
          </list-show-more>
          <p class="sub-title mt-4">
            Required expertise:
          </p>
          <list-show-more :items="expertises">
            <template slot-scope="{ item }">
              <span>{{ item.name }}</span>
            </template>
          </list-show-more>
        </el-col>
      </el-row>

      <hr class="mt-5 mb-5" />

      <Trap>
        <el-row>
          <el-col :lg="12">
            <validation-errors
              v-if="validationErrors.length"
              :errors="validationErrors"
            ></validation-errors>

            <h3
              v-if="!decline && !accept"
              class="can-you-help"
            >
              {{ 'Can you help ' + helpRequest.trader.first_name + '?' }}
            </h3>
            <h3
              v-if="decline && !accept"
              class="can-you-help"
            >
              {{ 'Unsure if you can help ' + helpRequest.trader.first_name + '?' }}
            </h3>
            <h3
              v-if="!decline && accept"
              class="can-you-help"
            >
              Start the conversation
            </h3>
            <p
              v-if="decline && !accept"
              class="can-you-help-description"
            >
              {{ 'Chatting with ' + helpRequest.trader.first_name +' will allow you to get more detail and you can decide to close the request if you can’t help' }}
            </p>
            <p
              v-if="!decline && !accept"
              class="can-you-help-description"
            >
              If you choose to respond, you can ask more questions about the request or start offering support. If it turns out you can’t offer the support needed, you can always request to close the engagement.
            </p>
            <p
              v-if="!decline && accept"
              class="can-you-help-description"
            >
              Please leave a short message to start the conversation between yourself and ({{ helpRequest.trader.full_name }}).
            </p>

            <div
              v-if="!decline && !accept"
              class="d-flex mt-4 mb-5 buttons-container"
            >
              <el-form
                id="acceptForm"
                ref="acceptForm"
                :model="acceptForm"
                :rules="acceptForm.rules"
                @submit.native.prevent
                @validate="handleAcceptFormFieldInput"
              >
                <label for="terms_agreed">
                  <span
                    class="sr-only"
                    aria-live="polite"
                    :hidden="! hasInlineValidationErrorFor('terms')"
                    v-text="getInlineValidationErrorFor('terms')"
                  ></span>
                </label>
                <el-form-item prop="terms">
                  <checkbox
                    id="terms_agreed"
                    v-model="acceptForm.terms"
                    :html="termsLabel"
                    name="terms"
                    @input="confirmTerms"
                  />
                </el-form-item>
              </el-form>
            </div>

            <div
              v-if="!decline && !accept"
              class="d-flex mt-4 mb-5 buttons-container"
            >
              <el-button
                class="decline-btn"
                @click="decline = true"
              >
                Decline request
              </el-button>

              <el-button
                type="primary"
                class="accept-btn"
                @click="acceptRequest()"
              >
                Accept request and start chat
              </el-button>
            </div>

            <!-- Accept -->
            <div v-if="accept">
              <el-form
                id="acceptForm"
                ref="acceptForm"
                :model="acceptForm"
                :rules="acceptForm.rules"
                @submit.native.prevent
                @validate="handleAcceptFormFieldInput"
              >
                <el-form-item prop="message">
                  <template slot="label">
                    <span>Remember not to include and sensitive or confidential information.</span>
                    <span
                      class="sr-only"
                      aria-live="polite"
                      :hidden="! hasInlineValidationErrorFor('message')"
                      v-text="getInlineValidationErrorFor('message')"
                    ></span>
                  </template>

                  <el-input
                    v-model="acceptForm.message"
                    placeholder
                    type="textarea"
                    :rows="5"
                    maxlength="1500"
                    aria-required="true"
                  ></el-input>
                </el-form-item>

                <div class="col-24 mt-n4 mb-4">
                  <span
                    class="se-body-l-screen color-light-gray"
                  >You have {{ 1500 - acceptForm.message.length }} characters remaining</span>
                </div>

                <el-button
                  class="ml-auto submit-btn"
                  type="primary"
                  @click="submitRequestAccept()"
                >
                  Submit
                </el-button>
              </el-form>
              <a
                href="#"
                class="change-response-link"
                @click="accept = false"
              >Change response</a>
            </div>

            <!-- Decline -->
            <div v-if="decline">
              <el-form
                id="reasonForm"
                ref="reasonForm"
                :model="reasonForm"
                @submit.native.prevent
              >
                <div class="d-flex cant-help-container">
                  <el-form-item
                    prop="edit_sub_group_ids"
                    class="flex-grow-1 flex-shrink-0 mr-3 w-100 select-form-item"
                  >
                    <template slot="label">
                      <span aria-hidden="true">Let us know why you can't help with this request </span>
                      <span class="sr-only">Please select why you can't help {{ helpRequest.trader.first_name }} with this request</span>
                    </template>

                    <v-select
                      v-model="reasonForm.reason"
                      :value="reasonForm.reason"
                      :options="declineReasons"
                      :clearable="false"
                      autocomplete="false"
                      :reduce="reasonOption => reasonOption"
                      :select-on-tab="true"
                      :close-on-select="true"
                    />
                  </el-form-item>
                </div>

                <el-form-item
                  v-if="reasonForm.reason === 'Other'"
                  class="d-block"
                  label="Please add details (Optional)"
                >
                  <el-input
                    v-if="reasonForm.reason === 'Other'"
                    v-model="reasonForm.otherReason"
                  />
                </el-form-item>
                <el-button
                  class="ml-auto submit-btn"
                  type="primary"
                  @click="declineRequest()"
                >
                  Submit
                </el-button>
              </el-form>
              <a
                href="#"
                class="change-response-link"
                @click="decline = false"
              >Change response</a>
            </div>
          </el-col>
        </el-row>
      </Trap>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import gsApi from "@/services/gs.api";
import MapPinGradient from "@/components/assets/svg-map-pin-gradient";
import ListShowMore from "@/components/partials/list-show-more";
import validationErrors from "@/components/partials/validation-errors";
import UserAvatar from "@/components/core/user-avatar";
import helpers from "@/helpers/index";
import SvgGsProfileicon from '@/components/assets/svg-gs-profile-icon';
import SvgMemberProfileIcon from '@/components/assets/svg-member-profile-icon'
import SvgStaffProfileIcon from "@/components/assets/svg-staff-profile-icon";
import SvgVerticalDivider from "@/components/assets/svg-vertical-divider";
import Trap from 'vue-focus-lock';
import inlineValidationErrorsMixin from '@/mixins/inlineValidationErrorsMixin'
export default {
  name: "HelpRequestComponent",
  components: {
    "svg-map-pin-gradient": MapPinGradient,
    "list-show-more": ListShowMore,
    "svg-staff-profile-icon": SvgStaffProfileIcon,
    'svg-member-profile-icon': SvgMemberProfileIcon,
    'svg-gs-profile-icon': SvgGsProfileicon,
    'svg-vertical-divider': SvgVerticalDivider,
    'Trap' : Trap,
    validationErrors,
    UserAvatar,

  },
  mixins: [inlineValidationErrorsMixin],
  props: {
    helpRequest: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      helpers,
      validationErrors: [],
      reasonForm: {
        reason: null,
        otherReason: ""
      },
      termsLabel: '<span>I’ve read and accept the </span><a href="/terms-conditions" class="text__link" target="_blank">Terms and conditions <span class="sr-only">this link will open in a new window</span></a>',
      decline: false,
      accept: false,
      acceptForm: {
        terms: [],
        message: '',
        rules: {
          terms: [
            {
              type: "array",
              required: true,
              message: "Please confirm you agree with our terms",
              trigger: "change"
            }
          ],
          message: [
            {
              required: true,
              message: "Please enter your message",
              trigger: "blur"
            },
            {
              max: 1500,
              message: "Message should not be longer than 1500 characters",
              trigger: "change"
            },
          ]
        }
      },
      mainLoader: false
    };
  },
  computed: {
    declineReasons() {
      return this.helpRequest.decline_reasons;
    },
    regions() {
      let regions = this.helpRequest.trader.traderProfile
        ? this.helpRequest.trader.traderProfile.regions.data
        : [];
      return regions;
    },
    expertises() {
      let expertises = this.helpRequest.expertise.data;
      return expertises;
    },
    primaryBusiness() {
      let primary = this.helpRequest.trader.businesses.data.find(b => (b.is_primary))
      return primary ? primary : this.helpRequest.trader.businesses.data[0]
    },
    isGlobalScot() {
      return this.helpRequest.trader.expertProfile && this.helpRequest.trader.roles.includes("expert")
    },
    isStaff() {
      return this.helpRequest.trader.staffProfile && this.helpRequest.trader.roles.includes("staff")
    },
    location() {
      if (this.isGlobalScot || this.isStaff) {
        let profileKey = this.isGlobalScot ? 'expertProfile' : 'staffProfile';

        if (this.helpRequest.trader[profileKey].state && this.helpRequest.trader[profileKey].country) {
          return this.helpRequest.trader[profileKey].state.name + ', ' + this.helpRequest.trader[profileKey].country.name
        } else if (this.helpRequest.trader[profileKey].country) {
          return this.helpRequest.trader[profileKey].country.name
        } else if (this.helpRequest.trader[profileKey].state) {
          return this.helpRequest.trader[profileKey].state.name
        }

        return ''
      } else {
        let area = this.primaryBusiness.address.county
          ? this.primaryBusiness.address.county
          : this.primaryBusiness.address.town;
        return area + ", " + this.primaryBusiness.address.country.name;
      }
    },
    memberFor() {
      let memberFor = moment(this.helpRequest.trader.created_at.date).fromNow(
        true
      );
      return memberFor;
    }
  },
  created() {
    this.reasonForm.reason = this.declineReasons[0];
  },
  methods: {
    confirmTerms(value) {
      this.acceptForm.terms = value
    },
    acceptRequest() {
      this.$refs["acceptForm"].validate(valid => {
        if (valid) {
          this.accept = true
        }
      })
    },
    submitRequestAccept() {
      this.$refs["acceptForm"].validate(valid => {
        if (valid) {
          this.mainLoader = true;

          gsApi.globalscot.requestHelpAccept({ help_request_id: this.helpRequest.id, message: this.acceptForm.message })
            .then(response => {
              this.mainLoader = false;
              this.validationErrors = [];

              this.onDialogClose();
              if (this.$router.currentRoute.name == "My Chats") {
                this.$events.emit("refreshChatList", {});
              } else {
                this.$router.push({ name: "My Chats" });
              }
            })
            .catch(error => {
              this.validationErrors = [];
              this.mainLoader = false;

              if (error.response && error.response.status == 422 && error.response.data) {
                let errors = error.response.data.errors;
                for (let [key, value] of Object.entries(errors)) {
                  let obj = {};
                  obj.id = key;
                  obj.message = value[0];
                  this.validationErrors.push(obj);
                }
                window.scrollTo({ top: 0, behavior: "smooth" });
              } else if (error.response && error.response.status == 403 && error.response.data) {
                this.onDialogClose();
                this.$alert(
                  error.response.data.error.message,
                  "Error",
                  {
                    confirmButtonText: "Close",
                    showClose: false,
                    customClass: "notification-modal"
                  }
                );
              } else {
                this.$alert(
                  "There are errors in the form, please fix these errors and try again.",
                  "Error",
                  {
                    confirmButtonText: "Close",
                    showClose: false,
                    customClass: "notification-modal"
                  }
                );
              }
            });
        } else {
          this.validationErrors = [];
          this.$refs.acceptForm.fields.forEach(field => {
            if (field.validateState == "error") {
              let obj = {};
              obj.id = field.labelFor;
              obj.message = field.validateMessage;
              this.validationErrors.push(obj);
            }
          });
          this.$nextTick(() => {
            document
              .getElementById("formErrorBlock")
              .scrollIntoView({ behavior: "smooth", block: "start" });
            let text = document
              .getElementById("acceptForm")
              .getElementsByClassName("el-form-item__error")[0].textContent;
            this.$announcer.set(text);
          });
        }
      });
    },
    declineRequest() {
      this.mainLoader = true;
      gsApi.globalscot.requestHelpDecline({
        help_request_id: this.helpRequest.id,
        decline_reason:
            this.reasonForm.reason === "Other"
              ? "Other - " + this.reasonForm.otherReason
              : this.reasonForm.reason
      })
        .then(response => {
          this.mainLoader = false;

          this.onDialogClose();
          this.$emit("on-decline", this.helpRequest.trader.first_name);

        //MAKE SURE NOTIFICATION IS DELETED!
        })
        .catch(error => {
          this.mainLoader = false;

          if (error.response && error.response.status == 403 && error.response.data) {
            this.onDialogClose();
            this.$alert(
              error.response.data.error.message,
              "Error",
              {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal"
              }
            );
          } else {
            this.$alert(
              "Something went wrong, please try again later.",
              "Error",
              {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal"
              }
            );
          }
        });
    },
    onDialogClose() {
      //-- always reset the Accdeptance form :
      this.resetAcceptForm();
      this.$emit("clicked");
    },
    startDeclineRequest(){
      this.resetAcceptForm();
      this.canHelp = false
    },
    resetAcceptForm(){
      this.validationErrors= [];
      if(this.$refs.acceptForm){
        this.$refs.acceptForm.resetFields();
      }
      if(this.$refs.reasonForm)
      {
        this.$refs.reasonForm.resetFields();
      }
    },
    handleAcceptFormFieldInput() {
      this.handleSingleFormFieldInput('acceptForm');
    }
  }
};
</script>
<style lang="scss">


.user-info .title-name p, .help-request-details-title {
  font-weight: 500 ;
  font-size: $font-size-30;
  line-height: $line-height-42;
  color:black;
}

</style>
