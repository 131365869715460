<template>
  <svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
    <path
      d="M39.77 8.88979C39.77 10.4273 39.3008 11.9302 38.4218 13.2086C37.5427 14.4869 36.2933 15.4834 34.8315 16.0717C33.3697 16.6601 31.7611 16.8141 30.2093 16.5142C28.6575 16.2142 27.232 15.4738 26.1132 14.3867C24.9944 13.2995 24.2324 11.9143 23.9237 10.4064C23.6151 8.89848 23.7735 7.33535 24.379 5.91492C24.9845 4.49448 26.0099 3.28061 27.3255 2.42644C28.6411 1.57227 30.1878 1.11621 31.77 1.11621C33.8918 1.11621 35.9266 1.93532 37.4269 3.39315C38.9272 4.85098 39.77 6.82811 39.77 8.88979Z"
      stroke="#A8308C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M44.569 28.3743H18.969C18.969 25.0756 20.3176 21.912 22.718 19.5794C25.1185 17.2469 28.3742 15.9365 31.769 15.9365C35.1638 15.9365 38.4195 17.2469 40.82 19.5794C43.2204 21.912 44.569 25.0756 44.569 28.3743V28.3743Z"
      stroke="#A8308C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.2241 53.6355C14.4096 53.6355 16.9921 51.1261 16.9921 48.0307C16.9921 44.9353 14.4096 42.426 11.2241 42.426C8.03848 42.426 5.45605 44.9353 5.45605 48.0307C5.45605 51.1261 8.03848 53.6355 11.2241 53.6355Z"
      stroke="#A8308C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.447 63.1162H1C1 60.4815 2.07711 57.9548 3.99439 56.0918C5.91167 54.2288 8.51206 53.1821 11.2235 53.1821C13.9349 53.1821 16.5353 54.2288 18.4526 56.0918C20.3699 57.9548 21.447 60.4815 21.447 63.1162V63.1162Z"
      stroke="#A8308C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M52.091 53.6355C55.2766 53.6355 57.859 51.1261 57.859 48.0307C57.859 44.9353 55.2766 42.426 52.091 42.426C48.9054 42.426 46.323 44.9353 46.323 48.0307C46.323 51.1261 48.9054 53.6355 52.091 53.6355Z"
      stroke="#A8308C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M62.314 63.1162H41.868C41.868 60.4815 42.9452 57.9548 44.8624 56.0918C46.7797 54.2288 49.3801 53.1821 52.0915 53.1821C54.803 53.1821 57.4034 54.2288 59.3206 56.0918C61.2379 57.9548 62.315 60.4815 62.315 63.1162H62.314Z"
      stroke="#A8308C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M52.091 53.6355C55.2766 53.6355 57.859 51.1261 57.859 48.0307C57.859 44.9353 55.2766 42.426 52.091 42.426C48.9054 42.426 46.323 44.9353 46.323 48.0307C46.323 51.1261 48.9054 53.6355 52.091 53.6355Z"
      stroke="#A8308C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M62.314 63.1162H41.868C41.868 60.4815 42.9452 57.9548 44.8624 56.0918C46.7797 54.2288 49.3801 53.1821 52.0915 53.1821C54.803 53.1821 57.4034 54.2288 59.3206 56.0918C61.2379 57.9548 62.315 60.4815 62.315 63.1162H62.314Z"
      stroke="#A8308C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M31.7699 53.6355C34.9555 53.6355 37.5379 51.1261 37.5379 48.0307C37.5379 44.9353 34.9555 42.426 31.7699 42.426C28.5844 42.426 26.002 44.9353 26.002 48.0307C26.002 51.1261 28.5844 53.6355 31.7699 53.6355Z"
      stroke="#A8308C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M41.992 63.1163H21.548C21.548 60.4819 22.6249 57.9555 24.5419 56.0928C26.4589 54.23 29.0589 53.1836 31.77 53.1836C34.481 53.1836 37.081 54.23 38.998 56.0928C40.915 57.9555 41.992 60.4819 41.992 63.1163V63.1163Z"
      stroke="#A8308C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.3411 36.1293V33.3853H52.209V36.1293"
      stroke="#A8308C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M31.771 27.8904V36.2392"
      stroke="#A8308C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template><script>
export default {};
</script>
