<template>
  <focus-lock>
    <el-dialog
      ref="mediaDialog"
      custom-class="cropper-dialog"
      :close-on-click-modal="false"
      :before-close="onCropperDialogClose"
      :show-close="false"
      :visible.sync="visible"
      @opened="dialogOpen('mediaDialog')"
    >
      <el-row>
        <el-col
          :sm="14"
          :md="16"
          :lg="18"
          class="text-center mb-2"
        >
          <vue-cropper
            v-if="cropperFile"
            ref="cropper"
            :src="cropperFile.url"
            alt="Source Image"
            :aspect-ratio="1"
            preview=".preview"
          >
          </vue-cropper>
        </el-col>
        <el-col
          :sm="10"
          :md="8"
          :lg="6"
          class="text-center"
        >
          <section
            class="preview-area"
            aria-label="Image Preview"
          >
            <p><b>Preview</b></p>
            <div class="preview-outer">
              <div class="preview"></div>
            </div>
          </section>
        </el-col>
      </el-row>
      <span
        slot="footer"
        class="dialog-footer d-flex justify-content-end"
      >
        <el-button
          type="secondary"
          @click="onCropperDialogClose"
        >Cancel</el-button>
        <el-button
          v-loading.lock="fileUploading"
          type="primary"
          element-loading-background="rgba(255, 255, 255, 0.8)"
          :disabled="fileUploading"
          @click="upload"
        >Upload</el-button>
      </span>
    </el-dialog>
  </focus-lock>
</template>

<script>
import VueCropper from 'vue-cropperjs'

export default {
  components: {
    VueCropper,
  },
  props: {
    cropperFile: {
      type: Object,
      required: true
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fileUploading: false
    };
  },
  methods: {
    onCropperDialogClose() {
      this.$emit("close");
    },
    upload() {
      this.fileUploading = true

      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        this.$emit("upload", {'file': blob, 'url': this.$refs.cropper.getCroppedCanvas().toDataURL()})
      })
    }
  }
};
</script>

<style lang="scss">
.preview-outer {
  height: 140px;
  width: 140px;
  line-height: $line-height-140;
  border-radius: 50%;
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
  color: #fff;
  background: #C0C4CC;
  font-size: $font-size-14;
  overflow: hidden;
}

.preview-area p {
  font-size: $font-size-18;
  margin: 0;
  margin-bottom: $font-size-base;
}

.preview {
  width: 140px;
  height: 140px;
  object-fit: cover;
  display: block;
  vertical-align: middle;
}
</style>
