import Vue from "vue";
import Router from "vue-router";
import ACLMiddleware from "@/middleware/acl";

//import components
import Index from "../pages/index";
import Home from "../pages/landing/home";
import About from "../pages/landing/about";
import Membership from "../pages/landing/membership";
//import Become from '../pages/landing/becoming-a-globals'
import ScotlandHouse from "../pages/landing/scotland-house-brussels";
import Contact from "../pages/landing/contact";
import TermsConditions from "../pages/landing/terms-conditions";
import SiteMap from "../pages/landing/site-map";
import Accessibility from "../pages/landing/accessibility";
import PrivacyPolicy from "../pages/landing/privacy-policy";
import CookiePolicy from "../pages/landing/cookie-policy";
import Feed from "../pages/platform/feed";
import Members from "../pages/platform/members";
//import Projects from '../pages/landing/projects'
//import Project from '../pages/platform/projects/project'
import NewsAndFeatures from "../pages/platform/news-and-features";
import EventsAndWebinars from "../pages/platform/events-and-webinars";
import Events from "../pages/platform/events-webinars/events";
import EventsPublic from "../pages/platform/events-webinars/public-events";
import ArticlesPublic from "../pages/platform/news-features/public-articles";
import News from "../pages/platform/news-features/news";
import Alerts from "../pages/platform/news-features/alerts";
import VerifyEmail from "../pages/landing/verify-email";
import EditProfile from "../pages/platform/edit-profile/edit-profile";
import PersonalDetails from "../pages/platform/edit-profile/personal-details";
import Settings from "../pages/platform/settings/settings";
import AccountDetails from "../pages/platform/settings/account-details";
//import AccountPassword from '../pages/platform/settings/account-password'
//import Companies from '../pages/platform/edit-profile/companies'
//import PlansAmbitions from '../pages/platform/edit-profile/plans-ambitions'
import ExperienceExpertise from "../pages/platform/edit-profile/experience-expertise";
import Followers from "../pages/platform/edit-profile/followers";
import Following from "../pages/platform/edit-profile/following";
import GsProfile from "../pages/platform/gs-profile";
//import NgsProfile from '../pages/platform/ngs-profile'
//import StaffProfile from '../pages/platform/staff-profile'
//import Chats from '../pages/platform/chats/chats'
import CaseStudiesPublic from "../pages/landing/case-studies-public";
import BusinessEventsWebinars from "../pages/landing/events-webinars-public";
import OrganisationProfile from "../pages/platform/organisation-profile";

// Updated UIs
import Login from "../pages/landing/login";
import ForgotPassword from "../pages/landing/forgot-password";
import ResetPassword from "../pages/auth/reset-password";
import SignupSelect from "../pages/landing/signup-select";
//import SignupNgs from "../pages/landing/signup-ngs";
import SignupGs from "../pages/landing/signup-gs";
//import SignupStaff from "../pages/landing/signup-staff";
import SignupGsComplete from "../pages/landing/signup-gs-complete";
//import SignupNgsComplete from "../pages/landing/signup-ngs-complete";
//import SignupStaffComplete from "../pages/landing/signup-staff-complete";
import SignupVerify from "../pages/landing/signup-verify";
import PageNotFound from "../pages/error/page-not-found";
import ActivateNewAddressEmail from "../pages/landing/activate-new-address-email";
import Unsubscribe from "../pages/unsubscribe";
// Pulse
import Pulse from "../pages/landing/pulse";

Vue.use(Router);

const router = new Router({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      component: Index,
      //redirect: "home",
      children: [
        {
          path: "/",
          component: Home,
          name: "Home",
          meta: {
            title: "Home page",
            gtm: "Scotland Europa: Networking Scotland in Europe",
          },
        },
        {
          path: "about",
          component: About,
          name: "About",
          meta: {
            gtm: "About our organisation",
            title: "About our organisation",
          },
        },
        {
          path: "news-resources",
          component: CaseStudiesPublic,
          name: "News and Resources Public",
          meta: { gtm: "Case studies", title: "News and Resources Public" },
        },
        {
          path: "business-events-webinars",
          component: BusinessEventsWebinars,
          name: "Business Events Webinars",
          meta: {
            gtm: "Business events and webinars",
            title: "Business Events Webinars",
          },
        },
        {
          path: "business-events-webinars/:slug",
          component: EventsPublic,
          name: "Public Events",
          meta: { gtm: "Business events and webinars", title: "Public Events" },
        },
        {
          path: "news-resources/:slug",
          component: ArticlesPublic,
          name: "Public Articles",
          meta: { gtm: "Case studies", title: "Public Articles" },
        },
        {
          path: "contact-us",
          component: Contact,
          name: "Contact Us",
          meta: { gtm: "Contact us", title: "Contact Us" },
        },
        {
          path: "accessibility",
          component: Accessibility,
          name: "Accessibility",
          meta: { title: "Accessibility" },
        },
        {
          path: "site-map",
          component: SiteMap,
          name: "Site Map",
          meta: { title: "Site Map" },
        },
        {
          path: "terms-conditions",
          component: TermsConditions,
          name: "Terms & Conditions",
          meta: { title: "Terms & Conditions" },
        },
        {
          path: "privacy-policy",
          component: PrivacyPolicy,
          name: "Privacy Policy",
          meta: { title: "Privacy Policy" },
        },
        {
          path: "cookie-policy",
          component: CookiePolicy,
          name: "Cookie Policy",
          meta: { title: "Cookie Policy" },
        },
        {
          path: "login",
          component: Login,
          name: "Login",
          meta: {
            gtm: "Sign in to your Scotland Europa account",
            title: "Login Page",
          },
        },
        {
          path: "sign-up-select",
          component: SignupSelect,
          name: "Signup Select Type",
          meta: {
            gtm: "Join the Scotland Europa network",
            title: "Join Scotland Europa",
          },
        },
        /*{
          path: "sign-up-ngs",
          component: SignupNgs,
          name: "Signup NGS",
          meta: { gtm: "Join as a business member" }
        },
        {
          path: "sign-up-ngs/complete",
          component: SignupNgsComplete,
          name: "Signup NGS Complete"
        },*/
        {
          path: "sign-up",
          component: SignupGs,
          name: "Signup SE",
          meta: { gtm: "Create an account", title: "Create an account" },
        },
        {
          path: "sign-up/complete",
          component: SignupGsComplete,
          name: "Signup SE Complete",
          meta: { title: "Sign up complete" },
        },
        /*{
          path: "staff-sign-up",
          component: SignupStaff,
          name: "Signup Staff"
        },
        {
          path: "staff-sign-up/complete",
          component: SignupStaffComplete,
          name: "Signup Staff Complete"
        },*/
        {
          path: "sign-up-verify",
          component: SignupVerify,
          name: "Verify Email Address",
          meta: { title: "Verify Email Address" },
        },
        {
          path: "feed",
          component: Feed,
          name: "Feed",
          props: true,
          meta: { title: "Feed" },
        },
        {
          path: "community-feed",
          component: Pulse,
          name: "Pulse",
          meta: { title: "Community Feed" },
        },
        {
          path: "members",
          component: Members,
          name: "Members",
          props: true,
          meta: { title: "Search for Members" },
        },
        /*{
          path: "projects",
          component: Projects,
          name: "Projects",
          meta: { title: "Projects | Scotland Europa" },
          props: true
        },
        {
          path: "projects/:id",
          component: Project,
          name: "Project",
          meta: { title: "Project | Scotland Europa" },
          props: true
        },*/
        {
          path: "news",
          component: NewsAndFeatures,
          name: "News and Features",
          props: true,
          meta: { title: "News" },
        },
        {
          path: "news/:slug",
          component: News,
          name: "News",
          props: true,
          meta: { title: "News Details" },
        },
        {
          path: "alerts/:id",
          component: Alerts,
          name: "Alerts",
          props: true,
          meta: { title: "Alert Details" },
        },
        {
          path: "events",
          component: EventsAndWebinars,
          name: "Events and Webinars",
          props: true,
          meta: { title: "Events and Webinars" },
        },
        {
          path: "events/:slug",
          component: Events,
          name: "Events",
          props: true,
          meta: { title: "Events Details" },
        },
        {
          path: "/edit-profile",
          component: EditProfile,
          name: "Edit Profile",
          redirect: "/edit-profile/personal-details",
          children: [
            {
              path: "/edit-profile/personal-details",
              component: PersonalDetails,
              name: "Personal Details",
            },
            /*{
              path: "/edit-profile/companies",
              component: Companies,
              name: "Companies"
            },
            {
              path: "/edit-profile/plans-ambitions",
              component: PlansAmbitions,
              name: "Plans and Ambitions"
            },*/
            {
              path: "/edit-profile/experience-expertise",
              component: ExperienceExpertise,
              name: "Experience and Expertise",
              meta: { title: "Interests and alerts" },
            },
            {
              path: "/edit-profile/following",
              component: Following,
              name: "Following",
              meta: { title: "Following List" },
            },
            {
              path: "/edit-profile/followers",
              component: Followers,
              name: "Followers",
              meta: { title: "Followers List" },
            },
          ],
        },
        {
          path: "/settings",
          component: Settings,
          name: "Settings",
          redirect: "/settings/account-details",
          children: [
            {
              path: "/settings/account-details",
              component: AccountDetails,
              name: "Account Details",
              meta: { title: "Account Details" },
            },
            /*{
              path: "/settings/account-password",
              component: AccountPassword,
              name: "Update Password"
            }*/
          ],
        },
        {
          path: "/verify-email",
          component: VerifyEmail,
          name: "Verify Email",
          meta: { title: "Verify Email" },
          props: true,
        },
        {
          path: "/forgot-password",
          component: ForgotPassword,
          name: "Forgot Password",
          meta: { title: "Forgot Password" },
        },
        {
          path: "/auth/reset/:id/:code",
          component: ResetPassword,
          name: "Reset Password",
          meta: { title: "Reset Password" },
        },
        {
          path: "/profile/:id",
          name: "SE Profile",
          component: GsProfile,
          meta: { title: "Profile Details" },
        },
        /*{
          path: "/ngs/profile/:id",
          name: "NonGlobalScot Profile",
          component: NgsProfile
        },
        {
          path: "/staff/profile/:id",
          name: "Staff Profile",
          component: StaffProfile
        },
        {
          path: "/chats",
          name: "My Chats",
          component: Chats
        },*/
        {
          path: "/membership",
          name: "Membership",
          component: Membership,
          meta: {
            gtm: "About Scotland Europa membership",
            title: "About Scotland Europa membership",
          },
        },
        /*{
          path: "/become-a-member",
          name: "Become Member",
          component: Become,
          meta: { gtm: "Becoming a Member of Scotland Europa", title:'Becoming a Member of Scotland Europa' }
        },*/
        {
          path: "/scotland-house-brussels",
          name: "Scotland House Brussels",
          component: ScotlandHouse,
          meta: {
            gtm: "Scotland House Brussels",
            title: "Scotland House Brussels",
          },
        },
        {
          path: "/activate-email",
          name: "Activate User Email",
          component: ActivateNewAddressEmail,
          meta: { gtm: "Email Activation", title: "Activate User Email" },
        },
        {
          path: "/organisation/:slug",
          name: "Organisation Profile",
          component: OrganisationProfile,
          meta: { gtm: "Organisation Profile", title: "Organisation Profile" },
        },
        {
          path: "unsubscribe",
          component: Unsubscribe,
          name: "Unsubscribe from email alert",
          meta: { title: "Unsubscribe from email alert" },
        },
      ],
    },
    {
      path: "/*",
      name: "NotFound",
      component: PageNotFound,
    },

    // Redirects
    {
      path: "/~/media/documents/globalscot-company-brochure-2015.ashx",
      redirect: "home",
    },
    {
      path: "/BecomingaGlobalScot/getting-involved.aspx",
      redirect: "home",
    },
    {
      path: "/becomingaglobalscot/getting-involved.aspx",
      redirect: "home",
    },
    {
      path: "/accessing-globalscot",
      redirect: "home",
    },
    {
      path: "/accessing-globalscot/:slug",
      redirect: "home",
    },
    {
      path: "/ArchiveandResources/PhilipCarmichael.aspx",
      redirect: "home",
    },
    {
      path: "/asia-conference",
      redirect: "home",
    },
    {
      path: "/company-register",
      redirect: "home",
    },
    {
      path: "/Confirm",
      redirect: "home",
    },
    {
      path: "/DesktopModules/Admin/RadEditorProvider/DialogHandler.aspx",
      redirect: "home",
    },
    {
      path: "/downloads/GlobalScot%20Company%20Brochure%202018.pdf",
      redirect: "home",
    },
    {
      path: "/GlobalScot",
      redirect: "home",
    },
    {
      path: "/globalscot/allanrooney",
      redirect: "home",
    },
    {
      path: "/globalscot/hughsutherland",
      redirect: "home",
    },
    {
      path: "/globalscot/markgoldschmidt",
      redirect: "home",
    },
    {
      path: "/GS_ScottishOrganisationForm",
      redirect: "home",
    },
    {
      path: "/GS_SearchGlobalScots.aspx",
      redirect: "home",
    },
    {
      path: "/h/6454189.html",
      redirect: "home",
    },
    {
      path: "/Home/FullForm/:slug",
      redirect: "home",
    },
    {
      path: "/Home/home.aspx",
      redirect: "home",
    },
    {
      path: "/home/index",
      redirect: "home",
    },
    {
      path: "/Home/NotApprovedToUseGlobalScotService?msg=none",
      redirect: "home",
    },
    {
      path: "/Home/QuestionsForm/:slug",
      redirect: "home",
    },
    {
      path: "/Home/ThankYou",
      redirect: "home",
    },
    {
      path: "/index.html",
      redirect: "home",
    },
    {
      path: "/market-plans",
      redirect: "home",
    },
    {
      path: "/members/",
      redirect: "home",
    },
    {
      path: "/more-about-becoming-a-globalscot",
      redirect: "home",
    },
    {
      path: "/public/index.aspx",
      redirect: "home",
    },
    {
      path: "/staff-register",
      redirect: "home",
    },
    {
      path: "/swagger",
      redirect: "home",
    },
    {
      path: "/search",
      redirect: "home",
    },
    {
      path: "/sitesearch",
      redirect: "home",
    },
    {
      path: "/about-globalscot",
      redirect: "about",
    },
    {
      path: "/AboutGlobalScot/BuildingAGlobalNetwork.aspx",
      redirect: "about",
    },
    {
      path: "/aboutglobalscot/buildingaglobalnetwork.aspx",
      redirect: "about",
    },
    {
      path: "/AboutGlobalScot/TheMembership.aspx",
      redirect: "membership",
    },
    {
      path: "/home/accessibility",
      redirect: "accessibility",
    },
    {
      path: "/home/casestudies",
      redirect: "news-resources",
    },
    {
      path: "/home/casestudy/:slug",
      redirect: "news-resources",
    },
    {
      path: "/home/codeofconduct",
      redirect: "code-of-conduct",
    },
    {
      path: "/contact-us",
      redirect: "contact-us",
    },
    {
      path: "/Contact-Us.aspx",
      redirect: "contact-us",
    },
    {
      path: "/home/Contact",
      redirect: "contact-us",
    },
    {
      path: "/home/cookiepolicy",
      redirect: "cookie-policy",
    },
    {
      path: "/home/register",
      redirect: "sign-up-select",
    },
    {
      path: "/register",
      redirect: "sign-up-select",
    },
    {
      path: "/register/:slug",
      redirect: "sign-up-select",
    },
    {
      path: "/registration/:slug",
      redirect: "sign-up-select",
    },
    {
      path: "/Account/:slug",
      redirect: "login",
    },
    {
      path: "/dashboard/:slug",
      redirect: "login",
    },
    {
      path: "/forgotten-password",
      redirect: "login",
    },
    {
      path: "/General/loggedoff.aspx",
      redirect: "login",
    },
    {
      path: "/GlobalScotList",
      redirect: "login",
    },
    {
      path: "/GlobalScotList/:slug",
      redirect: "login",
    },
    {
      path: "/GS_Login.aspx",
      redirect: "login",
    },
    {
      path: "/my-dashboard",
      redirect: "login",
    },
    {
      path: "/profiles/:slug",
      redirect: "login",
    },
    {
      path: "/RequestStatus/:slug",
      redirect: "login",
    },
    {
      path: "/home/PrivacyPolicy",
      redirect: "privacy-policy",
    },
    {
      path: "/PrivacyPolicy",
      redirect: "privacy-policy",
    },
    {
      path: "/sitemap",
      redirect: "site-map",
    },
    {
      path: "/disclaimer",
      redirect: "terms-conditions",
    },
    {
      path: "/home/TermsAndConditions",
      redirect: "terms-conditions",
    },
    {
      path: "/legal",
      redirect: "terms-conditions",
    },
    {
      path: "*",
      redirect: "NotFound",
    },
  ],
});

router.beforeEach(ACLMiddleware);

export default router;
