<!--Article Page-->
<template>
  <div>
    <top-navigation></top-navigation>
    <div
      v-loading.fullscreen.lock="mainLoader"
      element-loading-background="rgba(255, 255, 255, 0.8)"
      class="news-detail-page events-detail-page mb-4"
    >
      <div v-if="event">
        <events-header-public
          :image="event.mediaFile"
          :alt-text="altText"
        ></events-header-public>

        <div class="news-content content-container">
          <div class="container">
            <el-row>
              <el-col
                :md="24"
                :lg="16"
                class="left-content"
              >
                <h2
                  id="main-content"
                  class="news-title"
                >
                  {{ event.title }}
                </h2>

                <div class="d-flex mt-3">
                  <div class="type-article">
                    {{ event.type_name }}
                  </div>
                  <div class="added-on">
                    Added: {{ event.created_at }}
                  </div>
                </div>

                <el-button
                  v-if="event.type == 1 && event.category == 5"
                  type="primary"
                  class="url-button el-button read-more-btn"
                  @click="trackWebinarWatch"
                >
                  Watch webinar
                </el-button>

                <el-button
                  v-if="
                    event.type == 2 &&
                      event.podcastMediaFile &&
                      !listenNowClicked
                  "
                  type="primary"
                  class="url-button el-button read-more-btn"
                  @click="trackPodcastListen"
                >
                  Listen now
                </el-button>

                <div
                  v-if="
                    event.type == 2 &&
                      event.podcastMediaFile &&
                      listenNowClicked
                  "
                  class="mt-4"
                >
                  <audio
                    controls
                    autoplay
                    class="podcast-player"
                  >
                    <source :src="event.podcastMediaFile.path" />
                    Your browser does not support the audio element.
                  </audio>
                </div>

                <!-- Webinar only content -->
                <div
                  v-if="event.type == 1 && event.category == 4"
                  class="address-information"
                >
                  <el-row>
                    <el-col
                      :md="24"
                      :lg="24"
                    >
                      <div class="d-flex flex-column">
                        <div class="d-flex mt-4">
                          <svg-calendar></svg-calendar>
                          <p
                            v-if="event.start_date == event.end_date"
                            class="information-p"
                          >
                            {{ event.start_date }}
                          </p>
                          <p
                            v-else
                            class="information-p"
                          >
                            {{ event.start_date }} - {{ event.end_date }}
                          </p>
                        </div>
                        <div class="d-flex mt-3">
                          <svg-time></svg-time>
                          <p class="information-p">
                            {{ event.start_time }} - {{ event.end_time }}
                          </p>
                        </div>
                        <div class="d-flex mt-3">
                          <svg-ticket></svg-ticket>
                          <p class="information-p">
                            Free
                          </p>
                        </div>
                      </div>

                      <div
                        v-if="event.has_ended"
                        class="mt-5"
                      >
                        <div class="webianr-ended-section mt-5">
                          <p class="ended-text">
                            This event has ended.
                            <router-link
                              :to="{ name: 'Business Events Webinars' }"
                              class="ended-link"
                            >
                              Back to search
                            </router-link>
                          </p>
                        </div>
                      </div>

                      <div v-else>
                        <el-row>
                          <el-col :md="24">
                            <div class="webianr-ended-section mt-5">
                              <p class="ended-text">
                                To attend this webinar please login.
                                <router-link
                                  :to="{ name: 'Login' }"
                                  class="ended-link"
                                >
                                  Login
                                </router-link>
                              </p>
                            </div>
                          </el-col>
                        </el-row>
                      </div>
                    </el-col>
                  </el-row>
                </div>
                <!-- Webinar only content -->

                <div
                  v-if="event.type == 0"
                  class="address-information"
                >
                  <el-row>
                    <el-col
                      :md="24"
                      :lg="12"
                    >
                      <div class="d-flex mt-4">
                        <svg-map-pin></svg-map-pin>
                        <div class="address d-flex flex-column">
                          <p class="address-line-p">
                            {{ event.address_line_1 }}
                          </p>
                          <p
                            v-if="event.address_line_2"
                            class="address-line-p"
                          >
                            {{ event.address_line_2 }}
                          </p>
                          <p class="address-line-p">
                            {{ event.city }}
                          </p>
                          <p class="address-line-p">
                            {{ event.postcode }}
                          </p>
                        </div>
                      </div>
                    </el-col>
                    <el-col
                      :md="24"
                      :lg="12"
                    >
                      <div class="d-flex flex-column">
                        <div class="d-flex mt-4">
                          <svg-calendar></svg-calendar>
                          <p class="information-p">
                            {{ event.start_date }} - {{ event.end_date }}
                          </p>
                        </div>
                        <div class="d-flex mt-4">
                          <svg-time></svg-time>
                          <p class="information-p">
                            {{ event.start_time }} - {{ event.end_time }}
                          </p>
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                </div>

                <hr class="content-hr" />
                <p class="news-description">
                  {{ event.description }}
                </p>
                <div
                  class="news-content"
                  v-html="$options.filters.domPurify(content)"
                ></div>
                <div v-if="attachedFiles.length">
                  <document-view :attached-files="attachedFiles" />
                </div>
                <div
                  v-if="event.networkMembers.data.length > 0"
                  class="featured-podcast-section"
                >
                  <b>Featured in this podcast:</b>
                  <div
                    v-for="member in event.networkMembers.data"
                    :key="member.id"
                    class="member-card d-flex"
                  >
                    <user-avatar
                      shape="circle"
                      :is-global-scot="member.is_global_scot"
                      :author-exist="!!member"
                      width="40px"
                      :size="40"
                      :src="member.avatar"
                      :alt="member ? member.fullname : '' + 'Profile picture'"
                    ></user-avatar>
                    <p>{{ member.fullname }}</p>
                  </div>
                </div>
              </el-col>
              <el-col
                :md="24"
                :lg="8"
                role="complementary"
              >
                <h4 class="related-news-title mb-3">
                  Other events and webinars
                </h4>
                <el-row v-if="relatedItems.length > 0">
                  <el-col
                    v-for="relatedItem in relatedItems"
                    :key="relatedItem.id"
                    :sm="24"
                    :md="12"
                    :lg="24"
                  >
                    <events-card
                      :key="relatedItem.id"
                      :event="relatedItem"
                    />
                  </el-col>
                </el-row>
                <el-row v-else>
                  <el-col :md="24">
                    <p>No other events found.</p>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import gsApi from "@/services/gs.api";
import EventsHeaderPublic from "@/components/partials/events-header-public";
import Calendar from "@/components/assets/svg-news";
import MapPin from "@/components/assets/svg-map-pin";
import Time from "@/components/assets/svg-time";
import Ticket from "@/components/assets/svg-ticket";
import TopNavigation from "@/layout/top-navigation";
import UserAvatar from "@/components/core/user-avatar";
import DocumentView from "@/components/core/document-view";
import helpers from "@/helpers/index";
export default {
  name: "PublicEvents",
  metaInfo: {
    title: "Events and webinars",
    meta: [
      {
        name: "description",
        content:
          "Browse our listing of free events and webinars in Scotland and around the world.",
      },
    ],
  },
  components: {
    "events-header-public": EventsHeaderPublic,
    "svg-map-pin": MapPin,
    "svg-time": Time,
    "svg-ticket": Ticket,
    "svg-calendar": Calendar,
    TopNavigation,
    UserAvatar,
    DocumentView,
  },
  data() {
    return {
      event: null,
      mainLoader: true,
      listenNowClicked: false,
      relatedItems: [],
      attachedFiles:[]
    };
  },
  computed: {
    altText() {
      return this.event.mediaFile && this.event.mediaFile.alt_attribute
        ? this.event.mediaFile.alt_attribute
        : null;
    },
    content() {
      let cloneDiv = document.createElement("div");
      cloneDiv.innerHTML = this.event.content;

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.$set(this, "attachedFiles", helpers.getAttachedFiles(cloneDiv));

      return cloneDiv.innerHTML.replaceAll("&nbsp;", "");
    },
  },
  watch: {
    $route(to, from) {
      this.mainLoader = true;

      gsApi.events
        .getBySlugPublic(to.params.slug)
        .then((response) => {
          this.mainLoader = false;
          this.event = response.data;

          this.getRelatedItems();
        })
        .catch((error) => {
          this.$router.push({ name: "Public Events" });
        });
    },
  },
  created() {
    this.fetchEvent();
  },
  methods: {
    getRelatedItems() {
      let params = {
        item_id: this.event.id,
        type: "Modules\\Event\\Entities\\Event",
        entity_type: "event__events",
        take: 2,
      };

      gsApi.articles
        .getPublicRelatedItems(params)
        .then((response) => {
          this.relatedItems = response.data.data;
        })
        .catch((error) => {
          this.relatedItems = [];
        });
    },
    fetchEvent() {
      gsApi.events
        .getBySlugPublic(this.$route.params.slug)
        .then((response) => {
          this.mainLoader = false;
          this.event = response.data;

          this.getRelatedItems();
          this.$nextTick(() => {
            this.$refs.eventContent;
          });
        })
        .catch((error) => {
          this.mainLoader = false;
          this.$router.push({ name: "Public" });
        });
    },
    trackWebinarWatch() {
      this.mainLoader = true;

      gsApi.events
        .trackPublicWebinarWatch(this.$route.params.slug)
        .then((response) => {
          this.mainLoader = false;

          window.open(this.event.url, "_blank");
        })
        .catch((error) => {
          this.mainLoader = false;

          this.$alert(
            "Unable to retrieve webinar, please try again later.",
            "Error",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal",
            }
          );
        });
    },
    trackPodcastListen() {
      this.mainLoader = true;

      gsApi.events
        .trackPublicPodcastListen(this.$route.params.slug)
        .then((response) => {
          this.mainLoader = false;
          this.listenNowClicked = true;
        })
        .catch((error) => {
          this.mainLoader = false;

          this.$alert(
            "Unable to retrieve podcast, please try again later.",
            "Error",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal",
            }
          );
        });
    },
  },
};
</script>
