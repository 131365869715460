<!-- Navigation Bar Component -->
<template>
  <nav
    class="main-navigation-menu-bar hidden-md-and-down top-nav-bar"
    aria-label="main navigation"
    role="menubar"
  >
    <ul
      class="navbar p-navbar"
      role="menu"
    >
      <li role="presentation">
        <router-link
          :to="{ name: 'Home' }"
          role="menuitem"
        >
          Home
        </router-link>
      </li>
      <li
        id="sub-menu-container"
        role="presentation"
      >
        <a
          id="about-link"
          class="sub-nav-item"
          tabindex="0"
          role="button"
          :aria-haspopup="true"
          :aria-expanded="ariaExpended"
          :class="{'router-link-exact-active': isAboutLinkActive()}"
          @mouseover="ariaExpended = true"
          @mouseleave="ariaExpended = false"  
        >
          About us <svg-white-chevron-down class="ml-2"></svg-white-chevron-down>
        </a>
        <ul
          id="sub-nav"
          class="sub-nav"
        >
          <li role="presentation">
            <router-link
              id="membership-link"
              :to="{ name: 'Membership'}"
              class="sub-nav-item"
              role="menuitem"
            >
              Membership
            </router-link>
          </li>
          <li role="presentation">
            <router-link
              id="about-organisation-link"
              :to="{ name: 'About'}"
              class="sub-nav-item"
              role="menuitem"
            >
              About our organisation
            </router-link>
          </li>
          <li role="presentation">
            <router-link
              id="scotland-house-brussels-link"
              :to="{ name: 'Scotland House Brussels'}"
              class="sub-nav-item"
              role="menuitem"
            >
              Scotland House Brussels
            </router-link>
          </li>
        </ul>
      </li>
      <li role="presentation">
        <router-link
          :to="{ name: 'News and Resources Public' }"
          role="menuitem"
        >
          News & resources
        </router-link>
      </li>
      <li role="presentation">
        <router-link
          :to="{ name: 'Business Events Webinars' }"
          role="menuitem"
        >
          Events & webinars
        </router-link>
      </li>
      <li role="presentation">
        <router-link
          :to="{ name: 'Contact Us' }"
          role="menuitem"
        >
          Contact us
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mixin as onClickOutside } from 'vue-on-click-outside'
import RolePermissionMixin from '@/mixins/RolePermissionMixin'
import WhiteChevronDown from "@/components/assets/svg-white-chevron-down";

export default {
  components: {
    "svg-white-chevron-down": WhiteChevronDown,
  },
  mixins: [onClickOutside, RolePermissionMixin],
  data: function () {
    return {
      activeIndex: '1',
      activeIndex2: '1',
      isCollapsed: true,
      isCollapsedNot: false,
      isDropDown: false,
      request: {},
      requestModalOpen: false,
      ariaExpended : false,
    }
  },
  mounted() {
    document.addEventListener('keyup', (e) => {
      this.subMenuFocusCheck(e)
    }, false);
  },
  methods: {
    isAboutLinkActive() {
      return this.$route.path === '/membership' || this.$route.path === '/scotland-house-brussels' || this.$route.path === '/about'
    },
    handleSelect(key, keyPath) {
      this.isDropDown = false;
    },
    showNot() {
      this.isCollapsedNot = true;
      // document.getElementById("notSidenav").style.transform = "translate(0)";
    },
    closeNot() {
      if (this.isCollapsedNot === true) {
        this.isCollapsedNot = false;
        // document.getElementById("notSidenav").style.transform = "translate(100%)";
      }
    },
    subMenuFocusCheck(event) {

      let subNav = document.getElementById('sub-nav')
      var activeElementClass = document.activeElement.id
      
      if (subNav) {
        if ( (activeElementClass == 'about-link' && event.keyCode == 13) || (activeElementClass == 'membership-link' || activeElementClass == 'scotland-house-brussels-link' ||  activeElementClass == 'about-organisation-link')) {
          this.ariaExpended = true;
          subNav.style.display = 'block'
        } else {
          this.ariaExpended = false;
          subNav.style.display = 'none'
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.slide-enter-active {
  transition: all 0.3s ease;
}
.slide-leave-active {
  transition: all 0.3s ease;
}
.slide-enter, .slide-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translate(10px);
  opacity: 0;
}
</style>

