<template>
  <nav aria-label="accessibility navigation">
    <ul class="route-buttons">
      <li>
        <router-link to="/accessibility">
          Accessibility
        </router-link>
      </li>
      <li>
        <router-link to="/cookie-policy">
          Cookies
        </router-link>
      </li>
      <li>
        <router-link to="/privacy-policy">
          Privacy
        </router-link>
      </li>
      <li>
        <router-link to="/terms-conditions">
          Terms
        </router-link>
      </li>
      <li>
        <router-link to="/site-map">
          Sitemap
        </router-link>
      </li>
    </ul>
  </nav>  
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>
