<template>
  <div class="feed-page">
    <feed-components></feed-components>
  </div>
</template>

<script>
import feedComponents from "../../components/partials/feed-components"

export default {
  name: 'PageFeed',
  metaInfo: {
    title: "Dashboard"
  },
  components: {
    feedComponents
  },
}
</script>
