/** Global helper functions **/
import enumHelper from "@/helpers/enumHelper";
export default {
  randomNumber(min, max) {
    return Math.floor(Math.random() * (max - min) + min);
  },
  getFileType(mime_type) {
    switch (mime_type) {
    case enumHelper.AllowedTypes.IMG_JPEG:
    case enumHelper.AllowedTypes.IMG_JPG:
    case enumHelper.AllowedTypes.IMG_PNG:
      return "Image";
    case enumHelper.AllowedTypes.VIDEO_OGG:
    case enumHelper.AllowedTypes.VIDEO_MPEG:
    case enumHelper.AllowedTypes.VIDEO_WEBM:
    case enumHelper.AllowedTypes.VIDEO_MP4:
      return "Video";
    case enumHelper.AllowedTypes.APP_POWERPOINT:
    case enumHelper.AllowedTypes.APP_OFFICE_PRESENTATION:
    case enumHelper.AllowedTypes.APP_DOC_PRESENTATION:
      return "Power Point";
    case enumHelper.AllowedTypes.APP_WORD:
    case enumHelper.AllowedTypes.APP_DOC:
    case enumHelper.AllowedTypes.APP_TEXT:
      return "Document";
    case enumHelper.AllowedTypes.PDF:
      return "PDF";
    case enumHelper.AllowedTypes.APP_EXCEL:
      return "Excel";
    case enumHelper.AllowedTypes.APP_SHEET:
      return "Sheet";
    case enumHelper.AllowedTypes.APP_SPREADSHEET:
      return "SpreadSheet";
    default:
      return "Document";
    }
  },
  getFormatedFileSize(bytes, decimals) {
    if (bytes == 0) return "0 Bytes";
    var k = 1024,
      dm = decimals || 2,
      sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  },
  isExpert(user) {
    return (
      user && user.roles && user.roles.length && user.roles.includes("expert")
    );
  },
  isMember(user) {
    return user && user.roles.length && user.roles.includes("trader");
  },
  isStaff(user) {
    return user && user.roles.length && user.roles.includes("staff");
  },
  covertFileSize(size) {
    let sizes = ["Bytes", "KB", "MB", "GB", "TB"];

    if (size == 0) return "0 Byte";

    let i = parseInt(Math.floor(Math.log(size) / Math.log(1024)));

    return Math.round(size / Math.pow(1024, i), 2) + " " + sizes[i];
  },
  getFileIcon(fileNameOrExtension, isExtension = false) {
    let extension = isExtension
      ? fileNameOrExtension
      : this.getFileExtension(fileNameOrExtension);

    switch (extension) {
    case enumHelper.FilesExtension.DOC:
    case enumHelper.FilesExtension.DOCX:
      return require("../../static/img/extensions/doc-docx.svg");
    case enumHelper.FilesExtension.JPG:
    case enumHelper.FilesExtension.PNG:
    case enumHelper.FilesExtension.JPEG:
      return require("../../static/img/extensions/jpg-png.svg");
    case enumHelper.FilesExtension.MP4:
    case enumHelper.FilesExtension.WEBM:
    case enumHelper.FilesExtension.MPEG:
    case enumHelper.FilesExtension.OGG:
    case enumHelper.FilesExtension.OGV:
      return require("../../static/img/extensions/mp4-webm-ogv.svg");
    case enumHelper.FilesExtension.ODT:
      return require("../../static/img/extensions/odt.svg");
    case enumHelper.FilesExtension.ODP:
      return require("../../static/img/extensions/odp.svg");
    case enumHelper.FilesExtension.PDF:
      return require("../../static/img/extensions/pdf.svg");
    case enumHelper.FilesExtension.PPT:
      return require("../../static/img/extensions/ppt.svg");
    case enumHelper.FilesExtension.XLS:
    case enumHelper.FilesExtension.XLSX:
      return require("../../static/img/extensions/doc-docx.svg");
    default:
      return require("../../static/img/extensions/default.svg");
    }
  },
  getFileExtension(fileName) {
    return fileName.split(".").pop();
  },
  formatedAddress(address) {
    if (address) {
      let inlineAddress = `${address.address_1}, `;

      if (address.address_2.length) {
        inlineAddress += `${address.address_2}, `;
      }

      if (address.town.length) {
        inlineAddress += `${address.town}, `;
      }

      if (address.county.length) {
        inlineAddress += `${address.county}, `;
      }

      inlineAddress += `${address.post_code}, `;

      if (address.country) {
        inlineAddress += address.country.name;
      }
      return inlineAddress;
    }
    return "";
  },
  getAttachedFiles(contentDiv) {
    const tags = contentDiv.getElementsByTagName("a");

    let availableFiles = [];
    let elementsToRemove=[];
    for (let i = 0; i < tags.length; i++) {
      const fileName = tags[i].href.split("/").pop(); // get last item

      if (!fileName) continue;

      const extension = fileName.split(".").pop();
      if (
        ["csv", "doc", "docx", "pdf", "xls", "xlsx"].includes(
          extension.toLowerCase()
        )
      ) {
        availableFiles.push({
          path: tags[i].href,
          file_name:
              tags[i].innerText != tags[i].href ? tags[i].innerText : fileName,
          icon: this.getFileIcon(extension, true),
          extension: extension,
        });
        
        elementsToRemove.push(tags[i].parentNode)
      }
    }

    for (let i = 0; i < elementsToRemove.length; i++) {
      elementsToRemove[i].remove()
    }
    return availableFiles;
  },
};
