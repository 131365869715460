<template>
  <article>
    <el-col
      :sm="24"
      :md="12"
      :lg="8"
      :xl="8"
      class="news-card-col"
    >
      <el-card class="news-card box-card text-center">
        <div class="news-img">
          <el-image
            alt="alert backgroud"
            :src="require('../../../../static/img/se-email-alert-header.png')"
            fit="cover"
          ></el-image>
          <span
            v-if="alert.tag"
            class="news-type"
          >{{ alert.tag.name }}</span>
        </div>
        <div class="title-position">
          <h3 class="text-truncate">
            {{ alert.title }}
          </h3>
          <p class="text-truncate">
            {{ alert.content }}
          </p>
        </div>
        <div class="read-more-btn-container">
          <el-button
            type="primary"
            class="read-more-btn"
            @click="readMore(alert)"
          >
            Read more <span class="sr-only">about {{ alert.title }}</span>
          </el-button>
        </div>
      </el-card>
    </el-col>
  </article>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "AlertCard",
  components: {},
  props: {
    alert: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      articleTypes: {
        news: "News",
        spotlight: "Spotlight",
        feature: "Feature",
        event: "Event",
        casestudy: "Case Study"
      }
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    readMore(alert) {
      this.$router.push({name:"Alerts", params:{id:alert.id}})
    }
  }
};
</script>
