<template>
  <section class="footer-join" aria-label="Join Scotland Europa">
    <div class="container">
      <el-row>
        <el-col :xl="24" :lg="24" :md="24" :sm="24" :xs="24" class="head">
          <h1 class="text-white footer-text">{{footerText.heading}}</h1>
        </el-col>
        <el-col :xl="24" :lg="24" :md="24" :sm="24" :xs="24">
          <p class="content-std text-white footer-text join-sub-text join-hub-sub-text-first">{{footerText.sub}}</p>
          <p class="content-std text-white footer-text join-sub-text join-hub-sub-text-last">{{footerText.secondSub}}</p>
        </el-col>
        <el-button class="join-btn-bottom" type="primary" @click="$router.push({ name: 'Signup Select Type' })">Join Scotland Europa</el-button>
      </el-row>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      footerText: {
        heading: "Join Scotland Europa today",
        sub:
          "If your organisation is already a member, you can register with Scotland Europa today to get your own account to access our European services. You’ll be able to view upcoming events, read exclusive news stories and updates, network with other individual members and post in our community feed.",
        secondSub: " If your organisation isn’t a member yet, you can find out more about how to become a member."
      }
    };
  }
};
</script>
<style>
.join-hub-sub-text-first {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}
.footer-text {
  text-align: center;
  margin-top: 30px;
}
h1 {
  font-weight: 400;
}
.footer-text {
  padding-top: 10px;
  padding-bottom: 10px;
}
.join-btn-bottom {
  margin: auto;
}
.give-feedback p {
  color: white;
  margin-left: 10px;
  /* float: left; */
  text-decoration: underline;
}
.give-feedback {
  text-align: center;
  padding-top: 45px;
}
</style>
