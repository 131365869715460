import store from '../store'
import Vue from 'vue';
import gsApi from '@/services/gs.api'
const noAuthRoutes = [
  'Home',
  'Login',
  'Signup Select Type',
  'Signup NGS',
  'Signup SE',
  'Signup SE Complete',
  'Forgot Password',
  'Verify Email Address',
  'Home'
]

const authRoutes = [
  "Feed",
  "Members",
  "Pulse",
  "Events and Webinars",
  "Events",
  "News",
  "Alerts",
  "News and Features",
  "Projects",
  "Project",
  "SE Profile",
  "NonGlobalScot Profile",
  "Staff Profile",
  "Personal Details",
  "Edit Profile",
  "Companies",
  "Plans and Ambitions",
  "Experience and Expertise",
  "Settings",
  "Account Details",
  "Update Password",
  'Organisation Profile'
];

export default (to, from, next) => {

  let currentTimeZone = Vue.moment.tz.guess();
  store.dispatch('appAuthLoading')
    .then(() => {
      // && not a public access route
      if (store.state.user.id && !store.state.user.activated && to.name != 'Verify Email Address') {
        next({ name: 'Verify Email Address' })
      } else if ( !store.state.user.isLogged && !store.state.user.id
        && ['SE Profile', 'NonGlobalScot Profile', 'Staff Profile'].includes(to.name)) {
        next({ name: 'Home' })
      } else if ((!store.state.user.id || store.state.user.activated) && to.name == 'Verify Email Address') {
        next({ name: store.state.user.isLogged ? 'Feed' : 'Login' })
      } else if (store.state.user.isLogged && noAuthRoutes.includes(to.name)) {
        next({ name: 'Feed' })
      } else if (store.state.user.isLogged && to.name == 'Reset Password') {
        next()
      } 
      else if (!store.state.user.isLogged && authRoutes.includes(to.name)) {
        store.commit('UPDATE_REDIRECT_TO_URL', to.fullPath)
        next({ name: 'Login'})
      } 
      else if(store.state.user && store.state.user.isLogged && ['Events and Webinars', 'Events'].includes(to.name) && currentTimeZone!= '' &&  store.state.user.timezone != currentTimeZone ){
        let currentUser = JSON.parse(JSON.stringify(store.state.user));
        gsApi.users.updateTimeZone({timezone:currentTimeZone}).then(()=>{
          currentUser.timezone = currentTimeZone;
          store.commit('USER_LOGIN',currentUser);
          next();
        }).catch(()=>{
          next()
        })
      }
      else {
        next()
      }
    })


}
