<template>
  <focus-lock>
    <el-dialog
      ref="closedAccountDialog"
      :show-close="false"
      :visible.sync="dialogVisible"
      custom-class="closed-account-dialog"
      title="Reactivate account? It looks like this account has been temporarily closed. By
          reactivating, you’ll be able to access the network and be visible to
          other members again."
      @opened="dialogOpen('closedAccountDialog')"
      @close="onClose($event)"
    >
      <div class="body">
        <svg-danger-exclamation />
        <h1>Reactivate account?</h1>
        <p>
          It looks like this account has been temporarily closed. By
          reactivating, you’ll be able to access the network and be visible to
          other members again.
        </p>

        <el-form
          id="reactivateForm"
          ref="reactivateForm"
          :model="reactivateForm"
          :rules="rules"
          class="reactivateForm"
        >
          <el-form-item
            label="Re-enter password"
            prop="pass"
          >
            <el-input
              id="ca-password-input"
              ref="caPasswordInput"
              v-model="reactivateForm.password"
              type="password"
              auto-complete="off"
              placeholder="Your password"
              label="Please enter your password in this field"
              aria-required="true"
            />
          </el-form-item>

          <div class="button-wrap d-flex">
            <el-button @click="onClose($event)">
              Cancel
            </el-button>
            <el-button
              type="primary"
              native-type="submit"
              :loading="isLoading"
              @click.prevent="submit()"
            >
              Reactivate my account
            </el-button>
          </div>
        </el-form>
      </div>
    </el-dialog>
  </focus-lock>
</template>

<script>
import store from "@/store";
import gsApi from "@/services/gs.api";
import svgDangerExclamation from "@/components/assets/svg-danger-exclamation";

export default {

  components: {
    svgDangerExclamation
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },

  data() {
    let validatePassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Please input your password"));
      } else {
        callback();
      }
    };
    return {
      reactivateForm: {
        password: ""
      },
      rules: {
        password: [{ validator: validatePassword, required: true }]
      },
      isLoading: false,
      dialogVisible: false
    };
  },
  watch: {
    visible(value) {
      this.dialogVisible = this.visible;
    }
  },
  created() {
    this.dialogVisible = this.visible;
  },

  methods: {
    onClose(e) {
      if (store.state.app.loggedOutByClosedAccount) {
        store.commit("RESET_LOGGED_OUT_CLOSED_ACCOUNT");
      }

      this.dialogVisible = false;

      this.$emit("closed");
    },

    submit() {
      this.isLoading = true;

      this.$refs.reactivateForm.validate(valid => {
        if (valid) {
          gsApi.auth
            .reactivate(
              store.state.app.closedAccountEmailAddress,
              this.reactivateForm.password
            )
            .then(response => {
              this.validationErrors = [];
              if (response.data.activated === true) {
                store.commit("USER_LOGIN", response.data);
                if (store.state.redirectUrl) {
                  var redirectUrl = store.state.redirectUrl;
                  store.state.redirectUrl = null;
                  this.$router.push(redirectUrl);
                } else {
                  if (
                    !response.data.intro_viewed &&
                    response.data.imported_user
                  ) {
                    this.$router.push("/edit-profile/personal-details");
                  } else [this.$router.push("/feed")];
                }
              } else {
                store.commit("USER_LOGIN_UNVERIFIED", response.data);
                this.$router.push({ name: "Verify Email Address" });
              }
              this.isLoading = false;
            })
            .catch(error => {
              this.validationErrors = [];
              this.isLoading = false;

              if (
                error.response &&
                error.response.status == 422 &&
                error.response.data
              ) {
                let errors = error.response.data.errors;
                for (let [key, value] of Object.entries(errors)) {
                  let obj = {};
                  obj.id = key;
                  obj.message = value[0];
                  this.validationErrors.push(obj);
                }
                window.scrollTo({ top: 0, behavior: "smooth" });
              } else {
                const h = this.$createElement;
                //console.log(error.response.data.error.message);
                if (
                  error.response &&
                  error.response.status == 401 &&
                  error.response.data
                ) {
                  this.$alert(
                    h("p", null, [
                      h(
                        "a",
                        {
                          attrs: {
                            href: "forgot-password"
                          }
                        },
                        "Forgotten password?"
                      )
                    ]),
                    `Sorry, it looks like you've entered the wrong password. Please try again.`,
                    {
                      confirmButtonText: "Close",
                      showClose: false,
                      customClass: "notification-modal"
                    }
                  );
                } else {
                  this.$alert(
                    "",
                    `Unfortunately we're having problems contacting our service. Please try again later.`,
                    {
                      confirmButtonText: "Close",
                      showClose: false,
                      customClass: "notification-modal"
                    }
                  );
                }
              }
            });
        } else {
          this.isLoading = false;
          this.validationErrors = [];
          this.$refs.reactivateForm.fields.forEach(field => {
            if (field.validateState == "error") {
              let obj = {};
              obj.id = field.labelFor;
              obj.message = field.validateMessage;
              this.validationErrors.push(obj);
            }
          });
          this.$nextTick(() => {
            document
              .getElementById("formErrorBlock")
              .scrollIntoView({ behavior: "smooth", block: "start" });
            let text = document
              .getElementById("reactivateForm")
              .getElementsByClassName("el-form-item__error")[0].textContent;
            this.$announcer.set(text);
          });
        }
      });
    }
  }
};
</script>
