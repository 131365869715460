<!-- ACCOUNT DETAILS -->
<template>
  <gs-account-details></gs-account-details>
</template>

<script>
import GsAccountDetails from "@/components/platform/edit-profile/gs-account-details";
import { mapGetters } from "vuex";

export default {
  name: "account-details",
  components:{
    GsAccountDetails
  },
  metaInfo: {
    title: "Account details - Settings"
  },
  computed: {
    ...mapGetters(["isExpert"]),
  },
};
</script>

