<template>
  <div class="purple-top-section">
    <div class="purple-bg"></div>
    <div
      v-loading.fullscreen.lock="mainLoader"
      class="container"
      element-loading-background="rgba(255, 255, 255, 0.8)"
    >
      <el-row class="hello-container">
        <el-col
          :sm="24"
          :md="9"
          :lg="13"
          :xl="15"
        >
          <div id="main-content">
            <h1 class="hello-text">
              Hello {{ name }}
            </h1>
            <h2 class="question-hello-text mt-1">
              What would you like to do?
            </h2>
          </div>
        </el-col>
        <el-col
          :sm="24"
          :md="15"
          :lg="11"
          :xl="9"
        >
          <div class="hello-user-buttons">
            <!--             <el-button class="invite-btn mr-3" size="small">Invite Colleague</el-button>
            <el-button type="primary" style="margin:0px" size="small">View My Activity</el-button> -->
          </div>
        </el-col>
      </el-row>

      <div class="container-help-cards">
        <el-row
          :gutter="12"
          class="card-carousel"
        >
          <el-col
            v-for="(card, index) in boxCards"
            :key="index"
            :xs="24"
            :md="12"
            :lg="8"
            class="mb-3"
          >
            <el-card
              class="box-card help-card"
              shadow="always"
            >
              <div class="help-card-icon">
                <svg-globe-large v-if="index === 0"></svg-globe-large>
                <svg-chat-large
                  v-if="index === 1"
                  class="mt-2"
                ></svg-chat-large>
                <svg-events-large v-if="index === 2"></svg-events-large>
              </div>
              <div>
                <h3>{{ card.title }}</h3>
                <p class="sub-text">
                  {{ card.description }}
                </p>
              </div>
              <div class="help-btn">
                <el-button
                  class="btn-text"
                  size="medium"
                  @click.native="goTo(card.button.route)"
                >
                  {{ card.button.text }}
                </el-button>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
    <div>
      <new-members :organisations="organisations"></new-members>
    </div>

    <div>
      <events-and-webinars :events="events"></events-and-webinars>
    </div>
    <div>
      <news-and-events :articles="articles"></news-and-events>
    </div>
    <div>
      <recent-pulse-questions :posts="posts"></recent-pulse-questions>
    </div>
    <!--div v-if="!isNGS">
      <challenge-yourself :projects="projects"></challenge-yourself>
    </!--div-->
    <!--     <div>
        <get-in-touch></get-in-touch>
      </div>-->
  </div>
</template>
<script>
import { Carousel, Slide } from "vue-carousel";
import { mapState } from "vuex";
import store from "@/store";
import router from "@/router";
import newMembers from "./new-members.vue";
import recentPulseQuestions from "./recent-pulse-questions";
import newsAndEvents from "./news-and-events";
import challengeYourself from "./challenge-yourself";
import eventsAndWebinars from "./events-and-webinars";
import getInTouch from "./get-in-touch-gs";
import gsApi from "@/services/gs.api";
import { Loading } from "element-ui";
import GlobeLarge from "@/components/assets/svg-globe-large";
import EventsLarge from "@/components/assets/svg-events-large";
import ChatLarge from "@/components/assets/svg-chat-large";

export default {
  components: {
    Carousel,
    Slide,
    newMembers,
    recentPulseQuestions,
    newsAndEvents,
    challengeYourself,
    getInTouch,
    Loading,
    eventsAndWebinars,
    "svg-globe-large": GlobeLarge,
    "svg-events-large": EventsLarge,
    "svg-chat-large": ChatLarge
  },
  computed: {
    ...mapState(["user"]),
    isGlobalScot() {
      return this.user.expertProfile && this.user.roles.includes("expert");
    },
    isNGS() {
      return this.user.traderProfile && this.user.roles.includes("trader");
    }
  },
  data() {
    return {
      name: "",
      boxCards: [
        {
          title: "Sign up for alerts",
          description:
            "Tell us what you’re interested in, and we’ll make sure you’re alerted when we post a relevant update.",
          button: {
            route: "Experience and Expertise",
            text: "Sign up or update preferences"
          }
        },
        {
          title: "What's new across our community?",
          description:
            "Check the community feed for the latest posts and updates from Scotland Europa and our members.",
          button: {
            route: "Pulse",
            text: "Go to community feed"
          }
        },
        {
          title: "Get the latest news and alerts",
          description:
            "Funding updates, policy changes, latest EU announcements and more.",
          button: {
            route: "News",
            text: "See the latest news"
          }
        }
      ],
      articles: [],
      events: [],
      organisations: [],
      posts: [],
      projects: [],
      mainLoader: false
    };
  },
  created() {
    /*if (!store.state.user.isLogged) {
      router.push("/login");
    } else {
      this.name = store.state.user.firstName;
    }
    this.fetchDashboard();*/
    if (store.state.user.isLogged) {
      this.name = store.state.user.firstName;
      this.fetchDashboard();
    }
  },
  methods: {
    goTo(route) {
      router.push({ name: route });
    },
    fetchDashboard() {
      if (!this.mainLoader) {
        this.mainLoader = true;
      }
      gsApi.users
        .dashboard()
        .then(response => {
          this.articles = response.data.articles.data.slice(0, 3);
          this.events = response.data.events.data.slice(0, 3);
          this.organisations = response.data.organisations.data.slice(0, 4);
          this.posts = response.data.posts.data.slice(0, 3);
          this.projects = response.data.projects.data.slice(0, 3);
          this.mainLoader = false;
        })
        .catch(error => {
          this.mainLoader = false;
        });
    }
  }
};
</script>
