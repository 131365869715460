<!-- EDIT PROFILE -->

<template>
  <div class="edit-profile">
    <div class="container">
      <div class="edit-profile-header d-flex">
        <h1>Edit Your Profile</h1>
        <div class="d-flex">
          <span>Want to update account info?</span>
          <router-link to="/settings">
            Go to settings
          </router-link>
        </div>
      </div>
      <hr />
      <el-row>
        <el-col
          :md="6"
          class="profile-sections"
        >
          <h2>Profile Sections</h2>
          <ul class="section-tabs">
            <li>
              <router-link to="/edit-profile/personal-details">
                Personal details
                <svg-white-arrow-left class="arrow"></svg-white-arrow-left>
              </router-link>
            </li>

            <li>
              <router-link to="/edit-profile/experience-expertise">
                Interests and alerts
                <svg-white-arrow-left class="arrow"></svg-white-arrow-left>
              </router-link>
            </li>
            <li>
              <router-link to="/edit-profile/following">
                Following
                <svg-white-arrow-left class="arrow"></svg-white-arrow-left>
              </router-link>
            </li>
            <li>
              <router-link to="/edit-profile/followers">
                Followers
                <svg-white-arrow-left class="arrow"></svg-white-arrow-left>
              </router-link>
            </li>
          </ul>
        </el-col>
        <el-col
          :md="2"
          class="profile-divider"
        >
          <hr class="vertical-divider" />
        </el-col>
        <el-col
          :md="16"
          class="profile-forms"
        >
          <router-view></router-view>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import WhiteArrowLeft from "@/components/assets/svg-white-arrow-left";
export default {
  name: "EditProfilePage",
  components: {
    "svg-white-arrow-left": WhiteArrowLeft,
  },
  metaInfo: {
    title: "Edit profile",
  },
  computed:{
    ...mapGetters(["isExpert"]),
  },

}
</script>