<template>
  <el-row :gutter="1" class="mt-2 d-flex" v-loading.lock="disableReply">
    <el-col :xs="4" :md="2" :lg="2" :sm="3" v-if="user" :class="[{'d-none d-sm-block' : entity === 'comment' }]" class="text-right pr-3">
      <user-avatar avatarClasses="d-inline-block" shape="circle" :isGlobalScot="helpers.isExpert(user)" width="45px"
        :size="45" :src="user.avatar" :authorExist="!!user" :alt="`${user.fullName} Profile picture`"></user-avatar>
    </el-col>
    <el-col :xs="24" :sm="21" :md="22" :lg="22" class="d-flex d-flex flex-column">
      <el-row>
        <el-col :xs="24" :sm="editMode? 24 : 17" :md="18" >
         <el-input
            v-if="! allowMention"
            id="pulse-comment"
            ref="commentTextArea"
            :autosize="{ minRows: 1 }"
            class="textarea rounded w-100"
            type="textarea"
            v-model="comment"
            @keydown.ctrl.enter.exact.native="reply"
          >
          </el-input>

          <el-card class="comment-input" v-else
            @keydown.ctrl.enter.exact.native="reply"
          >
            <pulse-comment-editor
              :projectId="projectId"
              v-model="comment"
            />
          </el-card>

          <el-button
            v-if="editMode"
            type="text"
            size="mini"
            class="pull-right mr-3"
            @click="cancelEdit"
            >Cancel</el-button
          >
        </el-col>
        <el-col :xs="24" :sm="editMode ? 24 : 7" :md="6">
          <a
            class="gs-link clickable ml-2 pull-right bottom pl-1"
            :class="{ 'not-editable': !editMode, 'editable' : editMode }"
            @click="reply"
            tabindex="0"
            v-on:keyup.enter="reply"
            :disabled="disableReply"
          >
            Submit <svg-arrow-right class="ml-2"></svg-arrow-right>
          </a>
        </el-col>
      </el-row>
      <el-row id="errors" v-if="errors.comment.length">
        <el-col :span="24" class="text-left mt-2">
          <p
            :key="index"
            v-for="(error, index) in errors.comment"
            class="text-danger"
          >
            {{ error }}
          </p>
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>

<script>
import store from "@/store";
import gsApi from "@/services/gs.api";
import SvgArrowRight from "@/components/assets/svg-arrow-right";
import UserAvatar from "@/components/core/user-avatar";
import helpers from '@/helpers/index'
import PulseCommentEditor from "@/components/platform/pulse/pulse-comment-editor";
export default {
  name: "pulse-comment-form",
  props: {
    replyToId: {
      type: Number,
      required: false,
    },
    entity: {
      type: String,
      required: true,
    },
    module: {
      type: String,
      required: true
    },
    commentToEdit: {
      type: Object,
      required: false,
    },
    editMode: {
      type: Boolean,
      default: false
    },
    allowMention: {
      type: Boolean,
      default: true
    },
    projectId: {
      type: Number,
      default: null,
    }
  },
  components: {
    SvgArrowRight,
    UserAvatar,
    PulseCommentEditor,
  },
  data() {
    return {
      helpers,
      comment: "",
      hashtags: [],
      errors: {
        comment: []
      },
      disableReply: false,
      user: {}
    };
  },
  created() {
    this.user = store.state.user;
    this.comment = this.commentToEdit
      ? this.commentToEdit.comment.split("<br />").join("")
      : "";
  },
  methods: {
    reply() {
      //this.errors.comment.length = 0;
      //TODO: front end validation and error handling
      if (this.comment.length && !this.disableReply) {

        const regex = /data-hashtag="(?:|\s)#([a-zA-Z\d]+)"/g;
        const str = this.comment;
        const array = [...str.matchAll(regex)];
        const hashtags = array.map(i => `#${i[1]}`);
        this.hashtags = [...new Set(hashtags)];

        this.disableReply = true;
        if (this.editMode) {
          gsApi.comment
            .update(this.commentToEdit.id, {
              module: this.module,
              entity: this.entity,
              comment: this.comment,
              hashtags: this.hashtags,
              id: this.replyToId
            })
            .then(response => {
              this.$emit("commentUpdate", response.data);
              this.comment = "";
              this.disableReply = false;
              this.errors.comment=[];
            })
            .catch(error => {
              this.disableReply = false;
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
              } else if (error.request) {
                // console.log(error.request);
              } else {
                // console.log('Error', error.message);
              }
            });
        } else {
          gsApi.comment
            .create({
              module: this.module,
              entity: this.entity,
              comment: this.$options.filters.domPurify(this.comment, {ALLOWED_TAGS: []}),
              hashtags: this.hashtags,
              id: this.replyToId,
              comment_reply_id: this.entity === "comment" ? this.replyToId : ""
            })
            .then(response => {
              this.$emit("replied", response.data);
              this.comment = "";
              this.disableReply = false;
              this.errors.comment=[];
            })
            .catch(error => {
              this.disableReply = false;
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
              } else if (error.request) {
                // console.log(error.request);
              } else {
                // console.log('Error', error.message);
              }
            });
        }
      }
    },
    cancelEdit() {
      this.comment = "";
      this.hashtags = [];
      this.disableReply = false;
      this.$emit("commentCancel");
    },
    newline() {
      this.comment = `${this.comment}\n`;
    }
  }
};
</script>
