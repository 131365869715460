<!-- Index Component -->
<template>
  <div class="wrapper new-ui">
    <!-- <v-beta-bar v-if="showHeader" /> -->
    <v-l-header
      v-if="showHeader && ((landingNavigation && !user.isLogged) || (landingNavigation && !platformNavigation ))"
      @onOpenMobileMenuLanding="openMobileMenuLanding"
    ></v-l-header>
    <v-p-header
      v-if="showHeader && (platformNavigation && user.isLogged)"
      @onOpenMobileMenu="openMobileMenu"
    ></v-p-header>
    <v-notification-dialog v-if="notificationDialogOpen"></v-notification-dialog>
    <v-content></v-content>
    <v-join-hub v-if="showHeader && joinHub && !user.isLogged"></v-join-hub>
    <!-- <v-ready-to-join v-if="showHeader && readyToJoin && !user.isLogged"></v-ready-to-join> -->
    <v-reach-us v-if="showHeader && reachUs && !user.isLogged"></v-reach-us>
    <!-- <v-not-sure-globalscot v-if="showHeader && notSureGlobalscotCheck && !user.isLogged"></v-not-sure-globalscot> -->
    <v-get-in-touch v-if="isGetInTouchRoute"></v-get-in-touch>
    <v-footer v-if="showHeader && footerExists"></v-footer>TY
    <mobile-menu
      v-if="showHeader && (platformNavigation && user.isLogged) && mobileMenuOpen"
      @close="closeMobileMenu"
      @logout="logout"
    ></mobile-menu>
    <mobile-menu-landing
      v-if="showHeader && (!platformNavigation && !user.isLogged) && mobileMenuOpenLanding"
      @close="closeMobileMenuLanding"
      @logout="logout"
    ></mobile-menu-landing>
    <!-- <v-cookies v-if="showHeader && ((landingNavigation && !user.isLogged) || (landingNavigation && !platformNavigation ))"></v-cookies> -->
  </div>
</template>

<script>
import store from "../store";
import { mapState } from "vuex";
import gsApi from '@/services/gs.api'
import Footer from "../layout/footer";
import Content from "../layout/content";
import JoinHub from "../layout/join-hub";
import ReadyToJoin from "../layout/ready-to-join";
import ReachUs from "../layout/reach-us";
//import NotSureGlobalscot from "../layout/not-sure-globalscot";
import GetInTouch from "../layout/get-in-touch";
import PlatformHeader from "../layout/p-header";
import LandingHeader from "../layout/l-header-2";
import MobileMenu from "@/components/slide-panel/panels/mobile-menu";
import MobileMenuLanding from "@/components/slide-panel/panels/mobile-menu-landing"
import NotificationDialog from "@/components/partials/notification-dialog"

const activeLandingNavigationRoutes = [
  "Home",
  "Login",
  "Join",
  "Signup Select Type",
  "Expert Join",
  "ExpertSignup",
  "About Us",
  "About",
  "Membership",
  //"Become Member",
  "Scotland House Brussels",
  "Find Expert",
  "Article",
  "Projects",
  "Project",
  "Artcles and Events",
  "Faq",
  "How Points Work",
  "Verify Email",
  "Forgot Password",
  'Reset Password',
  "Terms & Conditions",
  "Site Map",
  "Accessibility",
  "Privacy Policy",
  "Cookie Policy",
  "Contact Us",
  "Landing Article",
  "Landing Event",
  "Expert Profile",
  "How Payment Works",
  "Verify Email Address",
  "Signup NGS",
  "Signup SE",
  "Signup Staff",
  "Signup SE Complete",
  "Signup NGS Complete",
  "Signup Staff Complete",
  'News and Resources Public',
  'Business Events Webinars',
  'Public Events',
  'Public Articles',
  'Activate User Email'
];

const activeFooterRoutes = [
  "Home",
  "Login",
  "Join",
  "Signup Select Type",
  "ExpertSignup",
  "Expert Join",
  "About Us",
  "About",
  //"Become Member",
  "Scotland House Brussels",
  "Membership",
  "Artcles and Events",
  "Faq",
  "How Points Work",
  "Verify Email",
  "Forgot Password",
  'Reset Password',
  "Terms & Conditions",
  "Site Map",
  "Accessibility",
  "Privacy Policy",
  "Cookie Policy",
  "Contact Us",
  "Landing Article",
  "Landing Event",
  "How Payment Works",
  "Verify Email Address",
  "Signup NGS",
  "Signup SE",
  "Signup Staff",
  "Signup SE Complete",
  "Signup NGS Complete",
  "Signup Staff Complete",
  'News and Resources Public',
  'Business Events Webinars',

  "Feed",
  "Pulse",
  "Projects",
  "Project",
  'Members',
  "Events and Webinars",
  "Events",
  "Public Events",
  "Public Articles",
  "News and Features",
  "News",
  "Alerts",
  "SE Profile",
  "NonGlobalScot Profile",
  "Edit Profile",
  "Personal Details",
  "Companies",
  "Plans and Ambitions",
  "Experience and Expertise",
  "Profile",
  "Account Details",
  "Update Password",
  'Activate User Email',
  "Following",
  "Followers",
  'Organisation Profile'
];

const activePlatformNavigation = [
  "Feed",
  "Pulse",
  "Members",
  "News and Features",
  "Events and Webinars",
  "News",
  "Alerts",
  "Events",
  "Public Events",
  "Public Articles",
  "Find Expert",
  "My Bookings",
  "Challenges",
  "Projects",
  "Project",
  "Challenges Details",
  "Expertise",
  "Account",
  "Edit Profile",
  "Settings",
  "Account Details",
  "Update Password",
  "Personal Details",
  "Companies",
  "Plans and Ambitions",
  "Experience and Expertise",
  "Profile",
  "Article",
  "Event",
  "Reward",
  "Expert Profile",
  "SE Profile",
  "NonGlobalScot Profile",
  "Staff Profile",
  "Message with User",
  "My Messages",
  "Expert New Article",
  "My Chats",
  "Following",
  "Followers",
  'Organisation Profile'
];

const activeGetinInTouchRoutes = [
  'Login',
  'Signup Select Type',
  'Forgot Password',
  'Reset Password',
  'Verify Email Address',
  'Signup NGS',
  'Signup SE',
  'Signup Staff',
  'Signup SE Complete',
  "Signup NGS Complete",
  "Signup Staff Complete",
  'Accessibility',
  'Cookie Policy',
  'Privacy Policy',
  'Terms & Conditions',
  'Site Map',



  "Feed",
  "Pulse",
  "Projects",
  "Project",
  'Members',
  "Events and Webinars",
  "Events",
  "News and Features",
  "News",
  "Alerts",
  "SE Profile",
  "NonGlobalScot Profile",
  "Edit Profile",
  "Personal Details",
  "Companies",
  "Plans and Ambitions",
  "Experience and Expertise",
  "Profile",
  "Account Details",
  "Update Password",
  'Activate User Email',
  "Following",
  "Followers",
  'Organisation Profile'

];

export default {
  components: {
    "v-l-header": LandingHeader,
    "v-p-header": PlatformHeader,
    "v-content": Content,
    "v-footer": Footer,
    "v-join-hub": JoinHub,
    "v-ready-to-join": ReadyToJoin,
    "v-reach-us": ReachUs,
    // "v-not-sure-globalscot": NotSureGlobalscot,
    "v-get-in-touch": GetInTouch,
    "mobile-menu": MobileMenu,
    "mobile-menu-landing": MobileMenuLanding,
    "v-notification-dialog": NotificationDialog
  },
  data: function() {
    return {
      mobileMenuOpen: false,
      mobileMenuOpenLanding: false
    };
  },
  computed: {
    ...mapState(['user']),
    isGetInTouchRoute() {
      return activeGetinInTouchRoutes.includes(this.$route.name);
    },
    platformNavigation () {
      return activePlatformNavigation.includes(this.$route.name);
    },
    landingNavigation () {
      return activeLandingNavigationRoutes.includes(this.$route.name);
    },
    footerExists () {
      return activeFooterRoutes.includes(this.$route.name);
    },
    joinHub () {
      return this.$route.path === '/home' || this.$route.path === "/contact-us" || this.$route.path === "/membership" || this.$route.path === "/about" || this.$route.path === '/articles-and-events' || this.$route.path === '/faq' || this.$route.name === 'Rewards' || this.$route.name === 'Business Events Webinars' || this.$route.name === 'Public Events' || this.$route.name === 'News and Resources Public' || this.$route.name === 'Public Articles';
    },
    readyToJoin() {
      return false;
    },
    reachUs() {
      return this.$route.path === "/scotland-house-brussels";  
    },
    /*notSureGlobalscotCheck() {
      return this.$route.path === "/become-a-member"
    },*/
    showHeader () {
      return true;
    },
    notificationDialogOpen() {
      let modals = store.state.modals;
      return modals.pulse.isOpen || modals.request.isOpen || modals.requestDeclined.isOpen || modals.project.isOpen;
    }
  },
  methods: {
    openMobileMenu() {
      this.mobileMenuOpen = true;
    },
    openMobileMenuLanding(){
      this.mobileMenuOpenLanding = true;
    },
    closeMobileMenu() {
      this.mobileMenuOpen = false;
    },
    closeMobileMenuLanding() {
      this.mobileMenuOpenLanding = false;
    },
    logout() {
      gsApi.auth.logout(this.user.token).then(()=>{
        store.commit("USER_LOGOUT");
        this.$nextTick(()=>{
          this.$router.push({name: "Home"});
        })
      })

    }
  }
};
</script>
