<template>
  <el-row :gutter="1" class="comment mt-4">
    <el-col :span="24">
      <pulse-report-form v-if="comment.commenter"
        :visible="submittingCommentReport"
        title="Report Post"
        module="Commentable"
        entity="Comment"
        :entityID="comment.id"
        @close="submittingCommentReport = false"
      ></pulse-report-form>
      <div class="d-flex">
        <el-popover
          popper-class="author-card-popover"
          placement="top-start"
          width="290"
          trigger="hover"
          @show="fetchUserData"
        >
          <div v-loading="loadingUser" class="author-card-popover-loader">
            <author-card v-if="!loadingUser"
              :member="commentAuthor"
            ></author-card>
          </div>
          <user-avatar slot="reference" avatarClasses="d-inline-block flex-shrink-0" shape="circle" :isGlobalScot="comment.commenter.is_global_scot" width="45px"
          :size="45" :src="comment.commenter ? comment.commenter.avatar : null" :authorExist="!!comment.commenter" :alt="`${comment.commenter ? comment.commenter.full_name : ''} Profile picture`"></user-avatar>
        </el-popover>
        <div class="content ml-2 flex-grow-1">
          <div class="bubble p-3 bg-light rounded">
            <h3 class="text-black fs-20 mb-3" v-if="comment.commenter">
                <el-popover
                  popper-class="author-card-popover"
                  placement="top-start"
                  width="290"
                  trigger="hover"
                  @show="fetchUserData"
                >
                  <div v-loading="loadingUser" class="author-card-popover-loader">
                    <author-card
                      v-if="commentAuthor"
                      :member="commentAuthor"
                    ></author-card>
                  </div>
                  <span slot="reference" class="clickable"><strong v-if="comment.commenter">{{ comment.commenter.full_name }}</strong></span>
                </el-popover>

              <el-dropdown
                class="el-dropdown-hover pull-right"
                :class="{ 'el-dropdown-grey-background': listActionShown }"
                @visible-change="getListActionStatus"
              >
                <span class="el-dropdown-link">
                  <span class="sr-only">{{
                    user.id == comment.commenter.id
                      ? "manage comment menu"
                      : "report comment menu"
                  }}</span>
                  <svg-three-dots></svg-three-dots>
                </span>
                <el-dropdown-menu
                  slot="dropdown"
                  :class="{ 'only-one-child': user.id != comment.commenter.id }"
                >
                  <el-dropdown-item
                    v-if="user.id != comment.commenter.id"
                    @click.native="reportComment()"
                    >Report</el-dropdown-item
                  >
                  <el-dropdown-item
                    v-if="user.id == comment.commenter.id"
                    @click.native="showCommentEditForm = true"
                    >Edit</el-dropdown-item
                  >
                  <el-dropdown-item
                    v-if="user.id == comment.commenter.id"
                    @click.native="showCommentDeleteConfirmDialog = true"
                    >Delete</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </h3>
            <dynamic-link v-if="!showCommentEditForm" :content="comment.comment"></dynamic-link>
            <pulse-comment-form
              v-else
              :module="'commentable'"
              :entity="'comment'"
              :editMode="true"
              :replyToId="comment.commenter ? comment.commenter.id : null"
              :commentToEdit="comment"
              @commentUpdate="onCommentUpdated"
              @commentCancel="onCommentCanceled"
            ></pulse-comment-form>
          </div>
           <!-- Likes -->
          <el-row v-if="comment.likes" class="pt-2 likeable">
            <el-col :span="12">
              <svg-likes-counter />
              <span class="likes-counter">{{`${comment.likes.likes} Like${comment.likes.likes > 1 ? "s" : ""}`}}</span>
            </el-col>
            <el-col :span="12" class="d-flex justify-content-end">
              <el-button
                v-if="!comment.likes.has_liked"
                :disabled="likeablePostLoad"
                v-loading="likeablePostLoad"
                size="small"
                class="clickable svg-like like"
                @click="likeablePost('like')"
                >
                  <svg-like />
                  <small>Like</small>
              </el-button>
              <el-button
                :disabled="likeablePostLoad"
                v-loading="likeablePostLoad"
                type="primary"
                size="small"
                v-else-if="comment.likes.has_liked"
                class="clickable svg-like unlike"
                @click="likeablePost('unlike')"
              >
                <svg-unlike />
                <small>Liked</small>
              </el-button>
            </el-col>
          </el-row>
          <!-- Timestamp and Reply -->
          <el-row :gutter="0" class="mt-1">
            <el-col class="mt-2" :span="12">
              <!-- content -->
              <span>{{ comment.created_at_ago }}</span>
            </el-col>
            <el-col class="mt-1 text-right" :span="12" v-if=" comment.commenter && !comment.parent">
              <a
                tabindex="0"
                class="gs-link clickable fs-19"
                v-if="!replying && !comment.commenter.suspended"
                @click="replying = true"
                v-on:keyup.enter="replying = true"
              >
                Reply <svg-arrow-right class="ml-2"></svg-arrow-right>
                <!-- <svg-arrow-right class="ml-2"></svg-arrow-right> -->
              </a>
              <a
                tabindex="0"
                class="clickable fs-19"
                v-if="replying"
                @click="replying = false"
                v-on:keyup.enter="replying = false"
                >&times;</a
              >
            </el-col>
          </el-row>
        </div>
      </div>
    </el-col>
    <el-col v-show="replying" :span="24" class="mt-3 pl-5">
      <pulse-comment-form
        @replied="emitReplied"
        :replyToId="comment.id"
        :module="'commentable'"
        :entity="'comment'"
        :editMode="false"
        :allow-mention="false"
      ></pulse-comment-form>
    </el-col>
    <el-col :span="24" class="pl-5">
      <pulse-comment
        v-for="(reply, index) in replies"
        :key="index"
        :comment="reply"
        :allow-mention="false"
        @commentDeleted="refreshComments('commentDeleted')"
        @commentUpdated="refreshComments('commentUpdated')"
      ></pulse-comment>
    </el-col>
    <focus-lock>
    <el-dialog ref="confirmDialog" @opened="dialogOpen('confirmDialog')"
      :show-close="false"
      custom-class="confirm-dialog"
      :visible.sync="showCommentDeleteConfirmDialog"
      title="Warning.Are you sure you want to delete this comment?"
    >
      <el-row>
        <el-col :span="24" class="text-center">
          <h1 class="mt-0">Warning</h1>
          <p class="fs-19">Are you sure you want to delete this comment?</p>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer d-flex justify-content-end">
        <el-button
          type="secondary"
          @click="showCommentDeleteConfirmDialog = false"
          >Cancel</el-button
        >
        <el-button
          v-loading.lock="blockToDelete"
          type="primary"
          element-loading-background="rgba(255, 255, 255, 0.8)"
          :disabled="blockToDelete"
          @click="deleteComment"
          >Yes, delete comment</el-button
        >
      </span>
    </el-dialog>
    </focus-lock>
  </el-row>
</template>
<script>
import PulseCommentForm from "@/components/platform/pulse/pulse-comment-form";
import SvgArrowRight from "@/components/assets/svg-arrow-right";
import SvgThreeDots from "@/components/assets/svg-three-dots";
import SvgLike from "@/components/assets/svg-like";
import SvgUnlike from "@/components/assets/svg-unlike";
import SvgLikesCounter from "@/components/assets/svg-likes-counter";
import PulseReportForm from "@/components/platform/pulse/pulse-report-form";
import { mapGetters } from "vuex";
import AuthorCard from "./author-card";
import gsApi from "@/services/gs.api";
import UserAvatar from "@/components/core/user-avatar"
import DynamicLink from './dynamic-link.vue';
export default {
  name: "pulse-comment",
  props: {
    comment: {
      type: Object,
      required: true
    },
    allowMention: {
      type: Boolean,
      default: true
    }
  },
  components: {
    PulseCommentForm,
    SvgArrowRight,
    SvgThreeDots,
    PulseReportForm,
    AuthorCard,
    UserAvatar,
    SvgLike,
    SvgUnlike,
    SvgLikesCounter,
    DynamicLink
  },
  data() {
    return {
      likeablePostLoad: false,
      replying: false,
      id: this.uid,
      listActionShown: false,
      submittingCommentReport: false,
      showCommentDeleteConfirmDialog: false,
      showCommentEditForm: false,
      blockToDelete: false,
      loadingUser: false,
      commentAuthor: null
    };
  },
  computed: {
    ...mapGetters(["user"]),
    replies() {
      if (this.comment.replies && this.comment.replies.data) {
        return this.comment.replies.data;
      }

      return [];
    }
  },
  methods: {
    emitReplied() {
      this.$emit("replied");
      this.replying = false;
    },
    getListActionStatus(value) {
      this.listActionShown = value;
    },
    reportComment() {
      this.submittingCommentReport = true;
    },
    onCommentUpdated(comment) {
      this.comment.comment = comment.comment;
      this.refreshComments("commentUpdated");
      this.showCommentEditForm = false;
    },
    onCommentCanceled() {
      this.showCommentEditForm = false;
    },
    deleteComment() {
      this.blockToDelete = true;

      gsApi.comment.delete(this.comment.id)
      .then(response => {
        this.$emit("commentDeleted");
        this.showCommentDeleteConfirmDialog = false;
        this.blockToDelete = false;
        this.$alert("You successfully deleted your comment", "Success", {
          confirmButtonText: "Close",
          showClose: false,
          customClass: "notification-modal"
        });
      })
      .catch(error => {
        this.blockToDelete = false;

        this.$alert(
          "An unknown error occurred. Please try again later.",
          "Error",
          {
            confirmButtonText: "Close",
            showClose: false,
            customClass: "notification-modal"
          }
        );
      });
    },
    refreshComments(command) {
      this.$emit(command);
    },
    fetchUserData() {
      if(!this.comment.commenter) return;
      if(this.commentAuthor) return;
      this.loadingUser = true;

      gsApi.users.entityAuthor(this.comment.commenter.id)
      .then(response => {
        this.commentAuthor = response.data;
      })
      .catch(exception => {})
      .finally(() => {
        this.loadingUser = false;
      });
    },

    likeablePost(action) {
      this.likeablePostLoad = true;
      let data = {
        module: "Commentable",
        entity: "Comment",
        id: this.comment.id,
      };
      gsApi.likeable[action](data)
      .then((response) => {
        this.comment.likes = response.data;
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status == 422 &&
          error.response.data
        ) {
          let firstError =
            error.response.data.errors[
              Object.keys(error.response.data.errors)[0]
            ];
          this.$alert(
            "Sorry, your request could not be processed",
            firstError[0],
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal",
            }
          );
        } else {
          this.$alert(
            "An unknown error occurred. Please try again later.",
            "Error",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal",
            }
          );
        }
      })
      .finally(() => {
        this.likeablePostLoad = false;
      })
    },
  }
};
</script>
