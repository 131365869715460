<template>
  <svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
    <path
      d="M8.99805 9.40942H38.322"
      stroke="#A8308C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.327 17.4954H8.99805V33.6686H22.327V17.4954Z"
      stroke="#A8308C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M30.324 22.8868H38.322"
      stroke="#A8308C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M27.658 28.2771H38.321"
      stroke="#A8308C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M27.658 33.6686H38.321"
      stroke="#A8308C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.99805 39.0599H38.322"
      stroke="#A8308C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.99805 44.4502H38.322"
      stroke="#A8308C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.99805 49.8417H38.322"
      stroke="#A8308C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M51.652 14.8008H56.984V55.2332C56.984 55.9481 56.7031 56.6338 56.2031 57.1393C55.7032 57.6449 55.0251 57.9289 54.318 57.9289C53.6109 57.9289 52.9329 57.6449 52.4329 57.1393C51.9329 56.6338 51.652 55.9481 51.652 55.2332V5.36584H46.3201V10.7572"
      stroke="#A8308C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M54.317 63.3192H9C6.87827 63.3192 4.84343 62.4669 3.34314 60.9499C1.84285 59.4329 1 57.3754 1 55.2301V1.32227H46.319V55.2332C46.319 57.3785 47.1619 59.436 48.6622 60.953C50.1625 62.47 52.1972 63.3223 54.319 63.3223C56.4407 63.3223 58.4755 62.47 59.9758 60.953C61.4761 59.436 62.319 57.3785 62.319 55.2332V9.41137H51.651"
      stroke="#A8308C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template><script>
export default {};
</script>
