<template>
  <div class="py-4 gray-section">
    <div class="container">
      <div class="heading-text mb-30 py-0 d-flex justify-content-between align-items-center">
        <h2>Meet our member organisations</h2>
        <div class="view-members-link view-page-link hidden-sm-and-down">
          <router-link :to="{ name: 'Members', query: { type: 'organisations' } }">
            Search members
            <svg-purple-arrow-right class="arrow"></svg-purple-arrow-right>
          </router-link>
        </div>
      </div>
      <el-row>
        <el-col
          v-for="(organisation, index) in organisations"
          :key="index"
          :sm="24"
          :md="12"
          :lg="8"
          :xl="6"
        >
          <organisation-card :organisation="organisation" />
        </el-col>
      </el-row>
      <div class="view-members-link view-page-link hidden-md-and-up mt-3">
        <router-link :to="{ name: 'Members' }">
          Search members
          <svg-purple-arrow-right class="arrow"></svg-purple-arrow-right>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import PurpleArrowRight from "@/components/assets/svg-purple-arrow-right";
import OrganisationCard from "@/components/platform/organisations/organisation-card";

export default {
  components: {
    "svg-purple-arrow-right": PurpleArrowRight,
    OrganisationCard,
  },
  props: {
    organisations: {
      type: Array,
      required: true,
    },
  },
};
</script>
<style>
</style>
